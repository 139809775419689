import React, {Component} from 'react';
import { Typography } from '@material-ui/core';
import './../../CSSs/Politics/one/one.css';
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs';
import GDHeader from '../GDHeader';

class PoliticsEditOne extends Component{
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        electionText: "CAST YOUR VOTES!",
        aspirantName: "JAMES BROWN",
        electionPost: "FOR PRESIDENT",
        voteText: "No.1 Vote",
        bColor: "#FFFFFF"
    }
    changeBackgroundImage = (e) => {
        e.preventDefault();
        try {        
            const file = e.target.files[0];
            if(!file)  return;
            let back = document.getElementById("mainInsideDiv01");
            if(back){
                back.style.background = `linear-gradient(0deg, rgba(97,99,2, 0.9), rgba(97,99,2, 0.9)), url(${URL.createObjectURL(file)})`;
                back.style.backgroundSize = `cover`;
                back.style.backgroundRepeat = `no-repeat`;
                back.style.backgroundPosition = `center`;
            }
        } catch (e) {
            return;
        }
    }
    changeBackground=(e)=>{
        e.preventDefault();
        try {
            this.setState({bColor: e.target.value});
            let back = document.getElementById("outSideDiv01");
            let electionText = document.getElementById("castVotesClass01");
            if(back){
                back.style.backgroundColor= `${this.state.bColor}`;
                electionText.style.color= `${this.state.bColor}`;
            } 
        } catch (e) {
            return;
        }
    }
    changeImage = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const slot = document.getElementById('imgSlot01');
        if(!slot) return;
        slot.src = URL.createObjectURL(file);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    handleFinishEdit = (e) => {
      e.preventDefault();
      this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
      e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('outSideDiv01'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
                var link = document.createElement("a");
                link.download = "medillery-template.jpeg";
                link.href = dataUrl;
                link.click();
            });          
    }

    downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('outSideDiv01'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }

    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }
    render() {
        return ( <div style={{marginTop: '90px'}}>           
            <GDHeader caty='politics' />
  
            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-6 col-xl-6 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* this is the outside div that encapsulate all the others */}
                                        <div className="outSideDiv01">
                                            {/* The main insdie sideDiv for margin */}
                                            <div className="mainInsideDiv01">
                                                {/* this is the header portion */}
                                                <div className="logoHeaderText01">
                                                    {/* the castVotes div class  */}
                                                    <div className="castVotesDiv01">
                                                        <h2 className="castVotesClass01" id="castVotesClass01">{this.state.electionText}</h2>
                                                    </div>
                                                </div>
                                                {/* The main information text */}
                                                <div className="mainDetailsText01">
                                                    {/* div for the flexed image */}
                                                    <div className="imagesDivFlex01">
                                                        <img id="imgSlot01"
                                                            src={require("../../../../assets/images/templates/heroes/3.jpeg")} alt="politics" className="imgFlexed01" />
                                                    </div>
                                                    {/* this is the rounded circle */}
                                                    <div className="circle01">
                                                        <div className="noVotes01">{this.state.voteText}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* The underneath details of name and date */}
                                            <div className="underneathDetails01">
                                                {/* voters div container   */}
                                                <div className="voterDetailsText01">
                                                    <h5 className="voteText01">VOTE</h5>
                                                    <h1>{this.state.aspirantName}</h1>
                                                    <p>-{this.state.electionPost}-</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-6 col-xl-6 mb-4">
                                {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                        <div className="mt-3 row">
                                            <div className="col-8 form-group">
                                                <label>Background Image</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="file" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeBackgroundImage}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-4 form-group">
                                                <label>Color</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="color" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeBackground}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Image</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="file" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeImage}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Vote No</label>
                                                <input id="voteText"
                                                    type="text" value={this.state.voteText} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Election Title</label>
                                                <input id="electionText"
                                                    type="text" value={this.state.electionText} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Office Post</label>
                                                <input id="electionPost"
                                                    type="text" value={this.state.electionPost} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="col-12 form-group">
                                                <label>Aspirant Name</label>
                                                <input
                                                    type="text" id="aspirantName" 
                                                    value={this.state.aspirantName} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            
                                            {/* <div className="col-4 form-group">
                                                <label>Election Date</label>
                                                <input id="electionDate"
                                                    type="date" value={this.state.electionDate} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div> */}
                                        </div>
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                            > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4 variant="subtitle2" className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select 
                                                className="form-control form-control-sm rounded-pill"
                                                onChange={this.downloadNow}
                                            >   
                                                <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                            className="btn btn-block rounded-pill customPrimaryBgColor" 
                                            onClick={ this.backToEdit }
                                            > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>                      
        </div>);
    }
}

export default PoliticsEditOne;
