// @ts-nocheck
import Echo from 'laravel-echo';
import { processHeader } from '../action';
import { PROXY } from '../constants/action_types';

export const EXTRACTOR=(prefix,string, suffix)=>{
    try{
        const extract = window.atob(string);
        const after = extract.slice(0, extract.indexOf(suffix))
        const exact = after.slice(prefix.length)
        return exact;

    }catch(e){
        return false;
    }
}

/** Used to for base 64 encryption of string
 * @param prefix any random prefix string to strengthen the encryption
 * @param string actual string to encode
 * @param suffix  any random suffx string to strengthen the encryption
 * 
 */
export const DEFLECTION=(prefix,string, suffix)=>{
    try{
        if(!string) return false;
        return window.btoa((prefix+string+suffix));
    }catch(e){
        return false;
    }
}

export const ESTIMATE_TIME=(SEC)=>{
    const sec = Math.abs(SEC);
    if(sec < 60) return  Math.floor(sec) +"sec";
    else if(sec >=60 && sec<120) return Math.floor(sec/60) +"min";
    else if(sec >=120 && sec <3600) return Math.floor(sec/60) +"mins";
    else if( sec >= 3600 && sec < 7200) return Math.floor(sec/3600) +"hr";
    else if(sec >= 7200 && sec < 86400) return  Math.floor(sec/3600) +"hrs";
    else if(sec >= 86400 && sec < 172800) return Math.floor(sec/86400) +"day";
    else if(sec >= 172800 && sec < 2592000) return  Math.floor(sec/86400) +"days";        
    else if(sec >= 259200&& sec < 5184000) return Math.floor(sec/2592000) +"month";    
    else if(sec >= 5184000&& sec < 31557600) return Math.floor(sec/2592000) +"months";    
    else if(sec >= 31557600 && sec < 63115200) return  Math.floor(sec/31557600 ) +"yr";    
    else if(sec >63115200) return Math.floor(sec/31557600 ) +"yrs";
}

export const CHAT_LIKE_TIME=(time)=>{
    if(!time) return '';
    if(!window.luxon) return '';
    const local = window.luxon.DateTime.local();
    const format = time.trim().replace(' ',"T")
    const ms = window.luxon.DateTime.fromISO(format, {zone: 'Africa/Accra'});
    const rezoned = ms.setZone(local.zoneName); 
    // const diff = Date.now()-Date.parse(rezoned);
    // let sec=((diff)/(1000));
    // sec = Math.abs(sec);
    const min= rezoned.minute.toString().length < 2 ? `0${rezoned.minute}`: `${rezoned.minute}`;
    const hour= rezoned.hour.toString().length < 2 ? `0${rezoned.hour}`: `${rezoned.hour}`;
    const day = rezoned.day;
    const monthName =window.luxon.Info.months('short')[rezoned.month-1]
    const today = new Date();
    if(today.getDate()=== day && today.getMonth() === (rezoned.month-1) && today.getFullYear() === rezoned.year) return   `${hour}:${min}`;
    else if(today.getDate()-day === 1 && today.getMonth() === (rezoned.month-1) && today.getFullYear() === rezoned.year) return `Yesterday ${hour}:${min}`;
    else return `${monthName} ${day}, ${rezoned.year}`;
}

export const scrollUp=(e)=>{
    const container = window.document.getElementById(e);
    if(!container) return;
    container.scrollIntoView(true);
}

export const LUX_ZONE=(time)=>{
    if(!time) return '';
    if(window.luxon){
        const local = window.luxon.DateTime.local();
        const format = time.trim().replace(' ',"T")
        const ms = window.luxon.DateTime.fromISO(format, {zone: 'Africa/Accra'});
        const rezoned = ms.setZone(local.zoneName);
        let dater = new Date(Date.parse(rezoned)).toString()
        dater = dater.slice(0,dater.indexOf('GMT'))
        return `${dater}`;
    }
    return '';
}

export const plainTimeZone=(time)=>{
    if(!time) return '';
    if(window.luxon){
        const local = window.luxon.DateTime.local();
        const format = time.trim().replace(' ',"T")
        const ms = window.luxon.DateTime.fromISO(format, {zone: 'Africa/Accra'});
        const rezoned = ms.setZone(local.zoneName);
        return new Date(Date.parse(rezoned));
    }
    return '';
}

export const staticPusherConfig=()=>{
    window.Pusher =  require('pusher-js');
    window.Echo =  new Echo({
        broadcaster: 'pusher',
        key: 'b7c25b6dbdf8417de444',
        cluster: 'eu',
        forceTLS: true,
        authEndpoint: `${PROXY}/broadcasting/auth`,
        auth:{headers:processHeader()}
    });
}

export const generateFiveRandomNumbers=()=>{
    let numbers ="";
    for (let index = 0; index < 5; index++) {			
        numbers += Math.floor(Math.random() * 10)
    }
    return numbers;
}

export const hideSide=(path)=>{
    const check='/projects/';
    return (path.includes(check) && !path.includes('/projects/issue/') && path.length> path.indexOf(check)+check.length);
}

export const ROUNDS =(value)=>{
    if(!value) return;
    try {
        let num = Number(value);
        if(isNaN(num)) return;
        if(num < 100) return `${num}`;
        else if(num > 99  && num < 1000) return `${Math.trunc(num)}+`;
        else if(num > 999  && num < 1000000) return `${Math.trunc(num/1000)}K+`;
        else if(num > 999999  && num < 1000000000) return `${Math.trunc(num/1000000)}M+`;     
    } catch (e) {
     return;
    }
}

/** Add a day to current time */
export const addDays=(day)=>{
    const event = new Date();
    event.setDate(event.getDate()+day);
    return new Date(event).toUTCString();
}

export const cookieValue=(check)=>{
    return document.cookie.split('; ')
        .find((row) => row.startsWith(`${check}=`))
        ?.split('=')[1];
}

export const userPicPath =  `${PROXY}/storage/profilePictures/`;
export const allocationFilePath =`${PROXY}/storage/allocationFiles`;
export const messageFilePath = `${PROXY}/storage/PrivateChatFiles/`;
export const staticStatusFilePath = `${PROXY}/storage/StatusFiles/`;
export const noImage = require("../../assets/images/noImage.png");


export const countries = [
    { country:" Afghanistan", code: "93" },
    { country:" Albania", code: "355" },
    { country:" Algeria", code: "213" },
    { country:" A.Samoa", code: "1-684"},
    { country:" Andorra", code: "376" },
    { country:" Angola", code: "244" },
    { country: "Anguilla", code: "1-264"},
    { country:" Antarctica", code: "672" },
    { country:"Antigua. B",code:"1-268"},
    { country:" Argentina", code:"54"},
    { country:" Armenia", code: "374" },
    { country:" Aruba", code: "297" },
    { country:" Australia", code: "61"},
    { country:" Austria", code: "43" },
    { country:" Azerbaijan", code: "994" },
    { country:"Bahamas", code: "1-242"},
    { country:" Bahrain", code: "973" },
    { country:" Bangladesh", code: "880" },
    { country:"Barbados", code: "1-246"},
    { country:" Belarus", code: "375" },
    { country:" Belgium", code: "32" },
    { country:" Belize", code: "501" },
    { country:" Benin", code: "229" },
    { country:"Bermuda", code: "1-441"},
    { country:" Bhutan", code: "975" },
    { country:" Bolivia", code: "591" },
    { country:" Bosnia. H", code: "387" },
    { country:" Botswana", code: "267" },
    { country:" Brazil", code: "55" },
    { country:" British. IOT", code: "246" },
    { country:" British. VI", code: "1-284"},
    { country:" Brunei", code: "673"},
    { country:"Bulgaria", code: "359"},
    { country:" Burkina. F", code: "226" },
    { country:" Burundi", code: "257" },
    { country:" Cambodia", code: "855" },
    { country:" Cameroon", code: "237" },
    { country:" Canada", code: "1"},
    { country:"Cape Verde"   ,code: "238"} , 
    { country:"Cayman. I", code: "1-345"},
    { country:" Central. AR", code: "236" },
    { country:" Chad", code: "235" },
    { country:" Chile", code: "56" },
    { country:" China", code: "86" },
    { country:" Christmas. I", code: "61"},
    { country:" Cocos. I", code: "61"},
    { country:" Colombia", code: "57"},
    { country:" Comoros", code: "269"},
    { country:" Cook. I", code: "682" },
    { country:" Costa. R", code: "506"},
    { country:" Croatia", code: "385"},
    { country:" Cuba", code: "53"},
    { country:" Curacao", code: "599" },
    { country:" Cyprus", code: "357" },
    { country:" Czech. R", code: "420"},
    { country:" DRC", code: "243" },
    { country:" Denmark", code: "45" },
    { country:" Djibouti", code: "253"},
    { country:" Dominica", code:"1-767"},
    { country:" Dominican", code: "1-80"},
    { country:" East. T", code: "670" },
    { country:" Ecuador", code: "593" },
    { country:" Egypt", code: "20" },
    { country:"El Salvador",code :503} , 
    { country:" Equatorial. G", code: "240" },
    { country:" Eritrea", code: "291" },
    { country:" Estonia", code: "372" },
    { country:" Ethiopia", code: "251" },
    { country:" Falkland. I", code: "500" },
    { country:" Faroe. I", code: "298" },
    { country:" Fiji", code: "679" },
    { country:" Finland", code: "358" },
    { country:" France", code: "33" },
    { country:" French. P", code: "689" },
    { country:" Gabon", code: "241" },
    { country:" Gambia", code: "220" },
    { country:" Georgia", code: "995" },
    { country:" Germany", code: "49" },
    { country:" Ghana", code: "233" },
    { country:" Gibraltar", code: "350" },
    { country:" Greece", code: "30" },
    { country:" Greenland", code: "299" },
    { country:"Grenada", code: "1-473"},
    { country:"Guam", code: "1-671"},
    { country:" Guatemala", code: "502" },
    { country:"Guernsey", code: "4-1481"},
    { country:" Guinea", code: "224" },
    { country:" Guinea- Bissau", code: "245" },
    { country:" Guyana", code: "592" },
    { country:" Haiti", code: "509" },
    { country:" Honduras", code: "504" },
    { country:" Hong Kong", code:"852"} , 
    { country:" Hungary", code: "36" },
    { country:" Iceland", code: "354" },
    { country:" India", code: "91" },
    { country:" Indonesia", code: "62" },
    { country:" Iran", code: "98" },
    { country:" Iraq", code: "964" },
    { country:" Ireland", code: "353" },
    { country:"Isle. M", code: "4-1624"},
    { country:" Israel", code: "972" },
    { country:" Italy", code: "39" },
    { country:" Ivory. C", code: "225" },
    { country:"Jamaica", code: "1-876"},
    { country:" Japan", code: "81" },
    { country:"Jersey", code: "4-1534"},
    { country:" Jordan", code: "962" },
    { country:" Kazakhstan", code: "7"},
    { country:" Kenya", code: "254" },
    { country:" Kiribati", code: "686" },
    { country:" Kosovo", code: "383" },
    { country:" Kuwait", code: "965" },
    { country:" Kyrgyzstan", code: "996" },
    { country:" Laos", code: "856" },
    { country:" Latvia", code: "371" },
    { country:" Lebanon", code: "961" },
    { country:" Lesotho", code: "266" },
    { country:" Liberia", code: "231" },
    { country:" Libya", code: "218" },
    { country:" Liechtenstein", code: "423" },
    { country:" Lithuania", code: "370" },
    { country:" Luxembourg", code: "352" },
    { country:" Macau", code: "853" },
    { country:" Macedonia", code: "389" },
    { country:" Madagascar", code: "261" },
    { country:" Malawi", code: "265" },
    { country:" Malaysia", code: "60" },
    { country:" Maldives", code: "960" },
    { country:" Mali", code: "223" },
    { country:" Malta", code: "356" },
    { country:" Marshall. I", code: "692" },
    { country:" Mauritania", code: "222" },
    { country:" Mauritius", code: "230" },
    { country:" Mayotte", code: "262" },
    { country:" Mexico", code: "52" },
    { country:" Micronesia", code: "691" },
    { country:" Moldova", code: "373" },
    { country:" Monaco", code: "377" },
    { country:" Mongolia", code: "976" },
    { country:" Montenegro", code: "382" },
    { country:"Montserrat", code: "1-664"},
    { country:" Morocco", code: "212" },
    { country:" Mozambique", code: "258" },
    { country:" Myanmar", code: "95" },
    { country:" Namibia", code: "264" },
    { country:" Nauru", code: "674" },
    { country:" Nepal", code: "977" },
    { country:" Netherlands", code: "31" },
    { country:" Netherlands. A" ,code: 599} , 
    { country:" New Caledonia" ,code :687} , 
    { country:" New Zealand"   ,code:64} , 
    { country:" Nicaragua", code: "505" },
    { country:" Niger", code: "227" },
    { country:" Nigeria", code: "234" },
    { country:" Niue", code: "683" },
    { country:" N. Korea",code: 850} , 
    { country:"Northern. MI", code: "1-670"},
    { country:" Norway", code: "47" },
    { country:" Oman", code: "968" },
    { country:" Pakistan", code: "92" },
    { country:" Palau", code: "680" },
    { country:" Palestine", code: "970" },
    { country:" Panama", code: "507" },
    { country:" Papua. NG", code: "675" },
    { country:" Paraguay", code: "595" },
    { country:" Peru", code: "51" },
    { country:" Philippines", code: "63" },
    { country:" Pitcairn", code: "64" },
    { country:" Poland", code: "48" },
    { country:" Portugal", code: "351" },
    { country:"Puerto. R", code: "1-78"},
    { country:" Qatar", code: "974" },
    { country:" Congo", code: "242" },
    { country:" Reunion", code: "262" },
    { country:" Romania", code: "40" },
    { country:" Russia", code: "7"},
    { country:" Rwanda", code: "250" },
    { country:" Saint. B", code: "590" },
    { country:" Saint. H", code: "290" },
    { country:"Saint. KN", code: "1-869"},
    { country:"Saint. L", code: "1-758"},
    { country:" Saint. M", code: "590" },
    { country:" Saint. PM", code: "508" },
    { country:"Saint. VG", code: "1-784"},
    { country:" Samoa", code: "685" },
    { country:" S. Marino", code: "378" },
    { country:" Sao. TP", code: "239" },
    { country:" Saudi. A", code: "966"},
    { country:" Senegal", code: "221" },
    { country:" Serbia", code: "381" },
    { country:" Seychelles", code: "248" },
    { country:" Sierra. L", code: "232" },
    { country:" Singapore", code: "65" },
    { country:"Sint. Ma", code: "1-721"},
    { country:" Slovakia", code: "421" },
    { country:" Slovenia", code: "386" },
    { country:" Solomon. I", code: "677" },
    { country:" Somalia", code: "252" },
    { country:" South. A", code: "27" },
    { country:" S. Korea", code: "82" },
    { country:" S. Sudan", code: "211" },
    { country:" Spain", code: "34" },
    { country:" Sri Lanka", code: "94" }, 
    { country:" Sudan", code: "249" },
    { country:" Suriname", code: "597" },
    { country:" Svalbard JM", code:47} , 
    { country:" Swaziland", code: "268" },
    { country:" Sweden", code: "46" },
    { country:" Switzerland", code: "41" },
    { country:" Syria", code: "963" },
    { country:" Taiwan", code: "886" },
    { country:" Tajikistan", code: "992" },
    { country:" Tanzania", code: "255" },
    { country:" Thailand", code: "66" },
    { country:" Togo", code: "228" },
    { country:" Tokelau", code: "690" },
    { country:" Tonga", code: "676" },
    { country:"Trinidad. T", code:"1-868"},
    { country:" Tunisia", code: "216" },
    { country:" Turkey", code: "90" },
    { country:" Turkmenistan", code: "993" },
    { country:" Turks. CI", code:"1-649"},
    { country:" Tuvalu", code: "688" },
    { country:" U.S. VI", code:"1-340"},
    { country:" Uganda", code: "256" },
    { country:" Ukraine", code: "380" },
    { country:" UAE", code: "971" },
    { country:" UK", code: "44" },
    { country:" USA", code: "1"},
    { country:" Uruguay", code: "598" },
    { country:" Uzbekistan", code: "998" },
    { country:" Vanuatu", code: "678" },
    { country:" Vatican", code: "379" },
    { country:" Venezuela", code: "58" },
    { country:" Vietnam", code: "84" },
    { country: "Wallis. F", code: "681" },
    { country: "Western. S", code: "212" },
    { country: "Yemen", code: "967" },
    { country: "Zambia", code: "260" },
    { country: "Zimbabwe", code: "263" },
];


