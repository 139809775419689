import React, { Component } from 'react';
import '../../CSSs/Birthday/one/one.css';
import { Typography } from '@material-ui/core';
import download from 'downloadjs';
import GDHeader from '../GDHeader';
import * as htmlToImage from 'html-to-image'
import CopyRight from '../../CopyRight'



class BirthdayEditOne extends Component {
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        happyBirthdayText: "Happy Birthday",
        inAdvance: "In Advance",
        patricia: "Patricia",
        fromName: "From Name",
    }

    changeBackground = (e) => {
        e.preventDefault();
        try {        
            const file = e.target.files[0];
            if(!file)  return;
            let back = document.getElementById("parentContainer1");
            if(back){
                back.style.background = `linear-gradient(0deg, rgba(227, 151, 5, 0.7), rgba(227, 151, 5, 0.7)), url(${URL.createObjectURL(file)})`;
                back.style.backgroundSize = `100%`;
                back.style.backgroundRepeat = `no-repeat`;
                back.style.backgroundPosition = `center`;
            }
        } catch (e) {
            return;
          }
    }

    handleHappyBirthday = (e) => {
        e.preventDefault();
        this.setState({happyBirthdayText: e.target.value});
    }
    handleInAdvance = (e) => {
        e.preventDefault();
        this.setState({inAdvance: e.target.value});
    }
    handlePatricia = (e) => {
        e.preventDefault();
        this.setState({patricia: e.target.value});
    }
    handleFromName = (e) => {
        e.preventDefault();
        this.setState({fromName: e.target.value});
    }

    handleFinishEdit = (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('parentContainer1'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
              var link = document.createElement("a");
              link.download = "medillery-template.jpeg";
            link.href = dataUrl;
              link.click();
            });          
    }

    downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('parentContainer1'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }

    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        // alert( window.devicePixelRatio);
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }
    render() {
        return (<div style={{marginTop: '90px'}}>            
            <GDHeader caty='birthday' />

            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="parentContainer1" id="parentContainer1">
                                            <div className="happyBirthday1">{this.state.happyBirthdayText}</div>
                                            <div className="celebrantName1">{this.state.patricia}</div>
                                            <div className="bottomContent1">
                                                <div className="text-capitalize inAdvance1">{this.state.inAdvance}</div>
                                                <div className="text-capitalize fromName1">{this.state.fromName}</div>
                                            </div>
                                            <CopyRight />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-4">
                                {!this.state.isEditFinish?
                                    <div>
                                        <Typography variant="subtitle2">
                                            Insert your details to customize the template to your taste.
                                            Edit placeholders and change the colors.
                                        </Typography>
                                        <form>
                                            <div className="form-group mt-3">
                                                <label className="small">Background</label>
                                                <label className="form-control rounded-pill">
                                                    <input type="file" onChange={this.changeBackground} style={{display:'none'}} className="form-control rounded-pill" />
                                                </label>
                                            </div>
                                            <div className="form-group d-flex">
                                                <div className="mr-2">
                                                    <label>Happy Birthday</label>
                                                    <input disabled type="text" id="happyBirthdayText"
                                                         value={this.state.happyBirthdayText} 
                                                         onChange={ this.handleHappyBirthday } 
                                                         className="form-control rounded-pill" 
                                                    />
                                                </div>
                                                <div>
                                                    <label>in Advance</label>
                                                    <input disabled type="text" 
                                                        value={this.state.inAdvance} 
                                                        onChange={ this.handleInAdvance } 
                                                        className="form-control rounded-pill" 
                                                    />
                                                </div>
                                                
                                            </div>
                                            <div className="form-group d-flex">
                                                <div className="mr-2">
                                                    <label>Patricia</label>
                                                    <input type="text" value={this.state.patricia}
                                                        onChange={ this.handlePatricia } 
                                                        className="form-control rounded-pill" 
                                                    />
                                                </div>
                                                <div>
                                                    <label className="small">From Name</label>
                                                    <input type="text" value={this.state.fromName} 
                                                        onChange={ this.handleFromName } 
                                                        className="form-control rounded-pill" 
                                                    />
                                                </div>
                                            </div>
                                            <div className="">
                                                <button
                                                    className="btn btn-block rounded-pill customPrimaryBgColor"
                                                    onClick={ this.handleFinishEdit }
                                                >
                                                    <span className="small text-white">Insert Details</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>:
                                    <div className=" text-center">
                                        <h4 variant="subtitle2" className="mr-2 my-4 w3-text-blue">
                                            Your image is ready!
                                        </h4>
                                        <div className=' text-center d-flex justify-content-center'>
                                            <div className="w-50 mb-3 ">
                                                <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                                <select className="form-control form-control-sm rounded-pill"
                                                    onChange={this.downloadNow}
                                                >   <option>Choose Format</option>
                                                    {/* <option>Default</option> */}
                                                    <option >PNG</option>
                                                    <option >JPEG</option>
                                                    
                                                    {/*<option>PDF</option>*/}
                                                </select>
                                            </div>
                                        </div>
                                        <div className=" d-flex justify-content-center mt-2">
                                            <button 
                                            className="btn btn-block rounded-pill customPrimaryBgColor" 
                                            onClick={ this.backToEdit }
                                            > 
                                                <span className="small text-white">Back to edit</span>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>                                        
                        </div>
                    </div>
                </div>
            </div>
            
        </div>);
    }
}
// jquery.scrollLeft() to move the whole div left and right
// https://stackoverflow.com/questions/27313480/scroll-div-content-horizontally-using-left-and-right-arrow-in-jquery

export default BirthdayEditOne;