import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '90px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'capitalize',
  },
}));

const Faqs=()=> {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <div className="container">
            <div className="row customPrimaryBgColor my-4" style={classes.headerBg}>
                <div className="col-sm-12 col-md-2 col-lg-2 col-xl-3"></div>
                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-6 text-center py-4">
                    <Typography variant="h4" className="text-white mb-2">FAQs</Typography>
                    <Typography variant="subtitle2" className="text-white font-weight-light mb-2">How can we help you?</Typography>
                    <div>
                        <input type="text" className="form-control text-white rounded-pill faqSearchInput" placeholder="Type keyword to find answers" />
                    </div>
                </div>
                <div className="col-sm-12 col-md-2 col-lg-2 col-xl-3"></div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-1 col-lg-1 col-xl-2"></div>
                <div className="col-sm-12 col-md-10 col-lg-10 col-xl-8">
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>HOW DO I GET PAID AS A GRAPHIC DESIGNER?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Typography>
                            If you are Nigerian, we pay directly into your bank account. If you are not a Nigerian, 
                            we will contact you for a convenient means of payment. Very soon, we will be able to pay 
                            into your bank account irrespective of your country.
                          </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.heading}>HOW MANY POINTS DO I NEED TO GET BEFORE GETTING PAID?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Typography>
                            We start paying you on monthly basis immediately you have 100points.
                          </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography className={classes.heading}>DO I STILL GET PAID EVEN IF I STOPPED WORKING?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Typography>
                            Yes, we continue to pay you for months after you stop working.
                          </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4a-content"
                          id="panel4a-header"
                        >
                          <Typography className={classes.heading}>HOW MUCH DO I GET ON MONTHLY BASIS?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Typography>
                            Your payment is directly proportional to the points you gather on our system.
                          </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel5a-content"
                          id="panel5a-header"
                        >
                          <Typography className={classes.heading}>CAN I GET PAID IF I AM NOT A GRAPHIC DESIGNER?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Typography>
                            At the moment, you cannot get paid if you are not a graphic designer.
                            Very soon, we will add more skills like content writing and animations, 
                            and you can get paid by offering these services on our platform.

                          </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel6a-content"
                          id="panel6a-header"
                        >
                          <Typography className={classes.heading}>IS MEDILLERY MEANT FOR GRAPHIC DESIGNERS ONLY?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Typography>
                            You do not need to be a graphic designer to use medillery. You can give your designs to graphic designers on Medillery.
                            Also, Medillery is a social media, so you can meet new people on our lovely community and also enjoy beautiful images and videos made by our professionals in the <Link to="/dashboard/enjoy">enjoy</Link> section.
                          </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel7a-content"
                          id="panel7a-header"
                        >
                          <Typography className={classes.heading}>DO I NEED TO GIVE MY DESIGNS  TO GRAPHIC DESIGNERS BEFORE I GET IT DONE?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Typography>
                            No, you can use our template section for instant designs, but if you are not satisfied with any of the designs in our template, you  need to give your projects to a graphic designer.
                          </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel8a-content"
                          id="panel8a-header"
                        >
                          <Typography className={classes.heading}>WHAT IF A GRAPHIC DESIGNER REJECTS MY PROJECT?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Typography>
                            You can assign the project to another graphic designer without having to fill a new form.
                          </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                <div className="col-sm-12 col-md-1 col-lg-1 col-xl-2"></div>
            </div>
        </div>
    </div>
  );
} 

export default Faqs;