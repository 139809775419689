import React from 'react';
import ChatNotFound from './ChatNotFound';
import Sidenav from './Sidenav';
import DesktopSideNav from './DesktopSideNav';


const InboxList=()=>{
  
	const [inWidth, SetInWidth] = React.useState(window.innerWidth);
  React.useEffect(()=>{
		function tellScreenSize(){
			SetInWidth(window.innerWidth);
		}
		window.addEventListener('resize', tellScreenSize);
		return ()=>window.removeEventListener('resize', tellScreenSize);
  },[]);
  
  return(<div className="container">
    {(inWidth < 768)?
      <div className="row">
        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 sideNavStyle px-0">
          <Sidenav />
        </div>
      </div>:
      <div className="row">
        <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 sideNavStyle px-0" style={{height: '87vh'}}>
        <DesktopSideNav />
      </div>
      <div className="col-sm-12 col-md-8 col-lg-9 col-xl-9 px-0 mainDisplay">
					<ChatNotFound />
			</div>
      </div>
    }
  </div>)
}

export default InboxList;