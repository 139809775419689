import React from "react";
import { connect } from "react-redux";
import { noImage /*, userPicPath*/ } from "../../global/generalMethods/general";
import { Avatar, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Axios from "axios";
import { PROXY } from "../../global/constants/action_types";
import { loadGeneralSearchResult } from "../../global/action";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link, useNavigate } from "react-router-dom";

const Display = ({ result, initialValue, loadSearch, participant, close }) => {
  const mounted = React.useRef(true);
  const navigate = useNavigate();
  const [searching, setSearching] = React.useState(false);
  const [lastSearch, setLastSearch] = React.useState("");
  const [somethingWentWrong, setSomethingWentWrong] = React.useState(false);
  const [val, setVal] = React.useState(initialValue);
  const [loading, setLoading] = React.useState(true);

  const searchNow = (e) => {
    e.preventDefault();
    if (searching) return;
    const search = val.trim();
    if (!search) return;
    if (lastSearch === search) return;
    setSearching(true);
    setLastSearch(search);

    if (somethingWentWrong) setSomethingWentWrong(false);
    Axios.get(`${PROXY}/api/search_participants/${search}`)
      .then(async (res) => {
        // alert('arrived');
        if (!mounted.current) return;
        if (res.data.success) {
          await loadSearch(res.data.data);
          // console.log(res.data.data);
          await setSearching(false);
        } else {
          if (res.data.Error === "Intruder") {
            if (!mounted.current) return;
            window.localStorage.clear();
            navigate("/", {replace:true});
          }
        }
      })
      .catch(async (err) => {
        if (!mounted.current) return;
        //   if(err)console.log(err)
        await setSearching(false);
        await setSomethingWentWrong(true);
      });
  };

  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (!result) return;
    try {
      if (Object.entries(result).length > 0) {
        setLoading(false);
      }
    } catch (e) {
      // console.log(e)
    }
  }, [result]);

  React.useEffect(() => {
    const lister = document.getElementById("lister");
    if (!lister) return;
    const loadMore = () => {
      if (lister.clientHeight + lister.scrollTop >= lister.scrollHeight) {
        if (searching) return;
        if (!result.next_page_url) return;
        mounted.current && setSearching(true);
        Axios.get(result.next_page_url)
          .then(async (res) => {
            if (res.data.success) {
              const tempData = [...result.data, ...res.data.data.data];
              res.data.data.data = tempData;
              await loadSearch(res.data.data);
              mounted.current && setSearching(false);
            }
          })
          .catch((err) => {
            // if(err)console.log(err);
            mounted.current && setSearching(false);
          });
      }
    };
    lister.addEventListener("scroll", loadMore);

    return () => {
      if (lister) lister.removeEventListener("scroll", loadMore);
    };
  });

  return (
    <div className="position-relative">
      <IconButton
        onClick={() => {
          close();
        }}
        style={{
          position: "absolute",
          top: 0,
          marginTop: "-10px",
          right: 0,
          cursor: "pointer",
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <div>
        {loading && val && (
          <div className="d-flex justify-content-center">
            <div className="w3-spin spinner w3-circle "></div>
          </div>
        )}
      </div>
      <div className="text-center font-weight-bold">Search for anything</div>
      <div className="input-group pt-3 pb-2 px-3">
        <input
          id="searchBox"
          defaultValue={initialValue}
          type="search"
          className="form-control rounded-pill"
          placeholder="Search"
          aria-label="Search Box"
          onChange={(e) => {
            setVal(e.target.value);
          }}
        />
        <div className="input-group-append">
          <button
            onClick={searchNow}
            className="btn btn-sm rounded-pill ml-2 px-2 customPrimaryBgColor"
            style={{ height: "35px", color: "#F9FBFF" }}
          >
            <SearchIcon fontSize="small" />
          </button>
        </div>
      </div>
      {result && result.data ? (
        <div>
          <div>
            <div className="mb-1">
              <div className="text-center font-italic text-muted">
                {Number(result.total) > 1
                  ? `${result.total} results`
                  : `${result.total} result`}{" "}
                found
              </div>
            </div>

            {searching && (
              <div className="mb-1 d-flex justify-content-center">
                <div className="spinner-grow text-muted"></div>
                <div className="spinner-grow text-info"></div>
                <div className="spinner-grow text-primary"></div>
              </div>
            )}
          </div>
          <div
            id="lister"
            className="w3-card px-2"
            style={{ maxHeight: "78vh", overflowY: "auto" }}
          >
            {result.data.length > 0 ? (
              <div className="mt-1">
                {result.data.map((data, index) => (
                  <div
                    key={index}
                    className="d-flex w3-round align-items-center mb-2 smallerText w3-hover-pale-blue"
                  >
                    {participant.userName &&
                    participant.userName === data.userName ? (
                      <div>
                        {result.data.length === 1 ? (
                          <div className="px-3">No match found</div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="d-flex">
                        <Avatar
                          style={{ width: "50px", height: "50px" }}
                          alt="avatar1"
                          className="img-fluid mr-2"
                          src={data.profilePic ? data.profilePic : noImage}
                        />
                        <div className="d-flex flex-column">
                          <Typography variant="subtitle1">
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/user/${data.userName}`}
                            >
                              @{data.userName}
                            </Link>
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            className="font-weight-light"
                          >{`${data.firstName} ${data.lastName}`}</Typography>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div>No match found</div>
            )}
          </div>
          <div>
            {somethingWentWrong && (
              <small className="text-danger">Oops! Something went wrong</small>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    result: state.generalSearchResult,
    participant: state.participant,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadSearch: (payload) => dispatch(loadGeneralSearchResult(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Display);
