import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Grid, Typography, Box } from '@material-ui/core';
import Axios from 'axios';
import { PROXY } from '../../../global/constants/action_types';
import ErrorDisplay from '../../dashboard/Profile/ErrorMessage';
import { scrollUp } from '../../../global/generalMethods/general';
import LazyLoader from '../../../LazyLoader';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';


const SignupSchema = Yup.object().shape({
	newPassword: Yup.string()
	  .min(8, 'Minimum of 8 is required')
      .required(' Password is required')
      .test('new password', 'new password is required', function(value) {
        return this.parent.currentPassword !== value;}
      )
	  .matches(/(^([a-zA-Z0-9_]){8,}$)/, "0-9 , _ , a-z are only accepted"),

	confirmPassword: Yup.string()
		.required("Required")
		.test('passwords-match', 'Password must match', function(value) {
      return this.parent.newPassword === value;}
		),
});



const ResetPassword=()=>{

  const mounted = useRef(true);
  const navigate = useNavigate();
  const { email } = useParams();
  const [searchParams] = useSearchParams();

  const [checked, setChecked] =  useState(false);
  const [valid, setValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [successful, SetSuccessful ] = useState(false);
  const [somethingWentWrong, SetSomethingWentWrong] = useState(false);

  const confirmLink=()=>{
    Axios.get(`${PROXY}/api/check_reset_link/${email}`)
    .then(async(res)=>{
      if(res.data.success){
        if(!mounted.current) return;
        setChecked(true);
        setValid(true);
      } else if(res.data.Error === 'Invalid'){
        if(!mounted.current) return;
        setChecked(true);
        setValid(false);
      }
    })
    .catch(async(err)=>{
      if(!mounted.current) return;
      if(err && err.response){
        if( err.response.status === 500 ||  err.response.status === 404){
          setChecked(true);
          setValid(false);
        }
      }
    })
  }

  const handleSave=(values)=>{
    if(!mounted.current) return;
    if(submitting) return;
    if(successful) SetSuccessful(false);
    if(somethingWentWrong) SetSomethingWentWrong(false);
    const form = new FormData();
    form.append('password', values.newPassword);
    setSubmitting(true);
    Axios.post(`${PROXY}/api/reset_password/${email}`, form)
    .then(async(res)=>{
      if(res.data.success){
        if(!mounted.current) return;
        SetSuccessful(true);
        setSubmitting(false);
      }else if(res.data.Error === 'Intruder'){
        window.localStorage.clear();
        navigate('/', true);
      }
    })
    .catch(async(err)=>{
      if(!mounted.current) return;
      if(err && err.response && err.response.status === 500){
        await SetSomethingWentWrong(true);
        await setSubmitting(false);
      } 
    })
  }

  useEffect(() => {
    scrollUp('cover');
    confirmLink();
    return () => { mounted.current = false }
    // eslint-disable-next-line
  }, []);

  const Destination=()=>{
    const redirect = searchParams.get('redirect');
    const gotoRedirect=()=>{
      window.location.replace(redirect);
    }    
    return <div>
      {redirect?
        <button onClick={gotoRedirect}
          className="btn rounded-pill customPrimaryBgColor text-white"
        >
          Proceed
        </button>:
        <span>
          Proceed to <Link to="/">login</Link>  
        </span>        
      }
    </div>
  }

  return(<React.Fragment>
    <div id='cover' className='w3-container' style={{height: '87vh', marginTop: '70px'}}>
    {checked?
      <div className='w3-container'>
        {valid?
          <div>
            {successful?
              <div>
              <Grid container justifyContent='center' alignItems='center' spacing={1}>
                <Box m={1}>
                  <Typography className='w3-text-teal py-5' component='h6'>
                    Your password has been successfully change.
                  </Typography>
                  <br />  
                  {   <Destination /> }    
                </Box>
              </Grid>
            </div>:
              <div>
                <div className='py-5 d-flex justify-content-center' >
                  <h6>Enter new password here</h6>
                </div>
                <Formik
                    initialValues={{confirmPassword: '', newPassword: ''}}
                    validationSchema={SignupSchema}
                    onSubmit={handleSave}
                >
                  {
                    ({ errors, touched, values}) => (
                      <Form>
                        <div className='row d-flex justify-content-center'>
                          <div className='form-group col-sm-12 col-md-6'>
                            <label className="ml-3">New password</label>
                            <Field name='newPassword' type="password" className="form-control rounded-pill" placeholder="******" />
                            {
                              (touched.newPassword && errors.newPassword) && 
                              <ErrorDisplay message={errors.newPassword} />
                            }
                          </div>
                        </div>
                        <div className='row d-flex justify-content-center'>
                          <div className='form-group col-sm-12 col-md-6'>
                            <label className="ml-3">Confirm password</label>
                            <Field name='confirmPassword' type="password" className="form-control rounded-pill" placeholder="******" />
                            {
                              (touched.confirmPassword && errors.confirmPassword) && 
                              <ErrorDisplay message={errors.confirmPassword} />
                            }
                          </div>
                        </div>
                        <div className='form-group'>                                    
                          <div className='my-3 d-flex justify-content-center'>
                            {submitting &&
                              <div className="w3-spin spinner w3-circle"></div>
                            }
                            {somethingWentWrong &&
                              <small className='text-danger'>Oops! Something went wrong.</small>
                            }
                          </div>
                          <div className='d-flex justify-content-center'>
                            <button className="btn rounded-pill customPrimaryBgColor text-white" type='submit'>Save</button>
                          </div>
                        </div>                                            
                      </Form>)
                    }
                  </Formik>
                </div>
              }                            
            </div>:
            <div className='w3-container'>
              <Grid container justifyContent='center' className='mt-4' alignItems='center' spacing={1}>
                <h6 > Oops! Something went wrong</h6>
              </Grid>
            <div>
              <Grid container justify='center' alignItems='center' spacing={1}>
                <Grid item xs={12} md={6} sm={8}>
                  <Box m={1}>
                    This link could probably be an expired or invalid link.
                    <br />
                    You may want to go back to 
                    <Link to='/forget_password' className='px-1'> Forget Password </Link>
                    to resend the link if you still want to change you password.
                  </Box>
                </Grid>
              </Grid>       
            </div>
          </div>
          }
        </div>:
        <div className='w3-container'>
          <LazyLoader />
        </div>
      }
    </div>
  </React.Fragment>)
}

export default ResetPassword;