import React, { Component } from 'react';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { Avatar, Typography } from '@material-ui/core';
import Axios from 'axios';
import { connect } from 'react-redux';
import { PROXY } from '../../../global/constants/action_types';
import { noImage } from '../../../global/generalMethods/general';
import { processHeader } from '../../../global/action';
import LazyLoader from '../../../LazyLoader';
import { Link, useNavigate, useParams } from 'react-router-dom';

const classes = {
    ratingIcon: {
        fontSize: '50px',
    },
}
const submitButton= {
    background: 'linear-gradient(90deg, #387DFF -4.49%, #004BD6 104.49%)',
  }

  const quitRequest = Axios.CancelToken.source();

class RateProfessional extends Component {
    state = {
        loading:false,
        notFound:false,
        allocation:{},
        selectedRateDiv:null,
        code:null,
        message:null,
        noRate:false,
        processing:false,
        successful:false,
        somethingWentWrong: false,
        goBack: false,
        ratingIconList: [
            { title: 'Unsatisfied', icon: SentimentVeryDissatisfiedIcon, color: 'text-danger' },
            { title: 'Average', icon: SentimentSatisfiedIcon, color: 'text-primary' },
            { title: 'Awesome', icon: SentimentSatisfiedAltIcon, color: 'text-success' },
        ]
    }
    mounted;

    
    componentDidMount=async()=>{
        this.mounted = true;
        if(this.mounted) await this.filterRouteId();
    }

    componentWillUnmount=()=>{
        this.mounted = false;
        quitRequest.cancel();
    }



    filterRouteId=async()=>{
        if(!this.mounted) return;
        if(!this.props.participant.userName) return;
        await this.setState({loading:true})
        let encodedPath;
        try {
            encodedPath = window.atob(window.atob(this.props.params.details));
        } catch (e) {
            if(!this.mounted) return;
            await this.setState({notFound:true});
            await this.setState({loading:false})
            return;
        }
		let sliceRandomAway = encodedPath.slice(6, encodedPath.length);
        const allocationId = Number(sliceRandomAway.slice(0, sliceRandomAway.length-6));
        if(isNaN(allocationId)){
            if(!this.mounted) return;
            await this.setState({notFound:true});
            await this.setState({loading:false})
            return;
        }
        Axios.get(`${PROXY}/api/confirm_allocation/${this.props.participant.userName}/${allocationId}`,
         {headers:processHeader(), cancelToken: quitRequest.token})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                if(Number(res.data.data.status) !== 2 || Number(res.data.data.rated) === 1) {
                    return this.props.history.replace(`/dashboard/projects/${this.props.params.details}`);
                }
                await this.setState({allocation:res.data.data});
                await this.setState({loading:false})
            }else{
                if(res.data.Error === 'Intruder'){
                    if(!this.mounted) return;
                    try {
                        window.localStorage.clear();
                        return this.props.navigate('/');                
                    } catch (e) {}
                }
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            await this.setState({notFound:true});
            await this.setState({loading:false})
        })

    }

    prepareRating=async(id)=>{
        if(!this.mounted) return;
        if(this.state.selectedRateDiv){
            document.getElementById(`${this.state.selectedRateDiv}`).style.background = 'white';
            document.getElementById(`${this.state.selectedRateDiv}`).style.color = 'black';
        }
        await this.setState({selectedRateDiv:id});
        document.getElementById(`${this.state.selectedRateDiv}`).style.background = 'black';
        document.getElementById(`${this.state.selectedRateDiv}`).style.color = 'white';

        if(id === 'rate_0'){
        await this.setState({code: 'Vp003'});
        await this.setState({message:'VerypooR'});
        }else if(id === 'rate_1'){
        await this.setState({code:'G001'});
        await this.setState({message:'GooD'});            
        } else if(id === 'rate_2'){
        await this.setState({code:'Vg002'});
        await this.setState({message:'VerygooD'});            
        }
        await this.setState({noRate: false});
    }

    rateProject=async(e)=>{
        e.preventDefault();
        if(!this.mounted) return;
        if(this.state.processing) return;
        await this.setState({noRate: false});
        await this.setState({successful: false});
        await this.setState({goBack: false});
        await this.setState({somethingWentWrong: false});
        if(!this.state.message || !this.state.code){
            await this.setState({noRate: true});
            return;
        }
        const form = new FormData();
        const comment = document.getElementById('comment').value.trim()
        await this.setState({processing: true});
        form.append('remark', comment);
        form.append('code', this.state.code);
        form.append('message', this.state.message);
        form.append('allocation_id', this.state.allocation.id);
        Axios.post(`${PROXY}/api/rate_allocation/${this.props.participant.userName}`, form,
         {headers:processHeader(), cancelToken: quitRequest.token})
        .then(async(res)=>{
            if(res.data.success){               
                if(!this.mounted) return;
                await this.setState({successful: true});
                await this.setState({goBack: true});
                await this.setState({processing: false});
                
            }else{
                if(res.data.Error ==='Intruder'){
                    window.localStorage.clear();
                    return this.props.navigate('/');
                }
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            await this.setState({somethingWentWrong: true});
            await this.setState({successful: false});
            await this.setState({processing:false});
        })
    }

    render() {
        return (<div>            
            {this.state.loading?
                <div>
                    <LazyLoader />
                </div>:
                <div>
                {this.state.notFound ? 
                    <div style={{height:window.innerHeight}}>
                        Page Not found
                    </div> :
                    <div>
                        { Object.entries(this.state.allocation).length > 0 && 
                            <div className="card w3-animate-right">
                                <div>
                                    {(this.state.successful  && this.state.goBack) ?
                                        <div className='d-block'>
                                            <div className='w3-text-teal d-flex justify-content-center'>
                                                You have Successfully Submitted the project.                                                                 
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <div className="text-center py-2">
                                                    <Link  
                                                        className="btn text-decoration-none rounded-pill text-white"
                                                        style={submitButton}
                                                        to={`/dashboard/projects/${this.props.params.details}`}
                                                    >
                                                        Proceed
                                                    </Link>
                                                </div>            
                                            </div>
                                        </div>:
                                        <div>
                                            <div className="card-header d-flex align-items-center" style={{backgroundColor: '#F9FBFF'}}>
                                                <Avatar alt="avatar1" className="img-fluid mr-2"
                                                src={this.state.allocation.professional.profilePic?
                                                    `${this.state.allocation.professional.profilePic}`:`${noImage}`
                                                    } 
                                                />
                                                <Typography className="font-weight-bold">
                                                    {`@${this.state.allocation.professional.userName}`}
                                                </Typography>
                                            </div>
                                            <div className="card-body py-5">
                                                <div className="row mb-4">
                                                    <div className="col-sm-12 col-md-2"></div>
                                                    <div className="col-sm-12 col-md-8 text-center" >
                                                        <Typography 
                                                            variant="subtitle1" 
                                                            className="mb-4"
                                                        >
                                                            How satisfied are you with 
                                                            <span className="font-weight-bold">
                                                                @{this.state.allocation.professional.userName}'s
                                                            </span> work?
                                                        </Typography>
                                                        <Typography 
                                                            variant="subtitle1" 
                                                            className="mb-1"
                                                        >
                                                            Please click on one of three Rate Icons below to this work.
                                                        </Typography>
                                                        <div className="d-flex justify-content-around mb-1">
                                                            { this.state.ratingIconList.map((item, index) => (
                                                                <div onClick={()=>this.prepareRating(`rate_${index}`)} id={`rate_${index}`} key={index} className="w3-hover-pale-blue w3-round p-2">
                                                                    {/*<a href="/">
                                                                    </a>*/}
                                                                    <item.icon className={item.color} style={classes.ratingIcon} />
                                                                    <Typography variant="subtitle2">{item.title}</Typography>
                                                                </div>
                                                            )) }
                                                        </div>
                                                        <div className='mb-4'>
                                                            {this.state.noRate &&
                                                                <div className='text-danger'>
                                                                    <small>
                                                                        Please click on any Icon above rate this project
                                                                    </small>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div>
                                                            <form>
                                                                <div className="form-group">
                                                                    <textarea id='comment' className="form-control" rows="4" placeholder="Tell us more..."></textarea>                                                                
                                                                </div>
                                                                <div className="w3-container">
                                                                    {this.state.processing &&
                                                                        <div className='d-flex justify-content-center'>
                                                                            <div className='w3-spin spinner w3-circle mt-2'></div>                                                                   
                                                                        </div>
                                                                    }
                                                                    {this.state.somethingWentWrong &&
                                                                        <div className='text-danger w3-small d-flex justify-content-center'>
                                                                            Oops, something went wrong.                                                                 
                                                                        </div>
                                                                    }
                                                                    <button onClick={this.rateProject} className="btn rounded-pill customPrimaryBgColor text-white">
                                                                        <span className="small text-uppercase">Rate Project</span>
                                                                    </button>                                                                
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-2"></div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        }            
        </div>);
    }
}

const mapStateToProps=(state)=>{
    return{
        participant: state.participant,
    }
}

const withRouter=Child=>props=>{
    const params = useParams();
    const navigate = useNavigate();
    // const match = useMatch();
    // alert();
    // match={match}
    return(
        <Child 
        {...props} 
        params={params} 
        navigate={navigate} 
        />
 )
}



export default connect(mapStateToProps)(withRouter(RateProfessional));