import React, { Component } from 'react';
import { Typography, Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';


const classes = {
    headerBg: {
        borderRadius: '18px',
    }
}
class About extends Component {
    state = {  }
    render() {
        return (
            <div style={{marginTop: '90px'}}>
                <div className="container">
                    <div className="row customPrimaryBgColor my-4" style={classes.headerBg}>
                        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-3"></div>
                        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-6 text-center py-4">
                            <Typography variant="h4" className="text-white mb-2">About Us</Typography>
                            <Typography variant="subtitle2" className="text-white font-weight-light mb-2">Who we are</Typography>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-3"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-1 col-lg-1 col-xl-2"></div>
                        <div className="col-sm-12 col-md-10 col-lg-10 col-xl-8">
                            <div>
                                <Typography variant="h5" className="sectionTitle mb-3">We set out to make freelancing free indeed.</Typography>
                                <p>
                                    In 2016, during a period of economic recession in Nigeria, everything was on the rise and the naira
                                    to dollar ratio was as high as  &#8358;500 to $1. It was a period many state governments were owing salaries
                                    and most students saw the need to add vocational skills to their education.  At that time, the middle
                                    class of the society was shrinking and the country was divided into the “haves” and the “have not”.
                                    Everyone was trying to cut cost.
                                </p>
                                <p>
                                    Taye also learnt graphics design. During the training, he saw the fear of most young graphic designers.
                                    The major concern was “How do I get people to give jobs to me?”.
                                </p>
                                <p>
                                    He sat down and thought of a solution.
                                </p>
                                <p>
                                    “The country is trying to cut cost, the young designers are looking for clients”.
                                </p>
                                <p>
                                    What if we make it possible for people to design their images FOR FREE?
                                </p>
                                <p>
                                    A lot of people will take advantage of the opportunity by patronizing the graphics designers.
                                </p>
                                <p>
                                    Both parties will get their problems solved.
                                    Another problem came up, how do we pay the graphic designers for their jobs? Taye discussed this with Olamide and we gave birth to Medillery. 
                                </p>
                            </div>
                            <div>
                                <p>
                                    Life’s best gifts-hugs, friends, kisses, love, laughter- are free.
                                    If these best gifts were not free, imagine what it would mean to be poor.
                                    The best way to help a struggling society is to reduce the cost of living to the minimum.
                                </p>
                                <p>
                                    This is a worthy adventure, we are glad we are helping our society, <Link to="/medillery">will you join us?</Link>
                                </p>
                            </div>
                            <div>
                                <q>
                                    <i>While solving the problem of the Africa continent, the peculiarity of the continent must be kept in mind. In a continent that has over 70% of the world's poorest people, it is important that we offer services at the minimum possible amount to lift one another.</i>
                                </q>
                                <div><b>– Taye Nife Abidakun</b></div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-6 d-flex flex-column align-items-center">
                                    <Avatar className="img-fluid" style={{ width: '130px', height: '130px'}} src={ require('../../../assets/images/website/about/taye-abidakun.jpeg') } />
                                    <div className="font-weight-bold">Taye Abidakun</div>
                                    <div className="text-center">Co-Founder And CEO, Medillery Ltd.</div>
                                </div>
                                <div className="col-6 d-flex flex-column align-items-center">
                                    <Avatar className="img-fluid" style={{ width: '130px', height: '130px'}} src={ require('../../../assets/images/website/about/ceo2.jpeg') } />
                                    <div className="font-weight-bold">Olamide</div>
                                    <div className="text-center">Co-Founder, Medillery Ltd.</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-1 col-lg-1 col-xl-2"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;