import React from "react";
import { PRICING_PROXY } from "../../global/constants/action_types";
import { Typography } from "@material-ui/core";

export const Nosubscrition=({participant})=>{  
  const origin = window.location.origin
  const user_uuid = participant?.uuid
  const callback_redirect = `${origin}/dashboard/tag-professional`
  const redirect_type = "tag-profession"
  const pricing_path = `${PRICING_PROXY}?user_uuid=${user_uuid}&callback_redirect=${callback_redirect}&redirect_type=${redirect_type}`
  return (<>
    <Typography variant="h6" className="mb-2">Subscription is needed *</Typography>
    <div className="my-2">You need to have an active subscription of one our available packages before you
      can asign project to a professional.
    </div>
    <small >Click on proceed below to choose a suitable package from varieties of options</small>
    <div className="d-flex justify-content-center">
      <a href={pricing_path}
        className="customPrimaryBgColor d-flex justify-content-center mt-3 py-2 rounded w-full max-w-3h text-white "
      >
        Proceed to pricing
      </a>

    </div>
  </>)
}