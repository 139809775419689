import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import ErrorDisplay from '../../dashboard/Profile/ErrorMessage';
import Axios from 'axios';
import { PROXY } from '../../../global/constants/action_types';
import { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const SignupSchema = Yup.object().shape({
	email: Yup.string()
	  .email('Invalid email')
	  .required('Email is required'),
});

const ForgetPassword=()=>{
  const [processing, setProcessing] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const [notEligible, setNotEligible] = useState(false);

  const mounted = useRef(true);
  const navigate = useNavigate();
  const startUp = useRef(document.getElementById('cover'));

  const processReset=async(values)=>{
    if(!values) return;
    if(processing) return;
    if(!mounted.current) return;
    if(somethingWentWrong) setSomethingWentWrong(false);
    if(successful) setSuccessful(false);
    if(notEligible) setNotEligible(false); 
    if(incorrectEmail) setIncorrectEmail(false);
    const form = new FormData();
    form.append('email', values.email);
    setProcessing(true);
    Axios.post(`${PROXY}/api/forget_password`, form)
    .then(async(res)=>{
      if(!mounted.current) return;
      if(res.data.success){
          if(!mounted.current) return;
          setSuccessful(true);
          setProcessing(false);
      }else{
        if(!mounted.current) return;
        if(res.data.Error === 'IncorrectEmail'){
          setSomethingWentWrong(true);
          setIncorrectEmail(true)
          setProcessing(false);
        }
        else if(res.data.Error === 'Intruder'){
          window.localStorage.clear();
          return navigate('/', {replace:true});
        }
        else if(res.data.Error === 'Account_Not_Eligible'){
          setSomethingWentWrong(true);
          setNotEligible(true);
          setProcessing(false);
        }
      }
    })
    .catch(async(err)=>{
      if(!mounted.current) return;
      if(err && err.response && err.response.status === 500){
        console.log(err.response);
        setSomethingWentWrong(true);
        setProcessing(false);
      }
    })
  }

    useEffect(() => {
      mounted.current = true;
      if(startUp.current){
        window.scrollTo(0,0);
      }else{
        startUp.current = document.getElementById('cover');
        if(!startUp.current) return;
        window.scrollTo(0,0);
      }
      return () => {
        mounted.current = false;
      }
  }, [mounted, startUp])


  return(<div id='cover'>
    <div  className='w3-animate-zoom pt-5' style={{marginTop:'70px', height: `${window.innerHeight-70}px`}}>
      {successful ?
        <div className='w3-container'>
          <div className='w3-center'>
            <h6 className='my-3'>Operation was successful.</h6>
            <div className='mb-5'>
              Please check the email provided and click on the reset button to reset your password
            </div>
            <Link to='/' className='btn btn-sm customPrimaryBgColor px-3 text-white rounded-pill'>
              Continue
            </Link>
          </div>        
        </div>:
        <div className='w3-center w3-mobile'>
          <div className='w3-container'>
            <h6 className='text-primary my-2'> Please Provide the email associated with your account to
              receive the password reset link
            </h6>

            <Formik
              initialValues={{email:''}}
              validationSchema={SignupSchema}
              onSubmit={processReset}
            >
              {({errors, touched, values}) => (
                <Form>
                  <div className='form-group'>
                    <label className="ml-3">Email</label>
                    <div className='row'>
                      <div className='col-sm-12 col-md-3 d-flex justify-content-center'> </div>
                      <div className='col-sm-12 col-md-6 '>
                        <Field type="email" className="form-control rounded-pill" name='email' placeholder={values.email} />
                      </div>
                      <div className='col-sm-12 col-md-3 d-flex justify-content-center'></div>
                    </div>
                      <div>
                        {(touched.email && errors.email) && <ErrorDisplay message={errors.email} />}
                      </div>
                  </div>
                  <div className='form-group'>                                    
                    <div className='my-3 w3-center'>
                      {processing &&
                        <div className='d-flex justify-content-center'>
                          <div className="w3-spin spinner w3-circle"></div>
                        </div>
                      }                            
                      {somethingWentWrong &&
                        <small className='text-danger'>Oops! Something went wrong.</small>
                      }
                      {incorrectEmail &&
                        <small className='text-danger'>Email provided does no match any account</small>
                      }
                      {notEligible &&
                        <small className='text-danger'>
                          You cannot set password for your account type.
                          <br />
                          Use google login or try "Recovery Method" you were informed 
                          <br />
                          to set its password during registration up to convert your account to manual login type. 
                        </small>
                      }                                                
                    </div>
                    <div className='d-flex justify-content-center'>
                      <button className="btn rounded-pill customPrimaryBgColor text-white" type='submit'>Proceed</button>
                    </div>
                  </div>
                </Form>)
              }
            </Formik>
          </div>
        </div>
      }            
    </div>        
  </div>)
}

export default ForgetPassword;