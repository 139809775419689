import React, { Component } from 'react';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import '../../CSSs/Business/two/two.css';
import { Typography } from '@material-ui/core';
import GDHeader from '../GDHeader';

class BusinessEditTwo extends Component {
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        yourText: "Your",
        fashionPlugText: "Fashion Plug",
        extraText: "We sell all kinds of male and female wears and accessories at pocket friendly prices",
        hintText: "We are just a call/whatsapp message away",
        placeOrderText: "PLACE AN ORDER TODAY",
        phoneNumber: "080000000000",
        logoText: "ISHOP",
    }
    
    changeBackgroundOne = (e) => {
        e.preventDefault();
        try {
            const file = e.target.files[0];
            if(!file)  return;
            let back = document.getElementById("topHalf2");
            if(back){
                back.style.background = `url(${URL.createObjectURL(file)})`;
            }
        } catch (e) {
            return;
        }
    }
    changeBackgroundTwo = (e) => {
        e.preventDefault();
        try {
            const file = e.target.files[0];
            if(!file)  return;
            let back = document.getElementById("bottomHalf2");
            if(back){
                back.style.background = `url(${URL.createObjectURL(file)})`;
            }
        } catch (e) {
            return;
        }
    }
    changeLogo = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const logo = document.getElementById('logoSlot');
        if(!logo) return;
        logo.src = URL.createObjectURL(file);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }
    handleFinishEdit = (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('outSideContainer2'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
              var link = document.createElement("a");
              link.download = "medillery-template.jpeg";
              link.href = dataUrl;
              link.click();
            });          
    }

    downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('outSideContainer2'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }

    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }

    render() {
        return (<div style={{marginTop: '90px'}}>
            <GDHeader caty='business' />
            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="outSideContainer2" id="outSideContainer2">
                                            <div className="equalHalves2 topHalf2" id="topHalf2">
                                                <div className="coverWrapper2">
                                                    <div className="halfDiv2 divOne2">
                                                        <div className="middleDiv2">
                                                            <div className="midcircleDiv2">
                                                                <div className="middleCircle2">
                                                                    <h6 className="headandtail2 text-uppercase">{this.state.yourText}</h6>
                                                                    <h1 className="middletext2 text-uppercase">#1</h1>
                                                                    <h6 className="headandtail2 text-uppercase">{this.state.fashionPlugText}</h6>
                                                                </div>
                                                                <div className="text-center bottomRectangle2">
                                                                    <p>{this.state.extraText}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="underDiv2 text-center">
                                                                <p className="placeOrder2">{this.state.placeOrderText}</p>
                                                                <h2>{this.state.phoneNumber}</h2>
                                                                <p className="callaway2">{this.state.hintText}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="halfDiv2 divTwo2">
                                                        <div className="topRightDiv2">
                                                            <div className="yourLogo2">
                                                                <img id="logoSlot" src={require("../../../../assets/images/img.png")} 
                                                                    alt="living Room" className="logoImage2" />
                                                            </div>
                                                            {/* <span className="redDots2 firstDot2">•</span>
                                                            <h6 className="topRightText2">
                                                                {this.state.logoText}
                                                            </h6>
                                                            <span className="redDots2 secondDot2">•</span> */}
                                                        </div>
                                                        <p className="footerLink2"><small>www.medillery.com/templates</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="equalHalves2 bottomHalf2" id="bottomHalf2">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-4">
                            {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                        <div className="form-group mt-3">
                                            <label className="small">Top Background</label>
                                            <label className="form-control rounded-pill">
                                                <input 
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeBackgroundOne}
                                                    />
                                            </label>

                                            <label className="small">Bottom Background</label>
                                            <label className="form-control rounded-pill">
                                                <input 
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeBackgroundTwo}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label>Logo</label>
                                            <label className="form-control rounded-pill">
                                                <input
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeLogo}
                                                    />
                                            </label>
                                        </div>
                                        {/* <div className="form-group">
                                            <div>
                                                <label>Logo Here</label>
                                                <input 
                                                    type="text" value={this.state.logoText}
                                                    id="logoText"
                                                    name="logoText"
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div> */}
                                        <div className="form-group d-flex">
                                            <div className="mr-2">
                                                <label>Your</label>
                                                <input 
                                                    type="text" value={this.state.yourText}
                                                    id="yourText"
                                                    name="yourText"
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div>
                                                <label>Title Text</label>
                                                <input 
                                                    type="text"
                                                    id="fashionPlugText"
                                                    name="fashionPlugText"
                                                    value={this.state.fashionPlugText} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            
                                        </div>
                                        <div className="form-group d-flex">
                                            <div className="mr-2">
                                                <label>Phone Number</label>
                                                <input 
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    value={this.state.phoneNumber} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div>
                                                <label>Hint Text</label>
                                                <input
                                                    type="text"
                                                    id="hintText"
                                                    name="hintText"
                                                    value={this.state.hintText} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group d-flex">
                                            <div className="mr-2">
                                                <label>Extra Text</label>
                                                <textarea 
                                                    id="extraText"
                                                    name="extraText"
                                                    value={this.state.extraText} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                ></textarea>
                                            </div>
                                            <div>
                                                <label>Other texts</label>
                                                <input
                                                    type="text"
                                                    id="placeOrderText"
                                                    name="placeOrderText"
                                                    value={this.state.placeOrderText} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                            > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4 className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select className="form-control form-control-sm rounded-pill"
                                                onChange={this.downloadNow}
                                            >   <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                        className="btn btn-block rounded-pill customPrimaryBgColor" 
                                        onClick={ this.backToEdit }
                                        > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>);
    }
}
// jquery.scrollLeft() to move the whole div left and right
// https://stackoverflow.com/questions/27313480/scroll-div-content-horizontally-using-left-and-right-arrow-in-jquery

export default BusinessEditTwo;
