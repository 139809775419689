import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

const bigContainer = {
    background: 'linear-gradient(182.82deg, #387DFF 10.55%, #004BD6 86.92%)'
};
const Footer = () => {
    const currentYear = new Date().getFullYear();
    const state = {
        firstColumn: [
            { title: 'Terms & Conditions', link: '/terms-and-conditions' },
            // { title: 'Privacy Policy', link: '/privacy-policy' },
            // { title: 'Copyright Information', link: '/copyright-information' },
        ],
        secondColumn: [
            { title: 'Blog', link: 'https://blog.medillery.com' },
            // { title: 'Newsletter', link: '/newsletter' },
            // { title: 'Features', link: '/features' },
        ],
        thirdColumn: [
            { title: 'About Medillery', link: '/about' },
            // { title: 'Our Team', link: '/our-team' },
            // { title: 'Careers', link: '/careers' },
            // { title: 'Contact', link: '/contact' },
            { title: 'FAQs', link: '/faqs' },
        ]
    };

    return (
        <Box style={ bigContainer } py={3}>
            <Box py={4} className="container text-white" px={5}>
                <Box className="row">
                    <Box className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <Typography className="font-weight-bold mb-1">LEGAL</Typography>
                        { state.firstColumn.map((item, index) => (
                            <Typography variant="subtitle1" className="mb-2" key={index}>
                                <Link 
                                    className="text-decoration-none text-white font-weight-light" 
                                    to={ item.link}
                                >
                                    {item.title}
                                </Link>
                            </Typography>
                        )) }
                    </Box>
                    <Box className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <Typography className="font-weight-bold mb-1">CONTENT</Typography>
                        { state.secondColumn.map((item, index) => (
                            <Typography key={index} className="mb-2"><a className="text-decoration-none text-white font-weight-light" href={ item.link}>{item.title}</a></Typography>
                        )) }
                    </Box>
                    <Box className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <Typography className="font-weight-bold mb-1">INFORMATION</Typography>
                        { state.thirdColumn.map((item, index) => (
                            <Typography key={index} className="mb-2"><Link to={ item.link } className="text-decoration-none text-white font-weight-light">{item.title}</Link></Typography>
                        )) }
                    </Box>
                </Box>
            </Box>
            <Box className="container text-white" px={5}>
                <Box className="row">
                    <div className="col-12">
                        <hr width="85%" className="bg-light" />
                    </div>
                </Box>
                <Box className="row">
                    <Box className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <img className="img-fluid" src={ require('../../assets/images/medillery-logo.png') } alt="medillery-logo" />
                    </Box>
                    <Box className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <Typography variant="body2" className="font-weight-light spaceText">
                            Medillery Ltd &copy; {currentYear}. All Right Reserved
                        </Typography>
                    </Box>
                    <Box className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <Box className="d-flex">
                            <a href="https://web.facebook.com/groups/2171022572989188" className="text-white mr-1"><FacebookIcon /></a>
                            <a href="https://www.instagram.com/medillery" className="text-white mr-1"><InstagramIcon /></a>
                            <a href="https://twitter.com/medillery?s=09" className="text-white mr-1"><TwitterIcon /></a>
                            <a href="https://www.youtube.com/channel/UCACQS_65Gawj9oYfyz3KeNg" className="text-white mr-1"><YouTubeIcon /></a>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Footer;