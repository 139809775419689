import React, { Component } from "react";
import { Avatar, Typography, Box } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
// import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ShareIcon from "@material-ui/icons/Share";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Divider from "@material-ui/core/Divider";
// import Announcements from '../Announcements'
// import CompanyDetails from '../CompanyDetails'
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import { connect } from "react-redux";
import Axios from "axios";
import { PROXY } from "../../../global/constants/action_types";
import { processHeader, loadStatusPost } from "../../../global/action";
import {
  CHAT_LIKE_TIME,
  noImage,
  staticPusherConfig,
} from "../../../global/generalMethods/general";
import { Popover, OverlayTrigger } from "react-bootstrap";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link, useNavigate } from "react-router-dom";

const classes = {
  statusImageCard: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 0,
  },
  statusImageStyle: {
    objectFit: "cover",
    width: "100%",
    minHeight: "100%",
  },
  sendCommentBtn: {
    width: "30px",
    color: "#F9FBFF",
    background: "linear-gradient(92deg, #FA9347 5.16%, #F07672 99.12%)",
  },
};

const quitRequest = Axios.CancelToken.source();

class Statuses extends Component {
  state = {
    statusPosts: {},
    userName: null,
    fetching_post: false,
    postComment: null,
    commenting: false,
    deletingComment: false,
    openMenu: null,
    selectedStatusIdForDelete: null,
    SelectStatusUsernameForDelete: null,
  };

  mounted;

  componentDidMount = async () => {
    this.mounted = true;
    if (!this.props.participant.email_verified_at) return;
    const { initialStatusPosts } = this.props;
    await this.setState({ userName: this.props.participant.userName });
    if (Object.entries(initialStatusPosts).length < 1) {
      this.getStatusPost();
    } else {
      this.mounted &&
        (await this.setState({ statusPosts: initialStatusPosts }));
    }
    await staticPusherConfig();
    window.addEventListener("scroll", this.virtualScrolling);
  };
  UNSAFE_componentWillReceiveProps = async (next) => {
    if (next.initialStatusPosts.data) {
      this.mounted &&
        (await this.setState({ statusPosts: next.initialStatusPosts }));
    }
  };

  componentWillUnmount = () => {
    // quitRequest.cancel();
    window.removeEventListener("scroll", this.virtualScrolling);
    this.mounted = false;
  };

  /* This Method fetches the available status post by self or following participant from recent to oldest*/
  getStatusPost = async () => {
    if (!this.state.fetching_post && this.state.userName && this.mounted) {
      let url;
      if (
        Object.entries(this.state.statusPosts).length > 0 &&
        this.state.statusPosts.next_page_url
      ) {
        const getUrl = this.state.statusPosts.next_page_url.indexOf("api");
        url = this.state.statusPosts.next_page_url.slice(
          getUrl,
          this.state.statusPosts.next_page_url.length
        );
      } else if (
        Object.entries(this.state.statusPosts).length > 0 &&
        !this.state.statusPosts.next_page_url
      ) {
        return;
      } else {
        url = `api/get_status_post/${this.state.userName}`;
      }
      await this.setState({ fetching_post: true });
      Axios.get(`${PROXY}/${url}`, {
        headers: processHeader(),
        cancelToken: quitRequest.token,
      })
        .then(async (res) => {
          if (!this.mounted) return;
          if (res.data.success) {
            // console.log(res.data.data)
            if (this.state.statusPosts.data) {
              const { data } = res.data.data.statusPosts;
              res.data.data.statusPosts.data = [
                ...this.state.statusPosts.data,
                ...data,
              ];
              await this.setState({ statusPosts: res.data.data.statusPosts });
            } else {
              await this.setState({ statusPosts: res.data.data.statusPosts });
              this.props.fetchInitialStatusPosts(res.data.data.statusPosts);
            }
            await this.setState({ fetching_post: false });
          } else {
            // console.log(res.data);
            await this.setState({ fetching_post: false });
          }
        })
        .catch(async (err) => {
          // if(err) console.log(err)
          if (!this.mounted) return;
          await this.setState({ fetching_post: false });
        });
    }
  };

  /** This methods gets more post if there is next page */

  virtualScrolling = () => {
    // console.log(`${document.body.scrollHeight}/${window.pageYOffset}/${window.innerHeight}/
    // ${document.body.offsetHeight}/${document.body.clientHeight}`);
    if (window.pageYOffset + window.innerHeight >= document.body.offsetHeight) {
      this.getStatusPost();
    }
  };

  /*This method checks if the participant has already liked Link particular status file by filtering the id of the
    file out  of the associated post files like monitor history using  the some method*/
  filterLiked = (id, monitor) => {
    if (monitor.length > 0) {
      return monitor.some((like) => like.status_file_id === id);
    } else {
      return false;
    }
  };

  triggerMenu = (e, postId, owner) => {
    const current = e.currentTarget;
    // console.log(e.currentTarget, e.target);
    this.setState({ selectedStatusIdForDelete: postId });
    this.setState({ SelectStatusUsernameForDelete: owner });
    this.setState({ openMenu: current });
  };

  closeMenu = () => {
    this.setState({ selectedStatusIdForDelete: null });
    this.setState({ SelectStatusUsernameForDelete: null });
    this.setState({ openMenu: null });
  };

  deleteStatus = () => {
    this.setState({ openMenu: null });
    if (
      !this.state.selectedStatusIdForDelete ||
      !this.state.SelectStatusUsernameForDelete
    )
      return;
    if (this.state.deletingPost) return;
    if (
      this.state.SelectStatusUsernameForDelete.toLowerCase() !==
      this.props.participant.userName.toLowerCase()
    )
      return;
    // return console.log(this.state.selectedStatusIdForDelete, this.state.SelectStatusUsernameForDelete)
    const form = new FormData();
    form.append("status_post_id", this.state.selectedStatusIdForDelete);
    this.setState({ deletingPost: true });
    Axios.post(`${PROXY}/api/delete_status_post/${this.state.userName}`, form, {
      headers: processHeader(),
    })
      .then(async (res) => {
        if (res.data.success) {
          if (!this.mounted) return;
          const tempStatusPosts = { ...this.state.statusPosts };
          const filtered = tempStatusPosts.data.filter(
            (item) => item.id !== this.state.selectedStatusIdForDelete
          );
          if (!filtered) return;
          tempStatusPosts.data = filtered;
          await this.setState({ statusPosts: tempStatusPosts });
          this.mounted && this.setState({ selectedStatusIdForDelete: null });
          this.mounted &&
            this.setState({ SelectStatusUsernameForDelete: null });
          this.mounted && this.setState({ deletingPost: false });
        } else {
          if (res.data.Error === "Intruder") {
            if (!this.mounted) return;
            window.localStorage.clear();
            return this.props.navigate("/");
          }
        }
      })
      .catch(async (err) => {
        this.mounted && this.setState({ deletingPost: false });
      });
  };

  deleteStatusComment = (statusPostId, commentId) => {
    if (!commentId) return;
    const form = new FormData();
    form.append("status_comment_id", commentId);
    Axios.post(
      `${PROXY}/api/delete_status_comment/${this.state.userName}`,
      form,
      { headers: processHeader(), cancelToken: quitRequest.token }
    )
      .then(async (res) => {
        if (res.data.success) {
          const tempStatusPosts = { ...this.state.statusPosts };
          const tempData = [...tempStatusPosts.data];
          const actualPost = tempData.find((el) => el.id === statusPostId);
          const actualPostIndex = tempData.findIndex(
            (el) => el.id === statusPostId
          );
          const commentIndex = actualPost.comments.findIndex(
            (el) => el.id === commentId
          );
          actualPost.comments.splice(commentIndex, 1);
          tempData.splice(actualPostIndex, 1, actualPost);
          tempStatusPosts.data = tempData;
          await this.setState({ statusPosts: tempStatusPosts });
        }
      })
      .catch((err) => {});
  };

  commentOnStatus = async (statusId, comment, inputId) => {
    if (
      comment &&
      comment.trim().length > 0 &&
      this.state.userName &&
      !this.state.commenting
    ) {
      await this.setState({ commenting: true });
      document.getElementById(inputId).value = "";
      const form = new FormData();
      form.append("status_post_id", statusId);
      form.append("comment", comment);
      const AUTH = processHeader();
      AUTH["X-Socket-ID"] = window.Echo.socketId();
      Axios.post(
        `${PROXY}/api/comment_on_status/${this.state.userName}`,
        form,
        { headers: AUTH, cancelToken: quitRequest.token }
      )
        .then(async (res) => {
          if (res.data.success) {
            if (!this.mounted) return;
            const tempStatusPosts = { ...this.state.statusPosts };
            const tempData = [...tempStatusPosts.data];
            const actualPost = tempData.find((el) => el.id === statusId);
            const actualPostIndex = tempData.findIndex(
              (el) => el.id === statusId
            );
            if (actualPost.comments.length > 2) {
              const lastTwoComments = actualPost.comments.splice(0, 2);
              lastTwoComments.unshift(res.data.data);
              actualPost.comments = lastTwoComments;
            } else {
              actualPost.comments.unshift(res.data.data);
            }
            actualPost.comments_count++;
            tempData.splice(actualPostIndex, 1, actualPost);
            tempStatusPosts.data = tempData;
            await this.setState({ statusPosts: tempStatusPosts });
            await this.setState({ [inputId]: undefined });
          }
        })
        .then(async (err) => {
          if (!this.mounted) return;
          await this.setState({ commenting: false });
        });
    }
  };
  likeStatusPost = (id) => {
    if (id) {
      const form = new FormData();
      form.append("status_post_id", id);
      const AUTH = processHeader();
      AUTH["X-Socket-ID"] = window.Echo.socketId();
      Axios.post(
        `${PROXY}/api/like_status_post/${this.props.participant.userName}`,
        form,
        { headers: AUTH, cancelToken: quitRequest.token }
      )
        .then(async (res) => {
          if (res.data.success) {
            if (!this.mounted) return;
            const statePostDuplicate = { ...this.state.statusPosts };
            const getSpecificPost = await this.state.statusPosts.data.find(
              (post) => post.id === id
            );
            const getIndex = await this.state.statusPosts.data.findIndex(
              (post) => post.id === id
            );
            if (res.data.data === "liked") {
              getSpecificPost.post_liked = { created_at: new Date() };
              getSpecificPost.likes++;
              statePostDuplicate.data.splice(getIndex, 1, getSpecificPost);
              await this.setState({ statusPosts: statePostDuplicate });
            } else if (res.data.data === "unliked") {
              getSpecificPost.post_liked = null;
              getSpecificPost.likes--;
              statePostDuplicate.data.splice(getIndex, 1, getSpecificPost);
              await this.setState({ statusPosts: statePostDuplicate });
            }
          } else {
            // console.log(res.data);
          }
        })
        .catch(async (err) => {
          // if(err) console.log(err)
        });
    }
  };

  likeStatusPostFile = (post_id, file_id) => {
    if (post_id && file_id) {
      const form = new FormData();
      form.append("status_post_id", post_id);
      form.append("status_file_id", file_id);
      const AUTH = processHeader();
      AUTH["X-Socket-ID"] = window.Echo.socketId();
      Axios.post(
        `${PROXY}/api/like_status_file/${this.props.participant.userName}`,
        form,
        { headers: AUTH, cancelToken: quitRequest.token }
      )
        .then(async (res) => {
          if (res.data.success) {
            if (!this.mounted) return;
            const statePostDuplicate = { ...this.state.statusPosts };
            const getSpecificFile = await this.state.statusPosts.data.find(
              (post) => post.status_files.some((file) => file.id === file_id)
            );
            const getIndex = await this.state.statusPosts.data.findIndex(
              (post) => post.status_files.some((file) => file.id === file_id)
            );
            if (res.data.data === "liked") {
              getSpecificFile.file_liked.push({ status_file_id: file_id });
              const file = [...getSpecificFile.status_files];
              const getFileArray = file.find((file) => file.id === file_id);
              const fileIndex = file.findIndex((file) => file.id === file_id);
              getFileArray.likes++;
              file.splice(fileIndex, 1, getFileArray);
              getSpecificFile.status_files = file;
              statePostDuplicate.data.splice(getIndex, 1, getSpecificFile);
              await this.setState({ statusPosts: statePostDuplicate });
            } else if (res.data.data === "unliked") {
              /* get index of the file like monitor element the splice it out of the array, Hence insert the
                         filtered element of main post back into statusPost.data */
              const index = getSpecificFile.file_liked.findIndex(
                (data) => data.status_file_id === file_id
              );
              getSpecificFile.file_liked.splice(index, 1);
              const file = [...getSpecificFile.status_files];
              const getFileArray = file.find((file) => file.id === file_id);
              const fileIndex = file.findIndex((file) => file.id === file_id);
              getFileArray.likes--;
              file.splice(fileIndex, 1, getFileArray);
              getSpecificFile.status_files = file;
              statePostDuplicate.data.splice(getIndex, 1, getSpecificFile);
              await this.setState({ statusPosts: statePostDuplicate });
            }
          } else {
            // console.log(res.data);
          }
        })
        .catch(async (err) => {
          // if(err) console.log(err)
        });
    }
  };

  encodeId = (id) => {
    try {
      return window.btoa(window.btoa(id));
    } catch (e) {
      return;
    }
  };

  prePlay = (e) => {
    try {
      if (e.cancelable) {
        e.preventDefault();
      }
      const dom = document.getElementById(e.target.id);
      if (!dom) return;
      this.mounted && dom.play();
      dom.muted = false;
    } catch (err) {
      return;
    }
  };

  prePause = (e) => {
    try {
      if (e.cancelable) {
        e.preventDefault();
      }
      const dom = document.getElementById(e.target.id);
      if (!dom) return;
      this.mounted && dom.pause();
    } catch (err) {
      return;
    }
  };

  render() {
    const { participant } = this.props;
    const url = "https://medillery.com/user/";
    return (
      <React.Fragment>
        {this.state.statusPosts.data &&
          this.state.statusPosts.data.map((status) => (
            <div className="mb-3" key={status.id} onClick={() => {} /*this.test(status.id)*/}>
              <Box py={2} px={2} className="componentBg">
                <div style={{ position: "relative" }}>
                  <div className="d-flex mr-2 mb-2">
                    <Avatar
                      alt={noImage}
                      className="img-fluid mr-2"
                      src={
                        status.poster.profilePic
                          ? `${status.poster.profilePic}`
                          : `${noImage}`
                      }
                    />
                    <Box className="d-flex flex-column">
                      <Typography className="font-weight-bold smallerText">
                        <Link
                          className="text-decoration-none"
                          to={
                            status.poster.userName.toLowerCase() ===
                            participant.userName.toLowerCase()
                              ? "/dashboard/profile"
                              : `/user/${status.poster.userName}`
                          }
                        >
                          @{status.poster.userName}
                        </Link>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ fontSize: "0.7rem" }}
                      >
                        {CHAT_LIKE_TIME(status.created_at)}
                      </Typography>
                    </Box>
                  </div>
                  {status.poster.userName.toLowerCase() ===
                    participant.userName.toLowerCase() && (
                    <div style={{ position: "absolute", right: 0, top: 0 }}>
                      <MoreHorizRoundedIcon
                        fontSize="large"
                        onClick={(e) =>
                          this.triggerMenu(e, status.id, status.poster.userName)
                        }
                        className="w3-circle w3-hover-light-gray "
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        disabled={true}
                      />
                    </div>
                  )}
                </div>
                <div className="smallerText">
                  {status.description && (
                    <div className="wordBreakWord">
                      {status.description.slice(0, 100)}
                      {status.description.length > 100 && (
                        <Link to={`/dashboard/post/${this.encodeId(status.id)}`}>
                          {" "}
                          read more
                        </Link>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className={`row my-1 ${
                    status.status_files.length >= 2 &&
                    status.status_files.length <= 4
                      ? ""
                      : ""
                  }`}
                >
                  {status.status_files.length > 0 &&
                    status.status_files.map((file, index) => (
                      <div
                        key={index}
                        className={`p-0 ${
                          status.status_files.length === 1
                            ? "col-12"
                            : status.status_files.length === 2
                            ? "col-6 "
                            : status.status_files.length === 3
                            ? "col-6"
                            : status.status_files.length === 4
                            ? "col-6"
                            : "col-12"
                        }`}
                      >
                        <div style={classes.statusImageCard}>
                          <Link
                            to={`/dashboard/post/${this.encodeId(status.id)}`}
                          >
                            {file.type < 3 ? (
                              <img
                                id="statusImage"
                                alt={noImage}
                                className="statusImage cursorZoomIn"
                                style={classes.statusImageStyle}
                                src={`${file.name}`}
                              />
                            ) : (
                              <video
                                style={classes.statusImageStyle}
                                className="w3-round m-2"
                                id={`vid-${index}`}
                                // onTouchStart={this.prePlay}
                                // onTouchEnd={this.prePause}
                                onMouseOver={this.prePlay}
                                onMouseOut={this.prePause}
                              >
                                <source
                                  src={`${file.name}`}
                                  type={`video/${file.name.slice(-3)}`}
                                />
                                Your browser does not support the video tag.
                              </video>
                            )}
                          </Link>
                        </div>
                        <div
                          className="small rounded-pill"
                          style={{
                            marginTop: "-20px",
                            width: "80px",
                            paddingLeft: "5px",
                            zIndex: 10,
                            background: "rgba(255, 255, 255, 0.3)",
                          }}
                        >
                          {this.filterLiked(file.id, status.file_liked) ? (
                            <FavoriteIcon
                              fontSize="small"
                              className="cursorPointer customPrimaryColor"
                              onClick={() =>
                                this.likeStatusPostFile(status.id, file.id)
                              }
                            />
                          ) : (
                            <FavoriteBorderIcon
                              fontSize="small"
                              className="cursorPointer"
                              onClick={() =>
                                this.likeStatusPostFile(status.id, file.id)
                              }
                            />
                          )}
                          <span className="mx-2">
                            {file.likes === 0
                              ? ""
                              : `${
                                  file.likes > 1
                                    ? `${file.likes} likes`
                                    : `${file.likes} like`
                                }`}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
                <div>
                  {status.tag && (
                    <div className="d-flex justify-content-center">
                      {status.tag
                        .toString()
                        .split(",")
                        .map((tag, index) => (
                          <div
                            key={index}
                            className="w3-tiny w3-light-grey p-1 btn rounded-pill mr-2"
                          >
                            {tag}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <div className="d-flex small mb-2">
                  <div>
                    {status.likes === 0
                      ? ""
                      : `${
                          status.likes > 1
                            ? `${status.likes} likes`
                            : `${status.likes} like`
                        }`}
                  </div>
                  <div className="ml-auto">
                    {status.comments_count === 1
                      ? `${status.comments_count} Comment`
                      : `${
                          status.comments_count > 1
                            ? `${status.comments_count} Comments`
                            : ""
                        }`}{" "}
                    &nbsp; {/*50 shares*/}
                  </div>
                </div>
                <Divider />
                <div className="py-1 px-4 d-flex justify-content-between align-items-center">
                  <div>
                    {status.post_liked ? (
                      <FavoriteIcon
                        fontSize="small"
                        className="cursorPointer customPrimaryColor"
                        onClick={() => this.likeStatusPost(status.id)}
                      />
                    ) : (
                      <FavoriteBorderIcon
                        fontSize="small"
                        className="cursorPointer"
                        onClick={() => this.likeStatusPost(status.id)}
                      />
                    )}
                  </div>
                  {/*
                                    <div>
                                        <span className="text-decoration-none text-dark small">
                                            <ChatBubbleOutlineIcon fontSize="small" />
                                            <span className="ml-1">comment</span>                                            
                                        </span>
                                    </div>
                                */}
                  <OverlayTrigger
                    trigger="click"
                    placement="auto"
                    overlay={
                      <Popover id={`popover-positioned-auto`}>
                        <Popover.Content className="px-5">
                          <FacebookShareButton
                            url={`${url}${
                              status.poster.userName
                            }/post/${this.encodeId(status.id)}`}
                          >
                            <FacebookIcon size={30} />
                          </FacebookShareButton>
                          <hr />
                          <TwitterShareButton
                            url={`${url}${
                              status.poster.userName
                            }/post/${this.encodeId(status.id)}`}
                          >
                            <TwitterIcon size={30} />
                          </TwitterShareButton>
                          <hr />
                          <WhatsappShareButton
                            url={`${url}${
                              status.poster.userName
                            }/post/${this.encodeId(status.id)}`}
                          >
                            <WhatsappIcon size={30} />
                          </WhatsappShareButton>
                          <hr />
                          <LinkedinShareButton
                            url={`${url}${
                              status.poster.userName
                            }/post/${this.encodeId(status.id)}`}
                          >
                            <LinkedinIcon size={30} />
                          </LinkedinShareButton>
                          <hr />
                          <TelegramShareButton
                            url={`${url}${
                              status.poster.userName
                            }/post/${this.encodeId(status.id)}`}
                          >
                            <TelegramIcon size={30} />
                          </TelegramShareButton>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <span className="ml-1" style={{ cursor: "pointer" }}>
                      <ShareIcon fontSize="small" /> share
                    </span>
                  </OverlayTrigger>
                </div>
                {/*commenter*/}
                <div className="d-flex mr-2 mb-2 mt-3">
                  <Avatar
                    alt={noImage}
                    className="img-fluid mr-2"
                    src={
                      this.props.participant.profilePic
                        ? this.props.participant.profilePic
                        : noImage
                    }
                    style={{ width: "30px", height: "30px" }}
                  />
                  <textarea
                    rows="1"
                    id={`${status.id}inputId`}
                    className="form-control form-control-sm mr-1 my-auto rounded-pill"
                    placeholder="comment"
                    maxLength="10000"
                    onChange={async (e) => {
                      await this.setState({ [e.target.id]: e.target.value });
                    }}
                  />
                  <button
                    type="submit"
                    className="btn btn-sm rounded-pill ml-auto my-auto customPrimaryBgColor"
                    style={classes.sendCommentBtn}
                    onClick={() =>
                      this.commentOnStatus(
                        status.id,
                        this.state[`${status.id}inputId`],
                        `${status.id}inputId`
                      )
                    }
                  >
                    <ChevronRightIcon fontSize="small" />
                  </button>
                </div>
                {status.comments.length > 0 && (
                  <Box style={{ overflow: "auto" }}>
                    {status.comments.map((comment, index) => (
                      <div className="media mb-2" key={index}>
                        <Avatar
                          alt={this.state.noImage}
                          className="mr-2"
                          style={{ width: "30px", height: "30px" }}
                          src={
                            comment.profilePic
                              ? comment.profilePic
                              : this.state.noImage
                          }
                        />
                        <div
                          className="media-body p-1 smallerText"
                          style={classes.customCommentBg}
                        >
                          <div className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                              <Link
                                to={`/user/${comment.userName}`}
                                className="text-decoration-none text-dark font-weight-bold mr-2"
                              >
                                {`@${comment.userName}`}
                              </Link>
                              {comment.role === 1 && (
                                <Typography
                                  variant="subtitle2"
                                  className="text-muted ml-2"
                                  style={{ fontSize: "0.7rem" }}
                                >
                                  Graphics Designer
                                </Typography>
                              )}
                            </div>
                            <Typography></Typography>
                            <Typography
                              variant="subtitle2"
                              className="text-muted ml-auto mr-3"
                              style={{ fontSize: "0.7rem" }}
                            >
                              {CHAT_LIKE_TIME(comment.created_at)}
                            </Typography>
                          </div>
                          {comment.comment}
                        </div>
                      </div>
                    ))}
                  </Box>
                )}
                <Box>
                  {status.comments_count > status.comments.length && (
                    <div className="d-flex justify-content-center text-center text-muted">
                      <Link
                        className="w3-btn w3-round-xxlarge d-block text-decoration-none small w3-hover-teal"
                        to={`/dashboard/post/${this.encodeId(status.id)}`}
                      >
                        Show comments
                      </Link>
                    </div>
                  )}
                </Box>
              </Box>
            </div>
          ))}
        <Menu
          id="simple-menu"
          anchorEl={this.state.openMenu}
          keepMounted
          open={Boolean(this.state.openMenu)}
          onClose={this.closeMenu}
          className=""
        >
          <MenuItem
            onClick={() => {
              this.deleteStatus();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    participant: state.participant,
    initialStatusPosts: state.initialStatusPosts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInitialStatusPosts: (payload) => dispatch(loadStatusPost(payload)),
  };
};
const withRouter=Child=>props=>{
	const navigate = useNavigate();
	return(
			<Child 
			{...props} 
			navigate={navigate} 
			/>
	)
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Statuses));
