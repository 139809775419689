import React from 'react';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import NoAuthUserPost from './NoAuthUserPost';
import AuthUserPost from './AuthUserPost';



const PostCover=({ participant})=>{

    return(
        <React.Fragment>
            <Typography variant="h5" className="sectionTitle mb-2">Posts</Typography>
            {Object.entries(participant).length < 1?
                <div>
                    <NoAuthUserPost />
                </div>:
                <div>
                    <AuthUserPost />
                </div>
                
            }
        </React.Fragment>
    )
}

const mapStateToProps=(state)=>{
	return{
        participant: state.participant,
	}
}


export default connect(mapStateToProps)(PostCover);