import React from 'react';
import NotFound from '../../../NotFound';
import UserCover from './user/UserCover';
import PostDetailsCover from './Post/PostDetailsCover';
import DoneProjects from './user/DoneProjects';



const UserRoute = [
    {
        path:'/',
        element: <NotFound />,
        index:true,
    },
    {
        path:'/:details',
        element: <UserCover  />,
    },
    {
        path:'/:details/post/:id',
        element: <PostDetailsCover  />,
    },
    {
        path:'/:details/completed-projects',
        element: <DoneProjects  />,
    },
]

export default UserRoute;
/*

onst UserRoute = {
    '/': ()=> <NotFound />,
    '/:details': ({details})=> <UserCover details={details} />,
    '/:details/post/:id': ({details, id})=> <PostDetailsCover details={details} id={id} />,
    '/:details/completed-projects': ({details})=><DoneProjects details={details} />,
}

*/