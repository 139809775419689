import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import DbPostDetails from './DbPostDetails';
import { PROXY } from '../../../global/constants/action_types';
import { processHeader, loadSpecificUserPost, somePageNotFound } from '../../../global/action';
import LazyLoader from '../../../LazyLoader';


const quitRequest = Axios.CancelToken.source();
const DbPostCover=({id,participant, somePageNotFound, ...rest})=> {

    const mounted = React.useRef(true);

    const [loadingPost, setLoadingPost] = useState(true);


    useEffect(()=>{
        let num;
        try {
            num = window.atob(window.atob(id));        
        } catch (e) {
            setLoadingPost(false);
            somePageNotFound(true);
            return;
        }

        const getPost=()=>{
            // eslint-disable-next-line
            Axios.get(`${PROXY}/api/get_auth_post/${participant.userName}/${num}`,
                {headers:processHeader(), cancelToken: quitRequest.token}
            ) 
            .then(async(res)=>{
                if(res.data.success){
                    if(!mounted.current) return;
                    // console.log(res.data.data[0]);
                   await rest.loadSpecificPost(res.data.data[0]);
                   await setLoadingPost(false);
                   await somePageNotFound(false);
                }
            })
            .catch(async(err)=>{
                if(!mounted.current) return;
                if(err && err.response && err.response.status === 404){
                    await somePageNotFound(true);
                }
            })

        }

        getPost();
        return ()=>{
            // quitRequest.cancel();
            mounted.current = false;
            }
        // eslint-disable-next-line
    },[id])




    return(<div>
        {(loadingPost) ?
            <div >
                <LazyLoader />
            </div> :
           <div>
           {mounted.current &&
               <DbPostDetails pathId={id} />

           }
            </div>
        }    
    </div>)




}





const mapStateToProps=(state)=>{
	return{
        participant: state.participant,
	}
}

const mapDispatchToProps=(dispatch)=>{
    return{
        loadSpecificPost: (payload)=>dispatch(loadSpecificUserPost(payload)),
        somePageNotFound:(payload)=>dispatch(somePageNotFound(payload)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DbPostCover);
