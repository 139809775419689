import React, { Component } from 'react';
import '../../CSSs/Business/six/six.css';
import { Typography } from '@material-ui/core';
import download from 'downloadjs';
import * as htmlToImage from 'html-to-image';
import GDHeader from '../GDHeader';
// import CopyRight from '../../CopyRight'
class BusinessEditSix extends Component {
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        logoText: "Your Logo",
        yourDetailsHere: "YOUR DETAILS HERE",
        discountText: "50%",
        featureTexts: "Feature1, Feature2, Feature3",
        contactDetails: "Your Contact Details",
        addressHere: "Address Here",
    }
   
    changeImageOne = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const slot = document.getElementById('imgSlotOne');
        if(!slot) return;
        slot.src = URL.createObjectURL(file);
    }
    changeImageTwo = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const slotTwo = document.getElementById('imgSlotTwo');
        if(!slotTwo) return;
        slotTwo.src = URL.createObjectURL(file);
    } 
    changeLogo = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const logo = document.getElementById('logoSlot');
        if(!logo) return;
        logo.src = URL.createObjectURL(file);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }
    handleFinishEdit = (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('outSideDiv6'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
                var link = document.createElement("a");
                link.download = "medillery-template.jpeg";
                link.href = dataUrl;
                link.click();
            });          
    }
    
    downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('outSideDiv6'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }
    
    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }

    render() {
        return (<div style={{marginTop: '90px'}}>        
        <GDHeader caty='business' />
        
            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* this is the outside div that encapsulate all the others */}
                                        <div className="outSideDiv6" id="outSideDiv6">
                                            {/* this is the zig-zag circles */}
                                            <div className="circle6">
                                                <div className="yourLogo6">
                                                    <img id="logoSlot" src={require("../../../../assets/images/img.png")} 
                                                        alt="living Room" className="logoImage6" />
                                                </div>
                                                {/* <div className="yourLogo6">{this.state.logoText}</div> */}
                                                <div className="triangle6"></div> 
                                                <div className="triangle6"></div> 
                                                <div className="triangle6"></div> 
                                                <div className="triangle6"></div> 
                                                <div className="triangle6"></div> 
                                                <div className="triangle6"></div> 
                                                <div className="triangle6"></div> 
                                                <div className="triangle6"></div> 
                                                <div className="triangle6"></div> 
                                                <div className="triangle6"></div> 
                                                <div className="triangle6"></div> 
                                                <div className="triangle6"></div>
                                            </div>
                                            {/* the text area div */}
                                            <div className="text-center detailHere6">
                                                <h1>{this.state.yourDetailsHere }</h1>
                                            </div>
                                            {/* This is the middle/center container div for images */}
                                            <div className="centerContainer6">
                                                {/* the thick blue background line */}
                                                <div className="blueLineDiv6"></div>
                                                {/* the Div containing the two images with flex  */}
                                                <div className="imagesFlexDiv6">
                                                    <img id="imgSlotOne" 
                                                        src={require("../../../../assets/images/templates/heroes/8.jpeg")} 
                                                        alt="jeans" className="halfImg6" />
                                                    <img id="imgSlotTwo"
                                                        src={require("../../../../assets/images/templates/heroes/7.jpeg")} alt="bags" className="halfImg6" />
                                                </div>
                                                {/* the bottom div with other informations */}
                                                <div className="belowImgDiv6">
                                                    {/* div seperating the zizag and the text  */}
                                                    <div className="divFlexCircle6">
                                                        <div className="circleTwo6">
                                                            <div className="getUpTo6">Get Up To<h5> {this.state.discountText} OFF</h5></div>
                                                            <div className="secondTriangle6"></div> 
                                                            <div className="secondTriangle6"></div> 
                                                            <div className="secondTriangle6"></div> 
                                                            <div className="secondTriangle6"></div> 
                                                            <div className="secondTriangle6"></div> 
                                                            <div className="secondTriangle6"></div> 
                                                            <div className="secondTriangle6"></div> 
                                                            <div className="secondTriangle6"></div> 
                                                            <div className="secondTriangle6"></div> 
                                                            <div className="secondTriangle6"></div> 
                                                            <div className="secondTriangle6"></div> 
                                                            <div className="secondTriangle6"></div>
                                                        </div>
                                                    </div>
                                                    {/* The text details  */}
                                                    <div className="divFlexText6">
                                                        <p>{this.state.featureTexts}</p>
                                                        <p>{this.state.contactDetails}</p>
                                                        <p>{this.state.addressHere}</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-4">
                            {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                        <div className="form-group mt-3">
                                            <label>Image 1</label>
                                            <label className="form-control rounded-pill">
                                                <input id="imgSlotOne"
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeImageOne}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label>Image 2</label>
                                            <label className="form-control rounded-pill">
                                                <input id="imgSlotTwo"
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeImageTwo}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label>Logo</label>
                                            <label className="form-control rounded-pill">
                                                <input
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeLogo}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group d-flex">
                                            <div className="mr-2">
                                                <label>Detail Title</label>
                                                <input
                                                    type="text" id="yourDetailsHere"
                                                    value={this.state.yourDetailsHere} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div> 
                                            <div>
                                                <label>Discounts</label>
                                                <input id="discountText"
                                                    type="text" value={this.state.discountText} 
                                                    onChange={this.handleChange}
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <label>Product Description</label>
                                                <input
                                                    type="text" id="featureTexts" 
                                                    value={this.state.featureTexts}
                                                    onChange={this.handleChange}  
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>  
                                        </div>
                                        <div className="form-group d-flex">
                                            <div className="mr-2">
                                                <label>Contact Details</label>
                                                <input
                                                    type="text" id="contactDetails"
                                                    value={this.state.contactDetails} 
                                                    onChange={this.handleChange}
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div>
                                                <label>Address</label>
                                                <input
                                                    type="text" id="addressHere"
                                                    value={this.state.addressHere}
                                                    onChange={this.handleChange} 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                            > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4 className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select className="form-control form-control-sm rounded-pill"
                                                onChange={this.downloadNow}
                                            >   <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                        className="btn btn-block rounded-pill customPrimaryBgColor" 
                                        onClick={ this.backToEdit }
                                        > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>);
    }
}
// jquery.scrollLeft() to move the whole div left and right
// https://stackoverflow.com/questions/27313480/scroll-div-content-horizontally-using-left-and-right-arrow-in-jquery

export default BusinessEditSix;
