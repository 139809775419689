import React from 'react';
import TagProfessional from './TagProfessional';
import Enjoy from './enjoy/Enjoy';
import Profile from './Profile/Profile';
import UpdateStatus from './UpdateStatus';
import Gallery from './Gallery';
import Projects from './ProjectPart/Projects';
import Subscriptions from './Subscriptions';
import Notifications from './Notifications';
import DbPostCover from './Status/DbPostCover';
import DetailsCover from './ProjectPart/DetailsCover';
import ProjectSubmission from './ProjectPart/ProjectSubmission';
import RateProfessional from './ProjectPart/RateProfessional';
import InboxList from './inbox/ListCover';
import ChatCover from './inbox/ChatCover'
import DbTemplates from './DbTemplates';
import ProfessionalDoneFiles from './Profile/ProfessionalDoneFiles';
import AdminChat from './adminChatBox/AdminChat';
import ReportTicket from './ProjectPart/ReportTicket';
import { Outlet } from 'react-router-dom';
import CoverUserAndPost from './User/CoverUserAndPost';
// import { TagProfessionalMaintenance } from './maintenance/MaintainAssignProject';


const dbRoutes =[
    {
        path:'/',
        element: <DbTemplates />,
        index:true,
    },
        // children: [
            {
                path:'/projects',
                element: <Projects />,    
            },
            {
                path:'/projects/:details',
                element: <DetailsCover />,
            },
            {
                path:'/projects/submit/:details',
                element: <ProjectSubmission />,
            },
            {
                path:'/projects/rate/:details',
                element: <RateProfessional />,
            },
            {
                path:'/projects/issue/:id',
                element: <ReportTicket />,
            },
            {
                path:'/user',
                element: <CoverUserAndPost><Outlet /></CoverUserAndPost>
                //  <User ><Outlet /> </User>,
            },
            {
                path:'/status',
                element: <UpdateStatus />,
            },
            {
                path:'/gallery',
                element: <Gallery />,
            },
            {
                path:'/notifications',
                element: <Notifications />,
            },
            {
                path:'/enjoy',
                element: <Enjoy />,
            },
            {
                path:'/profile',
                element: <Profile />,
            },
            {
                path:'/inbox',
                element: <InboxList />,
            },
            {
                path:'/inbox/:room',
                element: <ChatCover  />,
            },
            {
                path:'/post/:id',
                element:  <DbPostCover />,
            },
            {
                path:'/subscriptions',                
                element: <Subscriptions />,
                
            },
            {
                path:'/tag-professional',                
                // element: <TagProfessionalMaintenance />,
                element: <TagProfessional />,
                
            },
            {
                path:'/profile/completed-projects',
                element: <ProfessionalDoneFiles />,
            },
            {
                path:'/admin/:id',
                element: <AdminChat />,
            },
        // ]
    // },
]

export default  dbRoutes;
/*


const dbRoutes ={
    '/': ()=><DbTemplates />,
    '/projects': ()=> <Projects />,    
    '/projects/:details':({details})=> <DetailsCover details={details} />,
    '/projects/submit/:details':({details})=><ProjectSubmission details={details} />,
    '/projects/rate/:details': ({details})=><RateProfessional details={details} />,
    '/projects/issue/:id': ({id})=><ReportTicket id={id} />,
    '/user': ()=> <User />,
    '/status': ()=> <UpdateStatus />,
    '/gallery': ()=> <Gallery />,
    '/notifications': ()=> <Notifications />,
    '/enjoy': ()=> <Enjoy />,
    '/profile': ()=> <Profile />,
    '/inbox': ()=> <InboxList />,
    '/inbox/:room':({room})=><ChatCover  room={room} />,
    '/post/:id': ({id})=> <DbPostCover id={id} />,
    '/tag-professional': ()=><TagProfessional />,
    '/profile/completed-projects': ()=><ProfessionalDoneFiles />,
    '/admin/:id':({id})=><AdminChat id={id} />,
    

}
*/