import React, { Component } from 'react';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { Dialog, DialogTitle } from '@material-ui/core';
import { noImage, EXTRACTOR } from '../../../../global/generalMethods/general';
// import { statusPosts } from '../../../global/publicVariables/variables';
import BasicInfo from './BasicInfo';
import PostCover from '../Post/PostCover';
import { connect } from 'react-redux';
import { PUT_BEFORE, TOKES, PROXY } from '../../../../global/constants/action_types';
import { participantInfo, processHeader, loadRelationship, loadUserPageOwner } from '../../../../global/action';
import Axios from 'axios';
import MessageUser from './MessageUser';
import './../messageStyle.css';

const classes = {
    topBackground: {
        background: 'linear-gradient(270deg, #387DFF 0%, #004BD6 100%)',
        height: '170px',
        marginBottom: '-75px',
    },
    userImage: {
        height: '150px',
        width: '150px',
    },
    profileBox: {
        height: '90px',
        width: '90px',
    },
    statusImageCard: {
        height: '250px',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        background: 'rgba(0, 0, 0, 0.5)',
    },
    statusImageStyle: {
      width: '100%',
      minHeight: '100%',
      position: 'absolute',
      left: '0',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    sendCommentBtn: {
        width:'30px',
        color: '#F9FBFF',
        background: 'linear-gradient(92deg, #FA9347 5.16%, #F07672 99.12%)',
    }
}
class User extends Component {
    state = {
        loadingParticipant: true,
        showChatBox: false,
        checkingRelationship:true,
        isFollowed: null,
        isChecked: false,
        processing: false,
        somethingWentWrong: false,
        shouldUnFollow: false,
        
        
    }
    mounted;
    componentDidMount = async () => {
        this.mounted = true;
        if(!this.props.participant.userName){
            await this.getParticipant();
        }
        else{
            if(!this.mounted) return;
            await this.setState({loadingParticipant:false});
            this.checkRelationship(this.props.participant.userName);
        }        
    }

    componentWillUnmount=()=>{
        this.mounted = false;

    }

    UNSAFE_componentWillReceiveProps=(next)=>{
        if(next.participant.userName && !this.state.isChecked){
            if(!this.mounted) return;
            alert(next.participant.userName)
            this.checkRelationship(next.participant.userName);
        }
    }

    closeUnFollow=()=>{
        this.setState({shouldUnFollow: false});
    }

    propShowChatBox=(condition)=>{
        if(!this.mounted) return;
        this.setState({showChatBox:condition});
    }

    triggerShowChatBox=async(e)=>{
        e.preventDefault();
        if(!this.mounted) return;
        if(!this.props.participant.userName) return;
        await this.setState({showChatBox:true});
    }

    getParticipant=()=>{
        const processHeader=()=>{
            const identifier = EXTRACTOR(PUT_BEFORE,localStorage.MedToken,TOKES)
            if(!identifier || identifier.length < 50){
                return false;                
            }else{
              return {Authorization: `Bearer ${identifier}`};
            }
        }
        let auth = processHeader();
        if(!this.mounted) return;
        if(!auth) return this.setState({loadingParticipant: false});
        Axios.get(`${PROXY}/api/identify/XXPAXX`, {headers: auth})
       .then(async(res)=>{
           if (res.data.success){
              if(!this.mounted) return;
              await this.props.resetIdentity(JSON.parse(window.atob(res.data.data)));
              await this.checkRelationship(JSON.parse(window.atob(res.data.data)).userName);
              await this.setState({loadingParticipant: false});
           }
        }).catch(err=>{
            if(!this.mounted) return;
            return this.setState({loadingParticipant: false});
        });
    }

    checkRelationship=(participant)=>{
        Axios.get(`${PROXY}/api/check_relationship/${participant}/${this.props.user.userName}`, {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                // console.log(res.data.data);
                await this.setState({isFollowed:res.data.data});
                await this.setState({isChecked: true});
                await this.props.loadRelationship(res.data.data);
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            await this.setState({isChecked:true});
            // if(err.response.status === 500 || err.response.status === 400) console.log(err.response);
        })        
    }

    follow=async(e)=>{
        e.preventDefault();
        if(this.state.processing) return;
        if(!this.mounted) return;
        if(this.state.somethingWentWrong) await this.setState({somethingWentWrong: false});
        await this.setState({processing:  true});
		Axios.get(`${PROXY}/api/follow/${this.props.participant.userName}/${this.props.user.userName}`, {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                await this.setState((data)=>{return{isFollowed:{followed: 1, following:data.isFollowed.following}}});
                await this.props.loadRelationship({followed: 1, following:this.state.isFollowed.following});
                const tempUser = Object.assign({}, this.props.user);
                tempUser.followers_count++;
                await this.props.loadUser(tempUser);
                await this.setState({processing: false});
                // console.log(res.data.data, this.state.isFollowed)
            }
        })
        .then(async(err)=>{
            if(!this.mounted) return;
            // if(err && err.response) console.log(err.response);
            await this.setState({somethingWentWrong: true});
            await this.setState({processing: false});
        })
    }

    unFollow=async()=>{
        if(!this.mounted) return;
        if(this.state.processing) return;
        if(this.state.somethingWentWrong) this.setState({somethingWentWrong: false});
        await this.setState({processing:  true});
		Axios.get(`${PROXY}/api/unfollow/${this.props.participant.userName}/${this.props.user.userName}`, {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                await this.setState((data)=>{return{isFollowed:{followed: 0, following:data.isFollowed.following}}});
                await this.props.loadRelationship({followed: 0, following:this.state.isFollowed.following});
                const tempUser = Object.assign({}, this.props.user);
                tempUser.followers_count--;
                await this.props.loadUser(tempUser); 
                await this.setState({processing: false});
                // console.log(res.data.data, this.state.isFollowed)
            }
        })
        .then(async(err)=>{
            if(!this.mounted) return;
            // if(err && err.response) console.log(err.response);
            await this.setState({somethingWentWrong: true});
            await this.setState({processing: false});
        })
    }

    filterLiked=(id, monitor)=>{
        if(monitor.length>0){
          return  monitor.some(like=>like.status_file_id === id);
        }else{
            return false;
        }
    }


    render() {
        const {user, participant}= this.props
        return (
            <div className="container componentBg p-0" >
            	<div style={classes.topBackground}>
                </div>
                <div className="row justify-content-between align-items-center px-5">
                    {user.profilePic?
                        <a href={`${user.profilePic}`} rel="noopener noreferrer" target='_blank' download>
                            <img className="img-fluid rounded-circle" alt="alt-img"
                                style={classes.userImage}
                                src={user.profilePic}
                            />
                        </a>:
                        <img className="img-fluid rounded-circle" alt="alt-img"
                                style={classes.userImage}
                                src={noImage}
                        />
                    }
                    <div className="d-flex align-items-center mt-5">
                        <button onClick={this.triggerShowChatBox} className="btn btn-outline-primary rounded-pill mr-2">
                            <ChatBubbleOutlineIcon fontSize="small" />
                        </button>                        
                        { (this.state.isChecked && this.state.isFollowed) &&
                            <div> 
                                {(Number(this.state.isFollowed.followed) === 1) &&
                                    <div>
                                        <button className="btn rounded-pill customSecondaryBgColor"
                                            onClick={()=>{this.setState({shouldUnFollow: true})}}
                                        >
                                            <small className="text-white">Unfollow</small>
                                            
                                        </button>
                                        <Dialog aria-labelledby="simple-dialog-title"
                                            onClose={this.closeUnFollow}  open={this.state.shouldUnFollow}
                                        >
                                        <DialogTitle id="simple-dialog-title">
                                            <small className='w3-small'>Are you sure you want to unfollow {user.userName}?</small>
                                        </DialogTitle>
                                        <div className="text-center mb-2">
                                            <button 
                                                className='btn customPrimaryBgColor rounded-pill text-white mr-2' 
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    this.closeUnFollow();
                                                    this.unFollow()
                                                }}
                                            >
                                                Yes
                                            </button>
                                            <button 
                                                className='btn btn-warning rounded-pill' 
                                                onClick={(e)=>{e.preventDefault(); this.closeUnFollow()}}
                                            >
                                                No
                                            </button>
                                        </div>
                                        </Dialog>
                                    </div>
                                }
                                {(Number(this.state.isFollowed.followed) === 0) &&
                                    <button onClick={this.follow} className="btn rounded-pill customPrimaryBgColor">
                                        <small className="text-white">Follow</small>
                                    </button>
                                }
                            </div>
                        }
                    </div>
                </div>
            	<div className="row card-body">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="position-sticky" style={{ top: '70px'}}>
                            <BasicInfo />
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6">
                        {(!this.state.loadingParticipant) &&
                            <PostCover />                        
                        }
                    </div>
                </div>
                <div className='placing'>
                    {(participant.userName && this.state.showChatBox) &&
                        <MessageUser condition={this.propShowChatBox} />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps=(state)=>{
	return{
        participant: state.participant,
        user: state.userPageOwner,
	}
}

const mapDispatchToProps=(dispatch)=>{
    return{
        resetIdentity: (payload)=> dispatch(participantInfo(payload)),
        loadRelationship: (payload)=>dispatch(loadRelationship(payload)),
        loadUser: (payload)=>dispatch(loadUserPageOwner(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(User);