import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { GoogleLogin } from 'react-google-login';
import { Formik, Form, Field} from 'formik';
import {Row, Col} from 'react-bootstrap';
import * as Yup from 'yup';
import './FormStyle.css';
import ErrorFeedBack from './ErrorFeedBack';
import { connect } from 'react-redux';
import { participantInfo, is_login, showAuthModal } from '../../global/action';
import {TOKES, PUT_BEFORE, PROXY } from '../../global/constants/action_types';
import { DEFLECTION, addDays } from '../../global/generalMethods/general';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Axios from 'axios';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum of 8 is required')
    .required('Required')
		.matches(/(^([a-zA-Z0-9_]){8,}$)/, "0-9 , _ , a-z are only accepted"),

  email: Yup.string()
    .email('Invalid email')
		.required('Required'),
});

  // The SIGNIN CLASS STARTS HERE
class SignInModal extends Component {
	state = {
		processing: false,
		modal: true,
		validationError: null,
		directError: null,
		signUpper: true,
		recoverer:false,
		passwordType:'password',

	}

	changeToString=()=>{
		this.setState({passwordType:'string'});
	}

	changeToPassKey=()=>{
		this.setState({passwordType:'password'});
	}

	responseGoogle = (response) => {
		if(!this.state.processing){
			this.setState({ processing: true});
			if (response.profileObj){
				const apiReturn  = new FormData();
				apiReturn.append('type', "SocialAuth");
				apiReturn.append('email', response.profileObj.email);

				Axios.post(`${PROXY}/api/login`, apiReturn)
				.then(async(res)=>{
					if(res.data.success){
						await this.setState({ processing: false});
						localStorage.MedToken = DEFLECTION(PUT_BEFORE,res.data.token+TOKES);
						this.props.isLogin(true);
						const _participant = JSON.parse(window.atob(res.data.data))
						console.log(_participant);
						// add participant uuid to cookie
						if(_participant?.uuid) {
							const expireAt = addDays(4);
							document.cookie = `mediUuid=${_participant.uuid}; expires=${expireAt}`				
							const redirect = this.props.searchParams.get("redirect");
							if(redirect){
								const url = new URL(redirect);
								url.searchParams.append("user_uuid", _participant.uuid)
								window.location.replace(url);
								return
							}
						}
						await this.props.participant(_participant);
						if(this.props.goPage !== '/'){
							return this.props.navigate(this.props.goPage);
						}else{
							return this.props.navigate('/dashboard');
						}
					}else{
								await this.setState({ processing: false});
								if(res.data.Error && res.data.Error === 'xxx'){
									this.props.setAuthModal(false);
									localStorage.clear();
									return this.props.navigate('/review');
								}
						// if(res.data.message === "Invalid Email or Password"){
								res.data.validationError.email = "Invalid Email or Password "
								await this.setState({validationError: res.data.validationError});

						// }
					}
				})
				.catch( async(err)=>{
					// if(err) console.log(err);
					if(err.response && (err.response.status === 401)){
						await this.setState({directError: "Invalid Email or Password \n Please click on signup"});
					}
					await this.setState({ processing: false});
				});
			}else{
				this.setState({ processing: false});
			}
		}
	}

	submitSignIn = async(value) => {
		if(!this.state.processing){
			await this.setState({validationError: {} });
			await this.setState({directError: null});
			await this.setState({ processing: true});
			const form = new FormData()
			await form.append('type', "AppAuth");
			await form.append('password', value.password);
			await form.append('email', value.email);
			Axios.post(`${PROXY}/api/login`, form )
				.then(async(res)=>{
					if(res.data.success){
						this.setState({ processing: false});
						localStorage.MedToken = DEFLECTION(PUT_BEFORE,res.data.token+TOKES);
						await this.props.isLogin(true);
						const _participant = JSON.parse(window.atob(res.data.data))
						// add participant uuid to cookie
						if (_participant?.uuid) {
							const expireAt = addDays(4);
							document.cookie = `mediUuid=${_participant.uuid}; expires=${expireAt};`								
							const redirect = this.props.searchParams.get("redirect");
							if(redirect){
								const url = new URL(redirect);
								url.searchParams.append("user_uuid", _participant.uuid)
								window.location.replace(url);
								return
							}
						}
						await this.props.participant(_participant);
						if(this.props.goPage !== '/'){
							return this.props.navigate(this.props.goPage);
						}else{
							return this.props.navigate('/dashboard');
						}
					} else {
						// Disabled user
						if(res.data.Error && res.data.Error === 'xxx'){		
							this.props.setAuthModal(false);					
							localStorage.clear();
							return this.props.navigate('/review');
						}
						if(res.data.error){
						await this.setState({directError: "Invalid Email or Password "});
					}					
					await this.setState({ processing: false});
				}
			})
			.catch(async(err)=>{
				if( err && err.response && err.response.status === 401) await this.setState({directError: "Invalid Email or Password"});
				await this.setState({ processing: false});
			});
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="mt-4">

				<Formik
					initialValues={{password: '', email: '',}}
					validationSchema={SignupSchema}
					onChange={this.handleChange}
					onSubmit={this.submitSignIn}
				>
					{({ errors,
						touched,
						values}) => (
						<Form style={{marginTop: '10px'}}>
								<Row>
									<Col xs={1} sm={1} md={1}></Col>
									<Col xs={10} sm={10} md={10}>
										<Field 
											className= {touched.email && errors.email ?'notValid': 'Manually'}
											 required type="email" name='email' value={values.email} 
										/>
										{errors.email && touched.email ? (<small className="margin-reduce">{errors.email}</small>): null}
										<label className={touched.email && errors.email ?'redLabel': 'labelly'}><small>Email </small></label>
									</Col>
									<Col xs={1} sm={1} md={1}></Col>
								</Row>
								<Row>
									<Col xs={1} sm={1} md={1}></Col>
									<Col xs={10} sm={10} md={10}>
										<div style={{position:'relative'}}>
											<Field className= {touched.password && errors.password ?'notValid': 'Manually'}
												required type={this.state.passwordType} name='password'
												tyle={{marginRight:'20px'}}
											/>
											<span 
												style={{position:'absolute', cursor:'pointer', right:0, marginLeft:'2px'}}
											>
												{this.state.passwordType === 'password' &&
													<VisibilityIcon onClick={()=>this.changeToString()} />
												}
												{this.state.passwordType === 'string' && 
													<VisibilityOffIcon onClick={()=>this.changeToPassKey()} />
												}
											</span>
										</div>
										{touched.password && errors.password ? (<small className="margin-reduce">{errors.password}</small>): null}
										<label className={touched.password && errors.password ?'redLabel': 'labelly'}><small>Password </small></label>
									</Col>
									<Col xs={1} sm={1} md ={1}></Col>
								</Row>

								<Row>
									<Col xs={1} sm={1} md={1}></Col>
									<Col xs={10} sm={10} md={10}>
									<div style={{marginTop: '-25px', color:'#F44336'}}>
										{this.state.validationError && <ErrorFeedBack  errors={this.state.validationError} />}
										{this.state.directError && <small className='d-flex justify-center'> {this.state.directError}</small>}
									</div>
									</Col>
									<Col xs={1} sm={1} md ={1}></Col>
								</Row>
								<Row>
									<Col xs={1} sm={1} md={1}></Col>
									<Col xs={10} sm={10} md={10}>
										<div className="text-right mb-2" onClick={()=>this.props.closeModal()}>
											<Link to="/forgot-password" className="text-decoration-none small">Forgot password?</Link>
										</div>
									</Col>
									<Col xs={1} sm={1} md ={1}></Col>
								</Row>
								<Row>
									<Col xs={1} sm={1} md={1}></Col>
									<Col xs={10} sm={10} md={10}>
										<Button  type='submit' variant="contained" disableElevation 
											className="customPrimaryBgColor btn-block text-white rounded-pill"
										>
											<Typography className="small">Sign In</Typography>
										</Button>
									</Col>
									<Col xs={1} sm={1} md={1}></Col>
								</Row>

						</Form>
					)}
					</Formik>
				<Row>
					<Col xs={{span: 2, offset: 5}} >
						{this.state.processing && <div className="w3-spin spinner w3-circle mt-2"></div>}
					</Col>
				</Row>
				<Row>
					<Col xs={{span: 2, offset: 5}} >
						<Typography className="text-center my-1">OR</Typography>
					</Col>
				</Row>
				<Row>
					<Col xs={2} sm={2} md={2}></Col>
					<Col xs={8} sm={8} md={8} className="text-center">
						<GoogleLogin
							className="rounded-pill text-center"
							clientId="63312386785-pq5gh1anun4b0cugvqp3sh1a806j8t5q.apps.googleusercontent.com"
							buttonText="Login with Google"
							onSuccess={this.responseGoogle}
							onFailure={this.responseGoogle}
							cookiePolicy={'single_host_origin'}
						/>
					</Col>
					<Col xs={2} sm={2} md={2}></Col>
				</Row>
			</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps=(state)=>{
	return{
		goPage: state.intendedPage,
	}
}
const mapDispatchToProps = (dispatch)=>{
	return {
		participant: (payload)=>dispatch( participantInfo(payload)),
		isLogin: (payload)=>dispatch(is_login(payload)),
		setAuthModal: (payload)=>dispatch(showAuthModal(payload)),
	}
}

const withRouter=Child=>props=>{
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	return(
			<Child 
			{...props} 
			searchParams={searchParams} 
			navigate={navigate} 
			/>
	)
}

export default  connect(mapStateToProps, mapDispatchToProps)(withRouter(SignInModal));