import React, {Component} from 'react';
import { Typography } from '@material-ui/core';
import './../../CSSs/Politics/eight/eight.css';
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs';
import GDHeader from '../GDHeader';

class PoliticsEditEight extends Component{
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        presidentialText: "PRESIDENTIAL AND SURBODINATE ELECTION 2021",
        coreText: "Lorem ipsum dolor",
        sloganText: "sit amet,Lorem ipsum dolor sit Lorem ipsum dolor sit amet consectetur",
        aspirantOneName: "Jackson Nicholas",
        aspirantTwoName: "Allison Jake",
    }

    changeBackground = (e) => {
        e.preventDefault();
        try {        
            const file = e.target.files[0];
            if(!file)  return;
            let back = document.getElementById("mainInsideDiv08");
            if(back){
                back.style.background = `linear-gradient(0deg, rgba(242, 242, 242, 0.7), rgba(242, 242, 242, 0.7)), url(${URL.createObjectURL(file)})`;
                back.style.backgroundSize = `cover`;
                back.style.backgroundRepeat = `no-repeat`;
                back.style.backgroundPosition = `center`;
            }
        } catch (e) {
            return;
        }
    }
    changeImageOne = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const slot = document.getElementById('imgSlotOne08');
        if(!slot) return;
        slot.src = URL.createObjectURL(file);
    }
    changeImageTwo = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const slot = document.getElementById('imgSlotTwo08');
        if(!slot) return;
        slot.src = URL.createObjectURL(file);
    }
    changeLogo = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const logo = document.getElementById('logoSlot08');
        if(!logo) return;
        logo.src = URL.createObjectURL(file);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    handleFinishEdit = (e) => {
      e.preventDefault();
      this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
      e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('outSideDiv08'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
                var link = document.createElement("a");
                link.download = "medillery-template.jpeg";
                link.href = dataUrl;
                link.click();
            });          
    }

    downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('outSideDiv08'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }

    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }
    render() {
        return ( <div style={{marginTop: '90px'}}>           
            <GDHeader caty='politics' />
  
            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-6 col-xl-6 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* this is the outside div that encapsulate all the others */}
                                        <div className="outSideDiv08" id="outSideDiv08">
                                            {/* The main insdie sideDiv for margin */}
                                            <div className="mainInsideDiv08" id="mainInsideDiv08">
                                                {/* this is the zig-zag circles */}
                                                <div className="circle08">
                                                    {/* <div className="yourLogo08">Party Logo</div>  */}
                                                    <div className="yourLogo08">
                                                        <img id="logoSlot08" src={require("../../../../assets/images/img.png")} 
                                                            alt="living Room" className="logoImage08" />
                                                    </div>
                                                </div>
                                                {/* the div containing the header and the images below */}
                                                <div className="textImagesDiv08">
                                                    {/* The text details div  */}
                                                    <div className="presidentialText08">
                                                        <h5>{this.state.presidentialText}</h5>
                                                    </div>
                                                    
                                                    {/* this is the cover for both images */}
                                                    <div className="coverImgRectDiv08">
                                                        {/* div for the flexed images */}
                                                        <div className="imagesDivFlex08">
                                                            <img id="imgSlotOne08"
                                                                src={require("../../../../assets/images/templates/heroes/3.jpeg")} alt="politics" className="imgFlexed08 imgFlexedOne08" />
                                                            <div className="yellowBoard08">{this.state.aspirantOneName}</div>
                                                        </div>
                                                        {/* div for the flexed images */}
                                                        <div className="imagesDivFlex08 imgFlexedTwo08">
                                                            <img id="imgSlotTwo08"
                                                                src={require("../../../../assets/images/templates/heroes/8.jpeg")} alt="politics" className="imgFlexed08" />
                                                            <div className="yellowBoard08">{this.state.aspirantTwoName}</div>
                                                        </div>
                                                    </div>
                                                    {/* text only inside the div */}
                                                    <div className="textOnly08">
                                                        <h5>{this.state.coreText}</h5>
                                                        <p>{this.state.sloganText}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-6 col-xl-6 mb-4">
                                {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                        <div className="mt-3 row">
                                            <div className="col-8 form-group">
                                                <label>Background</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="file" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeBackground}
                                                        />
                                                </label>
                                            </div>
                                            <div className="col-4 form-group">
                                                <label>Logo</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="file" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeLogo}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Image One</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="file" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeImageOne}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Image Two</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="file" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeImageTwo}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Aspirant One</label>
                                                <input id="aspirantOneName"
                                                    type="text" value={this.state.aspirantOneName} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Aspirant Two</label>
                                                <input id="aspirantTwoName"
                                                    type="text" value={this.state.aspirantTwoName} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="form-group">
                                            <label>Vote Title</label>
                                            <input
                                                type="text" id="presidentialText" 
                                                value={this.state.presidentialText} 
                                                onChange={ this.handleChange } 
                                                className="form-control rounded-pill" 
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Main Text</label>
                                            <input 
                                            type="text" id="coreText" 
                                            value={this.state.coreText} 
                                            onChange={ this.handleChange }
                                            className="form-control rounded-pill" 
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Extra Note</label>
                                            <textarea id="sloganText"
                                                value={this.state.sloganText} 
                                                onChange={ this.handleChange } 
                                                className="form-control rounded-pill" 
                                            >
                                            </textarea>
                                        </div>
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                            > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4 variant="subtitle2" className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select 
                                                className="form-control form-control-sm rounded-pill"
                                                onChange={this.downloadNow}
                                            >   
                                                <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                            className="btn btn-block rounded-pill customPrimaryBgColor" 
                                            onClick={ this.backToEdit }
                                            > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>                      
        </div>);
    }
}

export default PoliticsEditEight;

