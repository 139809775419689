export const IS_LOGIN = 'is_login';
export const PROXY= 'https://medilleryserver.medillery.com';
export const EXT_PROXY = process.env.NODE_ENV === 'development' ?
  "https://meditestapi.medillery.com" : "https://templateapi.medillery.com";
export const PRICING_PROXY = "https://pricing.medillery.com";
export const BEFORE_GALLERY= 'before_gallery';
export const AFTER_GALLERY = 'after_gallery';
export const BEFORE_DETAILS = 'before_details';
export const AFTER_DETAILS = 'after_details';
export const BEFORE_STATUS = 'before_status';
export const AFTER_STATUS = 'after_status';
export const BEFORE_STATUS_File = 'before_status_file';
export const AFTER_STATUS_File = 'after_status_file';
export const PUT_BEFORE = "put_before_the_token";
export const TOKES = "medillery_tokers_med";
export const PASSED = "passed";
export const CATEGORY = 'category';
export const SUBCATEGORY= 'subCategory';
export const GALLERY = "gallery";
export const ALLOCATION = "allocation";
export const PARTICIPANT = "participant";
export const SEARCH_PROS = "search_pros";
export const GOOGLE_SIGN_UP_DATA = 'google_sign_up_data';
export const RELATIONSHIP = 'relationship';
export const LOADED_FOLLOWERS = 'loaded_followers';
export const LOADED_FOLLOWINGS = 'loaded_followings';
export const INITIAL_STATUS_POST='initial_status_post';
export const INITIAL_ALLOCATION = 'initial_allocation';
export const ALLOCATION_TO_DISPLAY = 'allocation_to_display';
export const LOADING_ALLOCATION_FILTERED = 'loading_allocation_filtered';
export const LOAD_PRIVATE_CHATS = 'load_private_chats';
export const LOAD_DESKTOP_PRIVATE_CHATS = 'load_desktop_private_chats';
export const LOAD_PC_DETAIL_CHANGES = 'load_pc_detail_changes';
export const SELECTED_ALLOCATION_ID = 'selected_allocation_id';
export const SOME_PAGE_NOT_FOUND = 'some_page_not_found';
export const MARK_SELECTED_CHAT = 'mark_selected_chat';
export const FETCHED_ENJOY_FILES = 'fetched_enjoy_files';
export const NOTIFICATION_COLLECTOR = 'notification_collector';
export const NOTIFICATION_COUNT = 'notification_count';
export const ENJOY_SEARCH_VALUE = 'enjoy_search_value';
export const BECOME_PROFESSIONAL_MODAL = 'become_professional_modal';
export const PERSONAL_DETAILS = 'personal_details';
export const USER_PAGE_OWNER = 'user_page_owner';
export const USER_PAGE_POST = 'user_page_post';
export const SPECIFIC_USER_POST ='specific_user_post';
export const TEMPORARY_NO_AUTH_ALLOCATION_DATA = 'temporary_no_auth_allocation_data';
export const DISPLAY_NO_AUTH_TAG_PROFESSIONAL = 'display_no_auth_tag_professional';
export const LOAD_GENERAL_SEARCH_RESULT = 'load_general_search_result';
export const SHOW_SIGNUP_MODAL = 'show_signup_modal';
export const SHOW_LOGIN_MODAL = 'show_login_modal';
export const SHOW_AUTH_MODAL = 'show_auth_modal';
export const USER_FOLLOWERS = 'user_followers';
export const USER_FOLLOWINGS = 'user_followings';
export const CHECK_RELATIONSHIP = 'check_relationship';
export const INTENDED_PAGE = 'intended_page';
export const TICKET_NOT_FOUND = 'ticket_not_found';
export const COVER_WENT_WRONG = 'cover_went_wrong';
