import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import ErrorDisplay from './ErrorMessage';
import { participantInfo, loadPersonalDetails, processHeader } from '../../../global/action';
import Axios from 'axios';
import { PROXY } from '../../../global/constants/action_types';

const SignupSchema = Yup.object().shape({
	firstName: Yup.string()
		.required('Your first name is required')
        .min(2, 'Minimum of 2 is required')
        .max(30, 'Maximum of 30 is required')
		.matches(/(^([-a-zA-Z]){2,}$)/, 'a-z and - is only accepted'),

	lastName: Yup.string()
		.required('Your last name is required')
        .min(2, 'Minimum of 2 is required')
        .max(30, 'Maximum of 30 is required')
        .matches(/(^([-a-zA-Z]){2,}$)/, 'a-z and - is only accepted'),
    
    phoneNumber: Yup.string()
        .nullable(),
    
    bio: Yup.string()
        .nullable()
        .max(120, 'Maximum of 120 is required'),
  });

class Personal extends Component{
    state={
        processing: false,
        somethingWentWrong: false,
        successful: false,
    }
    mounted;

    componentDidMount=async()=>{
        this.mounted = true;
    }

    componentWillUnmount=async()=>{
        this.mounted = false;
    }

   
    handleSave=async(values)=>{
        if(!this.props.participant.email_verified_at) return;
        if(this.state.processing) return;
        const {participant} = this.props;
        const phone = participant.phoneNumber?participant.phoneNumber:'';
        if(values.phoneNumber === phone &&
            values.firstName.trim() === participant.firstName.trim() &&
            values.lastName.trim() === participant.lastName.trim() &&
            values.bio === this.props.details.bio
        ){
            return ;
        }
        (this.mounted) && await this.setState({processing: true});
        (this.state.somethingWentWrong) && await this.setState({somethingWentWrong: false});
        (this.state.successful) && await this.setState({successful: false});
        const form = new FormData();
        form.append('phoneNumber', values.phoneNumber);
        form.append('bio', values.bio);
        form.append('firstName', values.firstName);
        form.append('lastName', values.lastName);

        Axios.post(`${PROXY}/api/update_personal_info/${this.props.participant.userName}`, form, {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                await this.props.resetIdentity(JSON.parse(window.atob(res.data.data.participant)));
                await this.props.loadDetails(res.data.data.details);
                this.mounted && await this.setState({successful: true});
                this.mounted && await this.setState({processing: false});               
            }else{
                if(res.data.Error === 'Intruder'){
                (this.mounted) && await this.setState({processing: false});
                    window.localStorage.clear();
                    return this.props.navigate('/', {replace:true});
                }
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            (this.mounted) && await this.setState({processing: false});
            (this.mounted) && await this.setState({somethingWentWrong: true});
            if(err.response) console.log(err)
        })
    }

    render(){
        return(
            <React.Fragment>
            <div className='w3-animate-zoom'>
                <div className='w3-text-teal'>                    
                    <label>Edit personal details</label>
                </div>
                <Formik
                    initialValues={{
                        bio: this.props.details.bio? this.props.details.bio: '',
                        phoneNumber: this.props.participant.phoneNumber ? this.props.participant.phoneNumber:'',
                        firstName: this.props.participant.firstName,
                        lastName: this.props.participant.lastName
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={this.handleSave}
                >
                {({ errors,
                    touched,
                    values}) => (
                        <Form>
                            <div className="row ">
                                <div className="form-group col-sm-12 col-md-6">
                                    <label className="ml-3">First Name</label>
                                    <Field className="form-control rounded-pill"  value={values.firstName}  name='firstName' />
                                    {(touched.firstName && errors.firstName) && <ErrorDisplay message={errors.firstName} />}
                                </div>
                                <div className="form-group col-sm-12 col-md-6">
                                    <label className="ml-3">Last Name</label>
                                    <Field type="text" className="form-control rounded-pill" value={values.lastName} name='lastName' />
                                    {(touched.lastName && errors.lastName) && <ErrorDisplay message={errors.lastName} />}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="ml-3">Mobile Number</label>
                                <Field type="tel" name='phoneNumber' className="form-control rounded-pill" value={values.phoneNumber} placeholder="+2348*********" />
                            </div>
                            <div className="form-group">
                                <label className="ml-3">Bio</label>
                                <Field component='textarea' className="form-control rounded-pill" name='bio' value={values.bio} 
                                    placeholder="describe yourself briefly"
                                 />
                                {(touched.bio && errors.bio) && <ErrorDisplay message={errors.bio} />}
                            </div>
                            <div className='form-group'>
                                <div className='d-flex justify-content-center'>
                                    <button className="btn rounded-pill customPrimaryBgColor text-white" type='submit'>Save</button>
                                </div>
                                <div className='my-3 d-flex justify-content-center'>
                                    {this.state.processing &&
                                        <div className="w3-spin spinner w3-circle"></div>
                                    }
                                    {this.state.somethingWentWrong &&
                                        <small className='text-danger'>Oops! Something went wrong.</small>
                                    }
                                    {this.state.successful && 
                                        <small className='w3-text-teal'><b>Operation was successful.</b></small>                                
                                    }
                                </div>
                            </div>                     
                        </Form>
                    )
                }
                </Formik>
            </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps=(state)=>{
	return{
        participant: state.participant,
        details: state.personalDetails,

	}
}

const mapDispatchToProps=(dispatch)=>{
    return{
        resetIdentity: (payload)=>dispatch(participantInfo(payload)),
        loadDetails: (payload)=>dispatch(loadPersonalDetails(payload)),
    }
}

  
export default connect(mapStateToProps, mapDispatchToProps)(Personal);