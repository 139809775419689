import React, {Component} from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { PROXY } from '../../../global/constants/action_types';
import { processHeader, loadPersonalDetails } from '../../../global/action';
import { countries } from '../../../global/generalMethods/general';


  
  

class SocialMedial extends Component{
    state={
        processing: false,
        somethingWentWrong: false,
        successful: false,
        code:'',
        codeSelected:true,
    }
    mounted;

    componentDidMount=async()=>{
        this.mounted = true;
    }

    componentWillUnmount=async()=>{
        this.mounted = false;
    }


    submitForm=async(e)=>{
        e.preventDefault();
        if(this.state.processing) return;
        if(!this.props.participant.email_verified_at) return;
        if(this.mounted) await this.setState({processing: true});
        (this.state.successful) && await this.setState({successful: false});
        (this.state.somethingWentWrong) && await this.setState({somethingWentWrong: false});
        const whatsApp = document.getElementById('what').value.trim();
        const facebook = document.getElementById('fb').value.trim();
        const instagram = document.getElementById('gram').value.trim();
        const twitter = document.getElementById('twit').value.trim();
        let code;
        if(whatsApp === this.props.details.whatsappNumber){
             code = whatsApp;
        }else if(this.state.code && whatsApp){
             code = `${this.state.code}${whatsApp}`
        }else{
            code = '';
        }
        const form = new FormData();
        form.append('whatsappNumber', code );
        form.append('facebook',facebook);
        form.append('instagram', instagram);
        form.append('twitter', twitter);
        Axios.post(`${PROXY}/api/update_social_info/${this.props.participant.userName}`, form,
         {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                 await this.props.loadDetails(res.data.data);
                (this.mounted) && await this.setState({successful: true});
                (this.mounted) && await this.setState({processing: false});
            }else{
                if(!this.mounted) return;
                 await this.setState({somethingWentWrong: false});
                await this.setState({processing: false});
            }

        })
        .catch(async(err)=>{
            if(!this.mounted) return;
           (err.response) && await this.setState({somethingWentWrong: false});
            await this.setState({processing: false});
        })

    }

    selectCode=async(e)=>{
        e.preventDefault();
        const code = e.target.value.trim();
        if(code === 'Country'){
            await this.setState({code: ''});
            await this.setState({codeSelected: true});
            document.getElementById('what').value = this.props.details.whatsappNumber;
            return;
        }
        if(document.getElementById('what').value.trim() === this.props.details.whatsappNumber){
            document.getElementById('what').value = '';
        }
        await this.setState({code: code});
        await this.setState({codeSelected: false});
    }

    render(){
        const {details} = this.props;
        return(
            <React.Fragment>
                <div className='w3-animate-zoom'>
                    <div className="row">

                        <div className="col-12 w3-text-teal">
                            <label className="ml-3">Social Media</label>
                        </div>
                        
                        <div className="form-group col-sm-12 col-md-6">
                            <input id='twit' type="text" 
                                defaultValue={details.twitter} 
                                className="form-control rounded-pill" placeholder="Twitter" 
                            />
                        </div>
                        <div className="form-group col-sm-12 col-md-6">
                            <input id='gram' type="text" defaultValue={details.instagram} 
                                className="form-control rounded-pill" placeholder="Instagram" 
                            />
                        </div>
                    </div>
                    <div>
                        <div className="form-group">
                            <input defaultValue={details.facebook} id='fb' type="text" 
                                className="form-control rounded-pill" placeholder="Facebook Link" 
                            />
                        </div>
                    </div>
                    <div>
                        <div className='input-group w3-round-xxlarge'>
                            <div className="input-group-prepend">
                                <select onChange={this.selectCode} id='codes' className='w3-round'>
                                    <option>Country</option>
                                    {countries.map((item,index) => 
                                        <option className='text-center' key={`${index}${item.country}`} value={item.code}>{item.country}</option>    
                                    )}
                                </select>
                            </div>
                            <input disabled={this.state.codeSelected} id='what' defaultValue={details.whatsappNumber} type="text" className="form-control" placeholder="Whatsapp" />                            
                        </div>                    
                    </div>
                    <div>
                    </div>
                    <div className='form-group'>                        
                        <div className='my-3 d-flex justify-content-center'>
                            {this.state.processing &&
                                <div className="w3-spin spinner w3-circle"></div>
                            }
                            {this.state.somethingWentWrong &&
                                <small className='text-danger'>Oops! Something went wrong.</small>
                            }
                            {this.state.successful && 
                                <small className='w3-text-teal'><b>Operation was successful.</b></small>                                
                            }
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button onClick={this.submitForm} className="btn rounded-pill customPrimaryBgColor text-white" >Save</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps=(state)=>{
	return{
        participant: state.participant,
        details: state.personalDetails,
	}
}

const mapDispatchToProps=(dispatch)=>{
    return{
        loadDetails:(payload)=>dispatch(loadPersonalDetails(payload)),
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(SocialMedial);