import React, { Component } from 'react';
import  * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import '../../CSSs/Birthday/eight/eight.css';
import { Typography } from '@material-ui/core';
import GDHeader from '../GDHeader';

class BirthdayEditEight extends Component {
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        happyBirthdayText: "Happy Birthday",
        inAdvance: "In Advance",
        celebrantName: "Adeola",
        celebrantImage: "5.jpeg",
    }

    changeBackground = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const slot = document.getElementById('imgSlot');
        if(!slot) return;
        slot.src = URL.createObjectURL(file);        
    }

    handleCelebrantName = (e) => {
        e.preventDefault();
        this.setState({celebrantName: e.target.value});
    }
    handleFinishEdit = (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('parentContainer8'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
              var link = document.createElement("a");
              link.download = "medillery-template.jpeg";
              link.href = dataUrl;
              link.click();
            });          
    }

    downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('parentContainer8'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }

    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }
    render() {
        return (<div style={{marginTop: '90px'}}>
            <GDHeader caty='birthday' />
            
            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 mb-2">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="topTag8"></div>
                                        <div className="bottomTag8"></div>
                                        <div id='parentContainer8' className="parentContainer8">
                                            <div className="imageContainer8">
                                                <div className="celebrantImageWrapper8">
                                                    <img 
                                                        src={ require("../../../../assets/images/templates/heroes/5.jpeg")}
                                                        className="celebrantImage8" alt="avatar"  id='imgSlot'
                                                    />
                                                </div>
                                                <div className="celebrantName8">{ this.state.celebrantName }</div>
                                            </div>
                                            <div className="happyBirthday8">
                                                <span>Happy</span>
                                                <span className="textBirthday8">Birthday</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-4">
                            {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                        <div className="form-group mt-3">
                                            <label>Image</label>
                                            <label className="form-control rounded-pill">
                                                <input 
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeBackground}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label>Celebrant Name</label>
                                            <input 
                                                type="text" 
                                                value={this.state.celebrantName} 
                                                onChange={ this.handleCelebrantName } 
                                                className="form-control rounded-pill" 
                                            />
                                        </div>
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                            > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4 variant="subtitle2" className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select className="form-control form-control-sm rounded-pill"
                                                onChange={this.downloadNow}
                                            >   <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                        className="btn btn-block rounded-pill customPrimaryBgColor" 
                                        onClick={ this.backToEdit }
                                        > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>);
    }
}
// jquery.scrollLeft() to move the whole div left and right
// https://stackoverflow.com/questions/27313480/scroll-div-content-horizontally-using-left-and-right-arrow-in-jquery

export default BirthdayEditEight;