import React from 'react';
import { Box, Typography, Avatar, Card } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

const avatarStyle = {
    height: 100,
    width: 100
}

const cardStyle = {
    boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
}

const Testimonies = () => {
  const testimonyList = [
    {
      name: 'Felicity',
      title: 'CEO and Co-Founder, Instapage',
      message: 'I have completed many jobs on Medillery, from fliers to logo, to banner all for free. Aside that I get my job done for free, the speed also interests me. God bless Medillery.',
      avatar: 'testimony-felicity',
    },
    {
      name: 'Ogundare Adebare',
      title: 'Author and Writer',
      message: 'Medillery is a home of professional ICT officials. They always make their words come true and this platform just did what was promised. Kudos to you guys.',
      avatar: 'testimony-ogundare',
    },
    {
      name: 'Ebony',
      title: 'Fashion Designer',
      message: 'I really enjoyed those designs I got from medillery, they are all awesome. There is nothing to say than to say, those designs (fliers,logo, business card) are good. I say a big thanks to medillery.',
      avatar: 'testimony-ebony',
    },
  ];

  return (
    <Box py={5} mb={5}>
      <Box className="container">
        <Typography variant="h4" 
          className="text-capitalize sectionTitle my-2 text-center font-weight-bold">
          Our Users' Testimonial
        </Typography>
        <Typography variant="subtitle2" className="heroSecondText mb-5 text-secondary">
          Showcase your creativity on the Medillery platform while also making money from your creations
        </Typography>
        <Box className="row">
          <Box className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <Box className="">
              <Carousel>
                {testimonyList.map((testimony, index) => (
                  <Card className="testimoniesCard mx-auto py-2 bg-white" key={index} style={cardStyle}>
                    <Box className="container px-4">
                      <Box className="row pt-3">
                        <Box className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mb-3">
                          <Avatar
                            alt={testimony.name}
                            className="m-auto"
                            src={require(`../../../assets/images/website/homepage/${testimony.avatar}.jpeg`)}
                            style={avatarStyle}
                          />
                        </Box>
                        <Box className="col-sm-12 col-md-9 col-lg-9 col-xl-9 my-auto pt-2">
                          <Typography className="font-weight-bold" style={{ color: '#387DFF' }}>
                            {testimony.name}
                          </Typography>
                          <Typography variant="subtitle2" className="text-muted mb-3">
                            {testimony.title}
                          </Typography>
                          <blockquote className="blockquote py-auto d-block">
                            <Typography variant="body1" style={{ fontSize: '1em' }}>
                              {testimony.message}
                            </Typography>
                          </blockquote>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                ))}
              </Carousel>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Testimonies;
