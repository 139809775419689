import React, {Component} from 'react';
import { Avatar } from '@material-ui/core';
import { processHeader, loadDesktopPCs, loadPcDetailChanges } from '../../../global/action';
import { PROXY } from '../../../global/constants/action_types';
import Axios from 'axios';
import { connect } from 'react-redux';
import { CHAT_LIKE_TIME, staticPusherConfig,
	generateFiveRandomNumbers, noImage 
} from '../../../global/generalMethods/general';
import { useNavigate, useParams } from 'react-router-dom';

const avatarStyle = {
  height: 35,
  width: 35
}

const quitRequest = Axios.CancelToken.source();
class DesktopSideMap extends Component{
  state = {
		userName:null,
		reserve:{},
		privateChats:null, // locat copy of chatlist to use 
		fetching_chats: false,
		fetching_more_chats:false,
		currentMessageIdParam:null, // route param room
	}

	timerHolder;
	scrollChat;
	mounted;
	componentDidMount=async()=>{
    this.mounted=true;
    if(!this.mounted) return;
		await this.setState({userName: this.props.participant.userName});
    await this.setState({reserve: this.props.chatLists});
		let messageRouteParam = this.getCurrentMessageIdParam();
		await this.setState({currentMessageIdParam: messageRouteParam});
		this.props.decodeRoom(messageRouteParam);
		this.scrollChat = await window.document.getElementById('scrollChat');
		staticPusherConfig();
    (this.mounted)  && await this.setState({privateChats: this.props.chatLists});
		await this.prepareGetMoreChats();
		this.listenToNewMessages();
	}

	// UNSAFE_componentWillReceiveProps=async(next, current)=>{
		
	// }

	componentWillUnmount=()=>{
		this.mounted=false;
		quitRequest.cancel();
		if(this.scrollChat){
			this.scrollChat.removeEventListener('scroll', this.scrollEventMethod);
		}
	}


	getCurrentMessageIdParam=()=>{
		let sliceRandomAway = this.props.room;
		sliceRandomAway =  window.atob(window.atob(sliceRandomAway));
		sliceRandomAway = sliceRandomAway.slice(5, sliceRandomAway.length);
		const decodedConversationId = sliceRandomAway.slice(0, sliceRandomAway.length-5);
		return decodedConversationId;
	}

	getMorePrivateChats=async()=>{
		if(this.props.chatLists.next_page_url && !this.state.fetching_more_chats){
			await this.setState({fetching_more_chats:true});
			Axios.get(`${this.props.chatLists.next_page_url}`,
			 {headers:processHeader(), cancelToken: quitRequest.token}
			)
			.then(async(res)=>{
				if(res.data.success){
					if(!this.mounted) return;
					/** Collect the present array of data rendering */
					const tempChats = [...this.props.chatLists.data];
					/** Collect incoming data of the next page */
					const incomingData = [...res.data.data.data];
					/** Join them add assign them back to the incoming data array alone to 
					 * give room for the next page url if items remain */
					res.data.data.data = [...tempChats,...incomingData];
					this.props.loadDesktopPCs(res.data.data);		
					await this.setState({reserve: res.data.data});			
					await this.setState({fetching_more_chats:false});
				}
			})
			.catch(async(err)=>{
				if(!this.mounted) return;
				await this.setState({fetching_more_chats:false});		
			})
		}
	}

	prepareMessageSearch=async(e)=>{
		e.preventDefault();
		const search = e.target.value.trim();
		if(this.timerHolder) clearTimeout(this.timerHolder);
		await this.setState({fetching_chats: true});
		if(search.trim() === ''){
			if(!this.mounted) return;
			await this.props.loadDesktopPCs(this.state.reserve)
			await this.setState({fetching_chats: false});
			return;
		}
		this.timerHolder = setTimeout((()=>{this.getSearchedPrivateChats(search)}),2000);

	}
	getSearchedPrivateChats=async(search)=>{
		if(!this.mounted || !search)  return;
		Axios.get(`${PROXY}/api/get_searched_private_chats/${this.state.userName}/${search}`,
		 {headers: processHeader(), cancelToken: quitRequest.token})
		.then(async(res)=>{
			if(res.data.success){
				if(!this.mounted) return;
				await this.props.loadDesktopPCs(res.data.data);
				await this.setState({fetching_chats: false});
			}
		})
		.catch(async(err)=>{
			if(!this.mounted) return;
			await this.setState({fetching_chats: false});
		})
	}

	getMoreSearchedPrivateChats=async()=>{
		if(!this.mounted) return;
		if(!this.props.chatLists.next_page_url && this.state.fetching_more_chats) return;
		await this.setState({fetching_more_chats:true});		
		Axios.get(`${this.props.chatLists.next_page_url}`,
			{headers: processHeader(), cancelToken: quitRequest.token}
		)
		.then(async(res)=>{
			if(res.data.success){
        if(!this.mounted) return;
				/** Collect the present array of data rendering */
				const tempChats = [...this.state.privateChats.data];
				/** Collect incoming data of the next page */
				const incomingData = [...res.data.data.data];
				/** Join them add assign them back to the incoming data array alone to 
				 * give room for the next page url if items remain */
				res.data.data.data = [...tempChats,...incomingData];
				// await this.setState({privateChats: res.data.data});				
				await this.props.loadDesktopPCs(res.data.data);
				await this.setState({fetching_more_chats:false});
			}
		})
		.catch(async(err)=>{
			if(!this.mounted) return;
			// if(err) console.log(err)
			await this.setState({fetching_more_chats:false});		
		})
	}

	scrollEventMethod=async()=>{
		if(!this.mounted) return;
		if(this.scrollChat.scrollTop === this.scrollChat.scrollHeight - this.scrollChat.clientHeight){ 
			if(this.state.privateChats.next_page_url){			
				if(this.state.privateChats.next_page_url.indexOf('get_searched_private_chats') > -1){
					await this.getMoreSearchedPrivateChats();
				}else if (this.state.privateChats.next_page_url.indexOf('get_all_private_chats') > -1){
					await this.getMorePrivateChats();
				}
			}
		}
	}

	prepareGetMoreChats=async()=>{
		if(!this.mounted) return;
		this.scrollChat.addEventListener('scroll', this.scrollEventMethod)
	}

	markAndNavigate=async(conversationId)=>{
    // console.log(conversationId + " and last"+ this.state.currentMessageIdParam)
		if(conversationId === this.state.currentMessageIdParam) return;
		try {
			await this.setState({currentMessageIdParam: conversationId});
			this.props.decodeRoom(conversationId);
			const dupStateChats = Object.assign({}, this.state.privateChats);
      const chat = dupStateChats.data.find(data=>data.conversation_id === conversationId);
       
			const routeEncode = await `${generateFiveRandomNumbers()}${conversationId}${generateFiveRandomNumbers()}`
			if(chat &&chat.unread_count > 0){
				const INDEX = dupStateChats.data.findIndex(data=>data.conversation_id === conversationId);
				chat.unread_count = 0;
				dupStateChats.data.splice(INDEX,1,chat);
				this.props.navigate(`/dashboard/inbox/${window.btoa(window.btoa(routeEncode))}`);
			}else{
        this.props.navigate(`/dashboard/inbox/${window.btoa(window.btoa(routeEncode))}`);
			}
		} catch (e) {
			// console.log(e)
		}
				
	}

	
	listenToNewMessages=()=>{
		window.Echo.private(`chat.${this.state.userName}`)
		.listen('.newChat', (data)=>{
			const incomingChat = JSON.parse(data[0])[0];
			const dupState = Object.assign({}, this.props.chatLists);
			const currentPrivateChats = [...dupState.data]
			const exists = currentPrivateChats.findIndex(data=>data.conversation_id === incomingChat.conversation_id);
			// console.log(exists)
			if(exists >-1){
				currentPrivateChats.splice(exists,1);
				// console.log(currentPrivateChats, 'after splicing from side');
				currentPrivateChats.unshift(incomingChat);
				// console.log(currentPrivateChats, 'after unshift from side');
			}
			else if(exists === -1){
				currentPrivateChats.unshift(incomingChat);
			}
			dupState.data = currentPrivateChats;
			this.props.loadDesktopPCs(dupState);
		})
	}

  render() {
		return (
			<nav className="mx-0 h-100">
				<div className="px-2 py-1" style={{ backgroundColor: "#F0F1F2"}}>
					<input type="text" onKeyUp={this.prepareMessageSearch} className="form-control rounded-pill" placeholder="search" />
				</div>
				<div id='scrollChat' className="small pt-3 px-2 pb-5 h-100" style={{ overflowY: 'auto'}}>
					{ this.state.fetching_chats &&
						<div className='text-center'>
							<div className='w3-spin spinner w3-circle mt-2'></div>
						</div>
          }
					{this.props.chatLists.data &&
						<div>
							{(this.props.chatLists.data.length > 0)?
                <div>
                  {this.props.chatLists.data.map((chat, index) =>
                    <div key={chat.id} className="d-flex mb-2 w3-hover-pale-blue" 
											id={chat.conversation_id} 
                      onClick={()=>this.markAndNavigate(chat.conversation_id)}
											style={{
												cursor:"pointer", 
												background: this.state.currentMessageIdParam === chat.conversation_id?
												"#44e0ff":'white'
											}}
                    >
											{/* {chat.conversation_id} */}
                      <div>
                        { chat.sender && <Avatar alt="avatar1" className="img-fluid mr-2"
                          src={chat.sender.profilePic?
                            `${chat.sender.profilePic}`:`${noImage}`}
                          style={ avatarStyle }
                        /> }
                        { chat.receiver && <Avatar alt="avatar1" className="img-fluid mr-2"
                        src={chat.receiver.profilePic?
                          `${chat.receiver.profilePic}`:`${noImage}`}
                          style={ avatarStyle }
                        /> }
                        
                      </div>
                      <div className="d-flex flex-column flex-fill justify-content-between my-auto">
                        <div className="d-flex justify-content-between">
                          <div className="font-weight-bold">
                            {chat.sender && <span >{ `@${chat.sender.userName}`}</span>}
                            {chat.receiver  && <span>{ `@${chat.receiver.userName}`}</span>}
                          </div>
                          <div className="text-muted small">{ CHAT_LIKE_TIME(chat.created_at) }</div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="text-muted">
                            {chat.type === 0 && <div>{chat.message.slice(0,20) }</div>}
                            {chat.type === 1 && <div>icon here Photo</div>}
                            {chat.type === 2 && <div>icon here Video</div>}
                          </div>
                          { chat.unread_count < 1?
                            <span className="small"></span>:
                            <span className="bg-success d-inline rounded-pill px-1 ml-1 my-auto text-white small">
                              { chat.unread_count }
                            </span> 
                          }
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.fetching_more_chats &&
                    <div className='text-center'>
                      <div className='w3-spin spinner w3-circle mt-2'></div>
                    </div>
                  }
								</div>:
								<div>
									No chat list found
								</div>
							}
						</div>
					}
				</div>
			</nav>
		);
	}
}

const mapStateToProps=(state)=>{
	return{
		participant: state.participant,
		chatLists: state.desktopInitialPCs,
    markSelectedChat: state.markSelectedChat, 
	}
}

const mapDispatchToProps=(dispatch)=>{
	return{
    loadDesktopPCs:(payload)=>dispatch(loadDesktopPCs(payload)),
    loadNewDetail:(payload)=>dispatch(loadPcDetailChanges(payload)),

	}
}

const withRouter=Child=>props=>{
	const params = useParams();
	const navigate = useNavigate();
	// const match = useMatch();
	// alert();
	// match={match}
	return(
		<Child 
		{...props} 
		params={params} 
		navigate={navigate} 
		/>
	)
}

	export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DesktopSideMap));




