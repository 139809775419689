import React from 'react';
// import Templates from '../template/Templates';



const DbTemplates =()=>{


  return(<div>
    <div>
    </div>
    <div>
      {/* <Templates /> */}
    </div>
    
    
    </div>)
}

export default DbTemplates;