import React, {useState, useEffect} from 'react';
import { Box, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { EXT_PROXY } from '../../global/constants/action_types';
import { processHeader } from '../../global/action';
import SearchIcon from '@material-ui/icons/Search';
import { ESTIMATE_TIME, noImage} from '../../global/generalMethods/general';
import { Link } from 'react-router-dom';

const Subscriptions = ({ participant }) => {

  const [subscriptions, setSubscriptions] = useState([]);
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    const getUserSubscriptions = async () => {
      try {
        if(!participant.email_verified_at) return;
        const url = `${EXT_PROXY}/api/subscriber/user/${participant.uuid}`
        const res = await fetch(url)
        const {subscribers} = await res.json()
        setSubscriptions(subscribers);
      } catch (error) {
        console.log(error);
      }
    }

    if(mounted){
      getUserSubscriptions();
    }

    return () => {
      setMounted(false);
    }
  }, [participant, mounted]);

  const formatTime = (time) => {
    if(!time) return;
    if(!window.luxon) return;
    const format = time.trim().replace(' ', "T")
    const ms = window.luxon.DateTime.fromISO(format, {zone: 'Africa/Accra'});
    const diff = Date.now()-Date.parse(ms);
    const sec=((diff)/(1000));
    return ESTIMATE_TIME(sec);
  }

  return (
    <div>
      <div className="row ">
        <div className="col-12" >
          <Typography variant="h5" className="mb-1 pl-1">Subscriptions</Typography>
          <Typography variant="subtitle1" className="small text-primary">
            List of your recent subscriptions
          </Typography>
        </div>
      </div>
      <div className="col-12">
        {(subscriptions.length > 0)?
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <th>Name</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Tickets</th>
                <th>Created</th>
              </thead>
              <tbody>
                {(subscriptions.map((item, ind) => (
                  <tr key={ind} 
                    className={(item.status === "ACTIVE")? "table-primary": "table-light"}>
                    <td>{item.subscription.name}</td>
                    <td>{item.subscription.amount}</td>
                    <td>{item.subscription.type}</td>
                    <td>{item.subscription.project_tickets}</td>
                    <td>{formatTime(item.subscription.created_at)}</td>
                  </tr>
                )) )}
              </tbody>
            </table>
          </div> : 
          <div className="d-flex justify-content-center">
            <Box my={5}>
              <Typography variant="subtitle1" className="text-danger font-italic">
                No subscriptions found
              </Typography>
            </Box>
          </div>
        } 
      </div>
    </div>
  )
}

const mapStateToProps =(state)=>{
  return {
    participant: state.participant,
  }
}

export default connect(mapStateToProps)(Subscriptions);