import React, { useEffect, useRef, useState } from "react";
import Sidenav from "./Sidenav";
import Statuses from "./Status/Statuses";
import NotFound from "../../NotFound";
import dbRoutes from "./DbRoutes";
import "./dashboard.scss"
import {
  participantInfo,
  showAuthModal,
  showLoginUpModal,
} from "../../global/action";
import { connect } from "react-redux";
import { EXTRACTOR, hideSide } from "../../global/generalMethods/general";
import SetUsername from "./SetUsername";
import ResendEmailConfirmation from "./ResendEmailConfirmation";
import DashboardNavbar from "./DashboardNavbar";
import LazyLoader from "../../LazyLoader";
import { PROXY, PUT_BEFORE, TOKES } from "../../global/constants/action_types";
import Axios from "axios";
import SomethingWentWrong from "../../SomethingWentWrong";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";

const Dashboard = ({
  participant,
  ticketNotFound,
  setParticipant,
  coverWentWrong,
  setAuthModal,
  setShowLogin,
}) => {
  const routers = useRoutes(dbRoutes);
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const mounted = useRef(true);

  const hideProjectDetails = hideSide(pathname);
  const [isWrong, setIsWrong] = useState(coverWentWrong);
  const [notFound, setNotFound] = useState(ticketNotFound);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const processHeader = () => {
      const identifier = EXTRACTOR(PUT_BEFORE, localStorage.MedToken, TOKES);
      if (!identifier || identifier.length < 50) {
        return false;
      } else {
        return { Authorization: `Bearer ${identifier}` };
      }
    };

    const fetchParticipant = async () => {
      await setIsWrong(false);
      await setLoading(true);
      let auth = processHeader();
      if (!auth) {
        setAuthModal(true);
        setShowLogin(true);
        return navigate("/");
      }
  
      Axios.get(`${PROXY}/api/identify/XXPAXX`, { headers: auth })
        .then(async (res) => {
          if (res.data.success) {
            mounted.current &&
              (await setParticipant(JSON.parse(window.atob(res.data.data))));
          } else {
            if (res.data.message === "xxx") {
              localStorage.clear();
              return navigate("/");
            }
            await setAuthModal(true);
            await setShowLogin(true);
            return navigate("/");
          }
        })
        .catch((err) => {
          mounted.current && setIsWrong(true);
        }).finally(()=>{
          mounted.current && setLoading(false)
        });
    };

    if (Object.entries(participant).length < 1) {
      fetchParticipant();
    }else{
      setLoading(false)
    }
    return () => {
      mounted.current = false;
    };
  }, [participant, setParticipant, navigate, setLoading, setShowLogin, setAuthModal]);

  useEffect(() => {
    mounted.current && setIsWrong(coverWentWrong);
  }, [coverWentWrong]);

  useEffect(() => {
    mounted.current && setNotFound(ticketNotFound);
  }, [ticketNotFound]);

  useEffect(() => window.scrollTo(0, 0));

  return (
    <div>
      {!loading ? (
        <section>
          {isWrong || notFound ? (
            <aside>
              {isWrong && <SomethingWentWrong />}
              {notFound && <NotFound />}
            </aside>
          ) : (
            <aside>
              {/* Navbar */}
              {participant.userName && <DashboardNavbar />}
              <div>
                {/*  Confirm Email */}
                { !participant.email_verified_at && (
                  <aside
                    className="row mx-sm-2 w3-round "
                    style={{
                      marginTop: participant.userName ? "70px" : "2px",
                      marginBottom: "-50px",
                    }}
                  >
                    <div className="col-sm-12 col-md-2"></div>
                    <div className="col-sm-12 col-md-8 mb-5">
                      <div className="w3-container mt-2 mb-3">
                        <ResendEmailConfirmation />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2"></div>
                  </aside>
                )}
                <div>
                  {/* Main routers */}
                  {participant.userName ? (
                    <aside
                      className="container-sm"
                      style={{ height: "100%", marginTop: "70px" }}
                    >
                      <div className="row">
                        {!pathname.includes("inbox") &&
                          !pathname.includes("enjoy") &&
                          !hideProjectDetails && (
                            <div className={`col-md-3 col-lg-2 col-xl-3 sideNavStyle px-0 d-md-block d-none`} >
                              {participant.userName && <Sidenav />}
                            </div>                          
                          )
                        }
                        <main
                          className={` ${pathname.includes("inbox")
                              ? "col-12"
                              : "col-sm-12 col-md-9 col-lg-7 col-xl-8"
                            } mainDisplay`}
                        >
                          {routers || <NotFound />}
                          <div>
                            {(pathname === "/dashboard" ||
                              pathname === "/dashboard/tag-professional" ||
                              pathname === "/dashboard/tag-professional/" ||
                              pathname === "/dashboard/status" ||
                              pathname === "/dashboard/status/" ||
                              pathname === "/dashboard/") && <Statuses />}
                          </div>
                          <Link to="/dashboard/tag-professional">
                            <Fab
                              size="medium"
                              className="tagProfessionalFab customPrimaryBgColor"
                              aria-label="tag-professional"
                            >
                              <EditIcon />
                            </Fab>
                          </Link>
                        </main>
                      </div>
                    </aside>
                  ) : (
                    <div style={{ height: `${window.innerHeight}px` }}>
                      <SetUsername />
                    </div>
                  )}
                </div>
              </div>
            </aside>
          )}
        </section>
      ) : (
          <LazyLoader />
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    participant: state.participant,
    ticketNotFound: state.ticketNotFound,
    coverWentWrong: state.coverWentWrong,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setParticipant: (payload) => dispatch(participantInfo(payload)),
    setShowLogin: (payload) => dispatch(showLoginUpModal(payload)),
    setAuthModal: (payload) => dispatch(showAuthModal(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
