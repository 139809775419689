import React from 'react';
import Dashboard from './components/dashboard/Dashboard';
import Home from './components/website/Home';
import TemplateCover from './components/template/TemplateCover';
import CoverUserAndPost from './components/dashboard/User/CoverUserAndPost';
import DisplayTest from './TestFiles/Display';
import TermsAndConditions from './components/website/Others/TermsAndConditions';
// import TagProfessionalCover from './components/website/Allocation/TagProfessionalCover';
import GoogleRecovery from './components/website/GoogleRecovery';
import ConfirmEmail from './components/website/ConfirmEmail';
import ForgetPassword from './components/website/ForgetPassword/ForgetPassword';
import ResetPassword from './components/website/ForgetPassword/ResetPassword';
import About from './components/website/aboutpage/About';
import HowItWorks from './components/website/Others/HowItWorks';
import Suspended from './components/website/Suspended/Suspended';
import Faqs from './components/website/faqs/Faqs';
import { Outlet } from 'react-router-dom';
import { TagProfessionalMaintenance } from './components/dashboard/maintenance/MaintainAssignProject';

export const noAuthRoutes = [
  {
    path: "/",
    element: <Home />,
    index:true,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />
  },
  {
    path: "/create-project",
    element: <TagProfessionalMaintenance />
    // element: <TagProfessionalCover />
  },
  {
    path: '/google-recovery',
    element: <GoogleRecovery />
  },
  {
    path: "/confirm-email/:email",
    element: <ConfirmEmail />,
  },
  {
    path:'/forgot-password',
    element: <ForgetPassword />
  },
  {
    path:"/reset-password/:email",
    element: <ResetPassword />
  },
  {
    path: "/faqs",
    element: <Faqs />
  },
  {
    path:"/how-it-works",
    element: <HowItWorks />
  },
  {
    path: "/review",
    element: <Suspended />
  },
  {
    path: "/test-image",
    element: <DisplayTest />
  }
]



export const authRoutes = [
  {
    path: '/dashboard/*',
    index:true,
    element: <Dashboard><Outlet /></Dashboard>
  },
  {
    path: '/user/*',
    index: true,
    element: <CoverUserAndPost><Outlet /></CoverUserAndPost>
  },
  {
    path: '/templates/*',
    index: true,
    element: <TemplateCover><Outlet/></TemplateCover>
  }
] 

