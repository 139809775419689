import React from 'react';
import Nav from 'react-bootstrap/Nav'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import SearchIcon from '@material-ui/icons/Search';
import { Paper } from '@material-ui/core';
import { loadAllocation, allocationToDisplay, loadingAllocation, processHeader } from '../../../global/action';
import Axios from 'axios';
import { PROXY } from '../../../global/constants/action_types';
import { connect } from 'react-redux';
import { Modal, Form, InputGroup, Col } from 'react-bootstrap';

const giveStyle={
  color:'#0751DB',
  cursor:'pointer',
  background:'white'
}

const Filter=({participant, loadingAllocations, displayAllocation, loadAllocations})=>{
  const mounted = React.useRef(true);
  const [fetchingProject, setFetchingProject]= React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const [byName, setByName] = React.useState('');
  const [byDescription, setByDescription] = React.useState('');
  const [byDate, setByDate] = React.useState('');
  const [byDateFrom, setByDateFrom] = React.useState('');
  const [byDateTo, setByDateTo] = React.useState('');
  const [status, setStatus] = React.useState(100);

  const getProjects=(e)=>{
    if(!e || typeof Number(e) !== 'number') return;
    if(Number(e) === 1){
      getAllAllocations();
    }else if(Number(e) === 2){
      getCompletedAllocations();
    }else if(Number(e) === 3){
      getPendingAllocations();
    }else if(Number(e) === 4){
      setShowSearch(true);
      //
    }
  }

  const getPendingAllocations=()=>{
    if(fetchingProject) return;
    mounted.current && loadingAllocations(true);
		(mounted.current) && setFetchingProject(true);
    Axios.get(`${PROXY}/api/get_pending_allocations/${participant.userName}`,
    {headers: processHeader()})
    .then(async(res)=>{
      if(res.data.success){
        displayAllocation('pend');
        loadAllocations(res.data.data.allocations);
        if(!mounted.current) return;
        setFetchingProject(false);
      }
    })
    .catch(async(err)=>{
      // if(err) console.log(err)
      if(!mounted.current) return;
      setFetchingProject(false);
      loadingAllocations(false);
    })
  }

  const getCompletedAllocations=()=>{
    if(fetchingProject) return;
    mounted.current && loadingAllocations(true);
		(mounted.current) && setFetchingProject(true);
    Axios.get(`${PROXY}/api/get_completed_allocations/${participant.userName}`,
    {headers: processHeader()})
    .then(async(res)=>{
      if(res.data.success){
        displayAllocation('comp');
        loadAllocations(res.data.data.allocations);
        loadingAllocations(false);
        if(!mounted.current) return;
        setFetchingProject(false);
      }
    })
    .catch(async(err)=>{
      if(!mounted.current) return;
      loadingAllocations(false);
      setFetchingProject(false);
    })
  }

  const getAllAllocations=()=>{
    if(fetchingProject) return;
    mounted.current && loadingAllocations(true);
		(mounted.current) && setFetchingProject(true);
		Axios.get(`${PROXY}/api/get_allocations/${participant.userName}`,
		{headers: processHeader()})
		.then(async(res)=>{
			if(res.data.success){
				displayAllocation('all');
				loadAllocations(res.data.data.allocations)
        loadingAllocations(false);
        if(!mounted.current) return;
        setFetchingProject(false);
			}
		})
		.catch(async(err)=>{
      if(!mounted.current) return;
      loadingAllocations(false);
      setFetchingProject(false);
			// if(err) console.log(err)
		})
  }
  

  const filter=(origin)=>{
    if(!origin) return; 
    let path;
    const form = new FormData();
    if(origin === 'name'){
      if(!byName.trim()) return;
      path = `api/filter_by_name/${participant.userName}`;
      form.append('type', status);
      form.append('name', byName);
      // console.log(origin, byName)
    }
    else if(origin === 'desc'){
      if(!byDescription.trim()) return;
      path = `api/filter_by_desc/${participant.userName}`;
      form.append('type', status);
      form.append('description', byDescription);
      // console.log(origin, byDescription)
    }
    else if(origin === 'date'){
      if(!byDate.trim()) return;
      path = `api/filter_by_date/${participant.userName}`;
      form.append('date', byDate);
      form.append('type', status);
      // console.log(origin, byDate)
    }
    else if(origin === 'between'){
      if(!byDateFrom.trim() || !byDateTo.trim()) return;
      path = `api/filter_by_between_dates/${participant.userName}`;
      form.append('from', byDateFrom);
      form.append('to', byDateTo);
      form.append('type', status);
      // console.log(origin, byDateFrom, byDateTo)
    }
    if(fetchingProject) return;
    mounted.current && loadingAllocations(true);
		(mounted.current) && setFetchingProject(true);
    Axios.post(`${PROXY}/${path}`, form, {headers: processHeader()})
    .then(async(res)=>{
			if(res.data.success){
				displayAllocation('filtered');
				loadAllocations(res.data.data.allocations)
        loadingAllocations(false);
        if(!mounted.current) return;
        setFetchingProject(false);
        setShowSearch(false);
			}
		})
		.catch(async(err)=>{
      if(!mounted.current) return;
      setShowSearch(false)
      loadingAllocations(false);
      setFetchingProject(false);
			// if(err) console.log(err)
		})
  }

  return(<div>
    <Paper variant='outlined'> 
      <Nav style={{width:'100%'}} fill variant='tabs' onSelect={getProjects}>
        <Nav.Item>
          <Nav.Link eventKey={1} >
            <AllInclusiveIcon style={{color:'#0751DB'}} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={2}>
            <AssignmentTurnedInIcon style={{color:'#0751DB'}} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={3}>
            <AssignmentLateIcon style={{color:'#0751DB'}} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={4}>
            <SearchIcon style={{color:'#0751DB'}} />
          </Nav.Link>
        </Nav.Item>
      </Nav> 
    </Paper>

    {/** The Search modal part */}
    <div>
      <Modal show={showSearch} onHide={()=>{setShowSearch(false)}}
      >
        <Modal.Body className='my-1'>
          <div className='d-flex my-2 justify-content-around'>
            <div  
              className='text-primary font-weight-bold' 
            >                  
              Filter Project
            </div>           
            <div style={{position: 'relative'}} >
							<div className='px-2 py-1 w3-circle w3-light-grey'
								onClick={()=>{setShowSearch(false)}} 
								style={{position:'absolute', top:0, right:0, cursor:'pointer'}}
								>
									X
							</div>
						</div>
          </div>
          
          <hr className='my-2' />
          <div className='mb-2' >
            {fetchingProject &&
              <div className="d-flex justify-content-center align-items-center">
                  <div className="spinner-grow text-muted"></div>
                  <div className="spinner-grow text-info"></div>
              </div>
            }
          </div>

          <Form className='d-block'>
            <Form.Row>
              <Col xs={2} md={3}></Col>
              <Col xs={8} md={6}>
                <Form.Group controlId="Status">
                  <Form.Label>Select Type</Form.Label>
                  <Form.Control as="select" 
                    onChange={(e)=>{setStatus(e.currentTarget.value)}}
                  >
                    <option value='100'>All</option>
                    <option value='1'>Accepted</option>
                    <option value='4'>Checked</option>
                    <option  value='2'>Completed</option>
                    <option  value='5'>Reassigned</option>
                    <option  value='3'>Rejected</option>
                    <option value={0}>Unchecked</option>
                    {/*<option value='1'>Terminated</option>*/}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xs={2} md={3}></Col>

            </Form.Row>

            <Form.Row className='d-flex justify-content-center mb-4'>
              <Col xs={2} md={3}></Col>
              <Col xs={8} md={6}>
                <InputGroup className='d-flex justify-content-center'>
                  <Form.Control  id="name-input" placeholder="name" 
                    onChange={(e)=>{setByName(e.currentTarget.value)}} 
                  />
                  <InputGroup.Append>
                    <InputGroup.Text onClick={()=>{filter('name')}}>
                      <SearchIcon style={giveStyle} />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col xs={2} md={3}></Col>
            </Form.Row>

            <Form.Row className='d-flex justify-content-center'>
              <Col xs={2} md={3}></Col>
              <Col xs={8} md={6}>
                <InputGroup>
                  <Form.Control id="desc-input" placeholder="description" 
                    onChange={(e)=>{setByDescription(e.currentTarget.value)}}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text onClick={()=>{filter('desc')}}>
                      <SearchIcon style={giveStyle} />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col xs={2} md={3}></Col>
            </Form.Row>

            <Form.Row className='d-flex justify-content-center my-4' >
            <Col xs={2} md={3}></Col>
            <Col xs={8} md={6}>
              <Form.Label> Date</Form.Label>
              <InputGroup>
                <Form.Control id="date-input" type='date' 
                  onChange={(e)=>{setByDate(e.currentTarget.value)}}
                />
                <InputGroup.Append>
                  <InputGroup.Text onClick={()=>{filter('date')}}>
                    <SearchIcon style={giveStyle} />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col xs={2} md={3}></Col>
            </Form.Row>

            <Form.Row >
              <Col xs={12}>
                <Form.Row>
                  <Col xs={1} md={3}></Col>
                  <Col xs={10} md={6} 
                    className='w3-light-grey d-flex justify-content-center'
                  >
                    Between Dates
                  </Col>
                  <Col xs={1} md={3}></Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={1} md={3}></Col>
                  <Col xs={10} md={6} className='w3-light-grey'>
                    <Form.Group controlId="StartTime">              
                      <Form.Label> Start</Form.Label>
                      <Form.Control type='datetime-local' 
                        onChange={(e)=>{setByDateFrom(e.currentTarget.value)}}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={1} md={3}></Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={1} md={3}></Col>
                  <Col xs={10} md={6} className='w3-light-grey pb-2'>
                    <Form.Label> End</Form.Label>
                    <InputGroup>
                      <Form.Control  type='datetime-local' 
                        onChange={(e)=>{setByDateTo(e.currentTarget.value)}}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text onClick={()=>{filter('between')}}>
                          <SearchIcon style={giveStyle} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                  <Col xs={1} md={3}></Col>
                </Form.Row>
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
       
  </div>)
   
}

const mapStateToProps=(state)=>{
  return{
    participant: state.participant,

  }
}

const mapDispatchToProps=(dispatch)=>{
  return{
    loadAllocations:  (payload)=>dispatch(loadAllocation(payload)),
    displayAllocation: (payload)=>dispatch(allocationToDisplay(payload)),
    loadingAllocations: (payload)=>dispatch(loadingAllocation(payload)),

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Filter);
