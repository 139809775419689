import { PARTICIPANT, PASSED, CATEGORY, SEARCH_PROS,
    GOOGLE_SIGN_UP_DATA,
    IS_LOGIN,
    LOADED_FOLLOWERS,
    LOADED_FOLLOWINGS,
    INITIAL_STATUS_POST,
    INITIAL_ALLOCATION,
    ALLOCATION_TO_DISPLAY,
    LOAD_PRIVATE_CHATS,
    SELECTED_ALLOCATION_ID,
    MARK_SELECTED_CHAT,
    FETCHED_ENJOY_FILES,
    NOTIFICATION_COLLECTOR,
    NOTIFICATION_COUNT,
    ENJOY_SEARCH_VALUE,
    BECOME_PROFESSIONAL_MODAL,
    PERSONAL_DETAILS,
    USER_PAGE_OWNER,
    USER_PAGE_POST,
    TEMPORARY_NO_AUTH_ALLOCATION_DATA,
    DISPLAY_NO_AUTH_TAG_PROFESSIONAL,
    LOAD_GENERAL_SEARCH_RESULT,
    SPECIFIC_USER_POST,
    SHOW_LOGIN_MODAL,
    SHOW_SIGNUP_MODAL,
    SHOW_AUTH_MODAL,
    SOME_PAGE_NOT_FOUND,
    USER_FOLLOWERS,
    USER_FOLLOWINGS,
    CHECK_RELATIONSHIP,
    LOAD_DESKTOP_PRIVATE_CHATS,
    LOAD_PC_DETAIL_CHANGES,
    LOADING_ALLOCATION_FILTERED,
    INTENDED_PAGE,
    TICKET_NOT_FOUND,
    COVER_WENT_WRONG} from "../constants/action_types";

const initialState = {
    isLogin:false,
    isPassed: null,
    participant:{},
    followers:{},
    followings:{},
    display:'all',
    loadingAllocation: false,
    initialStatusPosts:{},
    initialAllocations:{},
    categories: [],
    professionals:[],
    loadedEnjoyFiles:{},
    googleSignUpParam: new FormData(),
    initialPrivateChats:{},
    desktopInitialPCs:{},
    changedPcDetail:false,
    notifications:{},
    notificationCount:null,
    selectedAllocationId:null,
    somePageNotFound:false,
    markSelectedChat:null,
    enjoySearchValue:'',
    professionalModal:false,
    personalDetails:{},
    userPageOwner:null,
    userPagePost:{},
    tempNoAuthAllocation: null,
    displayNoAuthTagProfessional: true,
    generalSearchResult: null,
    specificUserPost: null,
    showLoginModal: false,
    showSignUpModal: false,
    showAuthModal: false,
    userFollowers: null,
    userFollowings: null,
    userRelationship: null,
    ticketNotFound: false,
    intendedPage: '/',
    coverWentWrong: false,
}

function rootReducer( state=initialState, action){
    if (action.type === PASSED){
        return Object.assign(
            {},
            state,
            {isPassed: action.payload}
        )
    }
    else if (action.type === PARTICIPANT){
        return Object.assign(
            {},
            state,
            {participant: action.payload}
        )
    }else if(action.type ===IS_LOGIN){
        return Object.assign(
            {},
            state,
            {isLogin: action.payload}
        )
    }
    else if (action.type === CATEGORY){
        return Object.assign(
            {},
            state,
            {categories: action.payload}
        )
    }
    else if(action.type === SEARCH_PROS){
        return Object.assign(
            {},
            state,
            {professionals: action.payload}
        )
    }
    else if(action.type === GOOGLE_SIGN_UP_DATA){;
        return Object.assign(
            {},
            state,
            {googleSignUpParam: action.payload}
            )
    }
    else if(action.type === LOADED_FOLLOWERS){
        return Object.assign(
            {},
            state,
            {followers: action.payload}
        )
    }
    else if(action.type === LOADED_FOLLOWINGS){
        return Object.assign(
            {},
            state,
            {followings: action.payload}
        )
    }
    else if(action.type === INITIAL_STATUS_POST){
        return Object.assign(
            {},
            state,
            {initialStatusPosts: action.payload}
        )
    }
    else if(action.type === INITIAL_ALLOCATION){
        return Object.assign(
            {},
            state,
            {initialAllocations: action.payload}
        )
    }
    else if(action.type === ALLOCATION_TO_DISPLAY){
        return Object.assign(
            {},
            state,
            {display: action.payload}
        )
    }
    else if(action.type === LOADING_ALLOCATION_FILTERED){
        return Object.assign(
            {},
            state,
            {loadingAllocation: action.payload}
        )
    }
    else if(action.type === LOAD_PRIVATE_CHATS){
        return Object.assign(
            {},
            state,
            {initialPrivateChats: action.payload}
        )
    }
    else if(action.type === LOAD_DESKTOP_PRIVATE_CHATS){
        return Object.assign(
            {},
            state,
            {desktopInitialPCs: action.payload}
        )
    }
    else if(action.type === LOAD_PC_DETAIL_CHANGES){
        return Object.assign(
            {},
            state,
            {changedPcDetail: action.payload}
        )
    }
    else if(action.type === SELECTED_ALLOCATION_ID){
        return Object.assign(
            {},
            state,
            {selectedAllocationId: action.payload}
        )
    }
    else if(action.type === SOME_PAGE_NOT_FOUND){
        return Object.assign(
            {},
            state,
            {somePageNotFound: action.payload}
        )
    }
    else if(action.type === MARK_SELECTED_CHAT){
        return Object.assign(
            {},
            state,
            {markSelectedChat: action.payload}
         )
    }
    else if(action.type === FETCHED_ENJOY_FILES){
        return Object.assign(
            {},
            state,
            {loadedEnjoyFiles: action.payload}
         )
    }
    else if(action.type === NOTIFICATION_COLLECTOR){
        return Object.assign(
            {},
            state,
            {notifications: action.payload}
         )
    }
    else if(action.type === NOTIFICATION_COUNT){
        return Object.assign(
            {},
            state,
            {notificationCount: action.payload}
         )
    }
    else if(action.type === ENJOY_SEARCH_VALUE){
        return Object.assign(
            {},
            state,
            {enjoySearchValue: action.payload}
         )
    }
    else if(action.type === BECOME_PROFESSIONAL_MODAL){
        return Object.assign(
            {},
            state,
            {professionalModal: action.payload}
         )
    }
    else if(action.type === PERSONAL_DETAILS){
        return Object.assign(
            {},
            state,
            {personalDetails: action.payload}
         )
    }
    else if(action.type === USER_PAGE_OWNER){
        return Object.assign(
            {},
            state,
            {userPageOwner: action.payload}
         )
    }
    else if(action.type === USER_PAGE_POST){
        return Object.assign(
            {},
            state,
            {userPagePost: action.payload}
         )
    }
    else if(action.type === TEMPORARY_NO_AUTH_ALLOCATION_DATA){
        return Object.assign(
            {},
            state,
            {tempNoAuthAllocation: action.payload}
         )
    }
    else if(action.type === DISPLAY_NO_AUTH_TAG_PROFESSIONAL){
        return Object.assign(
            {},
            state,
            {displayNoAuthTagProfessional: action.payload}
         )
    }
    else if(action.type === LOAD_GENERAL_SEARCH_RESULT){
        return Object.assign(
            {},
            state,
            {generalSearchResult: action.payload}
         )
    }
    else if(action.type === SPECIFIC_USER_POST){
        return Object.assign(
            {},
            state,
            {specificUserPost: action.payload}
         )
    }
    else if(action.type === SHOW_LOGIN_MODAL){
        return Object.assign(
            {},
            state,
            {showLoginModal: action.payload}
         )
    }
    else if(action.type === SHOW_SIGNUP_MODAL){
        return Object.assign(
            {},
            state,
            {showSignUpModal: action.payload}
         )
    }
    else if(action.type === SHOW_AUTH_MODAL){
        return Object.assign(
            {},
            state,
            {showAuthModal: action.payload}
         )
    }
    else if(action.type === USER_FOLLOWERS){
        return Object.assign(
            {},
            state,
            {userFollowers: action.payload}
         )
    }else if(action.type === USER_FOLLOWINGS){
        return Object.assign(
            {},
            state,
            {userFollowings: action.payload}
         )
    }else if(action.type === CHECK_RELATIONSHIP){
        return Object.assign(
            {},
            state,
            {userRelationship: action.payload}
         )
    }else if(action.type === INTENDED_PAGE){
        return Object.assign(
            {},
            state,
            {intendedPage: action.payload}
        )
    }else if(action.type === TICKET_NOT_FOUND){
        return Object.assign(
            {},
            state,
            {ticketNotFound: action.payload}
        )
    }else if(action.type === COVER_WENT_WRONG){
        return Object.assign(
            {},
            state,
            {coverWentWrong: action.payload}
        )
    }
    return state;
}

export default rootReducer;