import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import "./../../TemplateStyle.css";
import ForwardIcon from "@material-ui/icons/Forward";
import { Link } from "react-router-dom";
// import '../../assets/images/website/template/graphics-design.jpg'

const classes = {
  headerBg: {
    borderRadius: "18px",
  },
};
class Templates extends Component {
  state = {
    templatesList: [
      {
        title: "Graphics Design",
        image: "graphics-design",
        link: "/templates/graphics-design",
        statusNote: "",
      },
      // { title: 'Animations & Gifs', image: 'animations-gifs', link: '/templates/category', statusNote: 'Coming Soon' },
      // { title: 'Content Writing', image: 'content-writing', link: '/templates/category', statusNote: 'Coming Soon' },
    ],
  };
  render() {
    return (
      <div>
        <div className="container">
          <div
            className="row customPrimaryBgColor my-2"
            style={classes.headerBg}
          >
            <div className="col-sm-12 col-md-2 col-lg-2 col-xl-3"></div>
            <div className="w3-well col-sm-12 col-md-8 col-lg-8 col-xl-6 text-center py-4">
              <Typography variant="h4" className="text-white mb-2">
                Templates
              </Typography>
              {/* <Typography variant="subtitle2" className="text-white font-weight-light mb-2">   
                                Choose from our ready-to-use templates for your next project.
                            </Typography>
                            <div>
                                <input 
                                    type="text" 
                                    disabled
                                    className="form-control rounded-pill faqSearchInput" 
                                    placeholder="Search ready made templates"
                                />
                            </div> */}
              <Typography
                className="mt-3 text-white font-weight-light"
                variant="subtitle2"
              >
                You can as well assign your project to a professional
                <Link
                  to="/dashboard/tag-professional"
                  className="py-1 px-3 bg-white text-decoration-none  ml-1
                                    w3-hover-shadow w3-animate-opacity text-primary rounded-pill my-1 "
                >
                  Here
                </Link>
                . It's absolutely free!
              </Typography>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-2 col-xl-3"></div>
          </div>
        </div>
        <div className="container">
          <div className="row mb-4 py-4">
            <div className="col-sm-12 col-md-1 col-lg-1 col-xl-2"></div>
            <div className="col-sm-12 col-md-10 col-lg-10 col-xl-8">
              <Typography variant="h4" className="h4">
                Browse by category
              </Typography>
              <div className="row py-4">
                {this.state.templatesList.map((template, index) => (
                  <div
                    className="col-sm-12 px-sm-5 col-md-4 col-lg-4 col-xl-4"
                    key={index}
                  >
                    <div
                      className="w3-display-container d-flex w3-hover-shadow
                                            justify-content-center w3-hover-opacity w3-animate-zoom"
                    >
                      <Link to={template.link}>
                        <img
                          width="200"
                          className="img-fluid mb-2"
                          src={require("../../assets/images/website/template/" +
                            template.image +
                            ".jpg")}
                          alt={template.title}
                        />
                        <div className="w3-display-middle w3-animate-zoom w3-display-hover">
                          <ForwardIcon
                            className="w3-text-teal"
                            fontSize="large"
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="d-flex justify-content-center px-3">
                      <Link
                        to={template.link}
                        style={{ width: "150px" }}
                        className="nav-link sidebarText text-center py-1 bg-info w3-hover-shadow
                                                 text-white ml-1 rounded-pill my-1 w3-animate-zoom"
                      >
                        Explore
                      </Link>
                    </div>
                    <Typography
                      variant="body1"
                      className="font-weight-bold d-flex justify-content-center"
                    >
                      {template.title}
                    </Typography>
                    {template.statusNote && (
                      <span className="small">{template.statusNote}</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-sm-12 col-md-1 col-lg-1 col-xl-2"></div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-1 col-lg-1 col-xl-2"></div>
            <div className="col-sm-12 col-md-10 col-lg-10 col-xl-8">
              <Typography variant="h6" className="h6 sectionTitle">
                How to use templates
              </Typography>
              <Typography variant="subtitle1">
                <ul>
                  <li>Select template category</li>
                  <li>Select your preferred sub-category</li>
                  <li>Pick any template to edit.</li>
                  <li>Fill the inputs provided appropriately then submit.</li>
                </ul>
              </Typography>
            </div>
            <div className="col-sm-12 col-md-1 col-lg-1 col-xl-2"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Templates;
