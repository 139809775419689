import React,{Component} from 'react';
import Axios from 'axios';
import { PROXY } from '../../global/constants/action_types';
import { useParams, useNavigate } from 'react-router-dom';

class ConfirmEmail extends Component{
    state={
        success:false,
        error:false,
        already:false,
        name:null,
        eligible:false,
    }
    mounted;

    navigate=(path)=>{
        return this.props.navigate(path);
    }
    componentDidMount=async()=>{
        this.mounted= true;
        await this.verifyEmail();
    }
    
    componentWillUnmount=()=>{
        this.mounted = false;
    }
    
    verifyEmail=()=>{
        Axios.get(`${PROXY}/api/confirm_email/${this.props.params.email}`)
        .then(async(res)=>{
            if(res.data.success){
                (this.mounted) && await this.setState({success:true});
                (this.mounted) && await this.setState({name:res.data.data});
            }else{
                if(res.data.Error === 'Intruder'){
                    window.localStorage.clear();
                   return this.props.history.replace("/")
                }
                if(res.data.Error === 'Already'){
                    (this.mounted) && await this.setState({already:true});
                }
                if(res.data.Error === 'NotFound'){
                    (this.mounted) && await this.setState({error:true});
                }
            }
        })
        .catch(err=>{
            // console.log({err});
            (this.mounted) && this.setState({error:true});
        })
    }
    render(){
        return(
            <React.Fragment>
                <div className='w3-container' style={{marginTop:'70px', height:'90vh'}}>
                    {this.state.success &&
                        <div className="text-center my-3">
                            {this.state.name &&
                                <div><b> Dear {this.state.name},</b></div>
                            }
                            <div>Congratulations!! Your Email has been confirmed.</div>
                            <button onClick={()=>this.navigate('/dashboard')} className="px-5 btn my-2 customPrimaryBgColor text-white rounded-pill">
                                Proceed
                            </button>
                        </div>
                    }
                    {
                        this.state.error && 
                        <div  className="text-center my-3">
                            <div>Oops! Something went wrong. It could be an expired link or changed account.</div>
                            <button onClick={()=>this.navigate('/')} className="px-5 btn my-2 customPrimaryBgColor text-white rounded-pill">
                                Continue
                            </button>
                        </div>
                    }

                    { this.state.already && 
                        <div className="text-center my-3">
                            <div>You email has already been confirmed! </div>
                            <button onClick={()=>this.navigate('/dashboard')} className="px-5 btn my-2 customPrimaryBgColor text-white rounded-pill">
                                Proceed
                            </button>
                        </div>
                    }

                </div>     
            </React.Fragment>
        )
    }
}


const withRouter = WrappedComponent => props => {
  const params = useParams();
  const navigate = useNavigate();


  return (
    <WrappedComponent
      {...props}
      params={params}
      navigate={navigate}
    />
  );
};


export default withRouter(ConfirmEmail);