import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { GoogleLogin } from 'react-google-login';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import {Container, Row, Col} from 'react-bootstrap';
import * as Yup from 'yup';
import './FormStyle.css';
import ErrorFeedBack from './ErrorFeedBack'
import { googleSignUpData, participantInfo, is_login } from '../../global/action';
import { connect } from 'react-redux';
import { TOKES, PUT_BEFORE, PROXY } from '../../global/constants/action_types';
import { DEFLECTION, addDays } from '../../global/generalMethods/general';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';


const SignupSchema = Yup.object().shape({
	password: Yup.string()
	  .min(8, 'Minimum of 8 is required')
	  .required(' Password is required')
	  .matches(/(^([a-zA-Z0-9_]){8,}$)/, "0-9 , _ , a-z are only accepted"),

	confirmPassword: Yup.string()
		.required("Required")
		.test('passwords-match', 'Password must match', function(value) {
		  	return this.parent.password === value;}
		),

	email: Yup.string()
	  .email('Invalid email')
	  .required('Email is required'),

	firstName: Yup.string()
		.required('Your first name is required')
		.min(2, 'Minimum of 2 is required')
		.matches(/(^([a-zA-Z-]){2,}$)/, 'a-z and - is only accepted'),

	lastName: Yup.string()
		.required('Your last name is required')
		.min(2, 'Minimum of 2 is required')
		.matches(/(^([a-zA-Z-]){2,}$)/, 'a-z and - is only accepted'),
  });

// Beginning of the Signup class
class SignUpModal extends Component {
	state = {
		processing: false,
		validationError:{},
		somethingWentWrong: false,
	}

	responseGoogle = (response) => {
		if (!response.profileObj) return;
			const apiReturn  = new FormData()
			apiReturn.append('type', "SocialAuth");
			apiReturn.append('firstName', response.profileObj.givenName);
			apiReturn.append('lastName', response.profileObj.familyName);
			apiReturn.append('email', response.profileObj.email);
			this.props.closeModal();
			this.props.googleSignUpParams(apiReturn);
			let redirect = this.props.searchParams.get("redirect");
			redirect = redirect?`?redirect=${redirect}`:'';
			return this.props.navigate(`/google-recovery${redirect}`);
	}

	submitSignUp = (value) => {
		if(!this.state.processing){
			this.setState({somethingWentWrong: false});
			this.setState({ processing: true});
			this.setState({validationError: {} });
			const form = new FormData()
			form.append('type', "AppAuth");
			form.append('password', value.password);
			form.append('email', value.email);
			form.append('firstName', value.firstName);
			form.append('lastName', value.lastName);
			axios.post(`${PROXY}/api/register`, form)
			.then(async(res)=>{
				if(res.data.success){
					this.setState({ processing: false});				
					localStorage.MedToken = DEFLECTION(PUT_BEFORE,res.data.token,TOKES);
					this.props.isLogin(true);
					await this.props.closeModal()
					const _participant = JSON.parse(window.atob(res.data.data))
					// add participant uuid to cookie
					if (_participant?.uuid) {
						const expireAt = addDays(4);
						document.cookie = `mediUUid=${_participant.uuid}; expires=${expireAt};`							
					}
					await this.props.participant(_participant);
					if(this.props.goPage !== '/'){
						return this.props.navigate(this.props.goPage);
					}else{
						return this.props.navigate('/dashboard');
					}
				} else {
					if(res.data.validationError){
						this.setState({validationError: res.data.validationError});
					}
				}
			})
			.catch(err=>{
				if(err){
					this.setState({somethingWentWrong: true});
				}
			}).finally(()=>{
				this.setState({ processing: false});
			})
		}
	}


	render() {
		return (
			<div className="mt-5">
						<Formik
							initialValues={{password: '', confirmPassword: '', email: '', firstName: '', lastName: ''}}
							validationSchema={SignupSchema}
							onChange={this.handleChange}
							onSubmit={this.submitSignUp}
						>
							{({ errors,
								touched,
								values}) => (
								<Form style={{marginTop: '10px',}}>
									<Container className='d-sm-block justify-content-sm-center mr-xs-5' >
										<Row className="text-center">
											<Col sm={6} xs={12}  >
												<Field className= {touched.firstName && errors.firstName ?'notValid': 'Manually'} required type="text" name='firstName' value={values.firstName} />
												{errors.firstName && touched.firstName ? (<small className="margin-reduce">{errors.firstName}</small>): null}
												<label className={touched.firstName && errors.firstName ?'redLabel': 'labelly'}><small>FirstName </small></label>
											</Col>
											<Col sm={6} xs={12}>
												<Field className= {touched.lastName && errors.lastName ?'notValid': 'Manually'} required type="text" name='lastName' value={values.lastName} />
												{errors.lastName && touched.lastName ? (<small className="margin-reduce">{errors.lastName}</small>): null}
												<label className={touched.lastName && errors.lastName ?'redLabel': 'labelly'}><small>LastName </small></label>
											</Col>
										</Row>
										<Row className="text-center">
											<Col sm={6} xs={12}  >
												<Field className= {touched.email && errors.email ?'notValid': 'Manually'} required type="email" name='email' value={values.email} />
												{errors.email && touched.email ? (<small className="margin-reduce">{errors.email}</small>): null}
												<label className={touched.email && errors.email ?'redLabel': 'labelly'}><small>Email </small></label>
											</Col>
											<Col sm={6} md={6} xs={12}>
												<Field className= {touched.password && errors.password ?'notValid': 'Manually'} required type="password" name='password'/>
												{touched.password && errors.password ? (<small className="margin-reduce">{errors.password}</small>): null}
												<label className={touched.password && errors.password ?'redLabel': 'labelly'}><small>Password </small></label>
											</Col>
										</Row>
										<Row className="text-center">
											<Col md={{span: 6, offset: 3}} >
												<Field className= {touched.confirmPassword && errors.confirmPassword ?'notValid': 'Manually'} required type="password" name='confirmPassword' value={values.confirmPassword} />
												{touched.confirmPassword && errors.confirmPassword? (<small className="margin-reduce">{errors.confirmPassword}</small>): null}
												<label className={touched.confirmPassword && errors.confirmPassword ?'redLabel': 'labelly'}><small>Confirm Password </small></label>
											</Col>
										</Row>
									</Container>
									<Row className="text-center">
										<Col md={{span: 6, offset: 3}} >
											<div style={{marginTop: '-25px', color:'#F44336'}}>
												{this.state.validationError && <ErrorFeedBack  errors={this.state.validationError} />}
											</div>
										</Col>
										<Col md={{span: 6, offset: 3}}>
											{this.state.somethingWentWrong && <div className="text-danger my-1">
												<small>Oops, something went wrong, please check your internet connection and try again.</small>
											</div>}
										</Col>

									</Row>
									<Row className="mb-2">
										<Col className="text-center">
										<input type="checkbox" />
										<small>I have read and I agree to the <Link to="/terms-and-conditions">Terms and Conditions</Link> of Medillery.</small>
										</Col>
									</Row>
									<Button type='submit' variant="contained" disableElevation 
										className="customPrimaryBgColor btn-block text-white rounded-pill"
									>
										<Typography variant="body1">Signup</Typography>
									</Button>
								</Form>
							)}
						</Formik>
						<Row>							
							<Col xs={{span: 2, offset: 5}}>
								{this.state.processing && <div className="w3-spin spinner w3-circle mt-2"></div>}
							</Col>

						</Row>
						<Row>
							<Col xs={{span: 2, offset: 5}} className="text-center">
								<Typography className="text-center my-1">OR</Typography>
							</Col>
						</Row>
						<Row>
							<Col xs={2} sm={2} md={2}></Col>
							<Col xs={8} sm={8} md={8} className="text-center">
								<GoogleLogin
									className="rounded-pill"
									clientId="63312386785-pq5gh1anun4b0cugvqp3sh1a806j8t5q.apps.googleusercontent.com"
									buttonText="Signup with Google"
									onSuccess={this.responseGoogle}
									onFailure={this.responseGoogle}
									cookiePolicy={'single_host_origin'}
								/>
							</Col>
							<Col xs={2} sm={2} md={2}></Col>
						</Row>

			</div>
		);
	}
}

const mapStateToProps=(state)=>{
	return{
		goPage: state.intendedPage,
	}
}

const mapDispatchToProps=(dispatch)=>{
	return{
		googleSignUpParams: (payload)=>dispatch(googleSignUpData(payload)),
		participant: (payload)=>dispatch( participantInfo(payload)),		
		isLogin: (payload)=>dispatch(is_login(payload))
	}
}

const withRouter=Child=>props=>{
	const params = useParams();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	return(
			<Child 
			{...props} 
			params={params} 
			searchParams={searchParams}
			navigate={navigate} 
			/>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpModal));