import React, { Component } from 'react';
// import htmlToImage from 'html-to-image';
// import download from 'downloadjs';
import '../../CSSs/Business/nine/nine.css';
import { Typography } from '@material-ui/core';
import download from 'downloadjs';
import * as htmlToImage from 'html-to-image'
// import CopyRight from '../../CopyRight'
import GDHeader from '../GDHeader';

class BusinessEditNine extends Component {
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        logoText: "Your Logo",
        yourDetails: "Your Details",
        hereText: "Here",
        otherTexts: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, consectetur",
        productInfo: "Lorem ipsum dolor sit amet,Lorem ipsum dolor sit Lorem ipsum dolor sit amet consectetur",
        objTexts: "Lorem ipsum dolor sit amet, Lorem ipsum",
    }
    changeBackground = (e) => {
        e.preventDefault();
        try {        
            const file = e.target.files[0];
            if(!file)  return;
            let back = document.getElementById("outSideDiv9");
            if(back){
                back.style.background = `linear-gradient(0deg, rgba(45, 22, 136, 0.8), rgba(45, 22, 136, 0.8)), url(${URL.createObjectURL(file)})`;
                back.style.backgroundSize = `100%`;
                back.style.backgroundRepeat = `no-repeat`;
                back.style.backgroundPosition = `center`;
            }
        } catch (e) {
            return;
        }
    }
    changeImage = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const slot = document.getElementById('imgSlot');
        if(!slot) return;
        slot.src = URL.createObjectURL(file);
    }
    changeLogo = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const logo = document.getElementById('logoSlot');
        if(!logo) return;
        logo.src = URL.createObjectURL(file);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }
    handleFinishEdit = (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('outSideDiv9'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
                var link = document.createElement("a");
                link.download = "medillery-template.jpeg";
                link.href = dataUrl;
                link.click();
            });          
    }
    
    downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('outSideDiv9'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }
    
    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }

    render() {
        return (<div style={{marginTop: '90px'}}>
            <GDHeader caty='business' />
            
            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* this container's borderline is only visible on mobile for responsiveness */}
                                        <div className="parentContainer">
                                            {/* this is the outside div that encapsulate all the others */}
                                            <div className="outSideDiv9" id="outSideDiv9">
                                                {/* the left-sided div with texts */}
                                                <div className="leftSideDiv9">
                                                    {/* The main insdie sideDiv for margin */}
                                                    <div className="mainInsideDiv9">
                                                        {/* this is the zig-zag circles */}
                                                        <div className="circle9">
                                                            <div className="yourLogo9">
                                                                <img id="logoSlot" src={require("../../../../assets/images/img.png")} 
                                                                    alt="living Room" className="logoImage9" />
                                                            </div>
                                                            {/* <div className="yourLogo9">{this.state.logoText}</div>  */}
                                                            <div className="triangle9"></div> 
                                                            <div className="triangle9"></div> 
                                                            <div className="triangle9"></div> 
                                                            <div className="triangle9"></div> 
                                                            <div className="triangle9"></div> 
                                                            <div className="triangle9"></div> 
                                                            <div className="triangle9"></div> 
                                                            <div className="triangle9"></div> 
                                                            <div className="triangle9"></div> 
                                                            <div className="triangle9"></div> 
                                                            <div className="triangle9"></div> 
                                                            <div className="triangle9"></div>
                                                        </div>
                                                        {/* The text details div  */}
                                                        <div className="textDetailsDiv9">
                                                            <h1>{this.state.yourDetails} <span>{this.state.hereText}</span></h1>
                                                            <p>{this.state.otherTexts}</p>
                                                        </div>
                                                        {/* Clipped circle with its text div */}
                                                        <div className="roundWhiteCircle9 whiteCircleOne9">
                                                            {/* text only inside the div */}
                                                            <div className="textOnly9">
                                                                <p>{this.state.productInfo}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* the right-sided div with the images div */}
                                                <div className="rightSideDiv9">
                                                    {/* this is the cover for both the image circle */}
                                                    <div className="coverImgRectDiv9">
                                                        {/* div for the rounded circle image */}
                                                        <div className="imageDivRound9">
                                                            <img id="imgSlot"
                                                                src={require("../../../../assets/images/templates/heroes/1.jpeg")}
                                                                alt="bags" className="roundImageCircle9 whiteCircleTwo9" />
                                                            {/* the text div below the image circle */}
                                                            <div className="imagetextDiv9">
                                                                <p>{this.state.objTexts}</p>
                                                            </div>
                                                        </div>
                                                
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-4">
                            {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                        <div className="form-group mt-3">
                                            <label>Background Image</label>
                                            <label className="form-control rounded-pill">
                                                <input 
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeBackground}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label>Image</label>
                                            <label className="form-control rounded-pill">
                                                <input 
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeImage}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label>Logo</label>
                                            <label className="form-control rounded-pill">
                                                <input
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeLogo}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group d-flex">
                                            <div className="mr-2">
                            
                                                <label>Your Details</label>
                                                <input maxLength="17"
                                                    type="text" id="yourDetails" 
                                                    value={this.state.yourDetails} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />    
                                            </div>
                                            <div>
                                                <label>Here</label>
                                                <input 
                                                    type="text" id="hereText" 
                                                    value={this.state.hereText} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <label>Objectives</label>
                                                <input 
                                                    type="text" id="objTexts"
                                                    onChange={this.handleChange} 
                                                    value={this.state.objTexts}  
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <label>Other Information</label>
                                                <input 
                                                    type="text" id="otherTexts"
                                                    value={this.state.otherTexts} 
                                                    onChange={this.handleChange} 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>  
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <label>Product Info</label>
                                                <input 
                                                    type="text" id="productInfo"
                                                    onChange={this.handleChange}
                                                    value={this.state.productInfo} 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                            > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4  className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select className="form-control form-control-sm rounded-pill"
                                                onChange={this.downloadNow}
                                            >   <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                        className="btn btn-block rounded-pill customPrimaryBgColor" 
                                        onClick={ this.backToEdit }
                                        > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>);
    }
}
// jquery.scrollLeft() to move the whole div left and right
// https://stackoverflow.com/questions/27313480/scroll-div-content-horizontally-using-left-and-right-arrow-in-jquery

export default BusinessEditNine;
