import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

export default class NotFound extends Component {
    state = {
        footerItems: [
            { title: 'Terms & Conditons', link: '/terms-and-conditions' },
        ]
    }
    render() {
        return (<div>            
            <div className='container' style={{height:window.innerHeight, marginTop:'70px'}}>
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 my-auto">
                        <Typography variant="h3" className="mb-2 sectionTitle text-center">Page not found.</Typography>
                        <Typography variant="body1" className="mt-5 mb-2 text-center">
                            Oops! we can’t seem to locate the page you’re looking for.
                            Try going back to the previous page or see our <Link to="/" className="text-decoration-none"> Help Center</Link>
                            for more information.                            
                        </Typography>
                        <div className='text-center mt-5'>
                            <Link to="/" 
                                className="btn customPrimaryBgColor rounded-pill text-white text-nowrap mr-3"
                            >
                                Go to Homepage
                            </Link>
                            <Link to="/templates" 
                                className="btn customSecondaryBgColor rounded-pill text-white text-nowrap"
                            >
                                Check our Templates
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                        <div className="pageNotFoundBg w-100"></div>
                    </div>
                </div>
            </div>
            
        </div>)
    }
}
