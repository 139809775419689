import React from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import LazyLoader from '../../../LazyLoader';
import NotFound from '../../../NotFound';
import SomethingWentWrong from '../../../SomethingWentWrong';
import { PROXY } from '../../../global/constants/action_types';
import { LUX_ZONE } from '../../../global/generalMethods/general';


// const quitRequest = Axios.CancelToken.source();
const ProfessionalDoneFiles=({participant})=>{

  const mounted = React.useRef(true);

  const [loading, setLoading] = React.useState(true);
  const [notFound, setNotFound] = React.useState(false);
  const [wentWrong, setWentWrong] = React.useState(false);
  const [gettingMore, setGettingMore] = React.useState(false);
  const [projects, setProjects] =   React.useState({});

  React.useEffect(()=>{
    const getUser=()=>{
      Axios.get(`${PROXY}/api/get_professional_jobs/${participant.userName}`)
      .then(res=>{
        if(res.data.success){
          if(!mounted.current) return;
          mounted.current && setProjects(res.data.data);
          mounted.current && setLoading(false);
        }
      })
      .catch(err=>{
        if(err.response){
          if(err.response.status === 404){
            mounted.current && setNotFound(true);
            mounted.current && setLoading(false);
          }
          if(err.response.status === 500){
            mounted.current && setWentWrong(true);
            mounted.current && setLoading(false);
          }
        }else{
          mounted.current && setWentWrong(true);
          mounted.current && setLoading(false);
        }
      })
    }
    getUser();

    return()=>{
      mounted.current = false;
    }
  },[participant]);

React.useEffect(()=>{
  const getMoreFiles=()=>{
    if(gettingMore) return;
    if(!projects.next_page_url) return;
    setGettingMore(true);
    Axios.get(projects.next_page_url)
    .then(res=>{
      if(res.data.success){
        if(!mounted.current) return;
        const tempData= [...projects.data, ...res.data.data.data];
        res.data.data.data = tempData;
        mounted.current &&setProjects(res.data.data);
        mounted.current && setGettingMore(false);
      }
    })
    .catch(err=>{
      mounted.current && setGettingMore(false);
    })
  }

  const virtualScrolling=()=>{
    // console.log(`${document.body.scrollHeight}/${window.pageYOffset}/${window.innerHeight}/
    // ${document.body.offsetHeight}/${document.body.clientHeight}`);
    if(window.pageYOffset+window.innerHeight >= document.body.offsetHeight){
      getMoreFiles();
    }
  }
  window.addEventListener('scroll', virtualScrolling);

  return()=>{
    window.removeEventListener('scroll', virtualScrolling)
  }
})

  return(<div>

    <div>
      {loading?
        <div>
          <LazyLoader />
        </div>:
        <div>
          {notFound?
            <NotFound />:
            <div>
              {wentWrong?
                <SomethingWentWrong />:
                <div>
                  {projects.data  && projects.data.length > 0?
                    <div>
                    <div>
                      <div className='w3-panel d-flex justify-content-center '>
                        <div className='card w3-text-indigo pt-1 px-5 w3-round'>
                          <h4>Your Completed Files</h4>
                        </div>
                      </div>
                      </div>
                      <div className="w3-row-padding w3-margin-top">
                        { projects.data.map(data=>
                          <div  key={data.id}  className="mb-3 w3-animate-opacity px-sm-2 w3-third">
                            <div className="w3-card w3-hover-shadow ">
                              <a href={data.message} target='_blank' rel='noopener noreferrer'>
                                <img alt='nill' src={data.message} style={{width:'100%', height:'300px'}} />
                              </a>
                              <div className="w3-container mt-1 w3-center">
                                <h6>{LUX_ZONE(data.created_at)}</h6>
                              </div>
                            </div>
                          </div>
                        )}                     
                      </div>
                    </div>:
                    <div>
                      <div className='w3-panel d-flex justify-content-center py-4 '>
                        <div className='card mx-2 w3-text-indigo p-4 w3-round'>
                          <h5>You have no Completed jobs or files to display</h5>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
    
  </div>)
}

const mapStateToProps=(state)=>{
	return{
        participant: state.participant,
	}
}


export default connect(mapStateToProps)(ProfessionalDoneFiles);