import React, { Component } from 'react';
import { Avatar, Typography, Box, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Paper from '@material-ui/core/Paper';
import Axios from 'axios';
import "./projectDetails.scss"
import { connect } from 'react-redux';
import { PROXY } from '../../../global/constants/action_types';
import { Modal} from 'react-bootstrap';
import { processHeader } from '../../../global/action';
import { noImage, CHAT_LIKE_TIME, staticPusherConfig } from '../../../global/generalMethods/general';
import DescriptionPart from './DescriptionPart';
import LazyLoader from '../../../LazyLoader';
import NotFound from '../../../NotFound';
import { Link, useNavigate, useParams } from 'react-router-dom';

const classes = {
    userChatStyle: {
        float:'right',
        maxWidth: '85%',
        backgroundColor: '#F9FBFF',
        borderRadius: '15px 0 15px 15px',
    },
    otherChatStyle: {
        float:'left',
        maxWidth:'85%',
        borderRadius: '0 15px 15px 15px',
    },
    chatInput: {
        height: '50px',
    },
    chatInputDiv: {
        position: 'absolute',
        zIndex: '1',
        backgroundColor: '#FFFFFF',
        width: '96%',
        bottom: '0',
        padding: '7px 12px',
    },
    closeBtn: {
        position: 'absolute',
        right: 0,
        marginRight: '7px',
        zIndex: 50,
    }
}

const quitRequest = Axios.CancelToken.source();

class ProjectDetail extends Component {
    state = {
        allocationId:null,
        participant: {},
        type: 1,
        loading:false,
        notFound:false,
        allocation:{},
        sendInput:null,
        owner:null,
        partner:{},
        messageFile:[],
        initialUnread:0,
        unreadId:null,
        mobileModal: false,
        reportModal: false,
        reportKey: null,
        reportMessage: '',
        reportingError: false,
        reportKeyError: false,
        reporting: false,
        reportSuccessful: false,
        activeTab: "details",
    }

    mounted;
    timerHolder;
    scrollBox;

    componentDidMount=async()=>{
        this.mounted = true;        
        this.scrollBox= await window.document.getElementById('scrollBox');
        if(this.mounted){
            await this.setState({participant:this.props.participant});
            await this.filterRouteId();
            await staticPusherConfig();
            await this.listenToNewMessages();
        }
    }

    UNSAFE_componentWillReceiveProps=async(next)=>{
        if(Object.entries(next.participant).length > 0){
            if(!this.mounted) return;
            await this.setState({participant:next.participant});
        }
        if(Object.entries(next.professionalList).length > 0){
            if(!this.mounted) return;
            await this.setState({professionals: next.professionalList})
        }
    }

    componentWillUnmount=()=>{
        this.mounted = false;
    }

    listenToNewMessages=async()=>{
        if(!this.mounted) return;
        await window.Echo.private(`project.${this.props.participant.userName}.${this.state.allocationId}`)
        .listen('.allocationChat', async(data)=>{
            if(!this.mounted) return;
            const tempAllocation= Object.assign({}, this.state.allocation)
            const tempData = [...tempAllocation.messages,...JSON.parse(data[0])];
            tempAllocation.messages=tempData;
            if(!this.mounted) return;
            await this.setState({allocation:tempAllocation})
        })
    }

    filterRouteId=async()=>{
        if(!this.mounted) return;
        await this.setState({loading:true})
        let encodedPath;
        try {
         encodedPath = window.atob(window.atob(this.props.params.details));
        } catch (e) {
            if(!this.mounted) return;
            await this.setState({notFound:true});
            await this.setState({loading:false})
            return;
        }
		let sliceRandomAway = encodedPath.slice(6, encodedPath.length);
        const allocationId = Number(sliceRandomAway.slice(0, sliceRandomAway.length-6));
        if(isNaN(allocationId)){
            if(!this.mounted) return;
            await this.setState({notFound:true});
            await this.setState({loading:false})
            return;
        }
        if(!this.mounted) return;
        await this.setState({allocationId:allocationId});
        Axios.get(`${PROXY}/api/get_specific_allocation/${this.props.participant.userName}/${allocationId}`,
         {headers:processHeader(), cancelToken: quitRequest.token})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                const temp = [...res.data.data[0].messages]
                const unread = temp.filter(type=> type.userName !== this.state.participant.userName  && type.read === 0 &&
                    (type.type === 1 || type.type === 12 || type.type === 2 ||type.type === 22)
                );
                if(unread.length > 0){
                    if(!this.mounted) return;
                    await this.setState({initialUnread: unread.length});
                    await this.setState({unreadId: unread[0].id});                    
                }
                if(!this.mounted) return		
                this.markUnreadMessages();
                await this.setState({allocation:res.data.data[0]});
                await this.setState({loading:false})
                const gotoUnread = await document.getElementById('new_messages');
                if(gotoUnread){
                    gotoUnread.scrollIntoView();
                    window.document.body.scrollTop = 0;
                    window.document.documentElement.scrollTop = 0;
                    return;
                }else{
                    if(this.scrollBox){
                        this.scrollBox.scrollTop = this.scrollBox.scrollHeight - this.scrollBox.clientHeight;   
                    }else{
                        this.scrollBox= await window.document.getElementById('scrollBox');
                        if(!this.scrollBox) return;
                        this.scrollBox.scrollTop = this.scrollBox.scrollHeight - this.scrollBox.clientHeight;
                        return;
                    }
                }                
            }
            else{
                if(res.data.Error === 'Intruder'){
                    if(!this.mounted) return;
                    window.localStorage.clear();
                    return this.props.navigate('/');
                }
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            if(err && err.response && err.response) await this.setState({notFound:true});
            await this.setState({loading:false})
        })
    }

    handleFileUploadChange=async(e)=>{
        e.preventDefault();
        const fileCollector = e.target.files;
        await this.setState({minimumUploadAltered:false});
        if(fileCollector.length > 4){
            await this.setState({minimumUploadAlteredMessage: 'You can only send maximum of Four files'})
            await this.setState({minimumUploadAltered:true});
            return;
        }
        const Temp = [];
        let storeSize=0;
        for (let i = 0; i < fileCollector.length; i++) {
            Temp.push(fileCollector[i]);
            storeSize += fileCollector[i].size;
        }
        if(storeSize > 12582912){
            await this.setState({minimumUploadAlteredMessage: 'File(s) too large, maximum of 12MB is allowed'});
            await this.setState({minimumUploadAltered: true});
            return;
        }
        await this.setState({messageFile:Temp});
        await this.setState({type:2});
    }

    markUnreadMessages=async()=>{
        await Axios.get(`${PROXY}/api/mark_read_messages/${this.state.participant.userName}/${this.state.allocationId}`,
        {headers: processHeader(), cancelToken: quitRequest.token})
    }

    sendAllocationFileMessage=async(e)=>{
        e.preventDefault();
        if(this.state.allocation.status === 5 || this.state.allocation.status === 6){
            return;
        }
        const form = new FormData();
        if(!this.state.messageFile.length ) return;
        for (let i = 0; i < this.state.messageFile.length; i++) {
            form.append(`allocationMessageFile[${i}]`, this.state.messageFile[i]);
        }
        form.append('message', '');
        form.append('allocation_id', this.state.allocationId);
        Axios.post(`${PROXY}/api/message_allocation/${this.props.participant.userName}`, form, 
        {headers:processHeader(), cancelToken: quitRequest.token})
        .then(async(res)=>{
            if(res.data.success){
                const tempAllocation= Object.assign({}, this.state.allocation)
                const tempData = [...tempAllocation.messages,...res.data.data];
                tempAllocation.messages=tempData;
                if(!this.mounted) return;
                await this.setState({allocation:tempAllocation});
                if(this.scrollBox) this.scrollBox.scrollTop = this.scrollBox.scrollHeight - this.scrollBox.clientHeight;
                await this.setState({type:1});

            }else{
                if(res.data.Error === 'Intruder'){
                    window.localStorage.clear();
                    return this.props.navigate('/');
                }
            }

        })
        .catch(async(_)=>{
            if(!this.mounted) return;
            await this.setState({type:1});
        })

    }

    sendAllocationMessage=async(e)=>{
        e.preventDefault();
        if(this.state.allocation.status === 5 || this.state.allocation.status === 6){
            return;
        }
        const message = window.document.getElementById('sendInput').value.trim();
        if(!message) return;
        window.document.getElementById('sendInput').value='';
        const form = new FormData();
        form.append('message', message);
        form.append('allocation_id', this.state.allocationId);
        form.append('allocationMessageFile[]','');
        Axios.post(`${PROXY}/api/message_allocation/${this.props.participant.userName}`, form,
         {headers:processHeader(), cancelToken: quitRequest.token})
        .then(async(res)=>{
            if(res.data.success){
                const tempAllocation= Object.assign({}, this.state.allocation)
                const tempData = [...tempAllocation.messages,...res.data.data];
                tempAllocation.messages=tempData;
                if(!this.mounted) return;
                await this.setState({allocation:tempAllocation});
                if(this.scrollBox) this.scrollBox.scrollTop = this.scrollBox.scrollHeight - this.scrollBox.clientHeight;

            }else{
                if(res.data.Error === 'Intruder'){
                    window.localStorage.clear();
                    return this.props.navigate('/');
                }
            }
        })
        .catch((_)=>{})
    }   
    deleteFile=async(e)=>{
        e.preventDefault();
        const go = window.confirm("Do you want to remove this file?");
        if(!go) return;
        const collector = [...this.state.messageFile];
        collector.splice(Number(e.target.id), 1);
        if(collector.length < 1){
			await this.setState({type: 1});
			const uploader = document.getElementById('filer');
			uploader.value = null;
		}
        await this.setState({messageFile: collector});
    }

    setActiveTab=(_activeTab)=>{
        this.setState({activeTab: _activeTab})
    }
    resetAllocation=(newAllocation)=>{
        return this.setState({allocation:newAllocation});
    }
    hideMobileModal=()=>{
        this.setState({mobileModal:false});
    }
    hideReportModal=()=>{
        this.setState({reportModal:false});
    }
    closeReportModal=()=>{
        if(!this.state.reportModal) return;
        return this.setState({reportModal:false});
    }
    processReportKey=async(e)=>{
        // e.preventDefault();
        await this.setState({reportKey: e.target.value});
    }
    processReportMessage=(e)=>{
        e.preventDefault();
        this.setState({reportMessage: e.target.value});
    }

    reportProject=(e)=>{
        e.preventDefault();
        if(this.state.reporting) return;
        if(this.state.reportKeyError) this.setState({reportKeyError: false});
        if(!this.state.reportKey || typeof Number(this.state.reportKey) !== 'number'){
            this.setState({reportKeyError: true});
            return;
        }
        const form = new FormData();
        const reporter = this.state.allocation.owner? 1: 0;
        form.append('key', Number(this.state.reportKey));
        form.append('allocation_id', this.state.allocationId);
        form.append('report', this.state.reportMessage.trim());
        form.append('reporter', reporter);
        this.setState({reporting:true});
        Axios.post(`${PROXY}/api/report_allocation/${this.props.participant.userName}`,
        form, {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                const tempAllocation = {...this.state.allocation};
                tempAllocation.report = res.data.data;
                this.setState({allocation: tempAllocation});
                this.setState({reportSuccessful: true});
                this.setState({reporting: false});
                if(this.state.reportingError) this.setState({reportingError: false});
            }else{
                if(res.data.Error === 'Intruder'){
                    window.localStorage.clear();
                    return this.props.navigate('/');
                }
            }
        })
        .catch((err)=>{
            if(!this.mounted) return;
            this.setState({reportingError: true});
            this.setState({reporting: false});
        })
    }

    markAndNavigateToProjectIssue=()=>{
        return `/dashboard/projects/issue/${window.btoa(window.btoa(Number(this.state.allocation.report)))}`;
    }

    render() {
        const {userName} = this.props.participant;
        const {allocation} = this.state
        return ( <React.Fragment>
            {this.state.loading?
                <div>
                    <LazyLoader />
                </div>:
                <div>
                {this.state.notFound ?
                    <div>
                       <NotFound /> 
                    </div> :
                    <aside>
                    { Object.entries(allocation).length > 0 &&
                        <div className="card w3-animate-right">
                            {/* tabs */}
                            <aside className="d-flex gap-x-1">
                                <button onClick={()=>this.setActiveTab("details")}
                                    className={`tabs-btn border-l ${this.state.activeTab == "details" && 'active-tabs-btn'}`}
                                >
                                    Details
                                </button>
                                <button  onClick={()=>this.setActiveTab("messages")}
                                    className={`tabs-btn border-r ${this.state.activeTab =="messages" && 'active-tabs-btn'}`}
                                >
                                    Messages
                                </button>
                            </aside>

                            {this.state.activeTab == "messages"?
                            <div>
                                <div 
                                    className="card-header d-flex justify-content-between align-items-center p-2" 
                                    style={{backgroundColor: '#F9FBFF'}}
                                >
                                    <div className="d-flex align-items-center">
                                        <Avatar alt="avatar1" className="img-fluid mr-2" src={
                                            allocation.professional?
                                            `${allocation.professional.profilePic?
                                            `${allocation.professional.profilePic}`: `${noImage}`}`
                                            :
                                            `${allocation.owner.profilePic?
                                            `${allocation.owner.profilePic}`: `${noImage}`}`
                                        } />
                                        <Typography className="font-weight-bold">
                                            <Link to={`/user/${allocation.professional?
                                                allocation.professional.userName:allocation.owner.userName}`} 
                                                className='text-decoration-none'
                                            >
                                                {allocation.professional?
                                                `@${allocation.professional.userName}`:`@${allocation.owner.userName}`
                                                }
                                            </Link>
                                        </Typography>
                                    </div>
                                    <div className='mr-lg-5 d-flex'>
                                        <label className='mr-lg-5'>
                                            <AttachmentIcon className='mr-lg-5'/>
                                            <input 
                                                onChange={this.handleFileUploadChange}
                                                className='d-none' accept='.png, .svg, .gif, .jpg' 
                                                multiple type='file'  id='filer'
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="card-body py-2">
                                    <div className="row">
                                        {/* Message Box section */}
                                        <div 
                                            className="col-sm-12 mb-4 position-relative p-2 pt-sm-0 pt-md-2"
                                            style={{height:'78.5vh'}}
                                        >
                                            <div id='scrollBox' style={{height:'68vh', overflowY: 'auto'}}>
                                                <div>
                                                    { allocation.messages.filter(type=>type.type != 11 || type.type !=21).map((message) =>
                                                        <div key={message.id} >
                                                            {(this.state.unreadId && this.state.unreadId === message.id) &&
                                                                <div className='w3-container'>
                                                                    <small id='new_messages' className='w3-text-blue mb-4'>
                                                                        {this.state.initialUnread > 1? `${this.state.initialUnread} unread messages`:`${this.state.initialUnread} unread message`}
                                                                    </small>
                                                                </div>
                                                            }
                                                            { (message.type == 2 || message.type == 1) &&
                                                                <Paper elevation={0} style={message.userName === userName ? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
                                                                    <div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
                                                                    <Typography style={ {fontSize: '0.9rem'} }>
                                                                        {message.message}
                                                                    </Typography>
                                                                </Paper>
                                                            }
                                                            { (message.type == 22 || message.type == 12 )&&
                                                                <Paper elevation={0} style={message.userName === userName ? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
                                                                    <div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
                                                                    <Typography style={ {fontSize: '0.9rem'} }>
                                                                        <img src={`${message.message}`} alt="img" width='150' height='150' />
                                                                    </Typography>
                                                                </Paper>
                                                            }
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    ) }
                                                </div>
                                                <div style={classes.chatInputDiv}>
                                                    <form>
                                                        {this.state.type === 1 ?
                                                            <Box className="d-flex">
                                                                <textarea id="sendInput" className="form-control rounded-pill mr-1" 
                                                                    placeholder="your message here..." style={classes.chatInput}
                                                                    maxLength='10000'
                                                                >
                                                                </textarea>
                                                                <button className="btn customPrimaryBgColor text-white rounded-pill"
                                                                    onClick={this.sendAllocationMessage}
                                                                    disabled={(allocation && 
                                                                        (allocation.status === 5 || allocation.status === 6))?
                                                                        true: false
                                                                    }
                                                                >
                                                                    <ChevronRightIcon />
                                                                </button>
                                                            </Box>:
                                                            <Box style={{position: 'relative'}}>
                                                                <div className="d-flex justify-content-center" id="sendInput" style={classes.chatInput}>
                                                                    {this.state.messageFile.length > 0 && this.state.messageFile.map((data, index)=>
                                                                        <div className='mx-1 w3-round' key={index} style={{display: 'inline'}}>
                                                                            {data.type.includes('image')?
                                                                            <img 
                                                                                id={index} 
                                                                                onClick={this.deleteFile}  
                                                                                width='40' 
                                                                                height='40' 
                                                                                src={URL.createObjectURL(data)}
                                                                                alt='noImage'
                                                                            />:
                                                                            <video id={index} onClick={this.deleteFile} width='40' height='40' controls>
                                                                                <source src={URL.createObjectURL(data)} />
                                                                                Your device cannot play this video
                                                                            </video>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                    <button 
                                                                        className=" btn customPrimaryBgColor text-white rounded-pill" 
                                                                        onClick={this.sendAllocationFileMessage}
                                                                        style={{position: 'absolute', right:0}}
                                                                        disabled={(allocation && 
                                                                            (allocation.status == 5 || allocation.status == 6))?
                                                                            true: false
                                                                        }
                                                                    >
                                                                        <ChevronRightIcon />
                                                                    </button>
                                                                </div>
                                                            </Box>
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>:
                            <div className='p-1 p-sm-3'>
                                {Boolean(allocation.report)?
                                 <div className=" d-flex justify-content-center dropdown-item">
                                    <Link      
                                        to={this.markAndNavigateToProjectIssue()}
                                        className="btn btn-sm customPrimaryBgColor  px-3 py-1 text-white rounded-pill"
                                        >
                                        <span className="small">View Issue</span>
                                    </Link> 
                                </div>
                                :
                                <div className="">
                                    <aside>
                                        {allocation.owner ?
                                            <div>
                                                {
                                                ((allocation.status == 4) ||
                                                (allocation.status == 1) ||
                                                (allocation.status == 2 && !allocation.rated))  &&
                                                    <div>
                                                        <span className="btn rounded-pill w3-light-grey" type="button" 
                                                        onClick={()=>{this.setState({reportModal:true})}}
                                                        >
                                                            Report Project 
                                                        </span>
                                                    </div>
                                                }

                                            </div>:
                                            <div>
                                                {
                                                    (allocation.status == 0 ||
                                                    allocation.status == 1 ||
                                                    allocation.status == 4) &&
                                                    (!Boolean(allocation.rated) )  &&
                                                    <span className="btn rounded-pill w3-light-grey" type="button" 
                                                    onClick={()=>{this.setState({reportModal:true})}}
                                                    >
                                                        Report Project
                                                    </span>
                                                }
                                            </div>
                                        }
                                    </aside>
                                    {/* Allocation Description component */}
                                    <DescriptionPart 
                                        resetAllocation={this.resetAllocation}
                                        allocation={allocation} 
                                        closeModal={this.hideMobileModal}
                                        details={this.props.params.details}
                                    />
                                    {/* Report Modal */}
                                    <Modal
                                        show={this.state.reportModal}
                                        onHide={this.hideReportModal}
                                    >
                                        <Modal.Body className="py-4">
                                            <div>
                                                <IconButton 
                                                    variant="secondary"  
                                                    onClick={this.closeReportModal} 
                                                    style={classes.closeBtn}
                                                >
                                                    X
                                                </IconButton>
                                            </div>
                                            <h6 className="text-center">Having some trouble with this project? <br /> Tell us about it. We'd love to help.</h6>
                                            <div className="font-weight-bold mt-3">What went wrong, select a category?</div>
                                            <div className="d-flex">
                                                <div className="flex-fill d-flex flex-column mt-1">
                                                    <div>
                                                        <input onChange={this.processReportKey} value='1' type="radio" name='key' />
                                                        &nbsp; Project not accepted?
                                                    </div>
                                                    <div>
                                                        <input onChange={this.processReportKey} value='2' type="radio" name='key' />
                                                        &nbsp; Project Not rated?
                                                    </div>
                                                </div>
                                                <div className="flex-fill d-flex flex-column">
                                                    <div>
                                                        <input onChange={this.processReportKey} value='3' type="radio" name='key' />
                                                        &nbsp; No/Late response?
                                                    </div>
                                                    <div>
                                                        <input onChange={this.processReportKey} value='4' type="radio" name='key' />
                                                        &nbsp; Unclear Explanation?
                                                    </div>
                                                </div>
                                            </div>
                                            <textarea className="form-control mt-3" rows="5" placeholder="Tell us more"
                                                onChange={this.processReportMessage}
                                            >
                                            </textarea>
                                            <div className='d-flex justify-content-center mt-2'>
                                                { this.state.reportingError && !this.state.reporting &&
                                                    <small className='text-danger'> 
                                                        Oops, Something went wrong.
                                                    </small>
                                                }
                                                { this.state.reportKeyError && !this.state.reporting &&
                                                    <small className='text-danger'> 
                                                        Please choose a category.
                                                    </small>
                                                }
                                                { this.state.reporting &&
                                                    <div className="w3-spin spinner w3-circle"></div>
                                                }
                                                { this.state.reportSuccessful && 
                                                    <small>Your project has been successfully reported</small>
                                                }

                                            </div>
                                            <div className="text-center mt-3">
                                                {!Boolean(allocation.report)?
                                                    <button onClick={this.reportProject}
                                                    className="btn customPrimaryBgColor text-white rounded-pill px-5"
                                                >
                                                    Submit
                                                </button>:
                                                <button onClick={this.hideReportModal}
                                                    className="btn customPrimaryBgColor text-white rounded-pill px-5"
                                                >
                                                    Continue
                                                </button>
                                                }
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                                }
                            </div>                            
                            }                                
                        </div>
                    }
                    </aside>
                }
                </div>
            }
        </React.Fragment>);
    }
}

const mapStateToProps=(state)=>({
    participant:state.participant
})
const withRouter=Child=>props=>{
    const params = useParams();
    const navigate = useNavigate();
    return(
        <Child 
            {...props} 
            params={params} 
            navigate={navigate} 
        />
    )
}
export default connect(mapStateToProps)(withRouter(ProjectDetail));


/* <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-lg-block d-none">

</div> */