import { Typography, Box } from '@material-ui/core';

const CampaignLibrary = () => {
    return (
        <Box py={5} mb={5} className="libraryBackground mb-1" style={{backgroundColor: '#F5FAFE'}}>
            <Box className="container text-center" mb={4}>
                <Typography variant="h4" 
                    className="sectionTitle font-weight-bold mb-2">
                    Create Amazing Campaigns
                </Typography>
                <Typography variant="subtitle1" className="heroSecondText text-secondary text-center">
                    Funnel more conversations with our campaign creation tool
                </Typography>
                <Box className="container mx-auto mt-4">
                    <Box className="row libraryRow">
                        <Box className="col-12 col-sm-4 col-md-4 libraryCard campaignCard">
                            <Box className="m-1 card border-0 rounded-0 campaignOffset">
                                <img className="libraryImage"  
                                    src={ require('../../../assets/images/website/homepage/campaign-one.jpg') } 
                                    alt="Birthday" />
                                <Typography variant="h6" className="libraryCaption">
                                    Birthday
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="col-12 col-sm-4 col-md-4 libraryCard campaignCard">
                            <Box className="m-1 card border-0 rounded-0">
                                <img className="libraryImage"  
                                    src={ require('../../../assets/images/website/homepage/campaign-two.jpg') } 
                                    alt="Business" />
                                <Typography variant="h6" className="libraryCaption">
                                    Business
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="col-12 col-sm-4 col-md-4 libraryCard campaignCard">
                            <Box className="m-1 card border-0 rounded-0 campaignOffset">
                                <img className="libraryImage"  
                                    src={ require('../../../assets/images/website/homepage/campaign-three.jpg') } 
                                    alt="Politics" />
                                <Typography variant="h6" className="libraryCaption">
                                    Politics
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );  
}

export default CampaignLibrary;