import React, { Component } from "react";
import Badge from "@material-ui/core/Badge";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Avatar } from "@material-ui/core";

import Axios from "axios";
import { PROXY } from "../../global/constants/action_types";
import {
  processHeader,
  loadNotifications,
  notificationsCounter,
  loadGeneralSearchResult,
  participantInfo,
} from "../../global/action";
import { connect } from "react-redux";
import {
  noImage,
  staticPusherConfig,
} from "../../global/generalMethods/general";
// import { Popover } from '@material-ui/core';
import Display from "../search/Display";
import Sidenav from "./sidenav/Sidenav";
import DashboardErrorModal from "./DashboardErrorModal";
import { GoogleLogout } from "react-google-login";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const classes = {
  root: {
    // flexGrow: 1
  },
  logo: {
    height: "35px",
  },
  customColor: {
    color: "#FA9347",
  },
  searchBtn: {
    height: "35px",
    color: "#F9FBFF",
  },
  title: {
    flexGrow: 1,
    color: "#FA9347",
  },
  signupBtn: {
    color: "#F9FBFF",
    borderColor: "#ff7420",
    borderRadius: "18.5px",
    background:
      "linear-gradient(180deg, #FA9347 0%, #F07672 99.99%, #F07672 100%)",
  },
};

// export default function DashboardNavbar(){
class DashboardNavbar extends Component {
  state = {
    count: 0,
    searching: false,
    show: false,
    lastSearch: "",
    aria: null,
    size: window.innerWidth > 450 ? 2 : 1,
    initialValue: "",
    showSidenav: false,
    showMode: false,
    modeError: "",
  };

  mounted;

  componentDidMount = async () => {
    this.mounted = true;
    if (!this.props.participant.email_verified_at) return;
    if (this.props.counts === null) {
      await this.countNotifications();
      await staticPusherConfig();
      await this.listenToNotification();
    }
    window.addEventListener("resize", this.reSizer);
  };

  componentWillUnmount = async () => {
    this.mounted = false;
    window.removeEventListener("resize", this.reSizer);
  };

  closeNow = () => {
    if (!this.mounted) return;
    this.setState({ showSidenav: false });
  };

  reSizer = () => {
    if (!this.mounted) return;
    if (window.innerWidth > 450) {
      this.setState({ size: 2 });
    } else {
      this.setState({ size: 1 });
    }
  };

  ExitModeError = () => {
    this.setState({ showMode: false });
  };

  changeMode = () => {
    // e.preventDefault();
    // if(this.props.participant.role && this.props.participant.role !== 1) return;
    Axios.get(`${PROXY}/api/change_mode/${this.props.participant.userName}`, {
      headers: processHeader(),
    })
      .then(async (res) => {
        if (res.data.success) {
          console.log(JSON.parse(window.atob(res.data.data)));
          await this.props.loadParticipant(
            JSON.parse(window.atob(res.data.data))
          );
        } else {
          if (res.data.Error === "many_projects") {
            this.mounted &&
              this.setState({
                modeError:
                  "You cannot set Mode to active because you have More than two Uncompleted projects",
              });
            this.mounted && this.setState({ showMode: true });
          }
        }
      })
      .catch((err) => {
        return;
      });
  };

  addToNotificationList = async (note) => {
    const count =
      this.props.counts === null || this.props.counts < 1
        ? 0
        : this.props.counts;
    await this.props.loadNotificationsCount(Number(count) + 1);
    if (window.location.pathname !== "/dashboard/notifications") return;
    // alert('inside')
    const temp = Object.assign({}, this.props.noteList);
    // console.log(this.props.noteList)
    // console.log(temp);
    if (temp.data) {
      const position = temp.data.findIndex((data) => data.id === note.id);
      // alert(position, ' is the position position')
      if (position === -1) {
        // alert('new')
        temp.data = [...[note], ...temp.data];
      } else {
        // alert(' not new')
        temp.data.splice(position, 0);
        temp.data.splice(0, 1, note);
      }
    } else {
      // alert('empty')
      temp.data = [note];
    }
    // console.log(temp);
    await this.props.loadNotifications(temp);
  };

  listenToNotification = async () => {
    window.Echo.private(`notification.${this.props.participant.userName}`)
      .listen(".allocation", async (data) => {
        // alert(555);
        await this.addToNotificationList(JSON.parse(data[0])[0]);
      })
      .listen(".chat", async (data) => {
        await this.addToNotificationList(JSON.parse(data[0])[0]);
      })
      .listen(".relationship", async (data) => {
        await this.addToNotificationList(JSON.parse(data[0])[0]);
      })
      .listen(".adminNewChat", async (data) => {
        let id = window.btoa(
          window.btoa(`ADMIN_${this.props.participant.userName}`)
        );
        if (window.location.pathname !== `/dashboard/admin/${id}`) {
          await this.addToNotificationList(JSON.parse(data[0])[0]);
        }
      })
      .listen(".reportChat", async (data) => {
        if (!window.location.pathname.includes(`/dashboard/projects/issue/`)) {
          await this.addToNotificationList(JSON.parse(data[0])[0]);
        }
      });
  };

  countNotifications = async () => {
    Axios.get(
      `${PROXY}/api/count_notifications/${this.props.participant.userName}`,
      { headers: processHeader() }
    )
      .then(async (res) => {
        if (res.data.success) {
          if (!this.mounted) return;
          await this.props.loadNotificationsCount(res.data.data);
        }
      })
      .catch(async (err) => {
        // if(err) console.log(err)
      });
  };

  searchNow = (e) => {
    e.preventDefault();
    console.log(this.props);
    const search = document.getElementById("searchBox").value.trim();
    // if(!search) return;
    this.setState({ aria: e.currentTarget });
    this.setState({ show: true });
    if (this.state.searching) return;
    if (this.state.lastSearch === search) return;
    this.setState({ initialValue: search });
    this.setState({ searching: true });
    this.setState({ lastSearch: search });

    if (this.state.somethingWentWrong)
      this.setState({ somethingWentWrong: false });
    Axios.get(`${PROXY}/api/search_participants/${search}`)
      .then(async (res) => {
        if (!this.mounted) return;
        if (res.data.success) {
          await this.props.loadSearch(res.data.data);
          // console.log(res.data.data);
          await this.setState({ searching: false });
        } else {
          if (res.data.Error === "Intruder") {
            if (!this.mounted) return;
            window.localStorage.clear();
            this.props.navigate("/");
          }
        }
      })
      .catch(async (err) => {
        if (!this.mounted) return;
        await this.setState({ searching: false });
        await this.setState({ somethingWentWrong: true });
      });
  };

  noShow = () => {
    this.setState({ show: false });
  };

  logOut = (e) => {
    try {
      if (e.cancelable) e.preventDefault();
      Axios.get(`${PROXY}/api/logout`, { headers: processHeader() })
        .then((res) => {
          window.localStorage.clear();
          return this.props.navigate("/", {replace:true});
        })
        .catch((err) => {
          window.localStorage.clear();
          return this.props.navigate("/", {replace:true});
        });
    } catch (err) {
      window.localStorage.clear();
      return this.props.navigate("/", {replace:true});
    }
  };

  googleLogout = (response) => {
    try {
      Axios.get(`${PROXY}/api/logout`, { headers: processHeader() })
        .then((res) => {
          window.localStorage.clear();
          return this.props.navigate("/", {replace:true});
        })
        .catch((err) => {
          window.localStorage.clear();
          return this.props.navigate("/", {replace:true});
        });
    } catch (err) {
      // console.log(err)
    }
  };

  render() {
    // let opened = false;
    const { participant } = this.props;

    return (
      <div className={classes.root}>
        <nav
          className="navbar justify-content-between navbar-expand-md navbar-light bg-white px-3 fixed-top"
          style={{ borderBottom: "1px solid #E5E5E5" }}
        >
          <IconButton
            edge="start"
            onClick={() =>
              this.setState((data) => {
                return { showSidenav: !data.showSidenav };
              })
            }
            color="inherit"
            aria-label="menu"
            className="d-md-none d-lg-none d-sm-block"
          >
            <MenuIcon />
          </IconButton>
          <Link className="navbar-brand" to="/dashboard">
            <span className="d-sm-none d-md-block hideSm">
              <img
                className="img-fluid"
                style={classes.logo}
                alt="medillery-logo"
                src={require("../../assets/images/medillery-logo.png")}
              />
            </span>
            <HomeIcon
              fontSize="large"
              className="d-sm-block d-md-none"
              style={classes.customColor}
            />
          </Link>

          <IconButton
            onClick={this.searchNow}
            className="btn btn-sm rounded-pill ml-2 px-2"
          >
            <SearchIcon />
          </IconButton>
          <div className="">
            <Link to="/dashboard/notifications">
              {this.props.counts && this.props.counts > 0 ? (
                <Badge
                  color="secondary"
                  overlap="circular"
                  badgeContent={this.props.counts}
                >
                  <NotificationsIcon fontSize="large" />
                </Badge>
              ) : (
                <NotificationsIcon
                  fontSize="large"
                  style={classes.customColor}
                />
              )}
            </Link>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              {/* Don't remove for now */}
            </ul>
            <div className="d-flex">
              <div>
                <div className="input-group">
                  <input
                    id="searchBox"
                    type="search"
                    className="form-control rounded-pill"
                    placeholder="Search"
                    aria-label="Search Box"
                  />
                  <div className="input-group-append">
                    <button
                      onClick={this.searchNow}
                      className="btn btn-sm rounded-pill ml-2 px-2 customPrimaryBgColor"
                      style={classes.searchBtn}
                    >
                      <SearchIcon fontSize="small" />
                    </button>
                  </div>
                </div>
                <div>
                  <Modal show={this.state.show} onHide={this.noShow}>
                    <Modal.Body className="p-2">
                      <div>
                        <Display
                          close={this.noShow}
                          initialValue={this.state.initialValue}
                        />
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
              <div className="dropdown dropleft ml-4">
                <Avatar
                  alt="avatar1"
                  className="img-fluid cursorPointer"
                  data-toggle="dropdown"
                  src={
                    participant.profilePic ? participant.profilePic : noImage
                  }
                />
                <div className="dropdown-menu dropdown-menu-left">
                  {participant.role === "1" && (
                    <div className="dropdown-item">
                      {participant.active === "1" && (
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            this.changeMode();
                          }}
                        >
                          Busy
                        </div>
                      )}
                      {participant.active === "0" && (
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            this.changeMode();
                          }}
                        >
                          Active
                        </div>
                      )}
                    </div>
                  )}

                  {participant.type === "AppAuth" ? (
                    <div
                      onClick={this.logOut}
                      className="dropdown-item cursorPointer"
                    >
                      Sign Out
                    </div>
                  ) : (
                    <div
                      onClick={this.logOut}
                      className="dropdown-item cursorPointer"
                    >
                      <GoogleLogout
                        className="rounded-pill px-2 py-0"
                        clientId="63312386785-pq5gh1anun4b0cugvqp3sh1a806j8t5q.apps.googleusercontent.com"
                        buttonText="Logout"
                        onLogoutSuccess={this.googleLogout}
                        onFailure={this.googleLogout}
                      ></GoogleLogout>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>

        <Sidenav
          sidebarOpened={this.state.showSidenav}
          closeNow={this.closeNow}
        />
        {this.state.showMode && (
          <DashboardErrorModal
            Exit={this.ExitModeError}
            show={this.state.showMode}
            Error={this.state.modeError}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    participant: state.participant,
    noteList: state.notifications,
    counts: state.notificationCount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadNotifications: (payload) => dispatch(loadNotifications(payload)),
    loadNotificationsCount: (payload) =>
      dispatch(notificationsCounter(payload)),
    loadSearch: (payload) => dispatch(loadGeneralSearchResult(payload)),
    loadParticipant: (payload) => dispatch(participantInfo(payload)),
  };
};

const withRouter=Child=>props=>{
	const navigate = useNavigate();
	return(
			<Child 
			{...props} 
			navigate={navigate} 
			/>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardNavbar));
