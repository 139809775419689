import React, {Component} from 'react';

class ErrorDisplay extends Component{
    render(){
      const {message}=this.props;
      return(
          <React.Fragment>
          {message &&
            <small className='text-danger'>{message}</small>
          }
          </React.Fragment>
      )
    }
}

export default ErrorDisplay;