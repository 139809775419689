import React, {Component} from 'react';
import { Avatar, Button, Typography } from '@material-ui/core';
import Axios from 'axios';
import { connect } from 'react-redux';
import { PROXY } from '../../global/constants/action_types';
import { processHeader } from '../../global/action';
import SearchIcon from '@material-ui/icons/Search';
import { noImage } from '../../global/generalMethods/general'
import { Link } from 'react-router-dom';

const searchBtn = {
    color: '#F9FBFF',
    borderColor: '#ff7420',
    borderRadius: '18.5px',
    background: 'linear-gradient(92deg, #FA9347 5.16%, #F07672 99.12%)',
    boxShadow: 'none',
    // height: '40px',
}
const roundedClass = {
  borderRadius: '18.5px',
}
const avatarStyle = {
    height: 50,
    width: 50
}
const followBtn = {
	color: '#ffffff',
    border: 'none',
    borderRadius: '18.5px',
    background: 'linear-gradient(90deg, #387DFF -4.49%, #004BD6 104.49%)',
    boxShadow: 'none',
  	fontSize: '11px',
  	padding: '2px 15px',
}
// const unfollowBtn = {
//     color: '#ffffff',
//     border: 'none',
//     borderRadius: '18.5px',
//     background: 'linear-gradient(90deg, #FA9347 -4.49%, #F07672 104.49%)',
//  	boxShadow: 'none',
//   	fontSize: '11px',
//   	padding: '2px 15px',
// }
class Followings extends Component{

	state = {
		userName:null,
		loading:false,
		followingsSearchItem:'',
		reserveFollowings:{},
		followings: {},
		isFollowingsFetchedOnce:false,
	}

	mounted;


	componentDidMount=async()=>{
		this.mounted = true;
        await this.setState({userName:this.props.participant.userName});
        if(Object.entries(this.props.followings).length >0 ){
			await this.setState({followings: this.props.followings});
			await this.setState({reserveFollowings: this.props.followings});
			await this.setState({isFollowingsFetchedOnce: true});
		}
	}

	UNSAFE_componentWillReceiveProps=async(nextProps)=>{
		if(Object.entries(nextProps.followings).length >0 ){
			await this.setState({followings: nextProps.followings});
			await this.setState({reserveFollowings: nextProps.followings});
			await this.setState({isFollowingsFetchedOnce: true});
		}
	}

	componentWillUnmount=()=>{
		this.mounted = false;
	}


	testMember;

	unFollow =async(e,related)=>{
		if(!this.mounted) return;
		// console.log(this.state.followings, 'foll', e.target.innerText, related);
		if(e.target.innerText === 'Undo'){
			e.target.innerText= 'unfollow';
			clearTimeout(this.testMember)
		}
		else if(e.target.innerText === 'unfollow'){
			e.target.innerText = 'Undo';
			this.testMember= setTimeout((()=>{
				Axios.get(`${PROXY}/api/unfollow/${this.props.participant.userName}/${related}`, {headers: processHeader()})
				.then(res =>{
					// console.log(res.data);
					if(res.data.success){
						if(!this.mounted) return;
						const tempFollowing = {...this.state.followings};
						const tempDataIndex = tempFollowing.data.findIndex(data=>data.following.userName === related);
						tempFollowing.data.splice(tempDataIndex,1);
						this.setState({followings: tempFollowing});
					}
				}).then(err=>{
					// if(err) console.log(err)
				});					
			}),5000)
		}
	}

	getMoreFollowings=(e)=>{
        if(!this.state.followings.next_page_url) return;
        e.preventDefault();
		this.setState({loading: true})	
		Axios.get(`${this.state.followings.next_page_url}`, {headers:processHeader()})
		.then( async(res)=>{
			if(res.data.success){
				if(!this.mounted) return;
					const {data} = res.data.data;
					res.data.data.data = [...this.state.followings.data,...data];
					await this.setState({followings: res.data.data});
					await this.setState({loading:false});
			}
		}).catch(async(err) =>{
			if(!this.mounted) return;
			// if(err)  console.log(err)
			await this.setState({loading:false});
		})
		
	}

	collectFollowingsSearchItems=async(e)=>{
		if(!this.mounted) return;
		e.preventDefault();
		await this.setState({followingsSearchItem: e.target.value});
		// console.log(this.state.followingsSearchItem);
		if(this.state.followingsSearchItem.trim() === ""){
			await this.setState({followings: this.state.reserveFollowings});
		}
	}


	searchFollowings=(e)=>{
        e.preventDefault();
		if(this.state.followingsSearchItem.trim()){
            this.setState({loading: true})
			Axios.get(
				`${PROXY}/api/get_searched_followings/${this.state.userName}/${this.state.followingsSearchItem}`,
				{headers: processHeader()}
			)
			.then(async(res)=>{
				if(res.data.success){
					if(!this.mounted) return;
					await this.setState({followings: res.data.data})
					this.setState({loading: false})
				}
			})
			.then(async(err)=>{
				if(err){
					if(!this.mounted) return;
					await this.setState({followings: {}});
					this.setState({loading: false})
					// console.log(err);
				}
			})
		}
	}

  render(){
    return(<div>
			{(this.state.isFollowingsFetchedOnce && !this.props.getting)?
				<div>
						<div className="input-group mb-1">
								<input 
										readOnly={!this.state.reserveFollowings.data || this.state.reserveFollowings.data.length < 1}
										type="search"
										className="form-control" 
										style={roundedClass} 
										onChange={this.collectFollowingsSearchItems} 
										placeholder="Search Followings" 
										aria-label="Search Followings" 
								/>
								<div className="input-group-append">
										<Button 
												variant="contained" 
												onClick={this.searchFollowings} 
												className="ml-2" 
												style={ searchBtn }
												readOnly={!this.state.reserveFollowings.data || this.state.reserveFollowings.data.length < 1}
										>
												<SearchIcon />
										</Button>
								</div>
						</div>
						<div className='d-flex justify-content-center py-2'>
						{ typeof Number(this.state.followings.total) === 'number' && Number(this.state.followings.total) > 0 &&
							<small>
								{Number(this.state.followings.total) > 1 ?
									`${Number(this.state.followings.total)} followings found`:
									`${Number(this.state.followings.total)} following found`
								}
							</small>
						}
					</div>
					{(this.state.followings.data && this.state.followings.data.length >0)?
						<div>							
							<div className='d-flex justify-content-center'>
								{this.state.loading && 

										<div className="w3-spin spinner w3-circle"></div>
								}
							</div>
							<div style={{maxHeight:'68vh', overflowY:'auto'}}>
								{ this.state.followings.data.map((following, index) =>
									<div className="d-flex justify-content-between align-items-center my-2" key={index}>
										<div className="col d-flex align-items-center px-0">
											<Avatar alt={noImage} className="img-fluid mr-2 border" src={following.following.profilePic?
													`${following.following.profilePic}`:
													noImage} style={ avatarStyle }
												/>
											<div className="d-flex flex-column">
												<Link to={`/user/${following.following.userName}`} className="small font-weight-bold text-dark" style={{fontSize: "15px"}}>
													@{ following.following.userName}
												</Link>
												<Typography variant="subtitle2" className="font-weight-light">
													{`${ following.following.firstName} ${following.following.lastName}`}
												</Typography>
											</div>
										</div>
										<div className="col text-right px-0">
											<button className="my-auto" 
												onClick={(e)=>this.unFollow(e,following.following.userName)} 
												style={ followBtn }
											>
												unfollow
											</button>
										</div>
									</div>
								)}
							</div>
                            <div className='d-flex justify-content-center'>
                            
								{this.state.followings.next_page_url &&
									<button className="my-auto" style={ followBtn } onClick={this.getMoreFollowings} >more</button>
								}
							</div>


						</div>:
						<div className="d-flex justify-content-center">
							None found
						</div>
					}
				</div>:
				<div>
					<div className="d-flex justify-content-center">
						<div className="w3-spin spinner w3-circle"></div>
					</div>
				</div>
			}
        </div>)
    }
}

const mapStateToProps =(state)=>{
	return {
		participant: state.participant,
		followings: state.followings
	}
}

export default connect(mapStateToProps)(Followings);