import React, { useEffect } from 'react';
import LandingPage from './landing/LandingPage';

const Home=()=>{

    useEffect(() => window.scrollTo(0, 0));
    
    return (
        <React.Fragment >
            <div>
                <LandingPage />
            </div>
        </React.Fragment>
    );
}

export default Home;

