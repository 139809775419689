import React from 'react';
import Templates from './Templates';
import TemplatesSublist from './TemplatesSublist';
import BirthdayChildList from './BirthdayChildList';
// import TemplateEdit from './TemplateEdit';
import BirthdayEditOne from './Collection/Birthday/BirthdayEdit1';
import BirthdayEditTwo from './Collection/Birthday/BirthdayEdit2';
import BirthdayEditThree from './Collection/Birthday/BirthdayEdit3';
import BirthdayEditFour from './Collection/Birthday/BirthdayEdit4';
import BirthdayEditFive from './Collection/Birthday/BirthdayEdit5';
import BirthdayEditSix from './Collection/Birthday/BirthdayEdit6';
// import TemplateEditSeven from './Collection/Birthday/TemplateEdit7';
import BirthdayEditEight from './Collection/Birthday/BirthdayEdit8';
import BirthdayEditNine from './Collection/Birthday/BirthdayEdit9';
import BirthdayEditTen from './Collection/Birthday/BirthdayEdit10';
import BirthdayEditEleven from './Collection/Birthday/BirthdayEdit11';
import BirthdayEditTwelve from './Collection/Birthday/BirthdayEdit12';
import BirthdayEditThirteen from './Collection/Birthday/BirthdayEdit13';
// import TrendTemplateOne from './Collection/Trend/Trend1.jsx';
import BusinessChildList from './BusinessChildList';
// import BusinessEditOne from './Collection/Business/BusinessEdit1';
import BusinessEditTwo from './Collection/Business/BusinessEdit2';
import BusinessEditThree from './Collection/Business/BusinessEdit3';
import BusinessEditFour from './Collection/Business/BusinessEdit4';
import BusinessEditFive from './Collection/Business/BusinessEdit5';
import BusinessEditSix from './Collection/Business/BusinessEdit6';
import BusinessEditSeven from './Collection/Business/BusinessEdit7';
import BusinessEditEight from './Collection/Business/BusinessEdit8';
import BusinessEditNine from './Collection/Business/BusinessEdit9';
import BusinessEditTen from './Collection/Business/BusinessEdit10';
import BusinessEditEleven from './Collection/Business/BusinessEdit11';
import BusinessEditTwelve from './Collection/Business/BusinessEdit12';
import BusinessEditThirteen from './Collection/Business/BusinessEdit13';
import ChristmasChildList from './ChristmasChildList';
import ChristmasEditOne from './Collection/Christmas/ChristmasEdit1';
import ChristmasEditTwo from './Collection/Christmas/ChristmasEdit2';
import ChristmasEditThree from './Collection/Christmas/ChristmasEdit3';
import ChristmasEditFour from './Collection/Christmas/ChristmasEdit4';
import ChristmasEditFive from './Collection/Christmas/ChristmasEdit5';
import NewYearChildList from './NewYearChildList';
import NewYearEditOne from './Collection/NewYear/NewYearEdit1';
import NewYearEditTwo from './Collection/NewYear/NewYearEdit2';
import NewYearEditThree from './Collection/NewYear/NewYearEdit3';
import NewYearEditFour from './Collection/NewYear/NewYearEdit4';
import NewYearEditFive from './Collection/NewYear/NewYearEdit5';
import PoliticsChildList from './PoliticsChildList';
import PoliticsEditOne from './Collection/Politics/PoliticsEdit1';
import PoliticsEditTwo from './Collection/Politics/PoliticsEdit2';
import PoliticsEditThree from './Collection/Politics/PoliticsEdit3';
import PoliticsEditFour from './Collection/Politics/PoliticsEdit4';
import PoliticsEditFive from './Collection/Politics/PoliticsEdit5';
import PoliticsEditSix from './Collection/Politics/PoliticsEdit6';
import PoliticsEditSeven from './Collection/Politics/PoliticsEdit7';
import PoliticsEditEight from './Collection/Politics/PoliticsEdit8';
import PoliticsEditNine from './Collection/Politics/PoliticsEdit9';
import PoliticsEditTen from './Collection/Politics/PoliticsEdit10';
import ValentineChildList from './ValentineChildList';
import ValentineEditOne from './Collection/valentine/ValentineEdit1';
import ValentineEditTwo from './Collection/valentine/ValentineEdit2';
import ValentineEditThree from './Collection/valentine/ValentineEdit3';

const TemplateRoutes =[
    {
        path:'/', 
        element: <Templates />,
    },
    {
        path:'/graphics-design', 
        element: <TemplatesSublist />,
    },
    {
        path:'/graphics-design/birthday', 
        element: <BirthdayChildList />,
    },
    {
        path:'/graphics-design/birthday/one', 
        element: <BirthdayEditOne />,
    },
    {
        path:'/graphics-design/birthday/two', 
        element: <BirthdayEditTwo />,
    },
    {
        path:'/graphics-design/birthday/three', 
        element: <BirthdayEditThree />,
    },
    {
        path:'/graphics-design/birthday/four', 
        element: <BirthdayEditFour />,
    },
    {
        path:'/graphics-design/birthday/five', 
        element: <BirthdayEditFive />,
    },
    {
        path:'/graphics-design/birthday/six', 
        element: <BirthdayEditSix />,
    },
    {
        path:'/graphics-design/birthday/eight', 
        element: <BirthdayEditEight />,
    },
    {
        path:'/graphics-design/birthday/nine', 
        element: <BirthdayEditNine />,
    },
    {
        path:'/graphics-design/birthday/ten', 
        element: <BirthdayEditTen />,
    },
    {
        path:'/graphics-design/birthday/eleven', 
        element: <BirthdayEditEleven />,
    },
    {
        path:'/graphics-design/birthday/twelve', 
        element: <BirthdayEditTwelve />,
    },
    {
        path:'/graphics-design/birthday/thirteen', 
        element: <BirthdayEditThirteen />,
    },
    
    {
        path:'/graphics-design/business', 
        element: <BusinessChildList />,
    },
    {
        path:'/graphics-design/business/one', 
        element: <BusinessEditTwo />,
    },
    {
        path:'/graphics-design/business/two', 
        element: <BusinessEditThree />,
    },
    {
        path:'/graphics-design/business/three', 
        element: <BusinessEditFour />,
    },
    {
        path:'/graphics-design/business/four', 
        element: <BusinessEditFive />,
    },
    {
        path:'/graphics-design/business/five', 
        element: <BusinessEditSix />,
    },
    {
        path:'/graphics-design/business/six', 
        element: <BusinessEditSeven />,
    },
    {
        path:'/graphics-design/business/seven', 
        element: <BusinessEditEight />,
    },
    {
        path:'/graphics-design/business/eight', 
        element: <BusinessEditNine />,
    },
    {
        path:'/graphics-design/business/nine', 
        element: <BusinessEditTen />,
    },
    {
        path:'/graphics-design/business/ten', 
        element: <BusinessEditEleven />,
    },
    {
        path:'/graphics-design/business/eleven', 
        element: <BusinessEditTwelve />,
    },
    {
        path:'/graphics-design/business/twelve', 
        element: <BusinessEditThirteen />,
    },

    {
        path:'/graphics-design/christmas', 
        element: <ChristmasChildList />,
    },
    {
        path:'/graphics-design/christmas/one', 
        element: <ChristmasEditOne />,
    },
    {
        path:'/graphics-design/christmas/two', 
        element: <ChristmasEditTwo />,
    },
    {
        path:'/graphics-design/christmas/three', 
        element: <ChristmasEditThree />,
    },
    {
        path:'/graphics-design/christmas/four', 
        element: <ChristmasEditFour />,
    },
    {
        path:'/graphics-design/christmas/five', 
        element: <ChristmasEditFive />,
    },

    {
        path:'/graphics-design/new-year', 
        element: <NewYearChildList />,
    },
    {
        path:'/graphics-design/new-year/one', 
        element: <NewYearEditOne />,
    },
    {
        path:'/graphics-design/new-year/two', 
        element: <NewYearEditTwo />,
    },
    {
        path:'/graphics-design/new-year/three', 
        element: <NewYearEditThree />,
    },
    {
        path:'/graphics-design/new-year/four', 
        element: <NewYearEditFour />,
    },
    {
        path:'/graphics-design/new-year/five', 
        element: <NewYearEditFive />,
    },

    {
        path:'/graphics-design/politics', 
        element: <PoliticsChildList />,
    },
    {
        path:'/graphics-design/politics/one', 
        element: <PoliticsEditOne />,
    },
    {
        path:'/graphics-design/politics/two', 
        element: <PoliticsEditTwo />,
    },
    {
        path:'/graphics-design/politics/three', 
        element: <PoliticsEditThree />,
    },
    {
        path:'/graphics-design/politics/four', 
        element: <PoliticsEditFour />,
    },
    {
        path:'/graphics-design/politics/five', 
        element: <PoliticsEditFive />,
    },
    {
        path:'/graphics-design/politics/six', 
        element: <PoliticsEditSix />,
    },
    {
        path:'/graphics-design/politics/seven', 
        element: <PoliticsEditSeven />,
    },
    {
        path:'/graphics-design/politics/eight', 
        element: <PoliticsEditEight />,
    },
    {
        path:'/graphics-design/politics/nine', 
        element: <PoliticsEditNine />,
    },
    {
        path:'/graphics-design/politics/ten', 
        element: <PoliticsEditTen />,
    },

    {
        path:'/graphics-design/valentine', 
        element: <ValentineChildList />,
    },
    {
        path:'/graphics-design/valentine/one', 
        element: <ValentineEditOne />,
    },
    {
        path:'/graphics-design/valentine/two', 
        element: <ValentineEditTwo />,
    },
    {
        path:'/graphics-design/valentine/three', 
        element: <ValentineEditThree />,
    },
]

export default TemplateRoutes;