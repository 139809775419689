import React, {Component} from 'react';
import { Typography } from '@material-ui/core';
import './../../CSSs/Politics/six/six.css';
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs';
import GDHeader from '../GDHeader';

class PoliticsEditSix extends Component{
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        aspirantName: "DAVID ANDERSON",
        officeTitle: "FOR VICE CHANCELLOR",
        mandateText: "Lorem ipsum dolor",
        dateHereText: "date here",
        timeHereText: "time here",
        venueHereText: "venue here",
        bColor: "#ffffff"
    }

    changeBackground=(e)=>{
        e.preventDefault();
        try {
            this.setState({bColor: e.target.value});
            let back = document.getElementById("leftSidedDiv06");
            let venueText = document.getElementById("venueText06");
            if(back){
                back.style.backgroundColor= `${this.state.bColor}`;
                venueText.style.color= `${this.state.bColor}`;
            } 
        } catch (e) {
            return;
        }
    }
    changeImage = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const slot = document.getElementById('imgSlot06');
        if(!slot) return;
        slot.src = URL.createObjectURL(file);
    }
    changeLogo = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const logo = document.getElementById('logoSlot06');
        if(!logo) return;
        logo.src = URL.createObjectURL(file);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    handleFinishEdit = (e) => {
      e.preventDefault();
      this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
      e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('outSideDiv06'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
                var link = document.createElement("a");
                link.download = "medillery-template.jpeg";
                link.href = dataUrl;
                link.click();
            });          
    }

    downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('outSideDiv06'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }

    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }
    render() {
        return ( <div style={{marginTop: '90px'}}>           
            <GDHeader caty='politics' />
  
            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-6 col-xl-6 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* this is the outside div that encapsulate all the others */}
                                        <div className="outSideDiv06" id="outSideDiv06">
                                            {/* The left sideDiv for margin */}
                                            <div className="leftSidedDiv06" id="leftSidedDiv06">
                                                <div className="circleDiv06">
                                                    {/* this is the rounded circle */}
                                                    <div className="circle06">
                                                        {/* <div className="yourLogo06">Party Logo</div>  */}
                                                        <div className="yourLogo06">
                                                            <img id="logoSlot06" src={require("../../../../assets/images/img.png")} 
                                                                alt="living Room" className="logoImage06" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* The text and image details div  */}
                                                <div className="textInfoText06">
                                                    {/* voters div container   */}
                                                    <div className="voterDetailsText06">
                                                        <h1 className="voteText06">VOTE</h1>
                                                        <h1 className="aspirantName06">{this.state.aspirantName}</h1>
                                                        <p>{this.state.officeTitle}</p>
                                                    </div>
                                                </div>
                                                {/* the div containing the header and the images below */}
                                                <div className="textBelowDiv06">
                                                    {/* text only inside the div */}
                                                    <div className="textOnly06">
                                                        <p className="mandateText06">{this.state.mandateText} </p>
                                                        <p className="lastp">{this.state.dateHereText} 
                                                            <span> | </span>{this.state.timeHereText} <span> | </span>
                                                            <span className="venueText" id="venueText06">{this.state.venueHereText} </span> 
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* this  is the right blue side  */}
                                            <div className="rightSidedDiv06">
                                                {/* div for the flexed images */}
                                                <div className="imagesDivFlex06">
                                                    <img id="imgSlot06"
                                                        src={require("../../../../assets/images/templates/heroes/3.jpeg")} alt="politics" className="imgFlexed06" />                                               
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-6 col-xl-6 mb-4">
                                {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                        <div className="mt-3 row">
                                            <div className="col-8 form-group">
                                                <label>Background</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="color" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeBackground}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-4 form-group">
                                                <label>Logo</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="file" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeLogo}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Image</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="file" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeImage}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Aspirant Name</label>
                                                <input id="aspirantName"
                                                    type="text" value={this.state.aspirantName} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="col-7 form-group">
                                                <label>Office Title</label>
                                                <input
                                                    type="text" id="officeTitle" 
                                                    value={this.state.officeTitle} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="col-5 form-group">
                                                <label>Date Here</label>
                                                <input id="dateHereText"
                                                    type="date" value={this.state.dateHereText} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 form-group">
                                                <label>Time Here</label>
                                                <input
                                                    type="time" id="timeHereText" 
                                                    value={this.state.timeHereText} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="col-8 form-group">
                                                <label>Profile Title</label>
                                                <input 
                                                type="text" id="venueHereText" 
                                                value={this.state.venueHereText} 
                                                onChange={ this.handleChange }
                                                className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Mandate Text</label>
                                            <input
                                                type="text" id="mandateText" 
                                                value={this.state.mandateText} 
                                                onChange={ this.handleChange } 
                                                className="form-control rounded-pill" 
                                            />
                                        </div>
                                        
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                            > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4 variant="subtitle2" className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select 
                                                className="form-control form-control-sm rounded-pill"
                                                onChange={this.downloadNow}
                                            >   
                                                <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                            className="btn btn-block rounded-pill customPrimaryBgColor" 
                                            onClick={ this.backToEdit }
                                            > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>                      
        </div>);
    }
}

export default PoliticsEditSix;
