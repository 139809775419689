import React, {Component} from 'react';
// import { AppBar, Toolbar } from '@material-ui/core';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import SignUpModal from './SignUpModal';
import SignInModal from './SignInModal';
import{ Modal, Row, Col} from 'react-bootstrap'
import { connect } from 'react-redux';
import { showSignupModal, showLoginUpModal, showAuthModal } from '../../global/action';
import { Link } from 'react-router-dom';
import { cookieValue } from '../../global/generalMethods/general';
import { ReactComponent as Search } from "../../assets/images/search.svg";

const classes = {
  schem:{
    black: {color:"#404040", textTransform: 'capitalize'},
    btnCol: {background: "#018BE6"}
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    marginRight: '7px',
    zIndex: 50,
  }
}

class HomePageNavbar extends Component{

  state={
    shouldCloseModal:false,
    smallButton: window.innerWidth >576?'none':'inline',
  }

  mounted;
  componentDidMount=()=>{
    this.mounted = true;
    this.mounted && window.addEventListener('resize',  this.showOrHide);     
  }

  componentWillUnmount=()=>{
    window.removeEventListener('resize', this.showOrHide);
    this.mounted = false;
  }

  showOrHide=()=>{
    if(window.innerWidth > 576){
      // console.log('shouldHide')
      this.mounted && this.setState({smallButton:'none'})
    }else{
      // console.log('shouldShow')
      this.mounted && this.setState({smallButton:'inline'})
    }
  }

  closeModal =()=>{
    this.setState({shouldCloseModal: true});
  }

  exitModal=(condition)=>{
    if(!condition) return this.setState({shouldCloseModal:false});
    this.setState({shouldCloseModal:false});
    return this.props.setAuthModal(false);
  }

  closeModalForForgottenPassword=()=>{
    this.props.setAuthModal(false);
  }
  closeModalForRecovery=()=>{
    this.props.setAuthModal(false);
  }

  openModalLogin = (e)=>{
    e.preventDefault();
    this.props.setAuthModal(true);
    this.props.setShowLogin(true);
    this.props.setShowSignUp(false);
  }

  openModalSignUp = (e)=>{
    e.preventDefault();
    this.props.setAuthModal(true);
    this.props.setShowLogin(false);
    this.props.setShowSignUp(true);
  }

  checkUuid = ()=>{
    const cookie = cookieValue("mediUuid")
    return cookie?`?uuid=${cookie}`:"";
  }

  render=()=>{
    return(
      <React.Fragment >
        <nav className="navbar navbar-expand-sm navbar-light bg-white fixed-top" 
          style={ {borderBottom: '1px solid #E5E5E5'}}>
          <Link className="navbar-brand" to="/">
            <span>
              <img className="img-fluid" style={{height: '40px'}} 
                src={ require('../../assets/images/medillery-logo.png') } 
                alt="medillery-logo" />
            </span>
          </Link>
          <div className="navbarSearchBox">
            <input type="search" 
              placeholder="Search"
              className="rounded-pill px-2 py-1" 
              style={{border: '1px solid #018BE6'}} />
            <span style={{marginLeft: '-30px'}}>
              <Search style={{fill: '#018BE6', width: '20px'}} />
            </span>
          </div>
          <div className='ml-auto'>
            <Button variant="contained"
              disableElevation onClick={this.openModalSignUp} 
              style={{display: this.state.smallButton}}
              id='smallButton'
              className="customSecondaryBgColor text-white rounded-pill mr-3">
              Sign Up Free
            </Button>
            <button className="navbar-toggler border-0 customNavToggler"
                type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
              <MenuIcon />
            </button>
          </div>
          <div className="collapse navbar-collapse mr-2" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/how-it-works" className="text-decoration-none">
                  <Button color="inherit" style={classes.schem.black}>How it works?</Button>
                </Link>
              </li>
              <li className="nav-item">
                <a href='https://template.medillery.com' target="_blank" 
                  className="text-decoration-none"> 
                  <Button color="inherit" style={classes.schem.black}>Templates</Button>
                </a>
              </li>
              {/* <li className="nav-item">
                <Link to="/create-project" className="text-decoration-none"> 
                  <Button color="inherit" style={classes.schem.black}>Create Project</Button>
                </Link>
              </li> */}
              <li className="nav-item">
                <a href={`https://pricing.medillery.com${this.checkUuid()}`}
                className="text-decoration-none" target="_blank"
                > 
                  <Button color="inherit" style={classes.schem.black}>Pricing</Button>
                </a>
              </li>
              <li className="nav-item">
                <a href={`https://pricing.medillery.com${this.checkUuid()}`}
                className="text-decoration-none" target="_blank"
                > 
                  <Button color="inherit" style={classes.schem.black}>Creators</Button>
                </a>
              </li>
              <li className="nav-item">
                <Button color="inherit" style={classes.schem.black} 
                  onClick={this.openModalLogin}>Login </Button>
              </li>
            </ul>
          </div>
          <Button variant="contained" disableElevation 
            onClick={this.openModalSignUp} 
            className="customSecondaryBgColor text-white rounded-pill hideOnSmall mr-3">
              Sign Up Free
          </Button>
          
        </nav>
        <Modal
          show={this.props.showModal}
          size="md"
          onHide={this.closeModal}
        >
          <Modal.Body style={{zIndex:100}}>
            {this.state.shouldCloseModal &&
              <div className='small d-flex justify-content-center mb-3 rounded-lg shadow py-2'>
                <div>
                  <div className="mb-2">Do you wish to close the Form?</div>
                  <div className="text-center">
                    <button 
                      className='btn customPrimaryBgColor rounded-pill text-white mr-2' 
                      onClick={()=>{this.exitModal(true)}}
                    >
                      Yes
                    </button>
                    <button 
                      className='btn btn-warning rounded-pill' 
                      onClick={()=>{this.exitModal(false)}}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            }
            <IconButton variant="secondary"  onClick={this.closeModal} style={classes.closeBtn}>
              <CloseIcon />
            </IconButton>
            <Row>
              <Col xs={12} className="text center">
                { this.props.showSignUp && <div className="text-center"><h4 className="sectionTitle">Join Medillery</h4></div>}
                { this.props.showSignIn && <div className="text-center"><h4 className="sectionTitle">Welcome Back</h4></div>}
              </Col>
            </Row>
            { this.props.showSignUp && <SignUpModal closeModal={this.closeModalForRecovery} /> }
            { this.props.showSignIn &&  <SignInModal closeModal={this.closeModalForForgottenPassword} />}

            { this.props.showSignUp && <div className="small text-center mt-2">
              <div> 
                <b>Have an Account?
                  <span 
                    onClick={this.openModalLogin} 
                    style={{color: "#018BE6", cursor: "pointer"}}
                    className='pl-2'
                  >
                    
                    Sign in here
                  </span>
                </b>
              </div>
              
              </div>
            }
            { this.props.showSignIn && 
              <div className="small text-center mt-2">
                <div>
                  <b>New to medillery? 
                    <span 
                      onClick={this.openModalSignUp} 
                      style={{color: "#018BE6", cursor: "pointer"}}
                      className='pl-2'
                    >
                      Register here
                  </span> </b>
                </div>
              </div>
            }
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )
  }
}
const mapStateToProps=(state)=>{
  return{
    showSignIn: state.showLoginModal,
    showSignUp: state.showSignUpModal,
    showModal: state.showAuthModal,
  }
}

const mapDispatchToProps=(dispatch)=>{
  return{
    setShowSignUp: (payload)=>dispatch(showSignupModal(payload)),
    setShowLogin: (payload)=>dispatch(showLoginUpModal(payload)),
    setAuthModal: (payload)=>dispatch(showAuthModal(payload)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePageNavbar);
