import React, {Component} from 'react';
import { Typography } from '@material-ui/core';
import './../../CSSs/Politics/two/two.css';
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs';
import GDHeader from '../GDHeader';

class PoliticsEditTwo extends Component{
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        electionAndYear: "PRESIDENTIAL ELECTION DEBATE'21",
        electionDay: "19TH",
        electionMonth: "AUGUST",
        electionYear: "2021",
        partyNameOne: "PARTY NAME HERE",
        partyNameTwo: "PARTY NAME HERE",
        versusText: "vs",
    }
    changeBackgroundImgOne= (e) => {
        e.preventDefault();
        try {        
            const file = e.target.files[0];
            if(!file)  return;
            let back = document.getElementById("topInsideDiv02");
            if(back){
                back.style.background = `linear-gradient(0deg, rgba(239,203,3, 0.9), rgba(239,203,3, 0.9)), url(${URL.createObjectURL(file)})`;
                back.style.backgroundSize = `cover`;
                back.style.backgroundRepeat = `no-repeat`;
                back.style.backgroundPosition = `center`;
            }
        } catch (e) {
            return;
        }
    }
    changeBackgroundImgTwo = (e) => {
        e.preventDefault();
        try {        
            const file = e.target.files[0];
            if(!file)  return;
            let back = document.getElementById("bottomInsideDiv02");
            if(back){
                back.style.background = `linear-gradient(0deg, rgba(250,250,250, 0.9), rgba(250,250,250, 0.9)), url(${URL.createObjectURL(file)})`;
                back.style.backgroundSize = `cover`;
                back.style.backgroundRepeat = `no-repeat`;
                back.style.backgroundPosition = `center`;
            }
        } catch (e) {
            return;
        }
    }
    changeImage = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const slot = document.getElementById('imgSlot02');
        if(!slot) return;
        slot.src = URL.createObjectURL(file);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    handleFinishEdit = (e) => {
      e.preventDefault();
      this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
      e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('outSideDiv02'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
                var link = document.createElement("a");
                link.download = "medillery-template.jpeg";
                link.href = dataUrl;
                link.click();
            });          
    }

    downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('outSideDiv02'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }

    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }
    render() {
        return ( <div style={{marginTop: '90px'}}> 
            <GDHeader caty='politics' />
            
            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-6 col-xl-6 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* this is the outside div that encapsulate all the others */}
                                        <div className="outSideDiv02" id="outSideDiv02">
                                            {/* The top insdie Div for the image and text */}
                                            <div className="topInsideDiv02" id="topInsideDiv02">
                                                {/* The main information image */}
                                                <div className="mainDetailsText02">
                                                    {/* div for the flexed image */}
                                                    <div className="imagesDivFlex02">
                                                        <img id="imgSlot02"
                                                            src={require("../../../../assets/images/templates/heroes/3.jpeg")} alt="politics" className="imgFlexed02" />
                                                    </div>
                                                </div>
                                                    {/* the electionDebate div class  */}
                                                    <div className="electionDebateDiv02">
                                                        <h1 className="electionDebateClass02">{this.state.electionAndYear}</h1>
                                                        <p className="whiteBackDate">{this.state.electionDay} {this.state.electionMonth}, {this.state.electionYear}</p>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                
                                            </div>
                                            {/* The underneath details of name and date */}
                                            <div className="bottomInsideDiv02" id="bottomInsideDiv02">
                                                {/* voters div container   */}
                                                <div className="voterDetailsText02">
                                                    <div className="partyDiv"> {this.state.partyNameOne} </div>
                                                    <span className="versusText">VS</span>
                                                    <div className="partyDiv partyDivTwo"> {this.state.partyNameTwo}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-6 col-xl-6 mb-4">
                                {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                        <div className="mt-3 row">
                                            <div className="col-6 form-group">
                                                <label>Background Top</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="file" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeBackgroundImgOne}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Background Bottom</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="file" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeBackgroundImgTwo}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-8 form-group">
                                                <label>Election Title</label>
                                                <input id="electionAndYear"
                                                    type="text" value={this.state.electionAndYear} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="col-4 form-group">
                                                <label>Image</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="file" style={{display:'none'}} 
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeImage}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-4 form-group">
                                                <label>Day</label>
                                                <input id="electionDay"
                                                    type="text" value={this.state.electionDay} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="col-4 form-group">
                                                <label>Month</label>
                                                <input id="electionMonth"
                                                    type="text" value={this.state.electionMonth} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="col-4 form-group">
                                                <label>Year</label>
                                                <input id="electionYear"
                                                    type="text" value={this.state.electionYear} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Party Name One</label>
                                                <input
                                                    type="text" id="partyNameOne" 
                                                    value={this.state.partyNameOne} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="col-6 form-group">
                                                <label>Party Name Two</label>
                                                <input
                                                    type="text" id="partyNameTwo" 
                                                    value={this.state.partyNameTwo} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            
                                            {/* <div className="col-4 form-group">
                                                <label>Election Date</label>
                                                <input id="electionDate"
                                                    type="date" value={this.state.electionDate} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div> */}
                                        </div>
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                            > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4 variant="subtitle2" className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select 
                                                className="form-control form-control-sm rounded-pill"
                                                onChange={this.downloadNow}
                                            >   
                                                <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                            className="btn btn-block rounded-pill customPrimaryBgColor" 
                                            onClick={ this.backToEdit }
                                            > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>                      
        </div>);
    }
}

export default PoliticsEditTwo;
