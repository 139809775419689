import { connect } from 'react-redux';
import { Button, Typography, Box } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { showAuthModal, showSignupModal } from '../../../global/action';

const heroText = {
    color: '#FFFFFF'
}

const HeroContainer = ({ setShowSignUp, setAuthModal }) => {
    return (
        <header className="heroWallpaper mb-3">
            <Box className="heroBackground">
                <Box className="heroContainer d-flex flex-column justify-content-center">
                    <Typography variant="h4" style={ heroText } 
                        className="mb-2 heroText sectionTitle font-weight-bold text-center">
                        Quality branding at low cost
                    </Typography>
                    <Typography variant="subtitle1" style={ heroText} 
                        className="heroSecondText text-center mb-3 mx-1">
                        Design on the go or hire professionals to do it for you
                    </Typography>
                    <div className="d-flex justify-content-around row heroImageRow">
                        <div className="col-4 d-flex flex-column heroImageColumn">
                            <img className="heroCircleImage mb-2" 
                                src={ require('../../../assets/images/website/homepage/hero-template.png') } 
                                alt="template" />
                            <Typography variant="subtitle1" className="heroSecondText text-center">
                                Templates
                            </Typography>
                        </div>   
                        <div className="col-4 d-flex flex-column heroImageColumn">
                            <img className="heroCircleImage mb-2" 
                                src={ require('../../../assets/images/website/homepage/hero-campaign.png') } 
                                alt="campaign" />
                            <Typography variant="subtitle1" className="heroSecondText text-center">
                                Campaign
                            </Typography>
                        </div>   
                        <div className="col-4 d-flex flex-column heroImageColumn">
                            <img className="heroCircleImage mb-2" 
                                src={ require('../../../assets/images/website/homepage/hero-freelancing.png') } 
                                alt="freelancing" />
                            <Typography variant="subtitle1" className="heroSecondText text-center">
                                Freelancing
                            </Typography>
                        </div>   
                    </div>
                </Box>
            </Box>
            <Box className="d-flex justify-content-center heroTextBelow">
                <Box className="d-flex">
                    <Typography variant="subtitle1" 
                        className="heroBtnBelow text-dark">
                        Create an account to get started
                    </Typography>
                    <Box>
                        <Button 
                            className="customSecondaryColor text-capitalize heroStartBtn"
                            onClick={()=>{setAuthModal(true); setShowSignUp(true)}}
                        >
                            <Typography variant="subtitle1" className="heroStartText">
                                Get Started Free
                            </Typography> &nbsp;
                            <ArrowForwardIcon />
                        </Button>
                    </Box>
                </Box>
            </Box>
        </header>
    );  
}

const mapDispatchToProps = (dispatch) => {
    return {
      setShowSignUp: (payload) => dispatch(showSignupModal(payload)),
      setAuthModal: (payload) => dispatch(showAuthModal(payload)),
    }
}
 
export default connect(null, mapDispatchToProps)(HeroContainer);