import React, { Component } from 'react';
import EnjoyView from './EnjoyView'
import { Typography } from '@material-ui/core';
// import { noImage } from '../../../global/generalMethods/general';
import { loadEnjoyFiles, processHeader, enjoySearchValue } from '../../../global/action';
import { connect } from 'react-redux';
import Axios from 'axios';
import { PROXY } from '../../../global/constants/action_types';
// import pictures from '../../../assets/images/dashboard/enjoy/pictures.png';
// import gifs from '../../../assets/images/dashboard/enjoy/gifs.png';
// import videos from '../../../assets/images/dashboard/enjoy/videos.png';

const quitRequest = Axios.CancelToken.source();
class Enjoy extends Component {
    state = {
        enjoyData: {},
        extraEnjoyData: {},
        fetchingData: false,
        type:null,
        lastSearchValue: '',
        sidenavList: [
            { title: 'Pictures', image: '../../../assets/images/dashboard/enjoy/pictures.png', link: '#', type: 2},
            { title: 'Gifs', image: '../../../assets/images/dashboard/enjoy/gifs.png', link: '#' , type: 1 },
            { title: 'Videos', image: '../../../assets/images/dashboard/enjoy/videos.png', link: '#' , type: 3},
        ]
    }

    mounted;
    timerHolder;
    scrollMore;
    componentDidMount=async()=>{
        this.mounted = true;
        if(!this.props.participant.email_verified_at) return;
        if(this.props.enjoyFiles.data){
            if(this.mounted) await this.setState({enjoyData: this.props.enjoyFiles});
        }else{
            (this.mounted) && await this.preLoadFiles();
        }
        const TAG = document.getElementById('tagSearch');
        if(TAG) TAG.value= this.props.searchKey;
        window.addEventListener('scroll',this.virtualScrolling );
    }

    UNSAFE_componentWillReceiveProps=async(next)=>{
        if(next.enjoyFiles){
            if(this.mounted) await this.setState({enjoyData: next.enjoyFiles});
        }
    }

    componentWillUnmount=()=>{
        // quitRequest.cancel();
        window.removeEventListener('scroll',this.virtualScrolling );
        this.mounted = false;

    }

    preLoadFiles=async()=>{
        if(this.state.fetchingData || !this.mounted) return;
        if(this.mounted)await this.setState({fetchingData:true});
        Axios.get(`${PROXY}/api/enjoy_files/${this.props.participant.userName}`,
         {headers: processHeader(), cancelToken: quitRequest.token})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                // console.log(res.data.data, 'from enjoy')
                await this.props.modifyEnjoyFile(res.data.data);
                this.mounted && await this.setState({extraEnjoyData: res.data.data});
                if(this.mounted) await this.setState({fetchingData:false});
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            // if(err) console.log(err)
            await this.setState({fetchingData:false});
        })
    }

    loadSpecifiedFiles=async(type)=>{
        if(this.state.fetchingData || !this.mounted || type === this.state.type) return;
        if(this.mounted){
            await this.setState({type:type});
            await this.setState({fetchingData:true});
        }
        Axios.get(`${PROXY}/api/specified_enjoy_files/${this.props.participant.userName}/${type}`,
         {headers: processHeader(), cancelToken: quitRequest.token}
        )
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                await this.props.modifyEnjoyFile(res.data.data);
                await this.setState({extraEnjoyData: res.data.data});
                if(this.mounted) await this.setState({fetchingData:false});
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            // if(err) console.log(err)
            await this.setState({fetchingData:false});
        })
    }

    prepareSearch=async(e)=>{
        e.preventDefault();
        if(this.timerHolder) clearTimeout(this.timerHolder);
        if(!this.props.participant.email_verified_at) return;
        const tag = document.getElementById('tagSearch').value.trim();
        if(!tag){
            await this.props.keepSearchValue('');
            return this.setState((data)=>{return{enjoyData: data.extraEnjoyData}});
        } 
        if(tag.toLowerCase() === this.props.searchKey.toLowerCase()) return;
        this.timerHolder = setTimeout((async()=> await this.loadTagFiles(tag)), 2000);
    }

    loadTagFiles=async(tag)=>{
        if(this.state.fetchingData || !this.mounted || !tag) return;
        if(!this.props.participant.email_verified_at) return;
        if(this.state.extraEnjoyData && this.state.extraEnjoyData.data.length< 1) return;
        const url = this.state.type?
        `${PROXY}/api/specified_searched_files/${this.props.participant.userName}/${this.state.type}/${tag}`:
        `${PROXY}/api/searched_enjoy_files/${this.props.participant.userName}/${tag}`;
        if(this.mounted){
            await this.props.keepSearchValue(tag);
            await this.setState({fetchingData:true});
        }
        Axios.get(url, {headers: processHeader(), cancelToken: quitRequest.token})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                await this.props.modifyEnjoyFile(res.data.data);
                if(this.mounted) await this.setState({fetchingData:false});
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            // if(err) console.log(err)
            await this.setState({fetchingData:false});
        })
    }
    loadMoreFiles=async()=>{
        if(this.state.fetchingData || !this.mounted || !this.state.enjoyData.next_page_url) return;
        if(this.mounted) await this.setState({fetchingData:true});
        Axios.get(`${this.state.enjoyData.next_page_url}`, {headers: processHeader(), cancelToken: quitRequest.token})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                const tempData = [...this.state.enjoyData.data, ...res.data.data.data];
                res.data.data.data= tempData;
                await this.props.modifyEnjoyFile(res.data.data);
                this.mounted && await this.setState({fetchingData: false});
            }
        })
        .catch(async(err)=>{
            this.mounted && await this.setState({fetchingData: false});
        })
    }

    virtualScrolling=()=>{
        // console.log(`${document.body.scrollHeight}/${window.pageYOffset}/${window.innerHeight}/
        // ${document.body.offsetHeight}/${document.body.clientHeight}`);
        if(window.pageYOffset + window.innerHeight >= document.body.offsetHeight){
            this.loadMoreFiles();
        }
    }

    render() {
    	// const {galleryItems} = this.state;

        return (
            <div className="container-fluid componentBg">
            	<div className="row">
            		<div className="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                        <div className="d-flex flex-column">
                            <input 
                                onKeyUp={this.prepareSearch} 
                                id='tagSearch' type="search" 
                                className="form-control rounded-pill mb-3" 
                                placeholder="Search" 
                            />
                            <Typography variant="subtitle2" className="mb-2 ml-3">Categories</Typography>
                            <div className="d-flex flex-lg-column justify-content-around">
                                { this.state.sidenavList.map((item) => (
                                    <button 
                                        onClick={()=>this.loadSpecifiedFiles(item.type)} 
                                        key={item.type} 
                                        className="btn rounded-lg mb-2 border d-flex flex-sm-column justify-content-center align-items-center enjoySidenavBtn p-4"
                                         style={ {backgroundImage: `url(${item.image})`}}
                                    >
                                        <Typography variant="subtitle1">{item.title}</Typography>
                                    </button>
                                ))}
                            </div>
                        </div>
            		</div>
            		<div className="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                        <div className="d-flex">
                            <Typography variant="h5" className="mb-3 d-inline">Gallery</Typography>
                            {this.state.fetchingData && <div className="w3-spin spinner w3-circle ml-4"></div>}
                        </div>
                        <div>
                            {(this.state.enjoyData.data && this.state.enjoyData.data.length > 0)?
                                <div className="row">
                                    {this.state.enjoyData.data.map((item) =>
                                        <EnjoyView key={item.id} item={item} />
                                    )}
                                </div>:
                                <div className='row'>
                                    {(this.state.enjoyData.data && this.state.enjoyData.data.length < 1) &&
                                        <div className='d-flex justify-content-center'>No files</div>
                                    }
                                </div>
                            }
                        </div>
            		</div>
            	</div>
            </div>
        );
    }
}
const mapStateToProps=(state)=>{
    return{
        participant: state.participant,
        enjoyFiles: state.loadedEnjoyFiles,
        searchKey: state.enjoySearchValue,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        modifyEnjoyFile: (payload)=> dispatch(loadEnjoyFiles(payload)),
        keepSearchValue: (payload)=>dispatch(enjoySearchValue(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Enjoy);