import React, { useEffect } from 'react';
import HeroContainer from './HeroContainer';
import CampaignLibrary from './CampaignLibrary';
import Highlights from './Highlights';
import CreatorsCommunity from './CreatorsCommunity';
import TemplateLibrary from './TemplateLibrary';
import Testimonies from './Testimonies';
import WhyMedillery from './WhyMedillery';

const LandingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={{ marginTop: '65px' }}>
            <HeroContainer />
            <TemplateLibrary />
            <CampaignLibrary />
            <WhyMedillery />
            <Highlights />
            <CreatorsCommunity />
            <Testimonies />
        </div>
    );
}

export default LandingPage;
