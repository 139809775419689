import React from "react";
import Followers from "./Followers";
import { Avatar } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CreateIcon from "@material-ui/icons/Create";
import HomeIcon from "@material-ui/icons/Home";
import EmailIcon from "@material-ui/icons/Email";
import { FilterFrames } from "@material-ui/icons";
import SubscriptionIcon from '@mui/icons-material/Subscriptions';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import {
  processHeader,
  loadAllocation,
  allocationToDisplay,
  loadedFollowers,
  loadedFollowings,
  loadingAllocation,
} from "../../global/action";

import { connect } from "react-redux";

import Axios from "axios";
import { PROXY } from "../../global/constants/action_types";
import { noImage } from "../../global/generalMethods/general";
import { Modal } from "react-bootstrap";
import Followings from "./Followings";
import { GoogleLogout } from "react-google-login";
import { Link, useNavigate, useParams } from "react-router-dom";

const iconStyle = {
  fontSize: "2.2rem",
};
const avatarStyle = {
  height: 60,
  width: 60,
};

class Sidenav extends React.Component {
  state = {
    projectItems: [
      {
        title: "My Subscriptions",
        link: "/dashboard/subscriptions",
        icon: SubscriptionIcon,
        hint: "sub",
      },
      {
        title: "Projects",
        link: "/dashboard/projects",
        icon: AssignmentIcon,
        hint: "all",
      },
      {
        title: "Completed Projects",
        link: "/dashboard/projects",
        icon: DoneAllIcon,
        hint: "comp",
      },
      {
        title: "Pending Projects",
        link: "/dashboard/projects",
        icon: AssignmentLateIcon,
        hint: "pend",
      },
      {
        title: "Enjoy",
        link: "/dashboard/enjoy",
        icon: CameraAltIcon,
        hint: "enj",
      },
    ],
    userItems: [
      {
        title: "My Account",
        link: "/dashboard/profile",
        icon: PermIdentityIcon,
      },
      //{ title: 'Switch Account Type', link: '#', icon: ToggleOnIcon },
      //{ title: 'Settings', link: '#', icon: SettingsIcon },
      { title: "Logout", link: "#", icon: PowerSettingsNewIcon },
    ],
    showModal: false,
    showFollowing: false,
    showFollowers: true,
    gettingFollowers: false,
    gettingFollowings: false,
    fetchingProject: false,
  };

  mounted;
  componentDidMount = async () => {
    this.mounted = true;
  };
  componentWillUnmount = () => {
    this.mounted = false;
  };

  getFollowings = async () => {
    try {
      if (this.state.gettingFollowings) return;
      this.setState({ gettingFollowings: true });
      const result = await Axios.get(
        `${PROXY}/api/get_followings/${this.props.participant.userName}`,
        { headers: processHeader() }
      );
      if (result) {
        if (!this.mounted) return;
        await this.props.loadFollowings(result.data.data);
        await this.setState({ gettingFollowings: false });
      }
    } catch (error) {
      if (!this.mounted) return;
      this.setState({ gettingFollowings: false });
      this.props.loadFollowings({ data: [] });
    }
  };
  getFollowers = async () => {
    try {
      if (this.state.gettingFollowers) return;
      this.setState({ gettingFollowers: true });
      const result = await Axios.get(
        `${PROXY}/api/get_followers/${this.props.participant.userName}`,
        { headers: processHeader() }
      );
      if (result) {
        if (!this.mounted) return;
        await this.props.loadFollowers(result.data.data);
        await this.setState({ gettingFollowers: false });
      }
    } catch (error) {
      if (!this.mounted) return;
      this.props.loadFollowers({ data: [] });
      this.setState({ gettingFollowers: false });
    }
  };

  showFollowers = (e) => {
    e.preventDefault();
    this.getFollowers();
    this.setState({ showFollowers: true });
    this.setState({ showModal: true });
    this.setState({ showFollowings: false });
  };
  showFollowings = (e) => {
    e.preventDefault();
    this.getFollowings();
    this.setState({ showFollowers: false });
    this.setState({ showModal: true });
    this.setState({ showFollowings: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  getPendingAllocations = () => {
    if (this.state.fetchingProject) return;
    this.mounted && this.props.loadingAllocation(true);
    this.mounted && this.setState({ fetchingProject: true });
    Axios.get(
      `${PROXY}/api/get_pending_allocations/${this.props.participant.userName}`,
      { headers: processHeader() }
    )
      .then(async (res) => {
        if (res.data.success) {
          this.props.displayAllocation("pend");
          this.props.getPendingAllocations(res.data.data.allocations);
          this.props.loadingAllocation(false);
          if (!this.mounted) return;
          this.setState({ fetchingProject: false });
        }
      })
      .catch(async (err) => {
        if (!this.mounted) return;
        this.props.loadingAllocation(false);
        this.setState({ fetchingProject: false });
        // if(err) console.log(err)
      });
  };

  getCompletedAllocations = () => {
    if (this.state.fetchingProject) return;
    this.mounted && this.props.loadingAllocation(true);
    this.mounted && this.setState({ fetchingProject: true });
    Axios.get(
      `${PROXY}/api/get_completed_allocations/${this.props.participant.userName}`,
      { headers: processHeader() }
    )
      .then(async (res) => {
        if (res.data.success) {
          this.props.displayAllocation("comp");
          this.props.getCompletedAllocations(res.data.data.allocations);
          this.props.loadingAllocation(false);
          if (!this.mounted) return;
          this.setState({ fetchingProject: false });
        }
      })
      .catch(async (err) => {
        if (!this.mounted) return;
        this.props.loadingAllocation(false);
        this.setState({ fetchingProject: false });
      });
  };

  getAllAllocations = () => {
    if (this.state.fetchingProject) return;
    this.mounted && this.props.loadingAllocation(true);
    this.mounted && this.setState({ fetchingProject: true });
    Axios.get(
      `${PROXY}/api/get_allocations/${this.props.participant.userName}`,
      { headers: processHeader() }
    )
      .then(async (res) => {
        if (res.data.success) {
          this.props.displayAllocation("all");
          this.props.getCompletedAllocations(res.data.data.allocations);
          this.props.loadingAllocation(false);
          if (!this.mounted) return;
          this.setState({ fetchingProject: false });
        }
      })
      .catch(async (err) => {
        if (!this.mounted) return;
        this.props.loadingAllocation(false);
        this.setState({ fetchingProject: false });
      });
  };

  logOut = (e) => {
    try {
      if (e.cancelable) e.preventDefault();
      Axios.get(`${PROXY}/api/logout`, { headers: processHeader() })
        .then((res) => {
          window.localStorage.clear();
          return this.props.navigate('/',{replace:true})
        })
        .catch((err) => {
          window.localStorage.clear();
          return this.props.navigate('/',{replace:true})
        });
    } catch (_) {
      // console.log(err)
    }
  };

  googleLogout = (response) => {
    try {
      Axios.get(`${PROXY}/api/logout`, { headers: processHeader() })
        .then((res) => {
          window.localStorage.clear();
          return this.props.navigate('/',{replace:true})
        })
        .catch((err) => {
          window.localStorage.clear();
          return this.props.navigate('/',{replace:true})
        });
    } catch (_) {
      // console.log(err)
    }
  };

  render() {
    const { participant } = this.props;

    return (
      <nav className="py-2 mx-0 bg-white rounded-lg sideNavContent">
        <div className="text-center">
          <Avatar
            alt="avatar1"
            className="img-fluid mx-auto"
            src={participant.profilePic ? participant.profilePic : noImage}
            style={avatarStyle}
          />
          <div className="font-weight-bold smallerText">
            <Link
              to="/dashboard/profile"
              style={{ color: "black", textDecoration: "none" }}
            >
              {`@${participant.userName}`}
            </Link>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <hr width="80%" />
        </div>
        <div>
          <div className="navbar-nav">
            <div className="nav-item" style={{ cursor: "pointer" }}>
              <a href={`https://template.medillery.com/home?user_uuid=${participant.uuid}`}  
                target="_blank" className="decoration-none"
              >
                <div className="d-flex align-items-center">
                  <FilterFrames className="nav-link text-dark" style={iconStyle} />
                  <span className="nav-link sidebarText text-dark flex-grow-1 ml-1"> 
                    My Templates
                  </span>
                </div>
              </a>
              <a href={`https://pricing.medillery.com?user_uuid=${participant.uuid}`}  
                target="_blank" className="decoration-none"
              >
                <div className="d-flex align-items-center">
                  <RequestQuoteIcon className="nav-link text-dark" style={iconStyle} />
                  <span className="nav-link sidebarText text-dark flex-grow-1 ml-1"> 
                    Pricing
                  </span>
                </div>
              </a>
              <div className="d-flex align-items-center">
                <Link to="/dashboard/status">
                  <CreateIcon
                    className="nav-link text-dark"
                    style={iconStyle}
                  />
                </Link>
                <Link
                  to="/dashboard/status"
                  className="nav-link sidebarText text-dark flex-grow-1 ml-1"
                >
                  Upload Status
                </Link>
              </div>
            </div>
            <div className="nav-item" style={{ cursor: "pointer" }}>
              <div className="d-flex align-items-center">
                <Link to="/dashboard/">
                  <HomeIcon className="nav-link text-dark" style={iconStyle} />
                </Link>
                <Link
                  to="/dashboard/"
                  className="nav-link sidebarText text-dark flex-grow-1 ml-1"
                >
                  Home
                </Link>
              </div>
            </div>
            <div className="nav-item" style={{ cursor: "pointer" }}>
              <div className="d-flex align-items-center">
                <Link to="/dashboard/inbox">
                  <EmailIcon className="nav-link text-dark" style={iconStyle} />
                </Link>
                <Link
                  to="/dashboard/inbox"
                  className="nav-link sidebarText text-dark flex-grow-1 ml-1"
                >
                  Inbox
                </Link>
              </div>
            </div>
            {/* <div className="nav-item my-2 customPrimaryBgColor rounded-pill mx-1" style={{cursor: 'pointer'}}>
							<div className="d-flex align-items-center">                    
								<Link to="/dashboard/status"><CreateIcon className="nav-link text-white" style={iconStyle }  /></Link>
								<Link to="/dashboard/tag-professional" className="nav-link sidebarText text-white flex-grow-1 ml-1">
									Tag Professional
								</Link>
							</div>
						</div> */}
            <div className="nav-item" style={{ cursor: "pointer" }}>
              {participant.userName && (
                <div
                  className="smallCenter d-flex align-items-center"
                  onClick={() => {
                    if (!participant.email_verified_at) return;
                    this.getFollowers();
                    this.setState({ showModal: true });
                  }}
                >
                  <PeopleIcon
                    className="nav-link text-dark"
                    style={iconStyle}
                  />
                  <span className="sidebarText ml-1">Followers</span>
                </div>
              )}
            </div>
            {this.state.projectItems.map((items, index) => (
              <div className="nav-item" key={items.title}>
                <div
                  className="d-flex justify-content-start"
                  onClick={
                    items.hint === "comp"
                      ? this.getCompletedAllocations
                      : items.hint === "pend"
                      ? this.getPendingAllocations
                      : this.getAllAllocations
                  }
                >
                  <Link to={items.link}>
                    <items.icon
                      className="nav-link text-dark"
                      style={iconStyle}
                    />
                  </Link>
                  <Link
                    to={items.link}
                    className="nav-link text-dark sidebarText flex-grow-1 ml-1"
                  >
                    {items.title}
                  </Link>
                </div>
              </div>
            ))}

            <div className="d-flex justify-content-center">
              <hr width="80%" />
            </div>

            {this.state.userItems.map((items) => (
              <div className="nav-item" key={items.title}>
                {items.title === "Logout" ? (
                  <div
                    className={` ${
                      (this.props.participant.type) === "AppAuth"
                        ? "d-flex justify-content-start"
                        : " d-block"
                    } `}
                  >
                    {(this.props.participant.type) === "SocialAuth" ? (
                      <div className="py-0 d-block" onClick={this.logOut}>
                        <GoogleLogout
                          className="py-0"
                          clientId="63312386785-pq5gh1anun4b0cugvqp3sh1a806j8t5q.apps.googleusercontent.com"
                          buttonText="Logout"
                          onLogoutSuccess={this.googleLogout}
                          onFailure={this.googleLogout}
                        ></GoogleLogout>
                      </div>
                    ) : (
                      <div
                        className="d-flex justify-content-start"
                        style={{ cursor: "pointer" }}
                        onClick={this.logOut}
                      >
                        <span>
                          <items.icon
                            className="nav-link text-dark"
                            style={iconStyle}
                          />
                        </span>
                        <span className="nav-link text-dark flex-grow-1 sidebarText ml-1">
                          {items.title}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="d-flex justify-content-start">
                    <Link to={items.link}>
                      <items.icon
                        className="nav-link text-dark"
                        style={iconStyle}
                      />
                    </Link>
                    <Link
                      to={items.link}
                      className="nav-link text-dark flex-grow-1 sidebarText ml-1"
                    >
                      {items.title}
                    </Link>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Body className="py-1">
            <div className="d-flex my-2 justify-content-around">
              <div
                className={`${
                  this.state.showFollowers ? "text-primary" : "text-dark"
                } font-weight-bold`}
                onClick={this.showFollowers}
                style={{ cursor: "pointer" }}
              >
                Followers
              </div>
              <div
                className={`${
                  this.state.showFollowings ? "text-primary" : "text-dark"
                } font-weight-bold`}
                onClick={this.showFollowings}
                style={{ cursor: "pointer" }}
              >
                Followings
              </div>
              <div style={{ position: "relative" }}>
                <div
                  className="px-2 py-1 w3-circle w3-light-grey"
                  onClick={() => {
                    this.setState({ showModal: false });
                  }}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    cursor: "pointer",
                  }}
                >
                  X
                </div>
              </div>
            </div>
            <hr className="my-2" />

            {this.state.showModal && this.state.showFollowers && (
              <div>
                <Followers getting={this.state.gettingFollowers} />
              </div>
            )}
            {this.state.showModal && this.state.showFollowings && (
              <div>
                <Followings getting={this.state.gettingFollowings} />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </nav>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    participant: state.participant,
    initialAllocations: state.initialAllocations,
    initialPendingAlloc: state.initialPendingAllocations,
    initialCompletedAlloc: state.initialCompletedAllocations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadFollowers: (payload) => dispatch(loadedFollowers(payload)),
    loadFollowings: (payload) => dispatch(loadedFollowings(payload)),
    getPendingAllocations: (payload) => dispatch(loadAllocation(payload)),
    getCompletedAllocations: (payload) => dispatch(loadAllocation(payload)),
    displayAllocation: (payload) => dispatch(allocationToDisplay(payload)),
    loadingAllocation: (payload) => dispatch(loadingAllocation(payload)),
  };
};

const withRouter=Child=>props=>{
  const params = useParams();
  const navigate = useNavigate();
  return(
    <Child 
      {...props}
      params={params}
      navigate={navigate}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidenav));
