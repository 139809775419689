import React from "react";
import Sidenav from "../Sidenav";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const classes = {
  closeBtn: {
    position: "absolute",
    right: 0,
    marginTop: "0px",
    marginRight: "7px",
    zIndex: 50,
  },
  activeSidebar: {
    height: "100%",
    width: "100%",
    display: "flex",
    background: "rgba(0,0,0,0.6)",
  },
  activeSidebarContent: {
    width: "200px",
  },
  inactiveSidebar: {
    width: "0px",
    overflow: "auto",
  },
  backdrop: {
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.5)",
  },
};

const Sidebar = ({ sidebarOpened, closeNow }) => {
  return (
    <section
      id="sidenav"
      className="sidenav border"
      style={sidebarOpened ? classes.activeSidebar : classes.inactiveSidebar}
    >
      <div className="h-100 overflow-auto" style={classes.activeSidebarContent}>
        <div className="mx-0 h-100 position-relative bg-white">
          <IconButton
            variant="secondary"
            onClick={() => closeNow()}
            style={classes.closeBtn}
          >
            <CloseIcon />
          </IconButton>
          <Sidenav />
        </div>
      </div>
      <div className="flex-fill" onClick={() => closeNow()}></div>
    </section>
  );
};

export default Sidebar;
