import React, { Component } from 'react';
import '../../CSSs/Business/four/four.css';
import { Typography } from '@material-ui/core';
import download from 'downloadjs';
import * as htmlToImage from 'html-to-image'
import GDHeader from '../GDHeader';
// import CopyRight from '../../CopyRight'

class BusinessEditFour extends Component {
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        logoText: "Your Logo",
        blackFriday: "Black Friday Sales",
        otherTexts: "Fashion, Gadget, Electronics and so on",
        discountText: "50%",
        offerPeriod: "Offer runs from 13th - 27th",
        novemberText: "November",
        tcText: "T & C Applies",
    }
    
    changeImage = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const slot = document.getElementById('imgSlot');
        if(!slot) return;
        slot.src = URL.createObjectURL(file);
    }
    changeLogo = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const logo = document.getElementById('logoSlot');
        if(!logo) return;
        logo.src = URL.createObjectURL(file);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }
   
    handleFinishEdit = (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('outSideDiv4'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
                var link = document.createElement("a");
                link.download = "medillery-template.jpeg";
                link.href = dataUrl;
                link.click();
            });          
    }
    
    downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('outSideDiv4'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }
    
    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }

    render() {
        return (<div style={{marginTop: '90px'}}>
            <GDHeader caty='business' />

            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* this is the outside div that encapsulate all the others */}
                                        <div className="outSideDiv4" id="outSideDiv4">
                                            {/* for the leftside white section section */}
                                            <div className="leftSideDiv4">
                                                {/* this is the zig-zag circles */}
                                                <div className="yellowCircle4">
                                                    <div className="yourLogo4">
                                                        <img id="logoSlot" src={require("../../../../assets/images/img.png")} 
                                                            alt="living Room" className="logoImage4" />
                                                    </div>
                                                    <div className="triangle4"></div> 
                                                    <div className="triangle4"></div> 
                                                    <div className="triangle4"></div> 
                                                    <div className="triangle4"></div> 
                                                    <div className="triangle4"></div> 
                                                    <div className="triangle4"></div> 
                                                    <div className="triangle4"></div> 
                                                    <div className="triangle4"></div> 
                                                    <div className="triangle4"></div> 
                                                    <div className="triangle4"></div> 
                                                    <div className="triangle4"></div> 
                                                    <div className="triangle4"></div>
                                                </div>

                                                <div className="circleTwo4">
                                                    <div className="getUpTo4">Get Up To<h4> {this.state.discountText} OFF</h4></div>
                                                    <div className="secondTriangle4"></div> 
                                                    <div className="secondTriangle4"></div> 
                                                    <div className="secondTriangle4"></div> 
                                                    <div className="secondTriangle4"></div> 
                                                    <div className="secondTriangle4"></div> 
                                                    <div className="secondTriangle4"></div> 
                                                    <div className="secondTriangle4"></div> 
                                                    <div className="secondTriangle4"></div> 
                                                    <div className="secondTriangle4"></div> 
                                                    <div className="secondTriangle4"></div> 
                                                    <div className="secondTriangle4"></div> 
                                                    <div className="secondTriangle4"></div>
                                                </div>
                                                {/* div for the fixed margin */}
                                                <div className="insideDiv4">
                                                    {/* div for the text */}
                                                    <div className="textDiv4">
                                                        {/* the horizontal lines underneath h1 text */}
                                                        <div className="coverLineDiv4">
                                                            <div className="lineDiv4 firstlineDiv4"></div>
                                                            <div className="lineDiv4 secondlineDiv4"></div>
                                                            <div className="lineDiv4 thirdlineDiv4"></div>
                                                        </div>
                                                        <h1>{this.state.blackFriday}</h1>
                                                        <p>{this.state.otherTexts}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* for the rightside section */}
                                            <div className="rightSideDiv4">
                                                {/* the image div/border */}
                                                <div className="imageFrame4">
                                                    {/* the image itself */}
                                                    <img id="imgSlot"
                                                        src={require("../../../../assets/images/templates/heroes/8.jpeg")} 
                                                        alt="buyers" className="mainImg4" />
                                                </div>  
                                                {/* div for the offers date */}
                                                <div className="offerDateText"><span className="offerOne">{this.state.offerPeriod}</span><span className="offerTwo" id="november4">{this.state.novemberText}</span></div>
                                                <p className="tandc4"><small>{this.state.tcText}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-4">
                            {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                    <div className="form-group mt-3">
                                            <label>Image</label>
                                            <label className="form-control rounded-pill">
                                                <input 
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeImage}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label>Logo</label>
                                            <label className="form-control rounded-pill">
                                                <input
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeLogo}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group d-flex">
                                            <div className="mr-1">
                                                <label>Black Friday Sales</label>
                                                <input disabled
                                                    type="text" id="blackFriday" 
                                                    value={this.state.blackFriday} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="mr-1">
                                                <label>Discounts</label>
                                                <input id="discountText"
                                                    type="text" value={this.state.discountText}
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div>
                                                <label>T & C Applies</label>
                                                <input
                                                    type="text" id="tcText"
                                                    value={this.state.tcText} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <label>Other Information</label>
                                                <input
                                                    type="text" id="otherTexts"
                                                    value={this.state.otherTexts} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>  
                                        </div>
                                        <div className="form-group d-flex">
                                            <div className="mr-2">
                                                <label>Sales Period</label>
                                                <input 
                                                    type="text" id="offerPeriod"
                                                    value={this.state.offerPeriod}
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div>
                                                <label>November</label>
                                                <input 
                                                    type="text" id="novemberText"
                                                    value={this.state.novemberText}
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                            > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4 className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select className="form-control form-control-sm rounded-pill"
                                                onChange={this.downloadNow}
                                            >   <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                        className="btn btn-block rounded-pill customPrimaryBgColor" 
                                        onClick={ this.backToEdit }
                                        > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>);
    }
}
// jquery.scrollLeft() to move the whole div left and right
// https://stackoverflow.com/questions/27313480/scroll-div-content-horizontally-using-left-and-right-arrow-in-jquery

export default BusinessEditFour;
