import React, {Component} from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Avatar, Box} from '@material-ui/core'
import AttachmentIcon from '@material-ui/icons/Attachment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { processHeader, somePageNotFound,
   markSelectedChat, loadDesktopPCs, loadPcDetailChanges 
} from '../../../global/action';
import { connect } from 'react-redux';
import Axios from 'axios';
import { PROXY } from '../../../global/constants/action_types';
import { staticPusherConfig, noImage, CHAT_LIKE_TIME } from '../../../global/generalMethods/general';
import LazyLoader from '../../../LazyLoader';
import ChatNotFound from './ChatNotFound';
import { Link, useNavigate, useParams } from 'react-router-dom';


const classes = {
	userChatStyle: {
		float:'right',
		maxWidth: '85%',
		backgroundColor: '#F9FBFF',
		borderRadius: '15px 0 15px 15px',
	},
	otherChatStyle: {
		float:'left',
		maxWidth:'85%',
		borderRadius: '0 15px 15px 15px',
	},
	chatInput: {
		height: '50px',
	},
	chatInputDiv: {
		position: 'absolute',
		zIndex: '1',
		backgroundColor: '#F9FBFF',
		width: '100%',
		bottom: '0',
		padding: '7px 12px',
	}
}

const quitRequest = Axios.CancelToken.source();

class DesktopChat extends Component{
	state = {
		userName:null,
		notFound:false,
		partner:null,
		conversation_id:null,
		messageList:{},
		loading:false,
		fetchingMessages:false,
		initialUnread:0,
		unreadId:null,
		type: 1,
		messageFile:[],
		sendingMessage: false,
	}

	scrollBox;
	mounted;

	componentDidMount=async()=>{
		this.mounted= true;
		this.scrollBox= await window.document.getElementById('scrollBox');
		await this.setState({userName: this.props.participant.userName})
		await this.setState({conversation_id: this.props.room});
		await this.getConversationId();
		await this.prepareSearchMore();
		await staticPusherConfig();
	}

	componentWillUnmount=()=>{
		this.mounted = false;
		if(this.scrollBox)	this.scrollBox.removeEventListener('scroll', this.scrollBoxEvent);
	}

  UNSAFE_componentWillReceiveProps=async(next, current)=>{
    if(next.room !== this.state.conversation_id){
			await this.setState({conversation_id: next.room})
      this.getConversationId();
    }
	}

	/**Get the conversation Id from from the room prop and 
	 * dertermine the current user, get partne's details and call
	 * loadMessages method to load the messages
	 *  */
	getConversationId=async()=>{
    const {userName} = this.props.participant
		await this.props.chatNotFound(false);
		try {
			const decodedConversationId = this.state.conversation_id
			let receiver;
			const idLength = decodedConversationId.length;
			const index = decodedConversationId.indexOf(userName);
			if(index === -1){
				await this.setState({notFound: true});
				return this.props.chatNotFound(true);
			}
			else if(index > 0 && index+userName.length < idLength){
        await this.setState({notFound: true});
				return this.props.chatNotFound(true);
			}
			else if(index === 0){
				receiver = decodedConversationId.slice(userName.length+1, idLength);
			}
			else if(index > 0 && index+userName.length === idLength){
				receiver = decodedConversationId.slice(0, index-1);
			}
			Axios.get(`${PROXY}/api/get_private_partner/${window.btoa(decodedConversationId)}`,
			{headers:processHeader(), cancelToken: quitRequest.token})
			.then(async(res)=>{
				if(res.data.success){
					if(res.data.data.userName === receiver){
						await this.props.markSelectedChat(`${decodedConversationId}`);
						if(!this.mounted) return;
						// alert(decodedConversationId);
						await this.setState({partner:res.data.data});
						await this.setState({conversation_id: decodedConversationId});
						await this.loadMessages();
						await this.listenToNewMessages();
					}
				}else{
					this.props.chatNotFound(true);
					if(!this.mounted) return;
					await this.setState({notFound: true});
				}

			}).catch(async(_)=>{
				this.props.chatNotFound(true);
				if(!this.mounted) return;
				await this.setState({notFound: true});;
			})
		} catch (_) {
			this.props.chatNotFound(true);
			if(!this.mounted) return;
			await this.setState({notFound: true});
		}
	}

	loadMessages=async()=>{
		if(!this.state.notFound && this.state.conversation_id){
			await this.setState({fetchingMessages:true});
			Axios.get(`${PROXY}/api/get_private_messages/${window.btoa(this.state.conversation_id)}`,
			{headers:processHeader(), cancelToken: quitRequest.token})
			.then(async(res)=>{
				if(res.data.success){
					if(!this.mounted) return;
					res.data.data.messages.data = res.data.data.messages.data.reverse();
					const temp = [...res.data.data.messages.data];
					const unread = temp.filter(data=> data.read == 0 && data.userName == this.state.partner.userName);
					if(unread.length){
						if(!this.mounted) return;
						await this.setState({initialUnread: unread.length});
						await this.setState({unreadId: unread[0].id});
						if(this.mounted) this.markUnread();
					}
					if(!this.mounted) return;				
          await this.setState({messageList:res.data.data.messages});
          await this.setState({fetchingMessages:false});
          await this.props.loadNewDetail(false);
					const gotoUnread = await document.getElementById('new_messages');					if(gotoUnread){
						gotoUnread.scrollIntoView();
						window.document.body.scrollTop = 0;
						window.document.documentElement.scrollTop = 0;
						return;
					}else{
						if(this.scrollBox){
							this.scrollBox.scrollTop = this.scrollBox.scrollHeight - this.scrollBox.clientHeight;
						}else{
							this.scrollBox= await window.document.getElementById('scrollBox');
							if(!this.scrollBox) return;
							this.scrollBox.scrollTop = this.scrollBox.scrollHeight - this.scrollBox.clientHeight;
							return;
						}
					}
				}else{
					if(res.data.Error === 'Intruder'){
						if(!this.mounted) return;
						window.localStorage.clear();
						return this.props.navigate('/');
					}
				}
			})
			.catch(async(_)=>{
				if(!this.mounted) return;
				await this.setState({fetchingMessages:false});
			})
		}
	}
	markUnread=async()=>{
		Axios.get(`${PROXY}/api/mark_unread_messages/${window.btoa(this.state.conversation_id)}`,
		 {headers:processHeader(), cancelToken: quitRequest.token})
		.then(async(res)=>{
			if(res.data.success && res.data.data.status === 'marked'){
				if(!this.mounted) return;
			}
		})
		.catch(async(_)=>{
			if(!this.mounted) return;
		} )
	}

	fetchMoreMessages=async()=>{
		if(this.state.fetchingMoreMessages) return;
		if(this.state.messageList.next_page_url){
			await this.setState({fetchingMoreMessages:true});
			Axios.get(`${this.state.messageList.next_page_url}`, {headers: processHeader(), cancelToken: quitRequest.token})
			.then(async(res)=>{
				if(res.data.success){
					if(!this.mounted) return;
					const currentData = [...this.state.messageList.data];
					const incomingData = [...res.data.data.messages.data.reverse()];
					res.data.data.messages.data=[...incomingData,...currentData];
					await this.setState({messageList:res.data.data.messages})
					this.scrollBox.scrollTop = 5;
					await this.setState({fetchingMoreMessages:false});
				}
			})
			.catch(async(_)=>{
				if(!this.mounted) return;
				await this.setState({fetchingMoreMessages:false});
			})
		}
	}

	listenToNewMessages=async()=>{
		await window.Echo.private(`pc.${this.state.conversation_id}`)
		.listen('.newMessage', async(data)=>{
			const tempMessage= Object.assign({}, this.state.messageList)
			const tempData = [...tempMessage.data,...JSON.parse(data[0])];
			tempMessage.data=tempData;
			await this.setState({messageList:tempMessage})
			const dupStateChats = Object.assign({}, this.props.desktopInitialPCs);
			let chat = dupStateChats.data.find(data=>data.conversation_id === this.state.conversation_id);
			if(chat.unread_count < 1) return;
				chat.unread_count = 0;
				/** Collect last mesage data and replace them with current chat on left pane */
				let {id, conversation_id, created_at, type, message } = JSON.parse(data[0])
				chat = {...chat, id,conversation_id,created_at,type,message}
				const otherChats = dupStateChats.data.filter(({conversation_id})=> 
					conversation_id !== this.state.conversation_id
				)
				dupStateChats.data = [chat, ...otherChats];
				this.props.loadDesktopPCs(dupStateChats);
				const INDEX = dupStateChats.data.findIndex(data=>data.conversation_id === this.state.conversation_id);
				dupStateChats.data.splice(INDEX,1,chat);
				this.props.loadDesktopPCs(dupStateChats)
		})
	}


	handleFileUploadChange=async(e)=>{
		e.preventDefault();
		const fileCollector = e.target.files;
		if(fileCollector.length < 1) return;
		await this.setState({minimumUploadAltered:false});
		if(fileCollector.length > 4){
			await this.setState({minimumUploadAlteredMessage: 'You can only send maximum of Four files'})
			await this.setState({minimumUploadAltered:true});
			return;
		}
		const Temp = [];
		let storeSize=0;
		for (let i = 0; i < fileCollector.length; i++) {
			Temp.push(fileCollector[i]);
			storeSize += fileCollector[i].size;
		}
		if(storeSize > 12582912){
			await this.setState({minimumUploadAlteredMessage: 'File(s) too large, maximimum of 12MB is allowed'})
			await this.setState({minimumUploadAltered:true});
			return;
		}
		await this.setState({messageFile:Temp});
		await this.setState({type: 2});
	}

  sendMessageFile=async(e)=>{
		e.preventDefault();
		if(this.state.sendingMessage) return;
		const form = new FormData();
		if(this.state.messageFile.length > 0){
			for (let i = 0; i < this.state.messageFile.length; i++) {
				form.append(`pc_files[${i}]`, this.state.messageFile[i]);
			}
		}else{      
			await this.setState({type: 1});
			return;
		}
		form.append('message', '');
		form.append('receiver', this.state.partner.userName);
		const AUTH = processHeader();
		AUTH['X-Socket-Id'] = window.Echo.socketId();
		this.setState({sendingMessage: true});
		Axios.post(`${PROXY}/api/send_private_chat/${this.props.participant.userName}`, form,
		 {headers: AUTH, cancelToken: quitRequest.token})
		.then(async(res)=>{
        if(res.data.success){
				if(!this.mounted) return;
				const tempMessage= Object.assign({}, this.state.messageList)
				const tempData = [...tempMessage.data,...res.data.data];
				tempMessage.data=tempData;
				this.mounted && await this.setState({messageList:tempMessage})
				this.mounted && await this.setState({type: 1});
				this.mounted && await this.setState({sendingMessage: false});
				if(this.scrollBox) this.scrollBox.scrollTop = this.scrollBox.scrollHeight - this.scrollBox.clientHeight;
			}else if(res.data.Error === 'Intruder'){
				window.localStorage.clear();
				return this.props.navigate('/', {replace:true});
			}
		})
		.catch(async(_)=>{
		if(!this.mounted) return;
		await this.setState({type: 1});
		this.mounted && await this.setState({sendingMessage: false});
		})
	}

  sendPrivateMessage=async(e)=>{
		e.preventDefault();
		if(this.state.sendingMessage) return;
    const message = window.document.getElementById('sendInput').value.trim();
    if(!message) return;
    const form = new FormData();
    form.append('message', message);
    form.append('receiver', this.state.partner.userName);
		form.append('pc_files[]','');
		const AUTH = processHeader();
		AUTH['X-Socket-ID'] = window.Echo.socketId();
		Axios.post(`${PROXY}/api/send_private_chat/${this.props.participant.userName}`,
			form, {headers:AUTH, cancelToken: quitRequest.token}
		)
		.then(async(res)=>{
      if(res.data.success){
				if(!this.mounted) return;
				const tempMessage= Object.assign({}, this.state.messageList)
				const tempData = [...tempMessage.data,...res.data.data];
				tempMessage.data=tempData;
				window.document.getElementById('sendInput').value='';
				await this.setState({messageList:tempMessage});
				if(this.scrollBox) this.scrollBox.scrollTop = 
				(this.scrollBox.scrollHeight - this.scrollBox.clientHeight);
				// send this chat to the top on left chat list pane.
				const dupStateChats = {...this.props.desktopInitialPCs};
				/** Collect last mesage data and replace them with current chat on left pane */
				let {id, conversation_id, created_at, type, message } = res.data.data[0];
				// current chat
				let chat = dupStateChats.data.find(data=>data.conversation_id === this.state.conversation_id);
				chat = {...chat, id,conversation_id,created_at,type,message}
				const otherChats = dupStateChats.data.filter(({conversation_id})=> 
					conversation_id !== this.state.conversation_id
				)
				dupStateChats.data = [chat, ...otherChats];
				this.props.loadDesktopPCs(dupStateChats);
			}else if(res.data.Error === 'Intruder'){
				window.localStorage.clear();
				return this.props.navigate('/', {replace:true});
			}
    })
    .catch(async(err)=>{
			this.mounted && await this.setState({sendingMessage: false});
    })
  }

  deleteFile=async(e)=>{
		e.preventDefault();		
    const go = window.confirm("Do you want to remove this file?");
    if(!go) return;
    const collector = [...this.state.messageFile];
    collector.splice(Number(e.target.id), 1);
		if(collector.length < 1){
			await this.setState({type: 1});
			const uploader = document.getElementById('filer');
			uploader.value = null;
		} 
		await this.setState({messageFile: collector});
	}

	scrollBoxEvent=async()=>{
		if(this.scrollBox.scrollTop < 1){
			await this.fetchMoreMessages();
		}
	}
	prepareSearchMore=async()=>{
		await this.scrollBox.addEventListener('scroll', this.scrollBoxEvent);
		this.scrollBox.scrollTop = 5;
	}	

	render(){
		const {userName} = this.props.participant;
		return(
			<React.Fragment>
				{this.props.isDetailChanged?
          <div >
            <LazyLoader />
					</div>:
					<div>
						{(this.state.notFound)?
						 <div>
							 <ChatNotFound />
						 </div>:
						 <div className="position-relative bg-light" style={{height:'87vh'}} >
							<div className="card-header px-3 border-0 py-1">
								<div className="d-flex">
									<div className="d-flex">
										<Avatar alt="avatar1" className="img-fluid mr-2" style={ {width:'40px', height:'40px'} } src={
												this.state.partner && `${this.state.partner.profilePic?
													`${this.state.partner.profilePic}`:
													`${noImage}`
												}`
											}
										/>
										<h6 className="my-auto">
											<b>
												{this.state.partner &&
													<Link to={`/user/${this.state.partner.userName}`}
														className='text-decoration-none'
													>
														@{this.state.partner.userName}
													</Link>
												}
											</b>
										</h6>
									</div>
									<div className="mx-auto my-auto">										
										<label>
											<AttachmentIcon />
											<input 
												onChange={this.handleFileUploadChange}
												className='d-none' accept='.png, .svg, .gif, .jpg, .3gp, .avi, .mpeg, .mp4, .mkv' 
												multiple type='file'  id='filer'
											/>
										</label>
									</div>
								</div>
							</div>
							<div id='scrollBox' className="card" style={{height:'70vh', overflowY: 'auto'}}>
								<div className="card-body">
									<div>
										{this.state.fetchingMoreMessages &&
											<div className='text-center'>
												<div className='w3-spin spinner w3-circle mt-2'></div>
											</div>
										}
										{this.state.fetchingMessages?
											<div style={{overflow:'hidden'}}>
												<LazyLoader />
											</div>:
											<div>
												{this.state.messageList.data &&  this.state.messageList.data.map((message) =>
													<div key={message.id} >
														<div  className='w3-container'>
															{(this.state.unreadId && this.state.unreadId === message.id) &&
																<small id='new_messages' className='w3-text-blue mb-4'>
																	{this.state.initialUnread > 1? `${this.state.initialUnread} unread messages`:`${this.state.initialUnread} unread message`}
																 </small>
															}
														</div>
														{ (message.type === "0") &&
															<Paper elevation={0} style={message.userName === userName ? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
																<div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
																<Typography style={ {fontSize: '0.9rem'} }>
																	{message.message}
																</Typography>
															</Paper>
														}
														{ (message.type === "1")&&
															<Paper elevation={0} style={message.userName === userName ? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
																<div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
																<Typography style={ {fontSize: '0.9rem'} }>
																	<img src={`${message.message}`} alt='not found' width='150' height='150' />
																</Typography>
															</Paper>
														}
														{ (message.type === "2") &&
															<Paper elevation={0} style={message.userName === userName ? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
																<div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
																<Typography style={ {fontSize: '0.9rem'} }>
																	<video width='150' height='150' controls>
																		<source src={`${message.message}`} />
																		Your device cannot play this video
																	</video>
																</Typography>
															</Paper>
														}														
														<div className="clearfix" style={{clear:'both'}}></div>
													</div>
												) }
											</div>
										}
									</div>
								</div>
							</div>
							<div style={classes.chatInputDiv}>
								<form>
								{this.state.type === 1 ?
									<Box className="d-flex">
										<textarea id="sendInput" 
											className="form-control rounded-pill mr-1" 
											placeholder="your message here..." 
											style={classes.chatInput}
											maxLength='10000'
										/>
										<button className="btn customPrimaryBgColor text-white rounded-pill" onClick={this.sendPrivateMessage}>
											<ChevronRightIcon />
										</button>
									</Box>:
									<Box style={{position: 'relative'}}>
										<div className="d-flex justify-content-center" id="sendInput" style={classes.chatInput}>
											{this.state.messageFile.length > 0 && this.state.messageFile.map((data, index)=>
												<div className='mx-1 w3-round' key={index} style={{display: 'inline'}}>
													{data.type.includes('image')?
													<img 
														id={index} alt='not found' onClick={this.deleteFile}  
														width='40' height='40' src={URL.createObjectURL(data)} 
													/>:
													<video id={index} onClick={this.deleteFile} width='40' height='40' controls>
														<source src={URL.createObjectURL(data)} />
														Your device cannot play this video
													</video>
													}
												</div>
											)}
											<button 
												className=" btn customPrimaryBgColor text-white rounded-pill" 
												onClick={this.sendMessageFile}
												style={{position: 'absolute', right:0}}
											>
												<ChevronRightIcon />
											</button>
										</div>
									</Box>
								}
								</form>
							</div>
						</div>
						}
					</div>
				}
			</React.Fragment>
  	)
  }
}

const mapStateToProps=(state)=>{
	return{
		participant: state.participant,
    desktopInitialPCs: state.desktopInitialPCs,
    isDetailChanged: state.changedPcDetail,
	}
}
const mapDispatchToProps=(dispatch)=>{
	return{
		chatNotFound:(payload)=>dispatch(somePageNotFound(payload)),
		loadDesktopPCs:(payload)=>dispatch(loadDesktopPCs(payload)),
    markSelectedChat:(payload)=>dispatch(markSelectedChat(payload)),
    loadNewDetail: (payload)=>dispatch(loadPcDetailChanges(payload)),
	}
}
const withRouter=Child=>props=>{
	const params = useParams();
	const navigate = useNavigate();
	return(
		<Child 
			{...props} 
			params={params} 
			navigate={navigate} 
		/>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DesktopChat));
