import React, { Component } from 'react';
import '../../CSSs/Business/seven/seven.css';
import { Typography } from '@material-ui/core';
import download from 'downloadjs';
import * as htmlToImage from 'html-to-image'
// import CopyRight from '../../CopyRight'
import GDHeader from '../GDHeader';
import Icon from '@material-ui/core/Icon';

class BusinessEditSeven extends Component {
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        logoText: "Your Logo",
        titleText: "Monday Motivation",
        otherTexts: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidid. Ut enim ad minim veniam, quis nostru, exercitation ullamco laboris nisi ut aliquip ex ea commodo",
        bColor: "#ffffff"
    }
    changeBackground=(e)=>{
        e.preventDefault();
        try {
            this.setState({bColor: e.target.value});
            let back = document.getElementById("outSideDiv7");
            let textWrapper = document.getElementById("textWrapperDiv7");
            if(back){
                back.style.backgroundColor= `${this.state.bColor}`;
                textWrapper.style.backgroundColor= `${this.state.bColor}`;
            } 
        } catch (e) {
            return;
        }
    }
    changeLogo = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const logo = document.getElementById('logoSlot');
        if(!logo) return;
        logo.src = URL.createObjectURL(file);
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }
    handleFinishEdit = (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('outSideDiv7'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
                var link = document.createElement("a");
                link.download = "medillery-template.jpeg";
                link.href = dataUrl;
                link.click();
            });          
    }
    
    downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('outSideDiv7'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }
    
    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }

    render() {
        return (<div style={{marginTop: '90px'}}>
            <GDHeader caty='business' />
           
            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* this is the outside div that encapsulate all the others */}
                                        <div className="outSideDiv7" id="outSideDiv7">
                                            {/* the text area div */}
                                            <div className="detailHere7">
                                                {/* Div topic */}
                                                <h1>{this.state.titleText}</h1> 
                                            </div>
                                            {/* This is the middle/center container div*/}
                                            <div>
                                                {/* the thick blue background line */}
                                                <div className="blueLineDiv7"></div>
                                                {/* the Div containing the text  */}
                                                <div className="textWrapperDiv7" id="textWrapperDiv7">
                                                    {/* <i className="fa fa-quote-left fa-2x fa-pull-left"></i> */}
                                                    <Icon className="fas fa-quote-left"></Icon>
                                                    <div>{this.state.otherTexts}</div>
                                                    <Icon className="fas fa-quote-right"></Icon>
                                                    {/* <i className="fa fa-quote-right fa-2x fa-pull-right lastIcon" aria-hidden="true"></i> */}
                                                </div>

                                                {/* this is the zig-zag circles */}
                                                <div className="circle7">
                                                    <div className="yourLogo7">
                                                        <img id="logoSlot" src={require("../../../../assets/images/img.png")} 
                                                            alt="living Room" className="logoImage7" />
                                                    </div>
                                                    <div className="triangle7"></div> 
                                                    <div className="triangle7"></div> 
                                                    <div className="triangle7"></div> 
                                                    <div className="triangle7"></div> 
                                                    <div className="triangle7"></div> 
                                                    <div className="triangle7"></div> 
                                                    <div className="triangle7"></div> 
                                                    <div className="triangle7"></div> 
                                                    <div className="triangle7"></div> 
                                                    <div className="triangle7"></div> 
                                                    <div className="triangle7"></div> 
                                                    <div className="triangle7"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-4">
                            {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                        <div className="form-group mt-3">
                                            <div>
                                                <label>Background Color</label>
                                                <label className="form-control rounded-pill">
                                                    <input 
                                                        type="color" style={{display:'none'}}
                                                        value={this.state.bColor}
                                                        className="form-control rounded-pill" 
                                                        onChange={this.changeBackground}
                                                        />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Logo</label>
                                            <label className="form-control rounded-pill">
                                                <input
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeLogo}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <label>Title</label>
                                                <input
                                                    type="text" id="titleText"
                                                    value={this.state.titleText} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <label>Body</label>
                                                <input
                                                    type="text" id="otherTexts" 
                                                    value={this.state.otherTexts}
                                                    onChange={this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>  
                                        </div>
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                            > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4  className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select className="form-control form-control-sm rounded-pill"
                                                onChange={this.downloadNow}
                                            >   <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                        className="btn btn-block rounded-pill customPrimaryBgColor" 
                                        onClick={ this.backToEdit }
                                        > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>);
    }
}
// jquery.scrollLeft() to move the whole div left and right
// https://stackoverflow.com/questions/27313480/scroll-div-content-horizontally-using-left-and-right-arrow-in-jquery

export default BusinessEditSeven;
