import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import './../../TemplateStyle.css';
import ForwardIcon from '@material-ui/icons/Forward';
import { Link } from 'react-router-dom';

const classes = {
    headerBg: {
        borderRadius: '18px',
        backgroundColor: 'lightgrey',
    }
}
class NewYearChildList extends Component {
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        NewYearChildlist: [
            { title: 'New Year One', image: 'MedilNewYear1', link: '/templates/graphics-design/new-year/one' },
            { title: 'New Year Two', image: 'MedilNewYear2', link: '/templates/graphics-design/new-year/two' },
            { title: 'New Year Three', image: 'MedilNewYear3', link: '/templates/graphics-design/new-year/three' },
            { title: 'New Year Four', image: 'MedilNewYear4', link: '/templates/graphics-design/new-year/four' },
            { title: 'New Year Five', image: 'MedilNewYear5', link: '/templates/graphics-design/new-year/five' },
        ]
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row my-4" style={classes.headerBg}>
                        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-3"></div>
                        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-6 text-center text-dark py-4">
                            <Typography variant="h4" className="mb-2">{ this.state.subCategoryTitle.title }</Typography>
                            <Typography variant="subtitle2" className="font-weight-light mb-2">{ this.state.subCategoryTitle.subtitle }</Typography>
                            {/*<div>
                                <input type="text" className="form-control text-white rounded-pill faqSearchInput" placeholder="Search ready made templates" />
                            </div>*/}
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-3"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mb-4 py-4">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <Typography variant="h5" className="h5 text-center">Sub-category</Typography>
                            <div className="row justify-content-center py-4">
                                { this.state.NewYearChildlist.map((template, index) => (
                                    <div className="col-sm-12 col-md-3 col-lg-2 col-xl-2 mb-4" key={index}>
                                        <div className='w3-display-container w3-animate-zoom w3-hover-opacity'>
                                            <Link to={ template.link }>
                                                <img className="img-fluid mb-1" src={ require('../../assets/images/templates/categories/newyear/'+ template.image+'.jpg') } alt={template.title} />
                                                <div className="w3-display-middle w3-display-hover"
                                                >
                                                 <ForwardIcon className='w3-text-teal' fontSize='large' />
                                                </div>
                                            </Link>
                                        </div>
                                        <Typography variant="subtitle1" className="text-center">{template.title}</Typography>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
// jquery.scrollLeft() to move the whole div left and right
// https://stackoverflow.com/questions/27313480/scroll-div-content-horizontally-using-left-and-right-arrow-in-jquery

export default NewYearChildList;
