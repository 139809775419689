import React from 'react';
import { Typography, Box } from '@material-ui/core';

const textStyle = {
  fontSize: '.9rem',
  fontWeight: 'normal',
  wordSpacing: '0.2em',
};

function WhyMedillery() {
  const pointList = [
    {
      icon: 'folder', 
      note:'Use the templates to create your designs in seconds.'
    },
    {
      icon: 'photos', 
      note:'Hire our freelancers to help make designs to your taste.'
    },
    {
      icon: 'folder', 
      note:'Are you a designer? You can build your portfolio on Medillery.'
    },
    {
      icon: 'connect', 
      note:'Users can connect with other Users, meet new people, make new friends and chat.'
    },
    {
      icon: 'photos', 
      note:'Medillery serves as a recreation center where people can watch videos, nice designs, pictures, GIFs.'
    },
    {
      icon: 'blog', 
      note:'It serves as a blog and center for Information and Knowledge.'
    }
  ];

  return (
    <Box py={5} mb={4} className="whyMedilleryBackground">
      <Box className="container">
        <Box className="row my-1 d-flex align-items-center">
          <Box className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <Typography variant="h4" className="mb-2 sectionTitle font-weight-bold">
              Why Medillery?
            </Typography>
            <Typography variant="subtitle2" className="mb-4 text-light font-weight-light">
              Reasons why you should choose Medillery
            </Typography>
            <Box className="text-light my-1">
              {pointList.map((point, index) => (
                <Box className="my-2 d-flex align-items-center" key={index}>
                  <Box className="mr-3">
                    <img style={{width: '2em'}}  
                        src={ require(`../../../assets/images/website/homepage/why-icon-${point.icon}.png`) } 
                        alt={point.icon} />
                  </Box>
                  <Typography variant="subtitle2" 
                    className=" smallerText" style={textStyle}>
                    {point.note}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box className="col-sm-12 col-md-5 col-lg-5 col-xl-5 hideSm ml-auto">
            <Box className="row m-3 d-flex justify-content-end">
              <Box className="col-6 d-flex flex-column libraryCard p-1">
                <Box className="ml-3 mt-1">
                  <img className="libraryImage" style={{borderRadius: '8px'}}  
                      src={ require('../../../assets/images/website/homepage/why-flower.png') } 
                  alt="users" />
                </Box>
                <Box className="mt-1">
                  <img className="libraryImage" style={{borderRadius: '8px'}}
                      src={ require('../../../assets/images/website/homepage/why-book.png') } 
                  alt="blogs" />
                </Box>
              </Box>
              <Box className="col-6 d-flex flex-column libraryCard py-1">
                <Box className="mt-1">
                  <img className="libraryImage" style={{borderRadius: '8px'}}
                      src={ require('../../../assets/images/website/homepage/why-ice.png') } 
                  alt="community" />
                </Box>
                <Box className="mt-1">
                  <img className="libraryImage" style={{borderRadius: '8px'}}  
                      src={ require('../../../assets/images/website/homepage/why-table.png') } 
                  alt="jobs" />
                </Box>
              </Box>
              
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default WhyMedillery;
