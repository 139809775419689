import React from 'react';
import { Modal } from 'react-bootstrap';



const DashboardErrorModal=({show, Error, Exit})=>{

  return(<div>
      <Modal
        show={show}
        onHide={Exit}
      >
      <Modal.Body>
        {Error &&
          <div>
            {Error}
          </div>
        }
      </Modal.Body>
      </Modal>
    </div>)
}

export default DashboardErrorModal;