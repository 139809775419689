import React, {Component} from 'react';
import { Avatar, /*Button,*/ Typography, Box } from '@material-ui/core';
import { noImage, CHAT_LIKE_TIME} from '../../../../global/generalMethods/general';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
// import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { PROXY } from '../../../../global/constants/action_types';
import { /*processHeader,*/ loadUSerPosts } from '../../../../global/action';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { FacebookShareButton, FacebookIcon, TwitterShareButton,
    TwitterIcon, WhatsappShareButton, WhatsappIcon, LinkedinShareButton,
    LinkedinIcon, TelegramShareButton, TelegramIcon 
} from 'react-share';
import { Link } from 'react-router-dom';

const classes = {
    statusImageCard: {
        height: '250px',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        background: 'rgba(0, 0, 0, 0.5)',
    },
    statusImageStyle: {
      width: '100%',
      minHeight: '100%',
      position: 'absolute',
      left: '0',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    sendCommentBtn: {
        width:'30px',
        color: '#F9FBFF',
        background: 'linear-gradient(92deg, #FA9347 5.16%, #F07672 99.12%)',
    }
}



class NOAuthUserPost extends Component{
    state = {
        statusPosts:{},
        fetching_post:false,
        postComment:null,
        commenting: false,
        deletingComment:false,
    }

    mounted = true;

    componentDidMount=async()=>{
        this.mounted = true;
        await this.getStatusPost();
        window.addEventListener('scroll',this.virtualScrolling );
        
    }
    UNSAFE_componentWillReceiveProps=async(next)=>{
        if(next.posts && next.posts.data){
            await this.setState({statusPosts: next.posts});
        }
    }

    componentWillUnmount=()=>{
        window.removeEventListener('scroll',this.virtualScrolling);
        this.mounted= false;
    }

    prePlay=(e)=>{
        try{
            if(e.cancelable){
                e.preventDefault();
              }
            const dom = document.getElementById(e.target.id);
            if(!dom) return;
            this.mounted && dom.play();
            dom.muted = false;
        }catch(err){
            return
        }
    }

    prePause=(e)=>{
        try{
            if(e.cancelable){
                e.preventDefault();
              }
            const dom = document.getElementById(e.target.id);
            if(!dom) return;
            this.mounted && dom.pause();
        }catch(err){
            return
        }
    }

    /* This Method fetches the available status post by self or following participant from recent to oldest*/
    getStatusPost=async()=>{
        if(!this.mounted) return
        if(!this.state.fetching_post){
            await this.setState({fetching_post: true});
            let url;
            if(Object.entries(this.state.statusPosts).length >0 && this.state.statusPosts.next_page_url){
                const getUrl = this.state.statusPosts.next_page_url.indexOf('api');
				url = this.state.statusPosts.next_page_url.slice(getUrl, this.state.statusPosts.next_page_url.length);
            }else if(Object.entries(this.state.statusPosts).length >0 && !this.state.statusPosts.next_page_url){
                return;
            }
            else{
                url= `api/get_user_posts/${this.props.user.userName}`;
            }
            Axios.get(`${PROXY}/${url}`)
            .then(async(res)=>{
                if(res.data.success){
                    if(!this.mounted) return;
                    // console.log(res.data.data)
                    if(this.state.statusPosts.data){
                        const {data} = res.data.data;
                        res.data.data.data = [...this.state.statusPosts.data, ...data];
                        await this.setState({statusPosts:res.data.data});
                    }else{
                        if(!this.mounted) return;
                        await this.setState({statusPosts: res.data.data});
                        await this.props.loadPosts(res.data.data);
                        // console.log(this.state);
                    }
                    if(!this.mounted) return
                    await this.setState({fetching_post: false});
                }else{
                    if(!this.mounted) return
                    // console.log(res.data);
                    await this.setState({fetching_post: false});
                }
            })
            .catch(async(err)=>{
                if(!this.mounted) return
                // if(err) console.log(err)
                await this.setState({fetching_post: false});
            })
        }
    }

    /** This methods gets more post if there is next page */

    virtualScrolling=()=>{
        // console.log(`${document.body.scrollHeight}/${window.pageYOffset}/${window.innerHeight}/
        // ${document.body.offsetHeight}/${document.body.clientHeight}`);
        if(window.pageYOffset+window.innerHeight >= document.body.offsetHeight){
            this.getStatusPost();
        }
    }

    encodeId=(id)=>{
        try {
            return window.btoa(window.btoa(id));        
        } catch (e) {
            return
        }
    }

    render(){
        const url ="https://medillery.com/user/";
        return(
            <React.Fragment>
                {this.state.statusPosts.data && this.state.statusPosts.data.map((status) => (
                    <div key={status.id} onClick={()=>{}/*this.test(status.id)*/}>
                        <Box py={2} px={2} className="componentBg mt-2">
                            <div className="d-flex mr-2 mb-2" >
                                <Avatar alt={noImage} className="img-fluid mr-2"
                                    src={status.poster.profilePic?
                                        `${status.poster.profilePic}`:
                                        `${noImage}`
                                    }
                                />
                                <Box className="d-flex flex-column">
                                    <Typography className="font-weight-bold smallerText">{status.poster.userName}</Typography>
                                    <Typography variant="subtitle2" style={{ fontSize: '0.7rem' }}>
                                        { CHAT_LIKE_TIME(status.created_at) }
                                    </Typography>
                                </Box>
                            </div>
                            <div className="smallerText">
                                {status.description &&
                                    <div>
                                        {status.description.slice(0,100)}
                                        {status.description.length > 100 &&
                                            <Link to={`/user/${status.poster.userName}/post/${this.encodeId(status.id)}`}>read more</Link>
                                        }
                                    </div>
                                }
                            </div>
                            <div className="row my-1">
                            {status.status_files.length>0 && status.status_files.map((file, index)=>(
                                <div key={index} className="col-sm-12 col-md-6 p-0">
                                    <div className="card border-0">
                                        <div className="card-body p-0" style={classes.statusImageCard}>
                                            <Link  key={index} to={`/user/${status.poster.userName}/post/${this.encodeId(status.id)}`}>
                                                {file.type <3 ?
                                                    <img
                                                        id="statusImage"
                                                        alt={this.state.noImage}
                                                        className="img-fluid rounded cursorZoomIn"
                                                        style={classes.statusImageStyle}
                                                        src={`${file.name}`}
                                                        onClick={this.handleImageModal}
                                                    />:
                                                    <video 
                                                        style={classes.statusImageStyle}
                                                        // onTouchStart={this.prePlay}
                                                        // onTouchEnd={this.prePause}
                                                        onMouseOver={this.prePlay}
                                                        onMouseOut={this.prePause}
                                                        className='w3-round m-2'
                                                        id={`vid-${index}`}
                                                    >
                                                        <source src={`${file.name}`} type={`video/${file.name.slice(-3)}`} />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                }
                                            </Link>                
                                        </div>
                                        <div className="small rounded-pill" style={{marginTop: "-20px", width: '80px', paddingLeft: '5px', zIndex: 10, background: 'rgba(255, 255, 255, 0.3)'}}>                                     
                                            <FavoriteBorderIcon fontSize="small" className="cursorPointer" onClick={()=>{}} />
                                            <span className='mx-2'>
                                                {(file.likes ===0)?
                                                    '':
                                                    `${(file.likes > 1)?
                                                        `${file.likes} likes`:
                                                        `${file.likes} like` 
                                                    }`
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
                            <div>
                                {status.tag &&
                                    <div className='d-flex justify-content-center'>
                                        {status.tag.toString().split(',').map((tag, index)=>
                                            <div key={index} className='w3-tiny w3-light-grey p-1 btn rounded-pill mr-2'>
                                                {tag}
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                            <div className="d-flex small mb-2"> 
                                <div>
                                    {(status.likes ===0)?
                                        '':
                                        `${(status.likes > 1)?
                                            `${status.likes} likes`:
                                            `${status.likes} like` 
                                        }`
                                    }
                                </div>
                                <div className="ml-auto">
                                    {status.comment_count === 1?
                                        `${status.comment_count } Comment`:
                                        `${status.comment_count > 1?
                                            `${status.comment_count } Comments`:
                                            ''
                                         }`
                                        }{/*   &nbsp; 50 shares*/}
                                </div>                             
                            </div>
                            <Divider />
                            <div className="py-1 px-5 d-flex justify-content-between">
                                <div>
                                    <FavoriteBorderIcon fontSize="small" 
                                        className="cursorPointer" 
                                        onClick={()=>this.likeStatusPost(status.id)}
                                />
                                </div>
                                {/*<a to="#" className="text-decoration-none text-dark small">
                                    <ChatBubbleOutlineIcon fontSize="small" />
                                    <span className="ml-1">comment</span>
                                </a> */}
                                <OverlayTrigger
                                    trigger='click'
                                    placement='auto'
                                    overlay={
                                        <Popover id={`popover-positioned-auto`}>
                                            <Popover.Content className='px-5'>
                                                <FacebookShareButton url={`${url}${status.poster.userName}/post/${this.encodeId(status.id)}`}>
                                                    <FacebookIcon size={30} />
                                                </FacebookShareButton>
                                                <hr/>
                                                <TwitterShareButton url={`${url}${status.poster.userName}/post/${this.encodeId(status.id)}`}>
                                                    <TwitterIcon size={30} />
                                                </TwitterShareButton>
                                                <hr/>
                                                <WhatsappShareButton url={`${url}${status.poster.userName}/post/${this.encodeId(status.id)}`}>
                                                    <WhatsappIcon size={30} />
                                                </WhatsappShareButton>
                                                <hr/>
                                                <LinkedinShareButton url={`${url}${status.poster.userName}/post/${this.encodeId(status.id)}`}>
                                                    <LinkedinIcon size={30} />
                                                </LinkedinShareButton>
                                                <hr/>
                                                <TelegramShareButton url={`${url}${status.poster.userName}/post/${this.encodeId(status.id)}`}>
                                                    <TelegramIcon size={30} />
                                                </TelegramShareButton>
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >                                
                                    <span className="ml-1" style={{cursor:'pointer'}}>
                                        <ShareIcon fontSize="small" /> share
                                    </span>
                                </OverlayTrigger>
                            </div>
                            {/*commenter*/}
                            <div className="d-flex mr-2 mb-2 mt-3">
                            {/*
                                comment part with user icon
                                <Avatar alt={this.state.noImage} className="img-fluid mr-2" src={this.state.noImage} style={{width:'30px', height: '30px'}} />
                                <textarea rows="1" id={`${status.id}inputId`} className="form-control form-control-sm mr-1 my-auto rounded-pill" placeholder="comment"
                                    onChange={
                                    async(e)=>{ await this.setState({[e.target.id]: e.target.value});
                                        }}
                                ></textarea>
                                <button type="submit" className="btn btn-sm rounded-pill ml-auto my-auto customPrimaryBgColor" style={ classes.sendCommentBtn }
                                onClick={()=>this.commentOnStatus(status.id, this.state[`${status.id}inputId`], `${status.id}inputId` )}
                
                                >
                                    <ChevronRightIcon fontSize="small" />
                                </button>
                            */}
                            </div>
                            {status.comments.length>0 &&
                                <Box style={{overflow:'auto'}}>
                                {status.comments.map((comment, index)=>(
                                    <div className="media mb-2" key={index}>
                                        <Avatar alt={this.state.noImage} className="mr-2" 
                                            style={{width:'30px', height: '30px'}} 
                                            src={comment.profilePic?comment.profilePic:this.state.noImage}
                                        />
                                        <div className="media-body p-1 smallerText" style={classes.customCommentBg}>
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-column">
                                                <Link to={`/user/${comment.userName}`} className="text-decoration-none text-dark font-weight-bold mr-2">
                                                    { `@${comment.userName}` }
                                                </Link>
                                                {(comment.role === 1) &&
                                                    <Typography variant="subtitle2" className="text-muted ml-2" style={{ fontSize: '0.7rem' }}>
                                                        Graphics Designer
                                                    </Typography>
                                                }
                                                </div>
                                                <Typography></Typography>
                                                <Typography variant="subtitle2" className="text-muted ml-auto mr-3" style={{ fontSize: '0.7rem' }}>
                                                    {CHAT_LIKE_TIME(comment.created_at)}
                                                </Typography>
                                            </div>
                                            {comment.comment}
                                        </div>
                                    </div>
                                ))}
                                </Box>
                            }
                            <Box>   
                                {(status.comment_count > status.comments.length) &&
                                    <Link  className="text-decoration-none d-block text-center text-muted small"
                                        to={`/user/${status.poster.userName}/post/${this.encodeId(status.id)}`}
                                    >
                                        Show comments
                                    </Link>
                                }
                            </Box>
                        </Box>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}

const mapStateToProps=(state)=>{
	return{       
        user: state.userPageOwner,
        posts: state.userPagePost,
	}
}

const mapDispatchToProps=(dispatch)=>{
    return{
        loadPosts: (payload)=>dispatch(loadUSerPosts(payload))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NOAuthUserPost);

