import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import './../../TemplateStyle.css'
import ForwardIcon from '@material-ui/icons/Forward';
import { Link } from 'react-router-dom';


const classes = {
    headerBg: {
        borderRadius: '18px',
        backgroundColor: 'lightgrey',
    }
}
class TemplatesSublist extends Component {
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: ' ready made Graphic Design Templates' },
        templteSublist: [
            { title: 'Birthdays', image: 'birthdays', link: '/templates/graphics-design/birthday', status: '' },
            // { title: 'Trend', image: 'others', link: '/templates/edit/trend/one', status: '' },
            { title: 'Business', image: 'business-card', link: '/templates/graphics-design/business', status: '' },
            { title: 'Valentine', image: 'valentine', link: '/templates/graphics-design/valentine', status: ''},
            { title: 'Christmas', image: 'christmas', link: '/templates/graphics-design/christmas', status: ''},
            { title: 'New Year', image: 'newyear', link: '/templates/graphics-design/new-year', status: ''},
            { title: 'Politics', image: 'politics-one', link: '/templates/graphics-design/politics', status: '' },
            // { title: 'New Month', image: 'new-month', link: '', status: 'coming soon' },
            // { title: 'Easter', image: 'easter', link: '/templates/edit', status: 'coming soon' },
            // { title: 'Business Card', image: 'business-card', link: '/templates/edit', status: 'coming soon' },
            // { title: 'Book Cover', image: 'book-cover', link: '/templates/edit', status: 'coming soon' },
        ]
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row my-4" style={classes.headerBg}>
                        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-3"></div>
                        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-6 text-center text-dark py-4">
                            <Typography variant="h4" className="mb-2">{ this.state.subCategoryTitle.title }</Typography>
                            <Typography variant="subtitle2" className="font-weight-light mb-2">{ this.state.subCategoryTitle.subtitle }</Typography>
                            {/*<div>
                                <input type="text" className="form-control text-white rounded-pill faqSearchInput" placeholder="Search ready made templates" />
                            </div>*/}
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-3"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mb-4 py-4">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <Typography variant="h5" className="h5 text-center">Sub-category</Typography>
                            <div className="row justify-content-center py-4">
                                { this.state.templteSublist.map((template, index) => (
                                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 mb-4" key={index}>
                                        <div className="d-flex justify-content-center">
                                            <div  className='w3-display-container w3-animate-zoom w3-hover-opacity'>
                                                <Link to={ template.link }>
                                                    <img className="rounded mb-1"
                                                        height='200'
                                                        width='200'
                                                        src={ require('../../assets/images/website/template/graphics-design-images/'+template.image+'.png') } 
                                                        alt={template.title} 
                                                    />
                                                    <div className="w3-display-middle w3-display-hover"
                                                    >
                                                    <ForwardIcon className='w3-text-teal' fontSize='large' />
                                                    </div>
                                                </Link>                                            
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center px-3'>
                                            <Link to={template.link} 
                                                style={{width:'150px'}}
                                                className="nav-link sidebarText text-center py-1 badge-info
                                                w3-hover-shadow w3-animate-opacity text-white  ml-1 rounded-pill my-1 "
                                            >
                                                Explore
                                            </Link>
                                        </div>
                                        <Typography variant="body1" className="d-flex justify-content-center font-weight-bold">
                                            {template.title}
                                        </Typography>
                                        <Typography className="d-flex justify-content-center " variant="subtitle2">
                                            {template.status}
                                        </Typography>
                                    
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
// jquery.scrollLeft() to move the whole div left and right
// https://stackoverflow.com/questions/27313480/scroll-div-content-horizontally-using-left-and-right-arrow-in-jquery

export default TemplatesSublist;
