import React, {Component} from 'react';
import { Typography } from '@material-ui/core';
import './../../CSSs/Birthday/twelve/twelve.css';
import  * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import GDHeader from '../GDHeader';

class BirthdayEditTwelve extends Component{
  state = {
    subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
    isEditFinish: false,
    month: "FEB",
    day: "02",
    year: "2020",
    happyBirthday: "Happy Birthday",
    firstName: "Firstname",
    lastName: "Lastname",
    extraNote: "With love from Muhammed",
    celebrantImage: "5.jpeg",
  }

    changeBackground = (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      if(!file)  return;
      const slot= document.getElementById("imgSlot");
      if(!slot) return;
      slot.src = URL.createObjectURL(file);
    }
    handleMonth = (e) => {
        // console.log(e.target.value)
        this.setState({month: e.target.value});
    }
    handleDay = (e) => {
        // console.log(e.target.value)
        this.setState({day: e.target.value});
    }
    handleYear = (e) => {
        // console.log(e.target.value)
        this.setState({year: e.target.value});
    }
    handleExtraNote = (e) => {
        // console.log(e.target.value)
        this.setState({extraNote: e.target.value});
    }
    handleFirstName = (e) => {
        // console.log(e.target.value)
        this.setState({firstName: e.target.value});
    }
    handleLastName = (e) => {
        // console.log(e.target.value)
        this.setState({lastName: e.target.value});
    }
    handleFinishEdit = (e) => {
      e.preventDefault();
      this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
      e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
      htmlToImage
          .toJpeg(document.getElementById('parentContainer12'), { quality: 1.0, pixelRatio: 1 })
          .then(function(dataUrl) {
            var link = document.createElement("a");
            link.download = "medillery-template.jpeg";
            link.href = dataUrl;
            link.click();
          });          
  }

  downloadPNG=()=>{
      htmlToImage.toPng(document.getElementById('parentContainer12'), { quality: 1.0, pixelRatio: 1 })
      .then(function (dataUrl) {
          download(dataUrl, 'medillery-template.png');
      });
  }

  downloadNow=(e)=>{
      e.preventDefault();
      const value = e.target.value;
      if(value === 'PNG'){
          return this.downloadPNG();
      }else if(value==='JPEG'){
          return this.downloadJPEG();
      }
  }
  render() {
    return ( <div style={{marginTop: '90px'}}>           
      <GDHeader caty='birthday' />
  
      <div className="container">
          <div className="row mb-4 py-4">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="row py-4">
                      <div className="col-sm-12 col-md-7 col-lg-6 col-xl-6 mb-2">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div id='parentContainer12' className="parentContainer12">
                              <div className="celebrantImageDiv12">
                                <div className="celebrantImageWrapper12">
                                  <img 
                                    src={ require("../../../../assets/images/templates/heroes/5.jpeg") } 
                                    id='imgSlot' className="celebrantImage12" 
                                    alt="avatar" 
                                  />
                                </div>
                              </div>
                              <div className="bottomDiv12">
                                  <div className="leftDiv12">
                                      <div className="happyBirthday12">{this.state.happyBirthday}</div>
                                      <div className="celebrantName12">
                                          <div className="firstName12">{this.state.firstName}</div>
                                          <div className="lastName12">{this.state.lastName}</div>
                                      </div>
                                  </div>
                                  <div className="dateDiv12">
                                      <div className="month12">{this.state.month}</div>
                                      <div className="day12">{this.state.day}</div>
                                      <div className="year12">{this.state.year}</div>
                                  </div>
                              </div>
                              <div className="extraNote12">{this.state.extraNote}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5 col-lg-6 col-xl-6 mb-4">
                        {!this.state.isEditFinish?
                          <div>
                            <Typography variant="subtitle2">
                                Insert your details to customize the template to your taste.
                                Edit placeholders and change the colors.
                            </Typography>
                            <form>
                                <div className="form-group mt-3">
                                    <label>Image</label>
                                    <label className="form-control rounded-pill">
                                        <input 
                                            type="file" style={{display:'none'}} 
                                            className="form-control rounded-pill" 
                                            onChange={this.changeBackground}
                                        />
                                    </label>
                                </div>
                                <div className="form-group">
                                  <div className="d-flex">
                                    <div className="mr-2">
                                        <label>Month</label>
                                        <input 
                                          type="text" id="month" 
                                          value={this.state.month} onChange={ this.handleMonth } 
                                          className="form-control rounded-pill" 
                                        />
                                    </div>
                                    <div>
                                        <label>Day</label>
                                        <input 
                                          type="text" value={this.state.day} 
                                          onChange={ this.handleDay } 
                                          className="form-control rounded-pill" 
                                        />
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <div>
                                        <label>Year</label>
                                        <input 
                                          type="text" value={this.state.year} 
                                          onChange={ this.handleYear } 
                                          className="form-control rounded-pill" 
                                        />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="d-flex">
                                    <div className="mr-2">
                                        <label>First Name</label>
                                        <input 
                                          type="text" value={this.state.firstName}
                                          onChange={ this.handleFirstName } 
                                          className="form-control rounded-pill" 
                                        />
                                    </div>
                                    <div className="mr-2">
                                        <label>Last Name</label>
                                        <input 
                                          type="text" value={this.state.lastName} 
                                          onChange={ this.handleLastName } 
                                          className="form-control rounded-pill" 
                                        />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label>Extra Note</label>
                                  <textarea 
                                    value={this.state.extraNote} 
                                    onChange={ this.handleExtraNote } 
                                    className="form-control rounded-pill" 
                                  >
                                  </textarea>
                                </div>
                                <div className="">
                                    <button 
                                      className="btn btn-block rounded-pill customPrimaryBgColor" 
                                      onClick={ this.handleFinishEdit }
                                    > 
                                      <span className="small text-white">Insert Details</span>
                                    </button>
                                </div>
                            </form>
                          </div>:
                          <div className=" text-center">
                            <h4 variant="subtitle2" className="mr-2 my-4 w3-text-blue">
                              Your image is ready!
                            </h4>
                            <div className=' text-center d-flex justify-content-center'>
                              <div className="w-50 mb-3 ">
                                <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                <select 
                                  className="form-control form-control-sm rounded-pill"
                                  onChange={this.downloadNow}
                                >   
                                  <option>Choose Format</option>
                                  <option >PNG</option>
                                  <option >JPEG</option>
                                  {/*<option>PDF</option>*/}
                                </select>
                              </div>
                            </div>
                            <div className=" d-flex justify-content-center mt-2">
                              <button 
                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                onClick={ this.backToEdit }
                                > 
                                  <span className="small text-white">Back to edit</span>
                              </button>
                            </div>
                          </div>
                        }
                      </div>
                  </div>
              </div>
          </div>
        </div>                      
    </div>);
  }
}

export default BirthdayEditTwelve;
