import React, { Component } from 'react';
import { Avatar, Typography, Box, Input, FormControl, InputLabel, InputAdornment } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import AttachmentIcon from '@material-ui/icons/Attachment';
// import PanoramaIcon from '@material-ui/icons/Panorama';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import { noImage } from '../../global/generalMethods/general';
import { connect } from 'react-redux';
import Axios from 'axios';
import { PROXY } from '../../global/constants/action_types';
import { processHeader, loadStatusPost} from '../../global/action';

const classes = {
    chipBox: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        // padding: theme.spacing(0.5),
        margin: 0,
    },
    searchBtn: {
        color: '#F9FBFF',
        height: '40px',
    },
    roundedClass: {
      borderRadius: '18.5px',
    },
    customColor: {
        color: '#FA9347',
    },
    statusImageStyle: {
        maxHeight: '400px',
    }
}

class UpdateStatus extends Component {
    state = {
        addMoreTag:true,
        tagCollector:[],
        maximumTags:false,
        minimumUploadAltered: false,
        minimumUploadAlteredMessage: '',
        displayFiles:[],
        processingSubmit:false,
        postWentWrong:false,
        successful: false,
        
        chipData: [
            { id: 0, label: 'Angular' },
            { id: 1, label: 'jQuery' },
            { id: 2, label: 'Polymer' },
            { id: 3, label: 'React' },
            { id: 4, label: 'Vue.js' },
        ]
    }

    mounted;

    componentDidMount=async()=>{
        this.mounted = true;
        const description = document.getElementById('description');
        if(description) description.focus();
    }

    componentWillUnmount=()=>{
        this.mounted = false;
    }

    addTagToCollector=async(e)=>{
        e.preventDefault();
        if(this.state.tagCollector.length > 2){
            await this.setState({maximumTags: true});
            return;
        }
        const tagInput = window.document.getElementById('add_tag');
        if(tagInput.value.trim()){
            if(this.state.tagCollector.some(data=>data.payload.toLowerCase() === tagInput.value.trim().toLowerCase())){
                return;
            }
            await this.setState((state)=>{return {tagCollector: [...state.tagCollector,...[{index:state.tagCollector.length, payload: tagInput.value.trim()}]]}});
            // console.log(this.state.tagCollector)
            tagInput.value = '';
        }
    }
    removeTag=async(payload)=>{
        await this.setState((state)=>{return{tagCollector:[...state.tagCollector.filter(data=>data.payload !== payload)] }});
    }

    handleDelete = (chipToDelete) => {
        // console.log(chipToDelete)
        // setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
        this.setState({chipData: [...this.state.chipData.filter(chip => chip.id !== chipToDelete.id)]})
    };

    handleFileUploadChange=async(e)=>{
        e.preventDefault();
        const fileCollector = e.target.files;
        await this.setState({minimumUploadAltered:false});
        if(fileCollector.length > 4){
            await this.setState({minimumUploadAlteredMessage: 'Sorry, you can only upload maximum of Four files'})
            await this.setState({minimumUploadAltered:true});
            return;
        }
        const Temp = [];
        let storeSize=0;
        for (let i = 0; i < fileCollector.length; i++) {
            Temp.push(fileCollector[i]);
            // console.log(fileCollector[i].type)
            storeSize += fileCollector[i].size;
        }
        // console.log(storeSize);
        if(storeSize > 12582912){
            await this.setState({minimumUploadAlteredMessage: 'File(s) too large, maximimum of 12MB is allowed'})
            await this.setState({minimumUploadAltered:true});
            return;
        }
        await this.setState({displayFiles:Temp});
    }

    deleteFile=async(e)=>{
        e.preventDefault();
        const id = Number(e.target.id);
        const go = await window.confirm("Do you want to remove this file?");
        if(!go) return;
        // console.log(id)
        const collector = [...this.state.displayFiles];
        collector.splice(id, 1);
        if(collector.length < 1){
			const uploader = document.getElementById('icon-button-file');
			uploader.value = null;
		}
        await this.setState({displayFiles: collector});
        // console.log(this.state);
    }

    postStatus=async(e)=>{
        e.preventDefault();
        if(!this.state.processingSubmit){
            if(!this.mounted) return;
            if(this.state.displayFiles.length < 1 && !document.getElementById('description').value.trim()) return;
            
            await this.setState({successful: false});
            await this.setState({postWentWrong:false});
            const formMedia = new FormData();

            if(this.state.displayFiles.length > 0){
                for (let i = 0; i < this.state.displayFiles.length; i++) {
                    formMedia.append(`status_files[${i}]`, this.state.displayFiles[i]);
                }
            }
            let tag = '';
            if(this.state.tagCollector.length > 0){
                this.state.tagCollector.forEach(data=> tag += `${data.payload}, `);
            }
            tag= tag.trim();            
            formMedia.append('description', document.getElementById('description').value.trim());
            formMedia.append('tag', tag.slice(0, tag.length-1));
            await this.setState({processingSubmit: true})
            Axios.post(`${PROXY}/api/post_status/${this.props.participant.userName}`, formMedia, {headers:processHeader()})
            .then(async(res)=>{
                if(res.data.success){
                    if(!this.mounted) return;
                    // console.log(res.data.data);
                    await this.setState({displayFiles: []});
                    await this.setState({tagCollector: []}); 
                    document.getElementById('description').value = '';
                    const temp = Object.assign({}, this.props.initialStatusPosts);
                    const mergePost = [...res.data.data,...temp.data];
                    temp.data = mergePost;
                    this.props.fetchInitialStatusPosts(temp);
                    await this.setState({successful: true});
                    await this.setState({processingSubmit: false});
                }else{
                    if(!this.mounted) return;
                    await this.setState({postWentWrong:true});
                    await this.setState({processingSubmit: false});
                }

            })
            .catch(async(err)=>{
                if(!this.mounted) return;
                await this.setState({postWentWrong:true});
                await this.setState({processingSubmit: false});
            })

        }

    }

    
    render() {
        return (
            <div className="componentBg">
            <Typography variant="h6" className="mb-4">Status Update</Typography>
                {/*<form className="mt-4" onSubmit={ this.handleSubmit }>*/}
                <Box className="d-flex align-items-center mb-2">
                    <div className="mr-2">
                        <Avatar alt="avatar1" className="img-fluid" src={
                            this.props.participant.profilePic? `${this.props.participant.profilePic}`:
                            `${noImage}`
                        } />
                    </div>
                    <div className="update-status-group">
                      <textarea id='description' type="text" 
                        className="form-control rounded smallerText" 
                        style={{overflowY: 'hidden', height: ''}} 
                        placeholder="'What's happening"
                        maxLength='10000'
                    ></textarea>
                      <div className="btn btn-default searchIcon icon">
                            <input accept=".jpg, .jpeg, .png, .svg, .3gp, .avi, .mpeg, .mp4, .mkv, .gif" multiple
                                className="d-none" id="icon-button-file" type="file" 
                                onChange={this.handleFileUploadChange} 
                            />
                            <label htmlFor="icon-button-file">
                            <IconButton color="default" aria-label="upload picture" component="span">
                                <AttachmentIcon />
                            </IconButton>
                            </label>
                      </div>
                    </div>
                    {/*<textarea className="form-control d-block" placeholder="Describe your project" style={{borderRadius: '18.5px'}}></textarea>*/}
                </Box>
                <div className=" my-1">
                    {(this.state.displayFiles.length > 0) &&
                        <Box className="row justify-content-center mt-2">
                            {this.state.displayFiles.map((file, index)=>
                                <Box key={index} className="">
                                    {file.type.slice(0,5) === 'video' &&
                                        <video width="100" height="120" className='w3-round' id={index} style={{margin:'6px'}} onClick={this.deleteFile} controls>
                                            <source src={URL.createObjectURL(file)} type={file.type} />
                                            Your browser does not support the video tag.
                                        </video>
                                    }
                                    {file.type.slice(0,5) === 'image' &&
                                        <img alt=""  id={index} style={{margin:'6px'}} onClick={this.deleteFile}
                                            src={URL.createObjectURL(file)} width='100' height='120' className='w3-round'
                                        />
                                    }
                                </Box>
                            )}                            
                        </Box>
                    }
                    {this.state.minimumUploadAltered && 
                        <div className=' text-center text-danger'><small>{this.state.minimumUploadAlteredMessage}</small></div>
                    }
                </div>

                <Box className="d-flex align-items-end pl-5">
                    <div className="d-flex flex-column mr-1">
                    {this.state.tagCollector.length <3 &&
                        <div>
                            <Typography className="smallerText ">Add Tags </Typography>
                            <FormControl className='mx-2 mb-3'>
                                <InputLabel htmlFor="add_tag">Enter tag here</InputLabel>
                                <Input
                                type="text"
                                id="add_tag"
                                style={classes.textField}
                                endAdornment={
                                    <InputAdornment onClick={this.addTagToCollector} className='w3-tooltip' position="end">
                                        {this.state.addMoreTag &&
                                            <div>
                                            <AddCircleOutlinedIcon style={{cursor:'pointer', color: (this.state.tagCollector.length < 2)? 'teal':'orange'}}
                                            />
                                            {window.screen.availWidth > 768 && <span className="w3-text"><em><small>Click to add tag</small></em></span>}
                                            </div>
                                            
                                        }
                                    </InputAdornment>
                                }
                            />
                            </FormControl>
                        </div>
                        }
                        { this.state.tagCollector.length > 0 && 
                            <Paper elevation={0} component="ul" style={classes.chipBox} className="p-1 d-block border">
                                <span>Tags </span>
                                {this.state.tagCollector.map((data) => (
                                    <li className="mb-1" key={data.index}>
                                        <Chip
                                          // icon={icon}
                                          variant="outlined"
                                          size="small"
                                          label={data.payload}
                                          onDelete={() => this.removeTag(data.payload)}
                                          className="mx-1 my-auto"
                                        />
                                    </li>
                                ))}
                            </Paper>
                        }
                    </div>
                    
                    <div className="d-flex align-items-end ml-auto mr-2">
                        <button type="submit" onClick={this.postStatus} className="btn btn-sm rounded-pill ml-2 customPrimaryBgColor" style={ classes.searchBtn }>
                            <ChevronRightIcon />
                        </button>
                    </div>
                </Box>
                <div className='w3-container d-flex justify-content-center my-2'>
                    {this.state.postWentWrong &&
                        <div className='text-danger'><small>Oops! Something went wrong</small></div>
                    }
                    {this.state.successful &&
                        <div> Your post was successfully updated</div>
                    }
                    {this.state.processingSubmit &&
                        <div><div className="w3-spin spinner w3-circle "></div></div>
                    }
                </div>
                {/*</form>*/}
            </div>
        );
    }
}
const mapStateToProps=(state)=>{
    return{
        participant: state.participant,
        initialStatusPosts: state.initialStatusPosts,
        tt: state.ttt,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        fetchInitialStatusPosts:(payload)=>dispatch(loadStatusPost(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateStatus);


// ['jpg','gif', 'jpeg', 'png', 'svg', '3gp', 'avi', 'mpeg', 'mp4', 'mkv']