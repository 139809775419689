// @ts-nocheck
import React, {Component} from 'react';
import { Typography } from '@material-ui/core';
import './../../CSSs/NewYear/four/four.css';
import  * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import GDHeader from '../GDHeader';

class NewYearEditFour extends Component{
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        happyNewYearText: "Happy New Year",
        senderName: "Arike Fashion",
        extraNote: "May this new year bring you much joy and fun, May you find peace, love and success. Sending my heartiest new year wish for you!",
        celebrantImage: "8.jpeg",
    }

    changeBackground = (e) => {
        e.preventDefault();
        try {        
            const file = e.target.files[0];
            if(!file)  return;
                const slot = document.getElementById("imgSlot");
            if(!slot) return;
            slot.src= URL.createObjectURL(file);      
        } catch (e) {
            return;
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    handleFinishEdit = (e) => {
      e.preventDefault();
      this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
      e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
        .toJpeg(document.getElementById('parentContainer14'), { quality: 1.0, pixelRatio: 1 })
        .then(function(dataUrl) {
            var link = document.createElement("a");
            link.download = "medillery-template.jpeg";
            link.href = dataUrl;
            link.click();
        });          
    }

  downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('parentContainer14'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }

  downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }
    render() {
        return ( <div style={{marginTop: '90px'}}>           
            <GDHeader caty='new-year' />
    
            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-6 col-xl-6 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* overall container for the template */}
                                        <div id='parentContainer14' className="parentContainer14">
                                            {/* center div for the text and image */}
                                            <div className="centerImageTextDiv14">
                                                <h1 className="newYearText14">Happy New Year</h1>
                                                {/* the image div itself */}
                                                <div className="imageDiv14">
                                                    {/* the image element */}
                                                    <img 
                                                        src={ require("../../../../assets/images/templates/heroes/8.jpeg") } 
                                                        alt="image8" className="middleImage14" id="imgSlot" />
                                                </div>
                                                {/* the text div underneath the image */}
                                                <div className="underTextsDiv14">
                                                    {/* otherTexts/ christmas message element */}
                                                    <p className="otherTexts14">{this.state.extraNote}</p>
                                                    {/* <p className="otherTexts14">
                                                        May this new year bring you much joy and fun,
                                                        May you find peace, love and success. Sending my
                                                        heartiest new year wish for you!
                                                    </p> */}
                                                    {/* white rectangle text div */}
                                                    <p className="fromTexts14">From all of us at: <strong>{this.state.senderName}</strong></p>
                                                    {/* <p className="fromTexts14">From all of us at: <strong>Arike Fashion</strong></p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-6 col-xl-6 mb-4">
                                {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                        <div className="form-group mt-3">
                                            <label>Image</label>
                                            <label className="form-control rounded-pill">
                                                <input 
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeBackground}
                                                />
                                            </label>
                                        </div>
                                        <div className="form-group d-none">
                                            <label>New Year Text</label>
                                            <input 
                                                disabled
                                                type="text" id="happyNewYearText" 
                                                value={this.state.happyNewYearText} 
                                                onChange={ this.handleChange }
                                                className="form-control rounded-pill" 
                                            />
                                        </div>
                                        
                                        <div className="form-group">
                                            <div className="mr-2">
                                                <label>Sender Name</label>
                                                <input id="senderName"
                                                    type="text" value={this.state.senderName} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Extra Note</label>
                                            <textarea id="extraNote"
                                                value={this.state.extraNote} 
                                                onChange={ this.handleChange }
                                                className="form-control rounded-pill" 
                                            >
                                            </textarea>
                                        </div>
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                                > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4 variant="subtitle2" className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select 
                                            className="form-control form-control-sm rounded-pill"
                                            onChange={this.downloadNow}
                                            >   
                                                <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                            className="btn btn-block rounded-pill customPrimaryBgColor" 
                                            onClick={ this.backToEdit }
                                            > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>                      
        </div>);
    }
}

export default NewYearEditFour;

