import React, {Component} from 'react';
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import {Formik, Form,Field} from 'formik';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import './FormStyle.css';
import { participantInfo, is_login } from '../../global/action';
import { connect } from 'react-redux';
import { TOKES, PUT_BEFORE, PROXY } from '../../global/constants/action_types';
import { DEFLECTION } from '../../global/generalMethods/general';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

const recoverySchema = Yup.object().shape({
  password: Yup.string()
  .min(8, 'It should be minimum of 8 characters')
  .required('Required')
	.matches(/(^([a-zA-Z0-9_]){8,}$)/, "0-9 , _ , a-z are only accepted"),
});

const myBtn = {
	background: 'linear-gradient(90deg, #FA9347 -4.49%, #F07672 104.49%)',
  color: '#FFFFFF',
	textTransform: 'initial',
}

class GoogleRecovery extends Component{
  state = {
    modal: true,
    processing: false,
    somethingWentWrong: false,
  }

  componentDidMount=()=>{
    if(this.props.googleData.getAll('email').length < 1){
      return this.props.navigate("/")
    }
    const keyForm = window.document.getElementById('keyForm');
    if(keyForm) keyForm.focus();
  }

  submitSignIn =(value)=>{
    if(!value) return;
    if(!this.state.processing){
      this.setState({ processing: true});
      const {password} = value;
      if(this.props.googleData.getAll('email').length < 1){
        return this.props.navigate("/")
      }                
      const formValues = this.props.googleData;// formData from signup modal
      formValues.append('password', password);
      axios.post(`${PROXY}/api/register`, formValues)
      .then(res=>{
        if(res.data.success){
          this.setState({ processing: false});
          localStorage.MedToken = DEFLECTION(PUT_BEFORE,res.data.token,TOKES);
          const _participant = JSON.parse(window.atob(res.data.data))
          // Check if there is redirect search param then go to the page
          const redirect = this.props.searchParams.get("redirect");
          if(redirect){
            const url = new URL(redirect);
            url.searchParams.append("user_uuid", _participant.uuid)
            window.location.replace(url);
            return
          }
          this.props.participant(_participant);
          this.props.isLogin(true);
          if(this.props.goPage & this.props.goPage !== '/'){
            return this.props.navigate(this.props.goPage);
          }
          return this.props.navigate('/dashboard');
        } else {
          if(res.data.validationError){
            this.setState({validationError: res.data.validationError});
          }
          this.setState({ processing: false});
        }
      })
      .catch(err=>{
        this.setState({ processing: false});
        if(err && err.response){
          this.setState({somethingWentWrong: true});
        }
      })
    }
  }

  render(){
    return(
      <div style={{height:'100vh'}}>
        <Modal
          show={this.state.modal}
          onHide={()=>{}}
          size='md'
          centered
        >
          <Modal.Body className='p-2'>
            <div className='d-block' style={{position:'relative'}}>
              <Link 
                style={{position:'absolute', right:0}}
                className='text-decoration-none w3-teal w3-button w3-round-xxlarge w3-padding-small'
                to='/' 
              >   
                Back
              </Link>
            </div>
            <div className='d-flex mt-5 justify-content-center mb-4'>
              <h5> Please Set your Recovery key here</h5>
            </div>
            <Formik
              initialValues={{password: ''}}
              validationSchema={recoverySchema}
              onChange={this.handleChange}
              onSubmit={this.submitSignIn}
            >
              {({errors, touched, values})=> (
                <Form className='px-3'>
                  <Field 
                    className={touched.password && errors.password ?'notValid': 'Manually'}
                    required type="password" name='password' value={values.password}
                    id='keyForm'
                  />
                  {touched.password && errors.password ? 
                    (<small className="margin-reduce">{errors.password}</small>): null
                  }
                  <label className={touched.password && errors.password ?'redLabel': 'labelly'}>
                    <small>Password </small>
                  </label>
                  <Button type="submit" variant="contained" fullWidth style={ myBtn }>
                    <Typography variant="body1"> Continue </Typography>
                  </Button>
                  <div className='d-flex justify-content-center'>
                    {this.state.somethingWentWrong && 
                      <div className="w3-small text-danger"> Oops, something went wrong</div>
                    }
                  </div>											
                </Form>
              )}
            </Formik>
            <div className='d-flex justify-content-center mt-3'>                            
              <div>
                {this.state.processing && (<div className="w3-spin spinner w3-circle"></div>)}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps=(state)=>({
  googleData: state.googleSignUpParam,
  goPage: state.intendedPage,
})

const mapDispatchToProps=(dispatch)=>({
  participant: (payload)=>dispatch(participantInfo(payload)),
  isLogin: (payload)=>dispatch(is_login(payload))
})

const withRouter=Child=>props=>{
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	return(
    <Child 
    {...props} 
    searchParams={searchParams}
    navigate={navigate} 
    />
	)
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GoogleRecovery));