import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { loadUserPageOwner, participantInfo, loadSpecificUserPost, processHeader } from '../../../../global/action';
import AuthPostDetails from './AuthPostDetails';
import { PROXY, PUT_BEFORE, TOKES } from '../../../../global/constants/action_types';
import { EXTRACTOR } from '../../../../global/generalMethods/general';
import DashboardNavbar from '../../DashboardNavbar';
import Axios from 'axios';
import NotFound from '../../../../NotFound';
import NoAuthPostDetails from './NoAuthPostDetails';
import LazyLoader from '../../../../LazyLoader';
import HomePageNavbar from '../../../website/HomePageNavbar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const quitRequest = Axios.CancelToken.source();

const PostDetailsCover=({ participant, userPage, ...rest})=> {

    const {pathname} = useLocation();
    const navigate = useNavigate();
    const {id, details} = useParams();
    
    const mounted = useRef(true);
    // const [user, setUser] = useState(userPage);
    const [identity, setIdentity] = useState(participant);
    const [loadingParticipant, setLoadingParticipant] = useState(true);
    const [loadingUser, setLoadingUser] = useState(true);
    const [loadingPost, setLoadingPost] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [wentWrong, setWentWrong] = useState(false);
    // const [showType, setShowType] = useState(null);


    useEffect(() => {
        if(!userPage){
            const getUser=()=>{
                Axios.get(`${PROXY}/api/get_user/${details}`)
                .then(async(res)=>{
                    if(res.data.success){
                        await rest.loadUser(res.data.data);
                        mounted.current && await setLoadingUser(false);
                    }
                })
                .catch(async(err)=>{
                    if(err && err.response && err.response.status === 404){
                        await setNotFound(true);
                        await setLoadingUser(false);
                    }else if(err && err.response && err.response.status === 500){
                        await setWentWrong(true);
                        await setLoadingUser(false);
                    }
                })
            }
            getUser();
        }else{
            // setUser(userPage)
            setLoadingUser(false);
        }

        return () => { 
            // mounted.current = false 
        }
        // eslint-disable-next-line
    }, [userPage]);

    useEffect(()=>{
        if(Object.entries(participant).length < 1){
            const getParticipant=()=>{
                const processHeader=()=>{
                    const identifier = EXTRACTOR(PUT_BEFORE,localStorage.MedToken,TOKES)
                    if(!identifier || identifier.length < 50){
                        return false;                
                    }else{
                      return {Authorization: `Bearer ${identifier}`};
                    }
                }
                let auth = processHeader();
                if(!auth){
                    if(mounted.current){
                        return setLoadingParticipant(false);
                    }
                } 
                Axios.get(`${PROXY}/api/identify/XXPAXX`, {headers: auth, cancelToken: quitRequest.token})
               .then((res)=>{
                   if (res.data.success){
                       if(mounted.current){
                            rest.resetIdentity(JSON.parse(window.atob(res.data.data)));
                            mounted.current && setLoadingParticipant(false);
                       }
                   }
                }).catch(err=>{
                    return setLoadingParticipant(false);
                });
            }
            getParticipant();
        }else{
            if(participant.userName && participant.userName === details && !pathname.includes('/dashboard/enjoy')){
                return navigate(`/dashboard/post/${id}`);
            }
            setIdentity(participant);
            setLoadingParticipant(false);
            // console.log(participant);
        }
        return () => {
            quitRequest.cancel();
            mounted.current = false;
        }
        // eslint-disable-next-line
    }, [participant]);

    useEffect(()=>{
        if(loadingUser || loadingParticipant) return;

        const decode=(id)=>{
            try {
                return window.atob(window.atob(id))
            
            } catch (e) {
                setLoadingPost(false);
                setNotFound(true);
            }
        }
        const getNoAuthPost =()=>{
            Axios.get(`${PROXY}/api/get_no_auth_post/${details}/${decode(id)}`)
            .then(async(res)=>{
                if(res.data.success){
                    // console.log(res.data.data[0]);
                   await rest.loadSpecificPost(res.data.data[0]);
                //    await setShowType(2);
                   await setLoadingPost(false);
                }
            })
            .catch(async(err)=>{
                if(err && err.response && err.response.status === 404){
                    await setNotFound(true);
                }
            })
        }

        const getAuthPost=()=>{
            Axios.get(`${PROXY}/api/get_auth_post/${identity.userName}/${details}/${decode(id)}`,
                {headers:processHeader()}
            )
            .then(async(res)=>{
                if(res.data.success){
                    // console.log(res.data.data[0]);
                   await rest.loadSpecificPost(res.data.data[0]);
                //    await setShowType(1);
                   await setLoadingPost(false);
                }
            })
            .catch(async(err)=>{
                if(err && err.response && err.response.status === 404){
                    await setNotFound(true);
                }
            })

        }

        if(!identity.userName){
            getNoAuthPost();
        }else{
            if(identity.userName){
                getAuthPost();
            }
        }
        return ()=>{}
        // eslint-disable-next-line
    },[loadingUser,loadingParticipant])




    return(<div>
        {(loadingUser  || loadingPost) ?
            <div className='px-5 mt-5'>
                <LazyLoader />
            </div> :
            <div>
                { notFound?
                    <div><NotFound /></div>:
                    <div>
                        {wentWrong ?
                            <div>Oops! Something went wrong.</div>:
                            <div>
                                <div>
                                    {(Object.entries(identity).length < 1)?
                                        <HomePageNavbar />:
                                        <DashboardNavbar />
                                    }
                                </div>
                                <div>
                                {(Object.entries(identity).length < 1)?
                                    <NoAuthPostDetails pathId={id} />:
                                    <AuthPostDetails pathId={id} />
                                }
                                </div>                               
                            </div>
                        }
                    </div>
                }
            </div>
        }    
    </div>)




}





const mapStateToProps=(state)=>{
	return{
        participant: state.participant,
        userPage: state.userPageOwner,
	}
}

const mapDispatchToProps=(dispatch)=>{
    return{
        resetIdentity: (payload)=> dispatch(participantInfo(payload)),
        loadUser: (payload)=>dispatch(loadUserPageOwner(payload)),
        loadSpecificPost: (payload)=>dispatch(loadSpecificUserPost(payload)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PostDetailsCover)
