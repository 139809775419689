import React, {Component} from 'react';

class ErrorFeedBack extends Component{
    message=(error)=>{
        if(error) return Object.values(error)
    }
    render(){
        const error= this.props.errors;
        return(
            <React.Fragment>
                    {('email' in error) && <small> {this.message(error['email'])} <br/></small>}
                    {('password' in error) && <small>{this.message(error['password'])}<br/></small>}
                    {('firstName' in error) && <small>{this.message(error['firstName'])}<br/></small>}
                    {('lastName' in error) && <small>{this.message(error['lastName'])}<br/></small>}
                                
                      
            </React.Fragment>
        )
    }
}

export default ErrorFeedBack