import React from 'react';
import { connect } from 'react-redux';
import User from './User';
import { participantInfo, loadUserPageOwner } from '../../../../global/action';
import { useState } from 'react';
import { useEffect } from 'react';
import Axios from 'axios';
import { PROXY, PUT_BEFORE, TOKES } from '../../../../global/constants/action_types';
import { EXTRACTOR } from '../../../../global/generalMethods/general';
import DashboardNavbar from '../../DashboardNavbar';
import NotFound from '../../../../NotFound';
import LazyLoader from '../../../../LazyLoader';
import HomePageNavbar from '../../../website/HomePageNavbar';
import { useNavigate, useParams } from 'react-router-dom';


const UserCover=({participant, userPage, ...rest})=>{
    const {details} = useParams();
    // console.log({details, participant, userPage});

    const mounted = React.useRef(true);
    const navigate = useNavigate();

    // const [user, setUser] = useState(userPage);
    // const [identity, setIdentity] = useState(participant);
    const [loadingParticipant, setLoadingParticipant] = useState(true);
    const [loadingUser, setLoadingUser] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [wentWrong, setWentWrong] = useState(false);
    const [fetching, setFetching] = useState(false);

    useEffect(()=>{
        mounted.current = true;

        return () => {
            mounted.current = false;
        }
    })

    useEffect(() => {
        const getUser=()=>{
            if(fetching) return;
            setFetching(true);
            Axios.get(`${PROXY}/api/get_user/${details}`)
            .then(async(res)=>{
                if(res.data.success){
                    if(!mounted.current) return;
                    await rest.loadUser(res.data.data);
                    await setLoadingUser(false);
                }
            })
            .catch(async(err)=>{
                console.log({err});
                if(err.response && err.response.status === 404){
                    if(!mounted.current) return;
                    await setNotFound(true);
                }else if(err.response && err.response.status === 500){
                    if(!mounted.current) return;
                    await setWentWrong(true);
                }
            }).finally(async()=>{  
                if(!mounted.current) return;
                await setFetching(false);
                await setLoadingUser(false);
            })
        }             
        getUser();


        // eslint-disable-next-line
    }, [details]);



    useEffect(()=>{
        if(participant.userName){
            if(participant.userName.toLowerCase() === details.toLowerCase()){
                return navigate('/dashboard/profile', {replace:true});
            }
            if(mounted.current) setLoadingParticipant(false);
            return;
        }

        const processHeader=()=>{
            const identifier = EXTRACTOR(PUT_BEFORE,localStorage.MedToken,TOKES)
            if(!identifier || identifier.length < 50){
                return false;                
            }else{
                return {Authorization: `Bearer ${identifier}`};
            }
        }
        let auth = processHeader();
        if(!auth) return setLoadingParticipant(false);
        // fetch current user
        Axios.get(`${PROXY}/api/identify/XXPAXX`, 
            {headers: auth}
        )
        .then((res)=>{
            if (res.data.success){
                rest.resetIdentity(JSON.parse(window.atob(res.data.data)));
                if(mounted.current) setLoadingParticipant(false);
            }
        }).catch(err=>{
            console.log({err});
            if(mounted.current)  return setLoadingParticipant(false);
        });

        return () => {
            mounted.current = false;
        }
        // eslint-disable-next-line
    }, [participant])

    
    return(<div>
        {(loadingUser || loadingParticipant) ?
            <div className='px-5 mt-5'>
                <LazyLoader />
            </div> :
            <div>
                { notFound?
                    <div><NotFound /></div>:
                    <div>
                        {wentWrong ?
                            <div>Oops! Something went wrong.</div>:
                            <div>
                                <div>
                                    {participant.userName?
                                        <DashboardNavbar />:
                                        <HomePageNavbar />
                                    }
                                </div>
                                <div>
                                    <User /> 
                                </div>                               
                            </div>
                        }
                    </div>
                }
            </div>
        }        
   </div>)
}


const mapStateToProps=(state)=>{
	return{
        participant: state.participant,
        userPage: state.userPageOwner,
	}
}

const mapDispatchToProps=(dispatch)=>{
    return{
        resetIdentity: (payload)=> dispatch(participantInfo(payload)),
        loadUser: (payload)=>dispatch(loadUserPageOwner(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCover);