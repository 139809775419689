import React, { Component } from 'react';
import { Typography } from '@material-ui/core';

const classes = {
    headerBg: {
        borderRadius: '18px',
    }
}
class TermsAndConditions extends Component {
    state = {
        commitment: [
            'You are at least 13 years of age or the minimum legal age in your country',
            'We must not have previously disabled your account for violation of law or any of our policies.',
            'You will not share your password or give access to your account to others and you are responsible for the consequences of doing so.',
            'You will not share misleading or fraudulent information',
            'You will not violate someone else’s right including their intellectual property.',
            'You possess all the legal right to share any information shared using your account',
            'You will not share malicious code, virus or anything that could disable, overburden, or impair the proper working or appearance of our product.',
            'You will not attempt to harvest data from our product using automated means without prior permission from us.'
        ],
        liabilities: [
            'As much as we will continue to give our best to make our product safe for you, we do not direct what people post and we are not responsible for content shared by our users while using our product.',
            'While we work everyday to give you the best services, we do not guarantee that our product will be secure, safe or error-free  and we do not claim to have a perfect product, we hold no responsibility for damages incurred while using our product.'
        ],
        advertsAndPromos: [
            'We retain the rights to use all posts and completed projects on our products for advertisements and promotions.',
            'Our services may include advertisement products, links and services herein referred to as “Second Party Ads”. The use of such service is encouraged among users although it may involve service charge. When pricing and payment are involved, it will be quoted in naira. If you do not hold an account in this currency, you may use other payment option on Medillery. You should also know that, when debit/credit cards are used to make payment, We do not hold any of your card information, they are held by third party payment provider.',
            'We also make use of third parties ads, by using our product, you agree that we may share your information with these third parties.'
        ],
        yourProfile: [
            'All information in your profile are accurate',
            'Other users can see your profile.',
            'We can read, remove any content you post using our software if we have to or if they are harmful.'
        ],
        contactInformation: [
            'The contact information- such as email, phone number- you supplied to us will be used to identify and may be used to contact you if need be.',
            'Your contact information will be used to secure your account and we hold no responsibility for any loss, damages associated with the security breaches in your contact information.',
            'We may also send promotions, announcements and messages to the contact information you supplied to us.'
        ],
        transition: [
            'Your personal information will be transferred to the new entity',
            'This terms of service do not confer any third-party beneficiary rights on you'
        ],
        otherInfo: [
            'The original language of these Terms of Service, as well as all other texts throughout our product is English. In case of conflicts between the original English version and any translation, the English version shall prevail.',
            'We value and appreciate your feedbacks and suggestions but we are under no obligations to use them. In cases we use them, we are under no obligation to compensate you.',
            'Our policy may change from time to time, any changes we make to our policy will be posted on the website.',
            'We reserve all rights not expressly granted to you.',
            'You consent to and understand that Medillery is not liable for any consequential, punitive, incidental or exemplary damages of any sort culminating from your use of Medillery – this include such damages resulting from third party affairs. By using our product, you agree to this.'
        ]
    }
    render() {
        return (
            <div style={{marginTop: '90px'}}>
                <div className="container">
                    <div className="row customPrimaryBgColor my-4" style={classes.headerBg}>
                        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-3"></div>
                        <div className="col-sm-12 col-md-8 col-lg-8 col-xl-6 text-center py-4">
                            <Typography variant="h4" className="text-white mb-2">Terms And Condtions</Typography>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-3"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-1 col-lg-1 col-xl-2"></div>
                        <div className="col-sm-12 col-md-10 col-lg-10 col-xl-8">
                            <Typography variant="h5" className="sectionTitle">Terms of Service</Typography>
                            <div>
                                By using our product and services, you agree to all our Terms of Service. Your acceptance comes with adherence to our Privacy Policy. 
                            </div>
                            <Typography variant="h6">Your commitment</Typography>
                            <div>
                                <Typography variant="body1">By using our services, you agree that:</Typography>
                                <ul>
                                    {this.state.commitment.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>

                            <Typography variant="h6">Liabilities</Typography>
                            <div>
                                <ul>
                                    {this.state.liabilities.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>

                            <Typography variant="h6">Our Advertisements and Promotions</Typography>
                            <div>
                                <ul>
                                    {this.state.advertsAndPromos.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>

                            <Typography variant="h6">Your Profile</Typography>
                            <Typography variant="body1">By using our product, you agree that:</Typography>
                            <div>
                                <ul>
                                    {this.state.yourProfile.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>

                            <Typography variant="h5" className="sectionTitle text-uppercase">Contact Information</Typography>
                            <div>
                                <ul>
                                    {this.state.contactInformation.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>

                            <Typography variant="h6">Transition in our company</Typography>
                            <Typography>In case we go through business transition such as merger, bankruptcy, acquisition, reorganization, sale of all or part of our company or similar events.</Typography>
                            <div>
                                <ul>
                                    {this.state.transition.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>

                            <Typography variant="h6">Others</Typography>
                            <div>
                                <ul>
                                    {this.state.otherInfo.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>                            
                            <div>For enquiries and complaints, contact us on <strong>medillery@gmail.com</strong>, we will respond to your query.  </div>
                        </div>
                        <div className="col-sm-12 col-md-1 col-lg-1 col-xl-2"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TermsAndConditions;