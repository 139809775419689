import React, {Component} from 'react';
import { Avatar, Typography} from '@material-ui/core';
import StarsIcon from '@material-ui/icons/Stars';
import Axios from 'axios';
import "./projectDetails.scss"
import { PROXY } from '../../../global/constants/action_types';
import { Modal, Row, Col } from 'react-bootstrap';
import { fetchProfessionals, processHeader } from '../../../global/action';
import { ESTIMATE_TIME, LUX_ZONE, noImage, plainTimeZone } from '../../../global/generalMethods/general';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom'

const imageCard = {
  overflow: 'hidden',
  position: 'relative',
  background: 'rgba(0, 0, 0, 0.5)',
}

const quitRequest = Axios.CancelToken.source();
class DescriptionPart extends Component {
  state={    
    professionals:{},
    gettingProfessional:false,
    showProfessionals:false,
    selectedProfessionalName:null,
    professionalNameError: false,
    processing:false,
    reAssignWentWrong:false,
    reAssigning:false,
    reAssignedSuccessfully:false,
    modal:false,
    searching:false,
  }

  mounted
  componentDidMount=()=>{
    this.mounted = true;
  }
  
  componentWillUnmount=()=>{
    this.mounted = false;
    quitRequest.cancel();
  }
  UNSAFE_componentWillReceiveProps=async(next)=>{
    if(Object.entries(next.professionalList).length > 0){
      this.mounted && await this.setState({professionals: next.professionalList})
    }
  }
  gotoSubmit=(e)=>{
    e.preventDefault();
    try {                                            
      this.props.navigate(`/dashboard/projects/submit/${this.props.details}`);
      this.props.closeModal();
    } catch (error) {
      this.props.navigate(`/dashboard/projects/submit/${this.props.details}`);
      this.props.closeModal();
    }
  }

  acceptOrRejectProject=async(e,message)=>{
    e.preventDefault();
    if(this.state.processing) return;
    const form = new FormData();
    if(message === 'accept'){
      form.append('message', 'AccepT');
      form.append('code', 'A001')
    }else if(message ==='reject'){
      form.append('message', 'RejecT');
      form.append('code', 'R003')
    }
    form.append('allocation_id', this.props.allocation.id)
    await this.setState({processing:true});
    Axios.post(`${PROXY}/api/update_allocation_status/${this.props.participant.userName}`, form,
      {headers:processHeader(), cancelToken: quitRequest.token})
    .then(async(res)=>{
      if(res.data.success){
        const tempData = Object.assign({}, this.props.allocation);
        tempData.status = res.data.data
        if(!this.mounted) return;
        await this.props.resetAllocation(tempData);
        await this.setState({processing:false});
      }else{
        if(res.data.Error === 'Intruder'){
          window.localStorage.clear();
          return this.props.navigate('/');
        }
      }
    })
    .catch(async(_)=>{
      if(!this.mounted) return;
      await this.setState({processing:false});
    })
}

  getProfessionals =()=>{
    this.setState({gettingProfessional:true});
    let url;
    if(Object.entries(this.state.professionals).length>0 && this.state.professionals.next_page_url){
        const getUrl = this.state.professionals.next_page_url.indexOf('api');
        url = this.state.professionals.next_page_url.slice(getUrl, this.state.professionals.next_page_url.length);
    }else if(Object.entries(this.state.professionals).length>0 && !this.state.professionals.next_page_url){
        this.setState({gettingProfessional:false});
        return;
    }else{
      url = `api/get_specified_professionals/${this.props.participant.userName}/${this.props.allocation.category}/${this.props.allocation.subcategory}`;
    }

    Axios.get(`${PROXY}/${url}`, {headers:processHeader()})
    .then(async(res)=>{
      if(res.data.success){
        if(!this.mounted) return;
        await this.setState({showProfessionals: true});
        if(Object.entries(this.state.professionals).length>0){
          const tempData = [...this.state.professionals.data];
          const incomingData =[...res.data.data.data ];
          res.data.data.data =[...tempData,...incomingData];
          if(!this.mounted) return;
          await this.props.getProfessionals(res.data.data);
          await this.setState({gettingProfessional:false});
        }else{
          if(!this.mounted) return;
          await this.props.getProfessionals(res.data.data);
          await this.setState({gettingProfessional:false});
        }
      }else{
        if(res.data.Error === 'Intruder'){
          if(!this.mounted) return;
          window.localStorage.clear();
          return this.props.navigate('/');
        }
      }
    }).catch(err=>{
      if(!this.mounted) return;
      this.setState({gettingProfessional:false});
    })
  }

  clearTimeHolder=async()=>{
    await clearTimeout(this.timerHolder);
    if(!this.mounted) return;
    await this.setState({gettingProfessional:false});
  }

  showProfessionalModal=async(e)=>{
    e.preventDefault();
    if(!this.mounted) return;
    await this.setState({modal:true});
    await this.getProfessionals();
 }

  prepareSearchedProfessionals =async(e)=>{
    e.preventDefault();
    const searchedKey = e.target.value.trim();
    await this.clearTimeHolder();

    if(!searchedKey){
      if(!this.mounted) return;
      await this.setState({professionals: this.props.professionalList})
      await this.setState({gettingProfessional:false});
      return;
    }

    if(typeof(this.props.allocation.category) ==='number' && typeof(this.props.allocation.subcategory) ==='number'){
      const tempData = Object.assign({}, this.state.professionals);
      let matchedSearch;
      if(!this.mounted) return;
      await this.setState({gettingProfessional:true});
      if(tempData.data && tempData.data.length > 0){
        matchedSearch = await tempData.data.filter(data=>{
          return (
            data.userName.toLowerCase().includes(searchedKey.toLowerCase()) ||
            data.firstName.toLowerCase().includes(searchedKey.toLowerCase()) ||
            data.lastName.toLowerCase().includes(searchedKey.toLowerCase())
          )
        })
        if (matchedSearch && matchedSearch.length > 0){
          tempData.data = matchedSearch;
          if(!this.mounted) return;
          await this.setState({professionals: tempData});
          await this.setState({gettingProfessional:false});
          return;
        }
        else{
          if(!this.mounted) return;
          this.timerHolder=setTimeout((()=> this.getSearchedProfessionals(searchedKey)),2000);
        }
      }
      else{
        if(!this.mounted) return;
        if(searchedKey) this.timerHolder=setTimeout((()=> this.getSearchedProfessionals(searchedKey)),2000);
      }
    }
  }

  getSearchedProfessionals =(search)=>{
    if(!search.trim()) return;
    const url = `api/get_searched_professionals/${this.props.participant.userName}/
      ${this.props.allocation.category}/${this.props.allocation.subcategory}/${search}`
    Axios.get(`${PROXY}/${url}`, {headers:processHeader()})
    .then(async(res)=>{
      if(res.data.success){
        if(!this.mounted) return;
        await this.setState({showProfessionals: true})
        await this.setState({professionals: res.data.data});
        await this.setState({gettingProfessional:false});
      }else{
        if(res.data.Error === 'Intruder'){
          if(!this.mounted) return;
          window.localStorage.clear();
          return this.props.navigate('/');
        }
      }
    }).catch(err=>{
      if(!this.mounted) return;
      this.setState({gettingProfessional:false});
    })
  }

  getMoreProfessionals=()=>{
    let url;
    if(this.state.professionals.data && this.state.professionals.data.length > 0 && this.state.professionals.next_page_url){
      const getUrl = this.state.professionals.next_page_url.indexOf('api');
      url = this.state.professionals.next_page_url.slice(getUrl, this.state.professionals.next_page_url.length);
      Axios.get(`${PROXY}/${url}`, {headers:processHeader()})
      .then(async(res)=>{
        if(res.data.success){
          const incomingData =[...res.data.data.data ];
          const tempData = [...this.state.professionals.data];
          res.data.data.data =[...tempData,...incomingData];
          if(!this.mounted) return;
          await this.setState({professionals: res.data.data});
          await this.setState({gettingProfessional:false});
        }
      }).catch(err=>{
        if(!this.mounted) return;
        this.setState({gettingProfessional:false});
      })
    }
  }

  processProfessionalName=async(professionalName)=>{
    if(this.state.selectedProfessionalName){
      document.getElementById(`${this.state.selectedProfessionalName}`).style.background='white';
      document.getElementById(`${this.state.selectedProfessionalName}`).style.color='black';
    }
    if(!this.mounted) return;
    await this.setState({selectedProfessionalName: professionalName});
    await this.setState({professionalNameError: false});
    document.getElementById(`${this.state.selectedProfessionalName}`).style.background='black';
    document.getElementById(`${this.state.selectedProfessionalName}`).style.color='white';
  }

  processReAssignProject=async(status)=>{
    if(this.state.reAssigning) return;
    if(!this.state.selectedProfessionalName){
      if(!this.mounted) return; 
      await this.setState({professionalNameError: true});
      return;
    }
    await this.setState({reAssignWentWrong:false});
    let url;
    if(status === 3){
      url = `${PROXY}/api/reassign_allocation/${this.props.participant.userName}`;
    }else{
      url = `${PROXY}/api/handle_unaccepted_allocation/${this.props.participant.userName}`;
    }
    const form = new FormData();
    form.append('professional_name', this.state.selectedProfessionalName);
    form.append('allocation_id', this.props.allocation.id);
    await this.setState({reAssigning:true});
    Axios.post(url, form, {headers:processHeader() })
    .then(async(res)=>{
      if(res.data.success){
        // console.log(res.data.data);
        if(!this.mounted) return;
        const tempAllocation = Object.assign({}, this.props.allocation);
        tempAllocation.status = 5;
        await this.props.resetAllocation(tempAllocation);
        await this.setState({reAssigning:false});
        await this.setState({reAssignedSuccessfully:true});
      }
      else if(res.data.Error === 'Intruder'){
        if(!this.mounted) return;
        await this.setState({reAssignWentWrong:true});
        await this.setState({reAssigning:false});
      }
    })
    .catch(async(err)=>{
      if(!this.mounted) return;
      await this.setState({reAssignWentWrong:true});
      await this.setState({reAssigning:false});;
    })
  }

  estimateDueTime=(Created, dueTime)=>{
    const created_at = new Date(Created);
    const due_time = Number(dueTime)*3600000;
    return (((Date.parse(created_at)+due_time)-Number(Date.now()))/1000);
  }

  checkUnaccpetedDate=(date)=>{
    return Math.floor((Date.now() - Number(plainTimeZone(date)))/(1000*60*60*24));
  }

  isNeglectedAndDue=(status, date)=>{
    if((status === 0 || status === 4) && (date > 2)) return true;
    return false;
  }

  render(){
    const {allocation} = this.props
    return(<div>
     { (Object.entries(allocation).length) && <div>
      <div className="row mb-1">
        <Typography className="col-12 text-uppercase font-weight-bold">Project Details</Typography>
        <div className="col-12 my-1 smallerText">
            {allocation.description}
        </div>
        <div className="col-12 py-2">
          <div className='font-status-head'>Project status</div>
          { (allocation.owner) &&
            <div>
              {(allocation.status == 6) && <small>This project has been terminated</small>}
                {(allocation.status == 1) && <small> You have accepted this project</small>}
                {(allocation.status == 2) && <small> You have Completed this project</small>}
                {(allocation.status == 3 || allocation.status == 5) &&
                      <small> You have Rejected this project</small>
                }
                {(allocation.status == 0 || allocation.status == 4 ) &&
                  <div>
                    <small>Please Accept or Reject this project</small>
                    <br/>
                    <small>
                      Please note that if you neither accept nor reject the project within 3 days,
                      the client may assume it is rejected and there will be deduction in your
                      point by 1.
                    </small>
                  </div>
                }
              </div>
          }
          { (allocation.professional) &&
            <div>
              {(allocation.status == 6) && <small>This project has been terminated</small>}
                {(allocation.status == 1) && <small>Your project has been accepted</small>}
                {(allocation.status == 2) &&
                  <div>
                    <small>Your project has been completed.</small>
                    {allocation.rated == 0 && <small> Please rate to view the original</small>}
                  </div>
                }
                {(allocation.status == 3)&&
                  <small>Your project has been rejected, you can assign it to another professional</small>
                }
                {(allocation.status == 0 || allocation.status == 4 ) &&
                  <div>
                    {(this.checkUnaccpetedDate(allocation.created_at) < 3) ?
                      <small>The professional is yet to accept your project.
                        You can message him in the message box to increase notification chances
                      </small>:
                      <small> The professional is yet to accept your project.
                        We notice your project has not been accepted for too long, 
                        your can assign it to another professional now.
                      </small>
                    }
                  </div>
                }
                {(allocation.status == 5) &&
                  <small>This project had been assigned to another professional by you.</small>
                }
              </div>
            }
          </div>
        </div>
        {/* Green details panel */}
        <div className="row">            
          <div className="col-12">
            <div className="bg-success p-2 rounded small text-white">
              <div className="mb-2">
                <div className="text-uppercase">Created on :</div>
                <div className="text-capitalize">{LUX_ZONE(allocation.created_at)}</div>
              </div>
              <div className="mb-2">
                <div className="text-uppercase">Time Limit: </div>
                <div className="text-capitalize">{allocation.timeLimit} hrs</div>
              </div>
              {(allocation.status == 2 ) &&
                <div className="mb-2">
                  <div className="text-uppercase">Done on: </div>
                  <div>{LUX_ZONE(allocation.statusTime)}</div>
                </div>
              }
              {(allocation.status == 4 ) &&
                <div className="mb-2">
                  <div className="text-uppercase">Checked on:</div>
                  <div> {LUX_ZONE(allocation.statusTime)}</div>
                </div>
              }
              {(allocation.status == 3) &&
                <div className="mb-2">
                  <div className="text-uppercase">Rejected on:</div>
                  <div>{LUX_ZONE(allocation.statusTime)}</div>
                </div>
              }
              {(allocation.status == 1 ) &&
                <div>
                  <div className="mb-2">
                      <div className="text-uppercase">Project accepted on:</div>
                      <div> {LUX_ZONE(allocation.statusTime)}</div>
                  </div>
                  {(this.estimateDueTime(allocation.created_at, allocation.timeLimit) > 0)?
                    <div>
                      <div className="text-uppercase font-weight-bold">Due Time:</div>
                      <div>
                        {`${ESTIMATE_TIME(this.estimateDueTime(allocation.created_at, allocation.timeLimit))}`}
                      </div>
                    </div>:
                    <div className="text-center">
                      <div className="text-uppercase font-weight-bold">Out of Time:</div>
                      <div>{`${ESTIMATE_TIME(this.estimateDueTime(allocation.created_at, allocation.timeLimit))}`}</div>
                    </div>
                  }
                </div>
              }
              {(allocation.status == 5 && allocation.professional) &&
                <div className="mb-2">
                  <div className="text-uppercase">Reassigned on:</div>
                  <div>{LUX_ZONE(allocation.statusTime)}</div>
                </div>
              }
              {(allocation.status == 6) &&
                <div className="mb-2">
                  <div className="text-uppercase">Terminated on:</div>
                  <div>{LUX_ZONE(allocation.statusTime)}</div>
                </div>
              }
              {/* If current user is the owner */}
              {allocation.owner &&
                <div>
                  {allocation.status == 1 &&
                    <div className="text-center mt-2">
                      <button onClick={(e)=>{this.gotoSubmit(e)}}
                        className="btn btn-light btn-sm rounded-pill text-success"
                      >
                        Submit Project
                      </button>
                    </div>
                  }
                  {(allocation.status == 4 || allocation.status == 0)  &&
                    <div className="d-flex flex-column justify-content-center py-2">
                      <button className="btn btn-light btn-sm rounded-pill" 
                        onClick={(e)=>{this.acceptOrRejectProject(e,'accept')}}
                      > 
                        Accept Project
                      </button>
                      <div className="text-center py-2">Or</div>
                      <button className="btn btn-danger btn-sm rounded-pill" 
                        onClick={(e)=>{this.acceptOrRejectProject(e, 'reject')}}
                      > 
                        Reject Project
                      </button>
                    </div>
                  }
                </div>
              }
              {/* If current user is the owner */}
              {allocation.professional &&
                <aside>
                  {(allocation.status == 2 && allocation.rated == 0) &&
                    <button 
                      onClick={()=>{
                        this.props.closeModal();
                        try {
                          this.props.navigate(`/dashboard/projects/rate/${this.props.details}`)                                  
                        } catch (error) {                                  
                        }
                      }} 
                      className="btn btn-light btn-sm rounded-pill text-success"
                    >
                      Rate Project
                    </button>
                  }
                {/* Assign to another professional */}
                {((allocation.status == 3) || 
                  this.isNeglectedAndDue(allocation.status, this.checkUnaccpetedDate(allocation.created_at))
                  )  
                  &&
                  <div className="text-center py-2">
                    <button 
                      onClick={this.showProfessionalModal} 
                      className="btn btn-light btn-sm rounded-pill text-success"
                    >
                      <span className="small">Assign to another Professional</span>
                    </button>
                  {/* New professional modal, thsi should me modularized later */}
                  <Modal
                    show={this.state.modal}
                    size="md"
                    onHide={()=>{this.setState({modal:false})}}
                  >
                    <Modal.Header closeButton>
                    <Modal.Title className="d-flex justify-content-center">
                      <Row>
                        <Col xs={12} className="text center">
                          <Typography variant="h6">Tag a new Professional
                            { this.state.gettingProfessional &&
                              <div className='d-flex justify-content-center my-2'>
                                <div className='w3-spin spinner w3-circle mr-2'></div>
                                <span className='w3-small'>fetching professionals</span>
                              </div>
                            }
                          </Typography>
                        </Col>
                        </Row>
                      </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <input 
                            type="search" 
                            onKeyUp={this.prepareSearchedProfessionals} 
                            className="form-control rounded-pill mb-2" 
                            placeholder="Search for a professional" 
                          />
                        </div>
                        { (this.state.professionals.data && allocation.professional.userName)&&
                          <div>
                            {((this.state.professionals.data.length>0) && 
                              this.state.professionals.data.filter(pro=>pro.userName !== allocation.professional.userName).length > 0) ?
                              <div>
                                <Typography>Suggested</Typography>
                                {this.state.professionals.data.filter(pro=>pro.userName !== allocation.professional.userName)
                                  .map((data, index) => (
                                    <div 
                                      key={data.userName} 
                                      onClick={()=>{this.processProfessionalName(data.userName)}}
                                      className="d-flex align-items-center w3-round mb-1 smallerText w3-hover-pale-blue" 
                                      id={data.userName}
                                      >
                                      <div className="d-flex">
                                        <Avatar alt="avatar1" className="img-fluid mr-2" 
                                          src={data.profilePic?
                                            `${data.profilePic}`:
                                            `${noImage}`
                                          } 
                                        />
                                        <div className="d-flex flex-column">
                                          <Typography variant="subtitle1">@{ data.userName }</Typography>
                                          <Typography variant="subtitle2" className="font-weight-light">{`${ data.firstName} ${data.lastName}`}</Typography>
                                        </div>
                                    </div>
                                    <div className="ml-auto my-auto d-flex align-items-center">
                                      <span><StarsIcon fontSize="small" className="text-warning mr-1" /></span>
                                      <Typography variant="subtitle2" className="align-self-center pr-2">
                                        { data.ratings } points
                                      </Typography>
                                    </div>
                                  </div>
                                ))}
                                <div className='d-flex - justify-content-center'>
                                  {this.state.professionals.next_page_url &&
                                    <button 
                                      onClick={()=>{this.getMoreProfessionals()}}
                                      className="btn btn-block customPrimaryBgColor text-white rounded-pill"
                                    >
                                      More
                                    </button>
                                  } 
                                </div>
                              </div>:
                              <div className='text-center'>
                                <small>
                                  <b>Sorry, No professional found for this category or name right now.</b>
                                </small>
                              </div>
                            }
                            <div className='text-center my-1'>
                              {this.state.professionalNameError &&
                                <div className='text-danger'>
                                  <small>
                                    Please click on any professional to reassign your project
                                  </small>
                                </div>
                              }
                              {this.state.reAssignWentWrong &&
                                <div className='text-danger'>
                                  <small>
                                    Oops, something went wrong.
                                  </small>
                                </div>
                              }
                              {this.state.reAssigning &&
                                <div className='w3-text-teal w3-tiny '>
                                  <CircularProgress />
                                </div>
                              }
                              {(this.state.reAssignedSuccessfully && !this.state.professionalNameError) &&
                                <div className='w3-text-teal'>
                                  <small>
                                    Yor Project has been assigned to another professional successfully.
                                  </small>
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </Modal.Body>
                      <Modal.Footer>
                        <div className="text-center">
                          {((!this.state.reAssignedSuccessfully) && 
                            (this.state.professionals.data) &&
                            this.state.professionals.data.filter(pro=>pro.userName !== allocation.professional.userName).length > 0)&&
                            <button 
                              onClick={()=>{this.processReAssignProject(allocation.status)}}
                              className="btn btn-block customPrimaryBgColor text-white rounded-pill"
                            >
                            Assign Now
                            </button>
                          }
                          {(this.state.reAssignedSuccessfully && !this.state.professionalNameError) &&
                            <button 
                              onClick={()=>{this.setState({modal:false})}}
                              className="btn btn-block customPrimaryBgColor text-white rounded-pill"
                            >
                              Proceed
                            </button>
                          }
                        </div>
                      </Modal.Footer>
                      </Modal>
                  </div>
                }
              </aside>
              }
            </div>
          </div>
          {/*Project Attached file */}
          <div className="col-12 mb-2">
            <div className='d-flex my-2'>
              <Typography className="text-uppercase font-weight-bold mb-1">Attached Files</Typography>
            </div>
            {allocation.messages.filter(type=>type.type == 11).map(photo=>
              <a 
                key={photo.id} 
                target='_blank' 
                rel="noopener noreferrer" 
                href={`${photo.message}`}                           
                download
              >
                <img 
                  alt="image1" 
                  className="img-fluid w3-round m-2" 
                  height='100'
                  width='80'
                  style={imageCard} 
                  src={`${photo.message}`} 
                />
              </a>
            )}
            {(allocation.messages.filter(type=>type.type === 11).length < 1) &&
              <div className=''> No file attached</div>
            }                                             
          </div>
        </div>
        {allocation.status == 2 &&
          <div className=''>
            <h4><small><b>Completed Files</b></small></h4>
            <div className='mt-3'>
              <div>
                {( allocation.professional && allocation.rated == 0) &&
                   `Please rate the Project to view the original file(s)`}
              </div>
              <div className='d-flex mt-2 flex-wrap gap-10'>
                {allocation.messages.filter(type=>type.type == 21).map(file=>
                  <a  
                    key={file.id} 
                    className='shadow-sm card'
                    href={ allocation.owner?
                      file.message:
                      allocation.rated == 1?
                        file.message:
                        `${(file.message.replace(/nor__mal/ig, 'wa__tered'))}`
                    } 
                    download target='_blank' rel="noopener noreferrer"
                  >
                    <img width='200' height='180' alt="alt" 
                    src={ allocation.owner?
                      file.message:
                      allocation.rated == 1?
                        file.message:
                        `${(file.message.replace(/nor__mal/ig, 'wa__tered'))}`
                      }
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        }
      </div>}
    </div>)
  }
}
const mapStateToProps=(state)=>({
  participant:state.participant,
  professionalList: state.professionals
})

const mapDispatchToProps=(dispatch)=>({
  getProfessionals: (payload)=>dispatch(fetchProfessionals(payload))
})

const withRouter=Child=>props=>{
	const params = useParams();
	const navigate = useNavigate();
	return(
		<Child 
      {...props} 
      params={params} 
      navigate={navigate} 
		/>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DescriptionPart));