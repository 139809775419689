import React, { useEffect, useRef, useState } from "react";
import "./custom_modal.scss"

import closerArrow from "./../../assets/svg/arrow-left-short.svg"
import closerX from "./../../assets/svg/close.svg"

export  const CUstomModal=({
    trigger,
    closer,
    side="center",
    escape = true,
    maxW = "300px",
    gum,
    role,
    backgroundColorClass="bg-white",
    closeIcon = true,
    children
  })=>{
    const randStr = useRef(`modal-panel-${(Date.now()*Date.now()*Math.random()).toString(36)}`)

    const [closing, setCLosing] = useState(false)
    const [isCenter, setIsCenter] = useState(false)
    const [isFullOrCenter, setIsFullOrCenter] = useState(false)
    const [contentBoardStyle, setContentBoardStyle] = useState({})
    const [slotMaxHeight, setSlotMaxHeight] = useState({})
    

    const restoreStoreOverflow=()=>{      
      const activeMountedModals = document.querySelectorAll('.modal-style');
      if(activeMountedModals.length < 2) document.body.style.overflow = 'auto'; 
    }

    const closeModal=async()=>{
      await setCLosing(true)
      setTimeout(()=>{
        closer?.()
      }, 300)
    }  

    useEffect(()=>{
      console.log(trigger);
      if(!trigger){
        closeModal()
      }else{
        // document.body.innerText = 'flow';
        document.body.style.overflow = 'hidden';
        document.body.style.background = 'red';

        setCLosing(false)
      }
    },[trigger, closer])


    useEffect(()=>{
      setIsCenter(side == 'center')
      setIsFullOrCenter(['center', 'full'].includes(side))
      /** Compute max width of the modal content board*/
      setContentBoardStyle({
        maxWidth: ['left', 'right'].includes(side)? maxW :'100%'
      })
      /** Compute max height of the content slot on content board*/
      setSlotMaxHeight({
        maxHeight:  ['left', 'right', 'full'].includes(side)?
          `${window.innerHeight-50}px`: (side == 'center'? '88vh':'85vh')
      })
    },[side, maxW])  



    useEffect(()=>{
      /** Monitor click event to close modal if focused is not clicked*/
      const clicker=(e)=>{
        const modalPanel = document.getElementById(randStr.current);
        if(e.target == modalPanel){
          closeModal();
        }
      }
      /** MOnitor escape key press */
      const escaper=(e)=>{
        if(!gum && escape && e.key == "Escape"){
          closeModal();
        }
      }

      window.addEventListener('click', clicker);
      window.addEventListener('keydown', escaper);

      return ()=>{
        window.removeEventListener('click', clicker);
        window.removeEventListener('keydown', escaper);
        restoreStoreOverflow();
      }
    },[])

  return(<>

    {trigger && 
      <div id={randStr.current} role={role}
       className={`${isCenter?'flex-mid':''} fixed-page modal-style`}  
        > 
        {/*  Modal visible board */}
        <div style={contentBoardStyle}
          className={`${closing? side+'-leave' : side} ${backgroundColorClass}`}
        >   
          {/* Close Icon  */}
          { closeIcon &&
            <div className={`close-icon ${side}-close-icon`} >
              {isFullOrCenter?
                <img onClick={closeModal} width="20" alt="close-icon"
                    src={closerX} className="sq-icon"
                  />:
                <img onClick={closeModal} alt="close-icon" width="25" 
                    src={closerArrow} className="round-icon"
                />
              }
            </div> 
          }
          {/* <!-- Content Slot --> */}
          <div className="relative scroller" style={slotMaxHeight}>
            {children}
          </div>
        </div>
      </div>
    }
  </>
  )
}