import React from 'react';
import UserRoute from './UserRoutes';
import NotFound from '../../../NotFound';
import { useRoutes } from 'react-router-dom';

const CoverUserAndPost=()=>{
const userRoutes = useRoutes(UserRoute);

React.useEffect(() => window.scrollTo(0, 0));
return(<div style={{marginTop:'70px'}}>
        { userRoutes || <NotFound /> }
    </div>)
}

export default CoverUserAndPost;

