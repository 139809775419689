import React from 'react';
import ChatBox from './ChatBox';
import DesktopSideMap from './DesktopSideMap';
import { connect } from 'react-redux';
import DesktopChat from './DesktopChat';
import NotFound from '../../../NotFound';
import { somePageNotFound, loadPrivateChats, processHeader, loadDesktopPCs } from '../../../global/action';
import { PROXY } from '../../../global/constants/action_types';
import LazyLoader from '../../../LazyLoader';
import Axios from 'axios';
import { useParams } from 'react-router-dom';


// const usePrevious=(value)=> {
//   const ref = React.useRef();
//   React.useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }

const quitRequest = Axios.CancelToken.source();
const ChatCover=({ participant, ChatLists, pcChatList, somePageNotFound, ...rest})=>{

  const mounted = React.useRef(true);
  const {room} = useParams();

  const [inWidth, SetInWidth] = React.useState(window.innerWidth);
  const [notFound, setNotFound ] = React.useState(somePageNotFound)
  const [getList, setGetList] = React.useState(true);
  const [currentDecodedRoom, setCurrentDecodedDoom] = React.useState(null);
  
  React.useEffect(()=>{
    function tellScreenSize(){
      if(!mounted.current) return;
			SetInWidth(window.innerWidth);
		}
		window.addEventListener('resize', tellScreenSize);
		return ()=>{
      mounted.current = false
      window.removeEventListener('resize', tellScreenSize);
    };
  },[]);


  React.useEffect(()=>{
    if(!mounted.current) return;
		setNotFound(somePageNotFound);
		return ()=>{
      if(!mounted.current) return;
      setNotFound(false);
    };

    
  },[somePageNotFound]);

  React.useEffect(()=>{
    if(Object.entries(ChatLists).length < 1){
      const getPrivateChats=async()=>{
        if(!mounted.current) return;
        const url = `api/get_all_private_chats/${participant.userName}`;                
        Axios.get(`${PROXY}/${url}`, {headers:processHeader(), cancelToken: quitRequest.token})
        .then(async(res)=>{
          if(res.data.success){
            if(!mounted.current) return;
            if(inWidth < 768){
              // alert('mobile')
              rest.loadPrivateChats(res.data.data);
            }else{
              // alert('desktop')
              rest.loadDesktopPCs(res.data.data)
            }
            if(!mounted.current) return;
            setGetList(false);
          }
        })
        .catch(async(err)=>{
          if(!mounted.current) return;
          // setNotFound(true);
          setGetList(false);	
        })
      }
      getPrivateChats();
    }else{
      return setGetList(false);
    }
    return ()=>{
      mounted.current = false;
    }
    // eslint-disable-next-line
  },[ChatLists])


    
    

  
  return(<div className="container">
    {notFound?
      <div>
        <NotFound />
      </div>:
      <div>
        {!getList?
          <div>
            {(inWidth < 768)?
              <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mainDisplay px-0">
                  <ChatBox room={room} />
                </div>
              </div>:
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 sideNavStyle px-0" style={{height: '87vh'}}>
                  <DesktopSideMap room={room} decodeRoom={setCurrentDecodedDoom} />
                </div>
                <div className="col-sm-12 col-md-8 col-lg-9 col-xl-9 px-0 mainDisplay">
                  {currentDecodedRoom && 
                    <DesktopChat room={currentDecodedRoom}  />
                  }
                </div>
              </div>
            }
          </div>:
          <div>
            <LazyLoader />
          </div>
        }
      </div>
    }
  </div>)
}

const mapStateToProps=(state)=>{
	return{
    ChatLists: state.initialPrivateChats,
    pcChatList: state.desktopInitialPCs,    
    somePageNotFound: state.somePageNotFound,
    participant: state.participant,
	}
}

const mapDispatchToProps=(dispatch)=>{
	return{
    chatNotFound:(payload)=>dispatch(somePageNotFound(payload)),
    loadPrivateChats: (payload)=>dispatch(loadPrivateChats(payload)),
    loadDesktopPCs: (payload)=>dispatch(loadDesktopPCs(payload))
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(ChatCover);