import React, { Component } from 'react';
import '../../CSSs/Business/three/three.css';
import { Typography } from '@material-ui/core';
import download from 'downloadjs';
import * as htmlToImage from 'html-to-image'
import GDHeader from '../GDHeader';

class BusinessEditThree extends Component {
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        blackFriday: "Black Friday",
        dealsText: "Deals",
        logoText: "ISHOP",
        discountText: "50",
        extraNote: "on all purchases",
        salesPeriod: "November 1 - 30th",
        phoneNo: "0800 - 000 - 0000",
        productImage: "templateshoe.jpg",
        bColor: "#FFA800"
    }
    changeBackground=(e)=>{
        e.preventDefault();
        try {
            this.setState({bColor: e.target.value});
            let back = document.getElementById("firstHalf3");
            let periodColor = document.getElementById("salesPeriod3");
            let mynumbColor = document.getElementById("mynumber3");
            if(back){
                back.style.backgroundColor = `${this.state.bColor}`;
                periodColor.style.color = `${this.state.bColor}`;
                mynumbColor.style.color = `${this.state.bColor}`;
            } 
        } catch (e) {
            return;
        }
    }
    // changeBackground = (e) => {
    //     e.preventDefault();
    //     try {        
    //         const file = e.target.files[0];
    //         if(!file)  return;
    //         let back = document.getElementById("outSideDiv9");
    //         if(back){
    //             back.style.background = `linear-gradient(0deg, rgba(45, 22, 136, 0.8), rgba(45, 22, 136, 0.8)), url(${URL.createObjectURL(file)})`;
    //             back.style.backgroundSize = `100%`;
    //             back.style.backgroundRepeat = `no-repeat`;
    //             back.style.backgroundPosition = `center`;
    //         }
    //     } catch (e) {
    //         return;
    //     }
    // }
    
    changeProductImage = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const slot = document.getElementById('imgSlot');
        if(!slot) return;
        slot.src = URL.createObjectURL(file);
    }
    changeLogo = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const logo = document.getElementById('logoSlot');
        if(!logo) return;
        logo.src = URL.createObjectURL(file);
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }
    handleFinishEdit = (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('outSideDiv3'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
                var link = document.createElement("a");
                link.download = "medillery-template.jpeg";
                link.href = dataUrl;
                link.click();
            });          
    }
    
    downloadPNG=()=>{
        htmlToImage.toPng(document.getElementById('outSideDiv3'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }
    
    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }
    
    render() {
        return (<div style={{marginTop: '90px'}}>
            <GDHeader caty='business' />
            
            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* The cover/parent container */}
                                        <div className="outSideDiv3" id="outSideDiv3">
                                            {/* the yellow background container upwards */}
                                            <div className="halfDivs3 firstHalf3" id="firstHalf3">
                                                {/* the title and text container at the left side */}
                                                <div className="divHalf3 leftPart3">
                                                    {/* the title/logo container/canvas */}
                                                    <div className="titleCanvas3 text-center">
                                                        {/* the logo */}
                                                        <div className="yourLogo3">
                                                            <img id="logoSlot" src={require("../../../../assets/images/img.png")} 
                                                                alt="living Room" className="logoImage3" />
                                                        </div>
                                                    </div>
                                                    {/* the text div container */}
                                                    <div className="biggyText3">
                                                        <h1>{this.state.blackFriday} <div>{this.state.dealsText}</div></h1>
                                                        
                                                    </div>
                                                </div>
                                                {/* the Image right-sided container div*/}
                                                <div className="divHalf3 rightPart3">
                                                    {/* the white canvas for the images */}
                                                    <div className="whiteCanvas3">
                                                        {/* the image */}
                                                        <img 
                                                        src={require("../../CSSs/Business/assets/templateshoe.jpg")} 
                                                        alt="shoe" className="templateImage3" id="imgSlot"/>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Dark-colored lower container div */}
                                            <div className="secondHalfDivs3">
                                                <div className="secondFirstHalf3">
                                                    {/* the discount circle container */}
                                                    <div className="otherContainer3">
                                                        {/* the text inside the discount container */}
                                                        <div className="redCircles3 textCircle3">
                                                            <p>{this.state.discountText}<sup>%</sup></p>
                                                        </div>
                                                        {/* the clipped deep-red circle */}
                                                        <div className="redCircles3 clipCircle3"></div>
                                                    </div>

                                                    <div className="otherTexts3">
                                                        <p>Get up to a whooping</p>
                                                        <p className="discount3">DISCOUNT</p>
                                                        <p className="pSecond3">on all purchases</p> 
                                                        {/* the period of sale text */}
                                                        <p>from <span id="salesPeriod3">{this.state.salesPeriod}</span></p>
                                                    </div>
                                                </div>
                                                <div className="secondSecondHalf3">
                                                    <p className="calltitle3">Call/Whatsapp</p>
                                                    <p className="mynumber3" id="mynumber3">{this.state.phoneNo}</p>
                                                    <p className="placeOrder3">to place your order</p>
                                                    <p className="footerLink3"><small>www.medillery.com/templates</small></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-4">
                            {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                        <div className="form-group mt-3">
                                            <label>Background Color</label>
                                            <label className="form-control rounded-pill">
                                                <input 
                                                    type="color" style={{display:'none'}} 
                                                    value={this.state.bColor}
                                                    onChange={this.changeBackground}
                                                    className="form-control rounded-pill" 
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label>Image</label>
                                            <label className="form-control rounded-pill">
                                                <input 
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeProductImage}
                                                />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label>Logo</label>
                                            <label className="form-control rounded-pill">
                                                <input
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeLogo}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group d-flex">
                                            <div className="mr-1">
                                                <label>Black Friday</label>
                                                <input
                                                    disabled
                                                    type="text" id="blackFriday"
                                                    value={this.state.blackFriday} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div className="mr-1">
                                                <label>Deals</label>
                                                <input id="dealsText"
                                                    type="text" value={this.state.dealsText} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div>
                                                <label>Discounts</label>
                                                <input id="discountText"
                                                    type="text" value={this.state.discountText}
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="form-group d-flex">
                                            <div className="mr-2">
                                                <label>Sales Period</label>
                                                <input 
                                                    type="text" id="salesPeriod"
                                                    value={this.state.salesPeriod}
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div>
                                                <label>Phone Number</label>
                                                <input 
                                                    type="text" id="phoneNo"
                                                    value={this.state.phoneNo} 
                                                    onChange={ this.handleChange }
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                            > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4 className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select className="form-control form-control-sm rounded-pill"
                                                onChange={this.downloadNow}
                                            >   <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                        className="btn btn-block rounded-pill customPrimaryBgColor" 
                                        onClick={ this.backToEdit }
                                        > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>);
    }
}
// jquery.scrollLeft() to move the whole div left and right
// https://stackoverflow.com/questions/27313480/scroll-div-content-horizontally-using-left-and-right-arrow-in-jquery

export default BusinessEditThree;
