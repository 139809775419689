import {
    PARTICIPANT, CATEGORY, SEARCH_PROS, GOOGLE_SIGN_UP_DATA, RELATIONSHIP,
    PUT_BEFORE, TOKES, LOADED_FOLLOWERS, LOADED_FOLLOWINGS, LOAD_PRIVATE_CHATS,
    PROXY, INITIAL_STATUS_POST, INITIAL_ALLOCATION, ALLOCATION_TO_DISPLAY, IS_LOGIN,
    SELECTED_ALLOCATION_ID, SOME_PAGE_NOT_FOUND, MARK_SELECTED_CHAT,
    FETCHED_ENJOY_FILES, NOTIFICATION_COLLECTOR, NOTIFICATION_COUNT, ENJOY_SEARCH_VALUE,
    BECOME_PROFESSIONAL_MODAL, PERSONAL_DETAILS, USER_PAGE_OWNER, USER_PAGE_POST,
    TEMPORARY_NO_AUTH_ALLOCATION_DATA, DISPLAY_NO_AUTH_TAG_PROFESSIONAL,
    LOAD_GENERAL_SEARCH_RESULT, SPECIFIC_USER_POST, SHOW_LOGIN_MODAL, SHOW_SIGNUP_MODAL,
    SHOW_AUTH_MODAL, USER_FOLLOWERS, USER_FOLLOWINGS, CHECK_RELATIONSHIP, INTENDED_PAGE,
    LOAD_DESKTOP_PRIVATE_CHATS, LOAD_PC_DETAIL_CHANGES, LOADING_ALLOCATION_FILTERED,
    TICKET_NOT_FOUND,  COVER_WENT_WRONG
} from './../constants/action_types';
import Axios from 'axios';
import { EXTRACTOR } from '../generalMethods/general';

export const participantInfo = (payload) =>  ( {type:PARTICIPANT, payload } );
export const fetchCategories = (payload) => ({type: CATEGORY, payload});
export const fetchProfessionals = (payload)=> ({type: SEARCH_PROS, payload});
export const googleSignUpData = (payload) => ({type:GOOGLE_SIGN_UP_DATA, payload});
export const relationships = (payload) => ({type: RELATIONSHIP, payload});
export const loadedFollowers = (payload) =>  ({type:LOADED_FOLLOWERS, payload});
export const loadedFollowings = (payload) =>  ({type:LOADED_FOLLOWINGS, payload});
export const is_login= (payload) =>  ({type:IS_LOGIN, payload});
export const loadStatusPost= (payload) =>  ({type:INITIAL_STATUS_POST, payload});
export const loadAllocation= (payload) =>  ({type:INITIAL_ALLOCATION,  payload});
export const allocationToDisplay= (payload) =>  ({type:ALLOCATION_TO_DISPLAY, payload});
export const loadingAllocation= (payload) =>  ({type: LOADING_ALLOCATION_FILTERED, payload});
export const loadPrivateChats= (payload) =>  ({type:LOAD_PRIVATE_CHATS, payload});
export const loadDesktopPCs= (payload) =>  ({type:LOAD_DESKTOP_PRIVATE_CHATS, payload});
export const loadPcDetailChanges= (payload) =>  ({type:LOAD_PC_DETAIL_CHANGES, payload});
export const selectedAllocationId= (payload) =>  ({type:SELECTED_ALLOCATION_ID, payload});
export const somePageNotFound = (payload)=> ({type:SOME_PAGE_NOT_FOUND, payload});
export const markSelectedChat = (payload)=> ( {type:MARK_SELECTED_CHAT, payload});
export const loadEnjoyFiles = (payload)=> ({type:FETCHED_ENJOY_FILES, payload});
export const loadNotifications= (payload) =>  ({type:NOTIFICATION_COLLECTOR, payload});
export const notificationsCounter = (payload) => ({type:NOTIFICATION_COUNT, payload});
export const enjoySearchValue = (payload) => ({type:ENJOY_SEARCH_VALUE, payload});
export const becomeProfessionalModal = (payload) => ({type:BECOME_PROFESSIONAL_MODAL, payload});
export const loadPersonalDetails = (payload) => ({type:PERSONAL_DETAILS, payload});
export const loadUserPageOwner = (payload) => ({type: USER_PAGE_OWNER, payload});
export const loadUSerPosts= (payload) =>  ({type: USER_PAGE_POST, payload});
export const loadSpecificUserPost= (payload) =>  ({type: SPECIFIC_USER_POST, payload});
export const temporaryNoAuthAllocationData= (payload) =>  ({type: TEMPORARY_NO_AUTH_ALLOCATION_DATA, payload});
export const displayNoAuthTagProfessional= (payload) =>  ({type: DISPLAY_NO_AUTH_TAG_PROFESSIONAL, payload});
export const loadGeneralSearchResult=(payload) => ({type: LOAD_GENERAL_SEARCH_RESULT, payload});
export const showLoginUpModal=(payload) => ({type: SHOW_LOGIN_MODAL, payload});
export const showSignupModal= (payload) =>  ({type: SHOW_SIGNUP_MODAL, payload});
export const showAuthModal= (payload) =>  ({type: SHOW_AUTH_MODAL, payload});
export const loadUserFollowers = (payload) =>  ({type: USER_FOLLOWERS, payload});
export const loadUserFollowings = (payload) => ({type:USER_FOLLOWINGS, payload});
export const loadRelationship = (payload) => ({type: CHECK_RELATIONSHIP, payload});
export const intendedPage = (payload) => ({type: INTENDED_PAGE, payload});
export const ticketNotFound = (payload) => ({type: TICKET_NOT_FOUND, payload});
export const coverWentWrong = (payload) => ({type: COVER_WENT_WRONG, payload});


const origin = window.location.origin;

export const processHeader=()=>{
   const identifier = EXTRACTOR(PUT_BEFORE,localStorage.MedToken,TOKES)
   if(!identifier || identifier.length < 50){
       window.localStorage.clear();
       return window.location.replace(origin);
   }else{
     return {Authorization: `Bearer ${identifier}`};
   }
}
export const reGetParticipant=()=>{
   return dispatch=>{
       Axios.get(`${PROXY}/api/identify/XXPAXX`, {headers:processHeader()})
       .then(res=>{
           if (res.data.success){
               dispatch(is_login(true));
               return dispatch(participantInfo(JSON.parse(window.atob(res.data.data))));
           }else{
               if(res.data.message === 'xxx'){
                   localStorage.clear();
                    return window.location.href = origin+'/review';
               }
               window.location.href = origin
            }
        }).catch(err=>{
            window.location.href = origin
        })
   }
}

export const getAllCategory = ()=>{
   return dispatch =>{
       Axios.get(`${PROXY}/api/get_category` , {headers:processHeader()})
       .then(res=>{
           if (res.data) return dispatch(fetchCategories(res.data));
           return null;
       })
       .catch(err=>{console.log(err)})
   }
}
