import React from "react";
import Axios from "axios";
import { PROXY } from "../global/constants/action_types";

const DisplayTest = () => {
  const takeFile = React.useRef([]);
  const [file, setFile] = React.useState([]);

  const [forms, setForms] = React.useState(new FormData());

  const upload = e => {
    e.preventDefault();
    const coll = e.target.files;
    const form = new FormData();
    if (coll.length < 1) return;
    takeFile.current=[];
    for (let i = 0; i < coll.length; i++) {  
            takeFile.current.push(coll[i]);
            form.append(`norm[${i}]`, coll[i]);
        }
        setFile(takeFile.current);
        setForms(form)
  };

    const submit=(e)=>{
        e.preventDefault();
        Axios.post(`${PROXY}/api/files`, forms)
        .then(res=>{
            console.log(res.data)
        }
        )
        .catch(err=>{

            if(err) console.log(err)
        }
        )
    }


  

    return (<div className='d-flex justify-content-center'>        
        <div className='d-block'>
            <input multiple type="file" accept="images/*" onChange={upload} />
        </div>
        <div className='d-block'>
            {file.map((file, key) => (
            <div key={key}>
                <img
                alt="noImage"
                width="100"
                height="100"
                src={URL.createObjectURL(file)}
                />
            </div>
            ))}
        </div>
        <div className='d-block'><button onClick={submit}>seeeee</button></div>
        
    </div>);
};

export default DisplayTest;
