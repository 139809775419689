import React from 'react';
import { connect } from 'react-redux';
import { Typography, Box, Button } from '@material-ui/core';
import { showSignupModal, showAuthModal } from '../../../global/action';


const CreatorsCommunity = ({ setShowSignUp, setAuthModal }) => {
  return (
    <Box py={5} mb={5} className="communityBackground">
      <Box className="container">
        <Box className="row my-5">
          <Box className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
            <Box className="mx-2">
              <Typography variant="h4" className="mb-2 sectionTitle font-weight-bold">
                Join Our Community of Creators
              </Typography>
              <Typography variant="subtitle2" className="mb-3 text-light font-weight-light">
                Showcase your creativity on Medillery while also making money from your creations
              </Typography> 
              <Box className="text-xs-center">
                <Button variant="contained" disableElevation 
                  className="customSecondaryBgColor rounded-pill text-white text-capitalize"
                  onClick={()=>{setShowSignUp(true); setAuthModal(true);}}
                >
                  Become a Creator
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className="col-sm-12 col-md-6 col-lg-6 col-xl-6 hideSm">
            <Box className="d-flex justify-content-center">
              <Box className="row mx-auto">
                <Box className="col-6 mx-auto libraryCard p-1">
                  <Box style={{width: '15rem', height:'15rem', border: '4px solid #C3FFD3', borderRadius:'50%'}}>
                    <img className="" style={{marginTop: '3.7rem', marginLeft: '3.8rem'}} 
                      src={ require('../../../assets/images/website/homepage/creator-medillery.png') } 
                      alt="medillery" />  
                  </Box>
                </Box>
                <Box className="" style={{position: 'absolute'}}>
                  <img className="creatorImage" style={{top: '-2.2rem', left: '7.4rem'}} 
                    src={ require('../../../assets/images/website/homepage/creator-two.png') } 
                    alt="users" />
                  <img className="creatorIcon" style={{top: '-1.2rem', left: '15.5rem'}} 
                    src={ require('../../../assets/images/website/homepage/creator-finance-icon.png') } 
                    alt="icon" />
                  <img className="creatorIcon" style={{top: '3.8rem', left: '6rem'}} 
                    src={ require('../../../assets/images/website/homepage/creator-share-icon.png') } 
                    alt="icon" />
                  <img className="creatorImage" style={{top: '3.4rem', left: '16.6rem'}}  
                    src={ require('../../../assets/images/website/homepage/creator-one.png') } 
                    alt="users" />
                  <img className="creatorImage" style={{top: '7.2rem', left: '1.8rem'}}
                    src={ require('../../../assets/images/website/homepage/creator-three.png') } 
                    alt="users" />
                  <img className="creatorIcon" style={{top: '8.8rem', left: '14.6rem'}} 
                    src={ require('../../../assets/images/website/homepage/creator-chart-icon.png') } 
                    alt="icon" />
                  <img className="creatorIcon" style={{top: '14.2rem', left: '5.5rem'}} 
                    src={ require('../../../assets/images/website/homepage/creator-upload-icon.png') } 
                    alt="icon" />
                  <img className="creatorImage" style={{top: '12.5rem', left: '11.8rem'}} 
                    src={ require('../../../assets/images/website/homepage/creator-four.png') } 
                    alt="users" />
                </Box>

              </Box>
            </Box>
              
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowSignUp: (payload) => dispatch(showSignupModal(payload)),
    setAuthModal: (payload) => dispatch(showAuthModal(payload)),
  };
};

export default connect(null, mapDispatchToProps)(CreatorsCommunity);
