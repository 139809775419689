import React from "react";
import { SystemUpdateAltTwoTone, BuildRounded } from "@material-ui/icons";

const containerStyle={
  height: "60vh",
  width: "100%",
  padding: "10px 2% 10px 2%",
  border: "1px solid rgba(0,0,0,0.08)",
  borderRadius: "10px",
  boxShadow: "0.2px 0.9px 6px 0.8px rgba(0,0,0,0.1)"
}

export const TagProfessionalMaintenance=()=>{
  return(<section className="mb-4 bg-primary" style={containerStyle}>
    <h3 className="text-center text-white font-weight-bold py-2">
      <SystemUpdateAltTwoTone fontSize="large" /> System upgrade!
    </h3>
    <h5 className="pl-sm-5 mt-4 mb-3 text-white">
      You want to assign a project to a professional?
    </h5>
    <div className="px-sm-5 mt-2"
      style={{fontSize:"18px", color:"rgba(255,255,255, 0.8)"}}
    >
      <div >
        Sorry, we are undergoing a <BuildRounded /> system upgrade to give you a better experience.
      </div>
      <div className="my-2" >
        This may linger for a while.
      </div>
      <div>
        Kindly check back.
      </div>
    </div>
  </section>)
}