import React, { Component } from 'react';
import { connect } from 'react-redux';import { Link } from 'react-router-dom';
;

const classes = {
  enjoyImage: {
    width: '100%',
    minHeight: '100%',
    position: 'absolute',
    left: '0',
    top: '50%',
    transform: 'translateY(-50%)',
  },

  enjoyImageContainer: {
    height: '108px',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

class EnjoyView extends Component {
    state = {
        

    }

  encodeId=(id, user)=>{
    try {
        const encode = window.btoa(window.btoa(id)); 
        if(user.toLowerCase() === this.props.participant.userName.toLowerCase()){
          return `/dashboard/post/${encode}`;
        }else{
          return `/user/${user}/post/${encode}`;
        }
    } catch (e) {
        return
    }
  }

  prePlay=(e)=>{
    try{
        if(e.cancelable){
            e.preventDefault();
          }
        const dom = document.getElementById(e.target.id);
        if(!dom) return;
        this.mounted && dom.play();
        dom.muted = false;
    }catch(err){
        return
    }
}

prePause=(e)=>{
    try{
        if(e.cancelable){
            e.preventDefault();
          }
        const dom = document.getElementById(e.target.id);
        if(!dom) return;
        this.mounted && dom.pause();
    }catch(err){
        return
    }
}

  render() {
        let {item} = this.props;

        return (
        	<div className="col-md-3 col-lg-3 col-xl-3 col-4 p-0">
                { (item.type === 1 || item.type===2) &&
                  <div style={classes.enjoyImageContainer}> 
                    <Link to={this.encodeId(item.status_post_id, item.userName)}>
                      <img className="img-fluid cursorZoomIn" 
                        style={classes.enjoyImage} 
                        src={item.name} 
                        alt={item.name} 
                      />
                    </Link>
                  </div>
                }
            { (item.type === 3) &&
              <div className="embed-responsive embed-responsive-4by3 py-0" style={{height:'100%'}}>
                <Link to={this.encodeId(item.status_post_id, item.userName)}>              
                  <video className="embed-responsive-item"
                    style={{zIndex: 100}} id={`vid-${item.name}`}
                      onMouseOver={this.prePlay}
                      onMouseOut={this.prePause}
                    >
                    <source src={item.name} type={`video/${item.name.slice(-3)}`} />
                      Your browser does not support HTML5 video.
                  </video>
                </Link>
              </div>
            }
        	</div>
        );
    }
}

const mapStateToProps=(state)=>{
  return{
    participant: state.participant,
  }
}

export default connect(mapStateToProps)(EnjoyView);