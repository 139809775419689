import React, {Component} from 'react';
import { Avatar, Button, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Axios from 'axios';
import { connect } from 'react-redux';
import { processHeader } from '../../../../global/action';
import { PROXY } from '../../../../global/constants/action_types';
import { noImage /*, userPicPath*/ } from '../../../../global/generalMethods/general';
import { Link } from 'react-router-dom';

const searchBtn = {
    color: '#F9FBFF',
    borderColor: '#ff7420',
    borderRadius: '18.5px',
    background: 'linear-gradient(92deg, #FA9347 5.16%, #F07672 99.12%)',
    boxShadow: 'none',
    // height: '40px',
}
const roundedClass = {
  borderRadius: '18.5px',
}
const avatarStyle = {
    height: 50,
    width: 50
}
const followBtn = {
	color: '#ffffff',
	border: 'none',
	borderRadius: '18.5px',
	background: 'linear-gradient(90deg, #387DFF -4.49%, #004BD6 104.49%)',
	boxShadow: 'none',
	fontSize: '11px',
  padding: '2px 10px',
}

class UserFollowers extends Component{

	state = {
		userName:null,
		loading:false,
		followersSearchItem:'',
		reserveFollowers:{},
		followers: {},
		isFollowersFetchedOnce:false,
	}

	mounted;


	componentDidMount=async()=>{
		this.mounted = true;
		await this.setState({userName:this.props.participant.userName});
		if(this.props.followers && Object.entries(this.props.followers).length > 0 ){
			await this.setState({followers: this.props.followers});
			await this.setState({reserveFollowers: this.props.followers});
			await this.setState({isFollowersFetchedOnce: true});
		}
	}

	UNSAFE_componentWillReceiveProps=async(nextProps)=>{
		if(Object.entries(nextProps.followers).length > 0 ){
			await this.setState({followers: nextProps.followers});
			await this.setState({reserveFollowers: nextProps.followers});
			await this.setState({isFollowersFetchedOnce: true});
		}
	}

	componentWillUnmount=()=>{
		this.mounted = false;
	}

	testMember;

	unFollow =async(e,related)=>{
		if(!this.mounted) return;
		if(e.target.innerText === 'Undo'){
			e.target.innerText= 'unfollow';
			clearTimeout(this.testMember)
		}
		else if(e.target.innerText === 'unfollow'){
			e.target.innerText = 'Undo';
			this.testMember= setTimeout((()=>{
				Axios.get(`${PROXY}/api/unfollow/${this.props.participant.userName}/${related}`, {headers: processHeader()})
				.then(res =>{
					if(res.data.success){
						const tempFollower = {...this.state.followers};
						const index = tempFollower.data.findIndex(data=>data.follower.userName === related);
						tempFollower.data[index].followed = null;						
						this.setState({followers: tempFollower});
					}
				}).then(err=>{
					return;
				});
			}),5000)
		}
	}
	follow=(e,related)=>{
		console.log(this.state.followings, 'foll', e.target.innerText, related);
		if(e.target.innerText === 'Undo'){
			e.target.innerText= 'follow';
			clearTimeout(this.testMember);
		}
		else if(e.target.innerText === 'follow'){
			e.target.innerText = 'Undo';
			this.testMember= setTimeout((()=>{
				Axios.get(`${PROXY}/api/follow/${this.props.participant.userName}/${related}`, {headers: processHeader()})
				.then(res =>{
					if(res.data.success){
						if(!this.mounted) return;
						const tempFollower = {...this.state.followers};
						const index =  tempFollower.data.findIndex((data)=>data.follower.userName === related);
						tempFollower.data[index].followed = new Date();
						this.setState({followers: tempFollower});
					}
				})
				.then(err=>{
					if(!this.mounted) return;
				});
			}),5000)
		}
	}

	getMoreFollowers=(e)=>{
		if(!this.state.followers.next_page_url) return;
		e.preventDefault();
		this.setState({loading: true})	
		Axios.get(`${this.state.followers.next_page_url}`, {headers:processHeader()})
		.then( async(res)=>{
			if(res.data.success){
				if(!this.mounted) return;
					const {data} = res.data.data;
					res.data.data.data = [...this.state.followers.data,...data];
					await this.setState({followers: res.data.data});
					await this.setState({loading:false});
			}
		}).catch(async(err) =>{
			if(!this.mounted) return;
			await this.setState({loading:false});
		})		
	}

	collectFollowersSearchItems=async(e)=>{
		if(!this.mounted) return;
		e.preventDefault();
		await this.setState({followersSearchItem: e.target.value});
		console.log(this.state.followersSearchItem);
		if(this.state.followersSearchItem.trim() === ""){
			await this.setState({followers: this.state.reserveFollowers});
		}
	}
	

	searchFollowers=()=>{
		if(this.state.followersSearchItem.trim()){
			Axios.get(
				`${PROXY}/api/get_user_searched_followers/${this.state.userName}/${this.props.user.userName}
				/${this.state.followersSearchItem}`, {headers: processHeader()}				
			)
			.then(async(res)=>{
				if(res.data.success){
					if(!this.mounted) return;
					await this.setState({followers: res.data.data});		
				}
			})
			.then(async(err)=>{
				if(err){
					if(!this.mounted) return;
					await this.setState({followers: {}});
				}
			})
		}
	}

  render(){
		return(<div>
			{(this.state.isFollowersFetchedOnce && !this.props.getting)?
        <div>
          <div>
						<div className='py-2'>
							{(this.props.relationship && Number(this.props.relationship.followed) === 1) &&
								<div style={{position:'relative'}}>
									<div className='text-primary d-flex justify-content-center'>
										<small>
											You are following <b>@{this.props.user.userName}</b>
										</small>
									</div>
									<div className='px-2 py-1 w3-circle w3-light-grey'
										onClick={()=>{this.props.close()}} 
										style={{position:'absolute', top:0, right:0, cursor:'pointer'}}
									>
										X
									</div>
								</div> 
							}
							<hr className='mb-1 mt-0' />
						</div>
						<div className="input-group mb-3">
							<input 
								type="search"
								className="form-control" 
								style={roundedClass} 
								onChange={this.collectFollowersSearchItems} 
								placeholder="Search Followers" 
								aria-label="Search Followers"
								readOnly={!this.state.reserveFollowers.data || this.state.reserveFollowers.data.length < 1} 
							/>
							<div className="input-group-append">
								<Button 
									variant="contained" 
									onClick={this.searchFollowers} 
									className="ml-2" 
									style={ searchBtn }
									readOnly={!this.state.reserveFollowers.data || this.state.reserveFollowers.data.length < 1}
								>
									<SearchIcon />
								</Button>
							</div>
						</div>
          </div>   
          <div>                        
          {(this.state.followers.data && this.state.followers.data.length >0)?
            <div>							
              <div className='d-flex justify-content-center'>
								{this.state.loading &&
									<div className="w3-spin spinner w3-circle"></div>
								}
              </div>
            <div className='p-4 w3-card' style={{maxHeight:'75vh', overflowY:'auto'}}>
            	{ this.state.followers.data.map((follower, index) =>
                <div className="row justify-content-between mb-2 " key={follower.follower.userName}>
									<div className="col d-flex px-0">
										<Avatar alt={noImage} className="img-fluid mr-2" src={follower.follower.profilePic?
											follower.follower.profilePic:
											noImage} style={ avatarStyle }
										/>
										<div className="d-flex flex-column">
											<Link to={`/user/${follower.follower.userName}`} className="small font-weight-bold text-dark">
												{ follower.follower.userName}
											</Link>
											<Typography variant="subtitle2" className="font-weight-light">
												{`${ follower.follower.firstName} ${follower.follower.lastName}`}
											</Typography>
										</div>
									</div>
									<div className="col text-right px-0">
									{(follower.followed)?
										<button className="my-auto" 
											onClick={(e)=>this.unFollow(e,follower.follower.userName)} 
											style={ followBtn }
										>
											unfollow
										</button> :
										<button className="my-auto" 
											onClick={(e)=>this.follow(e, follower.follower.userName)} 
											style={ followBtn }
										>
											follow
										</button>
									}
								</div>
							</div>
            )}
					</div>
					<div className='d-flex justify-content-center'>
						{this.state.followers.next_page_url &&
							<button className="my-auto" style={ followBtn } 
								onClick={this.getMoreFollowers} 
							>
								more
							</button>
						}
					</div>
					</div>:
					<div className="d-flex justify-content-center">							
						No follower found
					</div>
				}
			</div>
			</div>:
				<div>
					<div className="d-flex justify-content-center">
						<div className="w3-spin spinner w3-circle"></div>
					</div>
				</div>
			}
    </div>)
  }
}

const mapStateToProps =(state)=>{
	return {
		participant: state.participant,
        followers: state.userFollowers,
        user: state.userPageOwner,
        relationship: state.userRelationship,
	}
}

export default connect(mapStateToProps)(UserFollowers);