import React, {Component} from 'react';
import { Avatar, Typography, Box } from '@material-ui/core';

import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
// import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { connect } from 'react-redux';
import Axios from 'axios';
import { noImage,CHAT_LIKE_TIME, staticPusherConfig } from '../../../global/generalMethods/general';
import { loadSpecificUserPost, processHeader } from '../../../global/action';
import { PROXY } from '../../../global/constants/action_types';
import DbPostModal from './DbPostModal';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { FacebookShareButton, FacebookIcon, TwitterShareButton,
    TwitterIcon, WhatsappShareButton, WhatsappIcon, LinkedinShareButton,
    LinkedinIcon, TelegramShareButton, TelegramIcon 
} from 'react-share';
import { Link } from 'react-router-dom';

const classes = {
    statusImageCard: {
        height: '250px',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        background: 'rgba(0, 0, 0, 0.5)',
    },
    statusImageStyle: {
      zIndex: 5,
      width: '100%',
      minHeight: '100%',
      position: 'absolute',
      left: '0',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    sendCommentBtn: {
        width:'30px',
        color: '#F9FBFF',
        background: 'linear-gradient(92deg, #FA9347 5.16%, #F07672 99.12%)',
    }
}

const quitRequest = Axios.CancelToken.source();

class DbPostDetails extends Component{
    state = {
        POST:{},
        userName:null,
        fetching_post:false,
        postComment:{},
        commenting: false,
        deletingComment:false,
        showModal:false,
        selectedFile: null,
        gettingComments: false,        
        openMenu: null,
        selectedStatusIdForDelete: null,
        SelectStatusUsernameForDelete: null,
    }

    mounted = true;

    componentDidMount=async()=>{
        // console.log(this.props);
        this.mounted = true;
        await this.setState({userName: this.props.participant.userName});
        if(this.props.post && this.mounted){
             await this.setState({POST:this.props.post});
             await staticPusherConfig();
             await this.listenToPostChanges();
        }
        

        
    }
    UNSAFE_componentWillReceiveProps=async(next)=>{
        if(next.post && next.post.data){
            if(!this.mounted) return;
            await this.setState({POST: next.post});
        }
    }

    componentWillUnmount=()=>{
        quitRequest.cancel();
        this.mounted= false;
    }

    prePlay=(e)=>{
        try{
            if(e.cancelable){
                e.preventDefault();
              }
            if(window.innerWidth > 768){
                const dom = document.getElementById(e.target.id);
                if(!dom) return;
                this.mounted && dom.play();
                dom.muted = false;
            }
        }catch(err){
            return
        }
    }

    prePause=(e)=>{
        try{
            if(e.cancelable){
                e.preventDefault();
              }
            const dom = document.getElementById(e.target.id);
            if(!dom) return;
            this.mounted && dom.pause();
        }catch(err){
            return
        }
    }

    /*This method checks if the participant has already liked a particular status file by filtering the id of the
    file out  of the associated post files like monitor history using  the some method*/
    filterLiked=(id, monitor)=>{
        if(monitor.length>0){
          return  monitor.some(like=>like.status_file_id === id);
        }else{
            return false;
        }
    }

    setShow=(condition)=>{
        this.setState({showModal: condition});
    }

    magnifyFile=async(selected)=>{
        await this.setState({selectedFile:selected});
        if(this.state.selectedFile){
            await this.setState({showModal: true});
        }
    }

    getComments=async(e)=>{
        e.preventDefault();
        if(this.state.gettingComments) return;        
        let url;
        let position;
        console.log(this.state.POST.poster.userName);
        if(Object.entries(this.state.postComment).length > 0 && this.state.postComment.next_page_url){
            url = this.state.postComment.next_page_url;
            position = 2;
        }else if(Object.entries(this.state.postComment).length > 0 && !this.state.postComment.next_page_url){
            return;
        }else if(Object.entries(this.state.postComment).length < 1){
            url = `${PROXY}/api/get_post_comments/${this.state.POST.poster.userName}/${this.state.POST.id}`;
            position = 1;
        }
        await this.setState({gettingComments: true});
        Axios.get(url, {cancelToken: quitRequest.token})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                if(!position) return;
                const tempPost = Object.assign({}, this.state.POST);
                if(position === 1){
                    await this.setState({postComment: res.data.data});
                    tempPost.comment = [...res.data.data.data];
                    await this.setState({POST: tempPost});
                }else if(position === 2){
                    await this.setState({postComment: res.data.data});
                    tempPost.comment = [...tempPost.comment,...res.data.data.data];
                    await this.setState({POST: tempPost});
                }

            await this.setState({gettingComments: false});
            }else if(res.data.Error === 'Intruder'){
                if(!this.mounted) return;
                try {
                    window.localStorage.clear();
                    return this.props.history.replace('/');                
                } catch (e) {
                    return;
                }
            }
            
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            await this.setState({gettingComments: false});
        })
    }

    deleteStatusComment=(statusPostId, commentId)=>{
        if(!commentId) return;
        const form = new FormData();
        form.append('status_comment_id', commentId);
        Axios.post(`${PROXY}/api/delete_status_comment/${this.state.userName}`,
        form, {headers:processHeader(), cancelToken: quitRequest.token})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                const tempPOST= {...this.state.POST}
                const tempData = [...tempPOST.data]
                const actualPost = tempData.find(el=>el.id===statusPostId);
                const actualPostIndex= tempData.findIndex(el=>el.id === statusPostId);
                const commentIndex = actualPost.comments.findIndex(el=>el.id===commentId)
                actualPost.comments.splice(commentIndex,1);
                tempData.splice(actualPostIndex,1,actualPost);
                tempPOST.data=tempData;
                await this.setState({POST: tempPOST});
            }

        })
        .catch(err=>{
            // if(err) console.log(err)
        })
    }


    commentOnStatus=async(statusId, comment, inputId)=>{
        if(comment && comment.trim().length>0 && this.state.userName && !this.state.commenting){
            await this.setState({commenting:true});
            document.getElementById(inputId).value='';
            const form = new FormData();
            form.append('status_post_id', statusId);
            form.append('comment', comment)
            const AUTH = processHeader();
            AUTH['X-Socket-ID'] = window.Echo.socketId();
            Axios.post(`${PROXY}/api/comment_on_status/${this.state.userName}`,form,
            {headers:AUTH, cancelToken: quitRequest.token})
            .then(async(res)=>{
                if(res.data.success){
                    if(!this.mounted) return;
                    const tempPOST= {...this.state.POST}
                    tempPOST.comment_count++;
                    tempPOST.comment.unshift(res.data.data);
                    await this.setState({POST: tempPOST});
                    await this.setState({[inputId]:undefined});
                }
            })
            .then(async(err)=>{
                if(!this.mounted) return;
                // if(err) console.log(err);
                await this.setState({commenting:false});
            })
        }
    }
    likeStatusPost=(id)=>{
        if(id){
            const form = new FormData();
            form.append('status_post_id', id);
            const AUTH = processHeader();
		    AUTH['X-Socket-ID'] = window.Echo.socketId();
            Axios.post(`${PROXY}/api/like_status_post/${this.props.participant.userName}`, form,
             {headers:AUTH, cancelToken: quitRequest.token})
            .then(async(res)=>{
                if(res.data.success){
                    if(!this.mounted) return;
                    const statePostDuplicate = {...this.state.POST};
                    if(res.data.data ==="liked"){
                        statePostDuplicate.post_liked={created_at: new Date()};
                        statePostDuplicate.likes++;
                        await this.setState({POST:statePostDuplicate});
                    }else if(res.data.data === "unliked"){
                        statePostDuplicate.post_liked=null;
                        statePostDuplicate.likes--;
                        await this.setState({POST:statePostDuplicate});
                    }
                }else{
                    // console.log(res.data);
                }
            })
            .catch(async(err)=>{
                // if(err) console.log(err)
            })
        }
    }

    likeStatusPostFile=(post_id, file_id)=>{
        if(post_id && file_id){
            const form = new FormData();
            form.append('status_post_id', post_id);
            form.append('status_file_id', file_id);
            const AUTH = processHeader();
            AUTH['X-Socket-ID'] = window.Echo.socketId();
            Axios.post(`${PROXY}/api/like_status_file/${this.props.participant.userName}`, form,
             {headers:AUTH, cancelToken: quitRequest.token})
            .then(async(res)=>{
                if(res.data.success){
                    if(!this.mounted) return;
                    const statePostDuplicate = {...this.state.POST};
                    const index = statePostDuplicate.status_files.findIndex(file=>file.id === file_id);
                    if(res.data.data ==="liked"){
                        statePostDuplicate.file_liked.push({status_file_id:file_id});
                        const file = statePostDuplicate.status_files.find(file=>file.id === file_id);
                        file.likes++;
                        statePostDuplicate.status_files.splice(index,1,file);
                        await this.setState({POST:statePostDuplicate});
                    }else if(res.data.data === "unliked"){
                        if(!this.mounted) return;
                        const position = statePostDuplicate.file_liked.findIndex(data=>data.status_file_id === file_id);
                        statePostDuplicate.file_liked.splice(position,1);
                        const file = statePostDuplicate.status_files.find(file=>file.id === file_id);                        
                        file.likes--;
                        statePostDuplicate.status_files.splice(index,1,file);
                        await this.setState({POST:statePostDuplicate});
                    }
                }else{
                    // console.log(res.data);
                }
            })
            .catch(async(err)=>{
                // if(err) console.log(err)
            })
        }
    }

    listenToPostChanges=()=>{
        if(Object.entries(this.state.POST).length< 1) return;
        if(!window.Echo) return;
        window.Echo.private(`post.${this.state.POST.id}`)
        .listen('.fileLiked', async(data)=>{
            if(!this.mounted) return;
            const tempPost = Object.assign({}, this.state.POST);
            const id = JSON.parse(data)[0]
            const index = await tempPost.status_files.findIndex(data=>Number(data.id) === Number(id));
            if(index < 0)return;
            const file = await tempPost.status_files.find(data=>Number(data.id) === Number(id));
            file.likes++;
            tempPost.status_files.splice(index,1,file);
            await this.setState({POST:tempPost})

            // console.log(id, index);

        })
        .listen('.fileUnLiked', async(data)=>{
            const tempPost = Object.assign({}, this.state.POST);
            const id = JSON.parse(data)[0]
            const index = await tempPost.status_files.findIndex(data=> Number(data.id) === Number(id));
            if(index < 0)return;
            const file = await tempPost.status_files.find(data=>Number(data.id) === Number(id));
            file.likes--;
            tempPost.status_files.splice(index,1,file);
            await this.setState({POST:tempPost});
            // console.log(JSON.parse(data)[0]);
        })
        .listen('.postLiking', async(data)=>{
            if(JSON.parse(data)[0]==='liked'){
                if(!this.mounted) return;
                const tempPost = Object.assign({}, this.state.POST);
                tempPost.likes++;
                await this.setState({POST:tempPost})

            }else if(JSON.parse(data)[0] === 'unLiked'){
                if(!this.mounted) return;
            const tempPost = Object.assign({}, this.state.POST);
            tempPost.likes--;
            await this.setState({POST:tempPost});
            }
        })
        .listen('.comment', async(data)=>{
            if(!this.mounted) return;
            const tempPost = Object.assign({}, this.state.POST);
            tempPost.comment.unshift(JSON.parse(data)[0])
            await this.setState({POST:tempPost});
            // console.log(JSON.parse(data)[0]);
        })
    }
    render(){
        const {POST} = this.state;
        const {participant, pathId} = this.props;
        const url ="https://medillery.com/user/";
        return(<div className='w3-container'>
            {(Object.entries(POST).length > 0) && (
                <div  onClick={()=>{}/*this.test(status.id)*/}>
                    <Box py={2} px={2} className="componentBg mt-2">
                        <div className="d-flex mr-2 mb-2" >
                            <Avatar 
                                alt={noImage}
                                className="img-fluid mr-2"
                                src={ POST.poster.profilePic? POST.poster.profilePic: noImage }
                            />
                            <Box className="d-flex flex-column">
                                <Typography className="font-weight-bold smallerText">
                                    <Link className='text-decoration-none' 
                                        to={
                                        POST.poster.userName.toLowerCase() === participant.userName.toLowerCase()?
                                        '/dashboard/profile':
                                        `/user/${POST.poster.userName}`
                                        }
                                    >
                                        @{POST.poster.userName}
                                    </Link>
                                </Typography>
                                <Typography variant="subtitle2" style={{ fontSize: '0.7rem' }}>
                                    {/* CHAT_LIKE_TIME(status.created_at)*/ }
                                </Typography>
                            </Box>
                            {/*this.testPusher(status.id)*/}
                        </div>
                        <div className="smallerText">{POST.description}</div>
                        <div className="row my-1">
                        {POST.status_files.length>0 && POST.status_files.map((file, index)=>(
                            <div key={index} className="col-sm-12 col-md-6 p-0">
                                <div className="card border-0">
                                <div className="card-body p-0" style={classes.statusImageCard}>
                                    {file.type <3 ?
                                        <img key={index}
                                            id="statusImage"
                                            alt={noImage}
                                            className="img-fluid rounded cursorZoomIn"
                                            style={classes.statusImageStyle}
                                            src={file.name}
                                            onClick={()=>this.magnifyFile(file)}
                                        />:
                                        <video style={classes.statusImageStyle}
                                            onClick={()=>this.magnifyFile(file)}
                                            className='w3-round m-2' id={`vid-${index}`}                                         
                                            // onTouchStart={this.prePlay}
                                            // onTouchEnd={this.prePause}
                                            onMouseOver={this.prePlay}
                                            onMouseOut={this.prePause} 
                                        >
                                            <source src={file.name} type={`video/${file.name.slice(-3)}`} />
                                            Your browser does not support the video tag.
                                        </video>
                                    }
                                </div>
                                <div className="small rounded-pill" style={{marginTop: "-20px", width: '80px', paddingLeft: '5px', zIndex: 10, background: 'rgba(255, 255, 255, 0.3)'}}>
                                    { this.filterLiked(file.id, POST.file_liked)?
                                        <FavoriteIcon fontSize="small" className="cursorPointer customPrimaryColor" onClick={()=>this.likeStatusPostFile(POST.id, file.id )} /> :
                                        <FavoriteBorderIcon fontSize="small" className="cursorPointer" onClick={()=>this.likeStatusPostFile(POST.id, file.id )} />
                                    }
                                    <span className='mx-2'>
                                        {(file.likes ===0)?
                                            '':
                                            `${(file.likes > 1)?
                                                `${file.likes} likes`:
                                                `${file.likes} like` 
                                            }`
                                        }
                                    </span>
                                </div>
                                </div>
                            </div>
                                ))}
                        </div>
                        <div>
                            {POST.tag &&
                                <div className='d-flex justify-content-center'>
                                    {POST.tag.toString().split(',').map((tag, index)=>
                                        <div key={index} className='w3-tiny w3-light-grey p-1 btn rounded-pill mr-2'>
                                            {tag}
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                        <div className="d-flex small mb-2">
                            <div>
                                {(POST.likes ===0)?
                                    '':
                                    `${(POST.likes > 1)?
                                        `${POST.likes} likes`:
                                        `${POST.likes} like` 
                                    }`
                                }
                            </div>
                            <div className="ml-auto">
                                {POST.comment_count === 1?
                                    `${POST.comment_count } Comment`:
                                    `${POST.comment_count > 1?
                                        `${POST.comment_count } Comments`:
                                        ''
                                        }`
                                    } {/* &nbsp; 50 shares*/}
                            </div>

                        </div>
                        <Divider />
                        <div className="py-1 px-5 d-flex justify-content-between">
                            <div>
                                { POST.post_liked?
                                    <FavoriteIcon fontSize="small" className="cursorPointer customPrimaryColor"  onClick={()=>this.likeStatusPost(POST.id)}/> :
                                    <FavoriteBorderIcon fontSize="small" className="cursorPointer" onClick={()=>this.likeStatusPost(POST.id)}/>
                                }
                            </div>
                            {/*<a to="#" className="text-decoration-none text-dark small">
                                <ChatBubbleOutlineIcon fontSize="small" />
                                <span className="ml-1">comment</span>
                            </a>*/}
                            <OverlayTrigger
                                trigger='click'
                                placement='auto'
                                overlay={
                                    <Popover id={`popover-positioned-auto`}>
                                        <Popover.Content className='px-5'>
                                            <FacebookShareButton url={`${url}${POST.poster.userName}/post/${pathId}`}>
                                                <FacebookIcon size={30} />
                                            </FacebookShareButton>
                                            <hr/>
                                            <TwitterShareButton url={`${url}${POST.poster.userName}/post/${pathId}`}>
                                                <TwitterIcon size={30} />
                                            </TwitterShareButton>
                                            <hr/>
                                            <WhatsappShareButton url={`${url}${POST.poster.userName}/post/${pathId}`}>
                                                <WhatsappIcon size={30} />
                                            </WhatsappShareButton>
                                            <hr/>
                                            <LinkedinShareButton url={`${url}${POST.poster.userName}/post/${pathId}`}>
                                                <LinkedinIcon size={30} />
                                            </LinkedinShareButton>
                                            <hr/>
                                            <TelegramShareButton url={`${url}${POST.poster.userName}/post/${pathId}`}>
                                                <TelegramIcon size={30} />
                                            </TelegramShareButton>


                                        </Popover.Content>
                                    </Popover>
                                }
                            >                                
                                <span className="ml-1" style={{cursor:'pointer'}}>
                                    <ShareIcon fontSize="small" /> share
                                </span>
                            </OverlayTrigger>
                        </div>
                        {/*commenter*/}
                        <div className="d-flex mr-2 mb-2 mt-3">
                            <Avatar 
                                alt={noImage} 
                                className="img-fluid mr-2"
                                src={participant.profilePic?participant.profilePic:noImage}
                                style={{width:'30px', height: '30px'}} 
                            />
                            <textarea rows="1" id={`${POST.id}inputId`} 
                                className="form-control form-control-sm mr-1 my-auto rounded-pill" 
                                placeholder="comment"
                                maxLength='10000'
                                onChange={
                                    async(e)=>{ await this.setState({[e.target.id]: e.target.value});
                                }}
                            ></textarea>
                            <button type="submit" className="btn btn-sm rounded-pill ml-auto my-auto customPrimaryBgColor" style={ classes.sendCommentBtn }
                            onClick={()=>this.commentOnStatus(POST.id, this.state[`${POST.id}inputId`], `${POST.id}inputId` )}
            
                            >
                                <ChevronRightIcon fontSize="small" />
                            </button>
                        </div>
                        {POST.comment.length>0 &&
                            <Box style={{overflow:'auto'}}>
                            {POST.comment.map((comment, index)=>(
                                <div className="media mb-2" key={index}>
                                    <Avatar alt={noImage} className="mr-2" style={{width:'30px', height: '30px'}} 
                                        src={comment.profilePic? comment.profilePic : noImage}
                                    />
                                    <div className="media-body p-1 smallerText" style={classes.customCommentBg}>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column">
                                            <Link to={`/user/${comment.userName}`} className="text-decoration-none text-dark font-weight-bold mr-2">
                                                { `@${comment.userName}` }
                                            </Link>
                                            {(comment.role === 1) &&
                                                <Typography variant="subtitle2" className="text-muted ml-2" style={{ fontSize: '0.7rem' }}>
                                                    Graphics Designer
                                                </Typography>
                                            }
                                            </div>
                                            <Typography></Typography>
                                            <Typography variant="subtitle2" className="text-muted ml-auto mr-3" style={{ fontSize: '0.7rem' }}>
                                                {CHAT_LIKE_TIME(comment.created_at)}
                                            </Typography>
                                        </div>
                                        {comment.comment}
                                    </div>
                                </div>
                                ))}
                                <div className='d-flex justify-content-center'>
                                    {this.state.POST.comment_count > this.state.POST.comment.length && 
                                        <button  onClick={this.getComments} className=" btn d-block text-center w3-hover-teal small">
                                            show more comments
                                        </button>
                                    }
                                </div>
                            </Box>
                        }
                    </Box>
                    {(POST.status_files.length > 0) && 
                        <DbPostModal all={POST.status_files} show={this.state.showModal} item={this.state.selectedFile} setShow={this.setShow} />
                    }
                </div>                        
            )}
        </div>)        
    }
}
const mapStateToProps=(state)=>{
	return{
        participant: state.participant,
        post: state.specificUserPost,
	}
}

const mapDispatchToProps=(dispatch)=>{
    return{
        loadPost: (payload)=>dispatch(loadSpecificUserPost(payload))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DbPostDetails);

