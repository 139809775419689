import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import { connect } from 'react-redux';
import { PROXY } from '../../global/constants/action_types';
import Axios from 'axios';
import { processHeader, participantInfo } from '../../global/action';
import { Paper, Chip } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

const classes = {
    chipBox: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        // padding: theme.spacing(0.5),
        margin: 0,
    },
    searchBtn: {
        color: '#F9FBFF',
        height: '40px',
    },
    roundedClass: {
      borderRadius: '18.5px',
    },
    customColor: {
        color: '#FA9347',
    },
    statusImageStyle: {
        maxHeight: '400px',
    }
}


class SetUsername extends Component {
    state = {
        generating: false,
        processing: false,
        geneCount:0,
        maximumSuggestion:false,
        suggestedNames:[],
        selectedSuggestedName:null,
        regenerate:false,
        taken:false,
        available: false,
        preChecking: false,
        /** participant object */
        package:null, 
        currentValue: null,
        warn: true,
        set:false,
    }

    mounted;
    timerHolder;

    componentDidMount=async()=>{
        this.mounted = true;
    }

    componentWillUnmount=async()=>{
        this.mounted = false;
    }

    checkUsername=async(val)=>{
        if(this.state.preChecking) return;
        if(!/(^[a-zA-Z0-9_]{3,})$/.test(val)){
            (this.mounted) && await this.setState({patternError: true});
            return;
        }
        await this.setState({currentValue:val});
        (this.mounted) && await this.setState({preChecking:true});
        Axios.get(`${PROXY}/api/check_username/${val}`, {headers:processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                await this.setState({available: true});
                await this.setState({selectedSuggestedName: val});
                await this.setState({preChecking:false});
            }else{
                if(!this.mounted) return;
                await this.setState({taken: true});
                await this.setState({selectedSuggestedName: null});
                await this.setState({preChecking:false});
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            (this.mounted) && await this.setState({preChecking:false});
        })

    }

    prepareCheckUsername=async(e)=>{
        e.preventDefault();
        const val = e.target.value.trim();
        if(this.timerHolder) clearTimeout(this.timerHolder)       
        if(!val || val.trim()===this.state.currentValue) return;
        if(val.toLowerCase() === 'taye abidakun' || val.toLowerCase() === 'atomty'){
            await this.setState({taken: true});
            return;
        }
        await this.setState({available: false});
        await this.setState({taken: false});
        await this.setState({patternError: false});
        await this.setState({set:false});
        await this.setState({warn:true});
        this.timerHolder = setTimeout((async()=>await this.checkUsername(val)),1600);
    }

    acceptSuggestion=async(val)=>{
        if(val){
            await this.setState({patternError: false});
            const data = val.trim().replace(/-/gi,'');
            if(data.length < 3){
                (this.mounted) && await this.setState({patternError: true});
                return;
            }
            document.getElementById('usernameBox').value = data;
            (this.mounted) && await this.setState({selectedSuggestedName: data});
        }
    }

    suggestUsername =async(e)=>{
        e.preventDefault();
        if(!this.mounted) return;
        if(this.state.generating) return;
        await this.setState({generating: true});
        if(this.state.regenerate) await this.setState({regenerate:false});
        if(this.state.geneCount >=2){
            if(!this.mounted) return;
            await this.setState({maximumSuggestion:true});
            await this.setState({generating: false});
            return;
        }
        Axios.get(`${PROXY}/api/suggest_username`, {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                (this.mounted) && await this.setState((state)=>{return{geneCount: state.geneCount+1}})
                if(res.data.data.length > 0){
                    const suggestions = [];
                    res.data.data.forEach(element => {
                        let ele = element.toString().replace(/-/gi,'');
                        if(element.length > 2) suggestions.push(ele);
                        
                    });
                    if (suggestions.length < 1){
                        (this.mounted) && await this.setState({regenerate:false});
                        return 
                    } 
                    (this.mounted) && await this.setState({suggestedNames:suggestions});                    
                }
                (this.mounted) && await this.setState({generating: false});
            }
        })
        .catch(async(_)=>{
            if(!this.mounted) return;
            await this.setState({generating: false})
        })
    }

    confirmUserName=async(e)=>{
        e.preventDefault();
        if(!this.mounted) return;
        if(this.state.taken) return;
        if(!this.state.available) return;
        if(this.state.processing) return;
        if(!this.state.selectedSuggestedName) return await this.setState({patternError: true});        
        const name = this.state.selectedSuggestedName.trim();
        if(name !== document.getElementById('usernameBox').value.trim()){
            (this.mounted) && await this.setState({patternError: true});
            return;
        }
        if(!name) return;
        if(!/(^[a-zA-Z0-9_]{3,})$/.test(name)){
            (this.mounted) && await this.setState({patternError: true});
            return;
        }
        await this.setState({warn:false});
        return await this.setState({set:true});        
    }


    setUserName=async(e)=>{
        if(this.state.processing) return;
        if(!e){
            await this.setState({set:false});
            await this.setState({warn:true});
            return;
        }        
        (this.mounted) && await this.setState({processing:true});
        (this.mounted) && await this.setState({warn:false});
        const name = this.state.selectedSuggestedName.trim();
        const form = new FormData();
        form.append('userName', name);
        Axios.post(`${PROXY}/api/set_username`, form, {headers:processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                await this.setState({package: JSON.parse(window.atob(res.data.data))});
                await this.setState({processing: false});               
            }else{
                if(res.data.Error === 'Intruder'){
                    if(!this.mounted) return;
                    (this.mounted) && await this.setState({processing:false});
                    window.localStorage.clear();
                    return this.props.navigate('/',{replace:true});
                }
            }
        })
        .catch(async(_)=>{            
            if(!this.mounted) return;
            await this.setState({processing: false})
        })
    }

    proceed=async()=>{
        if(!this.mounted) return;
        if(this.state.package){
            await this.props.resetIdentity(this.state.package);
        }
    }
    render() {
        return (
            <React.Fragment>
            {(this.state.package && this.state.package.userName)?
                <div className="componentBg py-5">
                    <Typography variant="h6" className="sectionTitle text-capitalize text-center pt-5 mb-5">
                        You have successfully set your Username 
                        <span className="font-weight-bold" style={{pointer:'torch'}}>
                            @{this.state.package.userName}
                        </span>
                    </Typography>
                    <div className='my-4 d-flex justify-content-center'>
                        <button onClick={this.proceed} className='btn customPrimaryBgColor text-white rounded-pill'>
                            Proceed
                        </button>
                    </div>
                </div>:
                <div className="componentBg py-4">
                    <Typography variant="h4" className="sectionTitle text-capitalize font-weight-bold text-center mb-5">
                        Set Your Username
                    </Typography>
                    <div className="row">
                        <div className="col-sm-12 col-md-2 col-lg-3 col-xl-3"></div>
                        <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6">
                            <Typography variant="body1" className="text-capitalize text-center mb-2">
                            <b> Dear {this.props.participant.firstName} {this.props.participant.lastName},</b>
                                To gain access to your Dashboard, choose a username
                            </Typography>
                            <div className='d-flex justify-content-center text-center mb-2'>
                                <small className="text-info">
                                    Please note that this cannot be changed in the future.
                                    Carefully choose a nice Username.
                                </small> 
                           </div>
                           <form>
                               <div className="form-group">
                                    {this.state.preChecking && 
                                        <div className='d-flex justify-content-center'>
                                            <div><div className="w3-spin spinner w3-circle mt-2"></div><small>verifying...</small></div>
                                        </div>                                    
                                    }
                                   <input 
                                        onKeyUp={this.prepareCheckUsername} 
                                        id='usernameBox' type="text" 
                                        className="form-control mb-2 rounded-pill" 
                                        placeholder="Choose Username" 
                                    />
                                   <div className='mb-2 d-flex justify-content-center'>
                                        {this.state.patternError &&
                                            <small className='text-danger'>
                                                Username can only be at least 3 characters and contain Alphanumeric and underscore
                                            </small>
                                        }
                                    </div>
                                   <div className="text-right">
                                        
                                        { this.state.available &&
                                            <Typography variant="subtitle2" className="text-capitalize text-success"><CheckBoxIcon fontSize="small" />
                                                Available
                                            </Typography>
                                        }
                                        { this.state.taken &&
                                            <Typography variant="subtitle2" className="text-capitalize text-danger"><CancelIcon fontSize="small" />
                                                Already Taken
                                            </Typography>
                                        }
                                   </div>
                                    {(this.state.warn && !this.state.set) &&
                                        <button 
                                            onClick={this.confirmUserName}
                                            className="btn btn-block rounded-pill customPrimaryBgColor text-white"
                                        >
                                            set Username
                                        </button>
                                    }
                                    {(this.state.set && !this.state.warn) &&
                                        <div className='d-block justify-content-center'>
                                            <div className='d-flex justify-content-center'>
                                                <div>
                                                    Do you really want to use 
                                                    <b className='mx-1'>{this.state.selectedSuggestedName}</b>
                                                    as your username?
                                                 </div>
                                            </div>
                                            <div className='small d-flex justify-content-center'>
                                                <div>                                                      
                                                    <button 
                                                        className='w3-button  w3-small w3-light-gray w3-hover-grey w3-round-xxlarge mx-2' 
                                                        onClick={(e)=>{e.preventDefault(); this.setUserName(true)}}
                                                    >
                                                        YES
                                                    </button>
                                                    <button 
                                                        className='w3-button w3-small w3-light-gray w3-hover-gray w3-round-xxlarge' 
                                                        onClick={(e)=>{e.preventDefault(); this.setUserName(false)}}
                                                    >
                                                        NO
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                               </div>
                               <div className="text-center">
                                    <div>
                                        { this.state.suggestedNames.length > 0 && 
                                            <Paper elevation={0} component="ul" style={classes.chipBox} className="p-1 mb-2 border">
                                                {this.state.suggestedNames.map((data, index) => (
                                                    <li className="mb-1" key={index}>
                                                        <Chip
                                                        // icon={icon}
                                                        variant="outlined"
                                                        size="small"
                                                        label={data}
                                                        onClick={() =>this.acceptSuggestion(data)}
                                                        className="mx-1 my-auto"
                                                        />
                                                    </li>
                                                ))}
                                            </Paper>
                                        }
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <div>
                                            <button onClick={this.suggestUsername} className={
                                                    `btn rounded-pill ${this.state.geneCount < 1? 'btn-primary':
                                                        `${this.state.geneCount === 1? 'btn-warning': 'btn-danger' }`
                                                    }`
                                                }
                                            >
                                                <span className="small">Auto Generate Username</span>
                                            </button>
                                        </div>
                                        {this.state.generating &&
                                            <div className='ml-4'>
                                                <div className="w3-spin spinner w3-circle mt-2"></div>
                                            </div>
                                        }
                                    </div>
                               </div>
                               <div className="text-center">
                                   {this.state.regenerating &&
                                    <div>You can click on generate once more</div>
                                    }
                                    {this.state.maximumSuggestion &&
                                        <div className='my-2'>
                                            <small>Sorry, Suggestion limit is reached</small>
                                        </div>
                                    }
                               </div>
    
                           </form>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-3 col-xl-3"></div>
                    </div>
                </div>
            }
            </React.Fragment>
        );
    }
}

const mapStateToProps=(state)=>({
    participant:  state.participant,
})

const mapDispatchToProps=(dispatch)=>({
    resetIdentity: (payload)=>dispatch(participantInfo(payload))
})

const withRouter=Child=>props=>{
    const params = useParams();
    const navigate = useNavigate();
    return(
        <Child 
            {...props} 
            params={params} 
            navigate={navigate} 
        />
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetUsername));