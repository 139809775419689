import React, {Component} from 'react';
import { Avatar, Typography, Paper } from '@material-ui/core'
import { connect } from 'react-redux';
import { PROXY } from '../../../global/constants/action_types';
import { processHeader, loadAllocation, allocationToDisplay } from '../../../global/action';
import Axios from 'axios';
import { ESTIMATE_TIME, LUX_ZONE, generateFiveRandomNumbers, noImage } from '../../../global/generalMethods/general';
import Filter from './Filter';
import { Link, useNavigate, useParams } from 'react-router-dom';

const avatarStyle = {
    height: 80,
    width: 80
}

const quitRequest = Axios.CancelToken.source();
class Projects extends Component{
    state = {
        userName:null,
        no_allocation:false,
        allocations:{},
        fetching_allocation:false,
        type:null,
    }

    mounted;

    componentDidMount=async()=>{
        this.mounted = true;
        console.log(this.props);
        (this.mounted) && await this.setState({userName:this.props.participant.userName});
        if(!this.props.participant.email_verified_at) return;
        if(Object.entries(this.props.initialAllocations).length < 1 
            && this.props.display === 'all' && !this.props.loadingAllocation){            
            (this.mounted) && this.getAllocations();
        }else{
            (this.mounted) && await this.setState({allocations:this.props.initialAllocations})
        }
    }

    componentWillUnmount=()=>{
        this.mounted = false;
        quitRequest.cancel();
    }

    UNSAFE_componentWillReceiveProps=async(next)=>{
        if(!this.mounted) return;
        if( next.initialAllocations){
            if(next.display === 'all'){
                if(!this.mounted) return;
                await this.setState({allocations: next.initialAllocations});
                await this.setState({type: null});
            }
            else if(next.display === 'pend'){
                if(!this.mounted) return;
                await this.setState({allocations: next.initialAllocations})
                await this.setState({type: 'Pending'});
            }
            else if(next.display === 'comp'){
                if(!this.mounted) return;
                await this.setState({allocations: next.initialAllocations});
                await this.setState({type: 'Completed'});
            }
            else if(next.display === 'filtered'){
                if(!this.mounted) return;
                await this.setState({allocations: next.initialAllocations});
                await this.setState({type: 'Filtered'});
            }
        }
    }
    getAllocations=async()=>{
        if(!this.state.fetching_allocation && this.state.userName){
            if(!this.mounted) return;
            await this.setState({fetching_allocation:true});
            let url;
            if(Object.entries(this.state.allocations).length>0 && this.state.allocations.next_page_url){
                const getUrl = this.state.allocations.next_page_url.indexOf('api');
                url = this.state.allocations.next_page_url.slice(getUrl, this.state.allocations.next_page_url.length);
            }else if(Object.entries(this.state.allocations).length < 1){
                url = `api/get_allocations/${this.state.userName}`
            }
            Axios.get(`${PROXY}/${url}`, {headers:processHeader(), cancelToken: quitRequest.token})
            .then(async(res)=>{
                if(res.data.success){
                    if(!this.mounted) return;
                    if(this.state.allocations.data){
                        const currentData =[...res.data.data.allocations.data]
                        res.data.data.allocations.data = [...this.state.allocations.data,...currentData];
                        (this.mounted) && await this.setState({allocations:res.data.data.allocations});
                    }else{
                        // await this.setState({allocations: res.data.data.allocations});
                        if(!this.mounted) return;
                        this.props.displayAllocation('all');
                        this.props.fetchInitialAllocations(res.data.data.allocations);
                        // console.log(this.state.allocations)
                    }
                }
                if(res.data.message === 'xxx'){
                    try {
                        localStorage.clear();
                         return this.props.navigate('/review');
                    } catch (_) { }
                }
                if(!this.mounted) return;
                await this.setState({fetching_allocation:false});
            })
            .catch(async(_)=>{
                if(!this.mounted) return;
                await this.setState({fetching_allocation:false});
            })
        }
    }
    estimateDueTime=(Created, dueTime)=>{
        const created_at = new Date(Created);
        const due_time = Number(dueTime)*3600000;
        return (((Date.parse(created_at)+due_time)-Number(Date.now()))/1000);
    }
    formatTime=(time)=>{
        //convert time to timestamp and compare with current timestamp
        if(!time) return;
        if(!window.luxon)return;
        const format = time.trim().replace(' ',"T")
        const ms = window.luxon.DateTime.fromISO(format, {zone: 'Africa/Accra'});
        const diff = Date.now()-Date.parse(ms);
        const sec=((diff)/(1000));
        return ESTIMATE_TIME(sec);
    }
    markUnreadMessages=(allocationId)=>{
        const formatRoute = `${generateFiveRandomNumbers()}_${allocationId}_${generateFiveRandomNumbers()}`;
        const encodedRoute= window.btoa(window.btoa(formatRoute));
        return `/dashboard/projects/${encodedRoute}`
    }

    render(){
        const {allocations, type} = this.state;
        return(
            <div className="row ">
                <div className="col-12" >
                    <Typography variant="h5" className="mb-3 pl-1">Projects</Typography>
                    <Typography variant="subtitle1" className="small text-primary">
                        Note that to assign a project to professional, you need an active subscription
                    </Typography>
                </div>
                <div className='col-12 my-2 d-flex justify-content-end'>
                    <Link className='rounded btn-primary p-2' 
                        to="/dashboard/tag-professional">
                        Create new project
                    </Link>
                </div>
                <div className='col-12 my-2' style={{position:'sticky', zIndex: 500, top:66}}>
                    <Filter  />
                </div>                
                <div className="col-12">
                    <div className="row" >
                    {(Object.entries(allocations).length)?
                        <div className='col-12'>
                            <Paper variant='outlined'  square
                                className='d-flex justify-content-center py-2 mb-1 w3-light-grey'
                                style={{marginLeft:'3px'}}
                            >
                                <b>Total {type && `${type}`} project(s): {allocations.total && `${allocations.total}`}</b>                        
                            </Paper>
                        {allocations.data && allocations.data.length?
                            <div className='row ml-1'>
                            { allocations.data.map((project, index) => (
                                <div key={index} className="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-2 pl-0">
                                  <div className="w3-animate-right card" style={{height:'300px'}}>
                                    <div className="card-body text-center">
                                        <small className='w3-text-teal' style={{fontSize:'80%'}}>{`${LUX_ZONE(project.created_at)}`}</small>
                                        {<Avatar alt="avatar1" className="img-fluid mx-auto"
                                            src={
                                                project.professional?
                                                `${
                                                    project.professional.profilePic?
                                                     `${project.professional.profilePic}` :
                                                     `${noImage}`
                                                }`
                                                :
                                                project.owner.profilePic?
                                                    `${project.owner.profilePic}`:
                                                    `${noImage}`
                                            }
                                            style={ avatarStyle }
                                         />}
                                        <div className="font-weight-bold" style={{cursor:'pointer'}}>
                                            @{project.professional?`${project.professional.userName}`:`${project.owner.userName}`}
                                        </div>
                                        <small className="small"><i className="fa fa-badge"></i>{`${project.description.slice(0,18)}...`}</small>
                                        <div className="mt-3">
                                            {project.status === 2&&
                                                <div>
                                                    <div className="text-success smallerText">{`Completed: ${this.formatTime(project.statusTime)}`}</div>
                                                </div>
                                            }
                                            {project.status === 1 &&
                                                <div>
                                                <div>
                                                    <div className="text-primary smallerText">{`Accepted: ${this.formatTime(project.statusTime)}`}</div>
                                                </div>
                                                    {/*(this.estimateDueTime(project.created_at, project.timeLimit) > 0)?
                                                        <div>
                                                            <div>Due Time:</div>
                                                            <div className={(this.estimateDueTime(project.created_at, project.timeLimit) > 3600)?
                                                                'text-primary': 'text-danger'
                                                            }>
                                                                {`${ESTIMATE_TIME(this.estimateDueTime(project.created_at, project.timeLimit))}`}
                                                            </div>
                                                        </div>:
                                                        <div className='text-danger'>
                                                            <div>Out of Time:</div>
                                                            <div>{`${ESTIMATE_TIME(this.estimateDueTime(project.created_at, project.timeLimit))}`}</div>
                                                        </div>
                                                    */}
                                                </div>
                                            }
                                            {project.status === 3 &&
                                                <div>
                                                    <div className="text-danger smallerText">{`Rejected: ${this.formatTime(project.statusTime)}`}</div>
                                                </div>
                                            }
                                            {project.status === 4 &&
                                                <div>
                                                    <div className="text-primary smallerText">{`Checked: ${this.formatTime(project.statusTime)}`}</div>
                                                </div>
                                            }
                                            {project.status === 0&&
                                                <div>
                                                    <div className="text-dark smallerText">Unchecked</div>
                                                </div>
                                            }
                                            {project.status === 5&&
                                                <div>
                                                    <div className="w3-text-orange smallerText">Rejected</div>
                                                </div>
                                            }


                                        </div>
                                        <div className="smallerText">Status: {project.professional?
                                             <span className='text-success'>Owner</span>:
                                             <span className='text-primary'>Professional</span>
                                            }
                                            <span className='badge text-white bg-success mx-2'>{project.unread_count}</span>
                                        </div>
                                        <div className="mt-2">
                                            <Link to={this.markUnreadMessages(project.id)} className='btn btn-light btn-sm border rounded-pill'>View</Link>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                            ))}
                                <div className="col-12 text-right">
                                  {(allocations.next_page_url) && <button className="btn rounded-pill border" onClick={this.getAllocations}><span className="small">Next</span></button>}
                                </div>
                            </div>:
                            <div className='my-2 d-flex justify-content-center py-2'>
                                <div className=' my-5 font-weight-bold text-black-50' style={{fontSize:"28px"}}>
                                    No {type && `${type}`} project yet. 
                                </div>
                            </div>
                      }
                      </div>:
                      <div className='row  ml-1'>
                       {this.props.participant.email_verified_at  && <b>Loading...</b>}
                      </div>

                    }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return{
    participant: state.participant,
    initialAllocations: state.initialAllocations,
    display: state.display,
    loadingAllocation: state.loadingAllocation,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        fetchInitialAllocations: (payload)=>dispatch(loadAllocation(payload)),
        displayAllocation: (payload)=>dispatch(allocationToDisplay(payload)),
    }
}

const withRouter=Child=>props=>{
    const params = useParams();
    const navigate = useNavigate();
    // const match = useMatch();
    // alert();
    // match={match}
    return(
        <Child 
        {...props} 
        params={params} 
        navigate={navigate} 
        />
 )
}

export default  connect(mapStateToProps,mapDispatchToProps)(withRouter(Projects));
