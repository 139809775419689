import { Typography, Box } from '@material-ui/core';

const TemplateLibrary = () => {
    return (
        <Box py={5} mb={5} className="libraryBackground my-1" style={{backgroundColor: '#ffffff'}}>
            <Box className="container text-center">
                <Typography variant="h4" 
                    className="sectionTitle font-weight-bold mb-2">
                    Explore Our Templates Library
                </Typography>
                <Typography variant="subtitle1" className="heroSecondText text-secondary mb-3">
                    Design on the go with templates from our amazing collection of curated designs
                </Typography>
                <Box className="container mx-auto mt-4">
                    <Box className="row libraryRow">
                        <Box className="col-6 col-md-4 libraryCard templateImgColumn">
                            <img className="libraryImage"  
                                src={ require('../../../assets/images/website/homepage/template-birthday.jpg') } 
                                alt="Birthdays" />
                            <Typography variant="h6" className="libraryCaption">
                                Birthdays
                            </Typography>
                        </Box>
                        <Box className="col-6 col-md-4 libraryCard templateImgColumn">
                            <Box className="col-12 libraryCard templateImg">
                                <img className="libraryImage"  
                                    src={ require('../../../assets/images/website/homepage/template-christmas.jpg') } 
                                    alt="Christmas" />
                                <Typography variant="h6" className="libraryCaption">
                                    Christmas
                                </Typography>
                            </Box>
                            <Box className="col-12 libraryCard templateImg templateImgSmall">
                                <img className="libraryImage"  
                                    src={ require('../../../assets/images/website/homepage/template-newyear.jpg') } 
                                    alt="New Year" />
                                <Typography variant="h6" className="libraryCaption">
                                    New Year
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="col-12 col-md-4 libraryCard templateImgColumn">
                            <Box className="row libraryCard">
                                <Box className="col-5 libraryCard templateImgSmall">
                                    <img className="libraryImage"  
                                        src={ require('../../../assets/images/website/homepage/template-valentine.jpg') } 
                                        alt="Valentine" />
                                    <Typography variant="h6" className="libraryCaption">
                                        Valentine
                                    </Typography>
                                </Box>
                                <Box className="col-7 libraryCard templateImgSmall">
                                    <img className="libraryImage"  
                                        src={ require('../../../assets/images/website/homepage/template-business.jpg') } 
                                        alt="Business Card" />
                                    <Typography variant="h6" className="libraryCaption">
                                        Business Card
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="col-12 libraryCard templateImg">
                                <img className="libraryImage"  
                                    src={ require('../../../assets/images/website/homepage/template-greetings.jpg') } 
                                    alt="Greetings" />
                                <Typography variant="h6" className="libraryCaption">
                                    Greetings
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );  
}

export default TemplateLibrary;