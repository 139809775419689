import React, { useEffect, useState } from 'react';
import './App.css';
import NotFound from './NotFound';
import { connect, useDispatch } from 'react-redux';
import { intendedPage, showLoginUpModal, showAuthModal } from './global/action';
import Footer from './components/website/Footer';
import LazyLoader from './LazyLoader';
import HomePageNavbar from './components/website/HomePageNavbar';
import { useLocation,  useRoutes, useSearchParams } from 'react-router-dom';
import { authRoutes, noAuthRoutes } from './OuterRoutes';
// import UpdateBox from './Update';

const App =({ setIntendedPage })=>{
	const outerRoutes = useRoutes(noAuthRoutes);
	const AuthRoutes = useRoutes(authRoutes);
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch()
	const [fetching] = useState(false);
	const {pathname} = useLocation()

	useEffect(()=>{
		if(pathname.includes('/dashboard')){
			setIntendedPage(pathname);
		}
	},[pathname, setIntendedPage]);
	
	useEffect(()=>{
		if(pathname === "/" && searchParams.get("redirect")){
			dispatch(showLoginUpModal(true));
			dispatch(showAuthModal(true));
		}
	},[pathname, searchParams])

	useEffect(() => window.scrollTo(0, 0));
	
	return(
		<React.Fragment>
			{fetching?
				<div style={{height: `${window.innerHeight}px`}}>
					<div><LazyLoader /></div>
				</div>:		
				<div>
					{pathname.includes('/user') ||pathname.includes('/templates') || pathname.includes('/dashboard')?
						<div>
							{/* <UpdateBox /> */}
							{AuthRoutes || <NotFound />}
						</div>:
						<div>
							<div>
								<HomePageNavbar />
								{/* <UpdateBox /> */}
								{outerRoutes || <NotFound />}
								<div className='mt-5'>
									<Footer />
								</div>
							</div>												
						</div>
					}
				</div>	
			}
		</React.Fragment>
	)
}

const mapDispatchToProps=(dispatch)=>{
	return{
		setIntendedPage:(payload)=>dispatch(intendedPage(payload)),
	}
}
export default connect(null, mapDispatchToProps)(App);
