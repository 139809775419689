import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import axios from 'axios';
import { connect } from 'react-redux';
import { EXTRACTOR } from '../../global/generalMethods/general';
import { PUT_BEFORE, TOKES } from '../../global/constants/action_types';
import { processHeader } from '../../global/action';



axios.defaults.headers = {
	// 'Accept' : 'Application/json',
	'Content-Type': 'multipart/form-data',
	'Authorization' : `Bearer ${EXTRACTOR(PUT_BEFORE, localStorage.Token, TOKES)}`
  }
class Gallery extends Component{

    state= {
        free:true,
        displayFiles:[],
    }

    componentDidMount=()=>{
    console.log(this.props, 'gallery');
    }

    handleChange=(e)=>{
        e.preventDefault();
        console.log(e.target.files)
        const Temp = [];
        for (let i = 0; i < e.target.files.length; i++) {
            Temp.push(e.target.files[i]);
        }
        // this.setState({displayFiles:Temp});
    }



    handlePost=()=>{
        if(this.state.free){
            if(this.state.displayFiles.length > 0){
                const formMedia = new FormData();
                for (let i = 0; i < this.state.displayFiles.length; i++) {
                    formMedia.append(`gallery_files[${i}]`, this.state.displayFiles[i])
                }
                formMedia.append('gallery_id', 1);
                formMedia.append('gallery_category_id', 2);
                formMedia.append('type', 3);
                formMedia.append('title', 'from frontend test');
                console.log(formMedia.getAll('gallery_files'))
                axios.post(`http://localhost:8000/api/gallery_post/${this.props.participant.userName}`,
                 formMedia, {headers:processHeader()})
                .then(res=>{
                console.log(res.data)
                })
                .catch(err=>{
                   if(err) console.log(err.response)
                })
            }
        }
    }

    deleter=(e)=>{
        e.preventDefault();
        const go = window.confirm("Do you want to remove this file?");
        if(!go) return;
        console.log(e.target.id)
        const collector = [...this.state.displayFiles];
        collector.splice(Number(e.target.id), 1);
        this.setState({displayFiles: collector});
        console.log(this.state);
    }

    render(){
        return(
            <div className="componentBg">
                <Typography>Upload and preview image</Typography>
                <Box>
                    <label className="btn btn-outline-secondary mt-2 mr-2">
                        <CameraAltIcon />
                        <input type="file" className="d-none" name='galleryFiles[]' multiple onChange={this.handleChange} />
                    </label>
                    <button className="btn btn-outline-secondary" onClick={this.handlePost}>Upload Gallery</button>
                </Box>
                <Box>
                    {this.state.displayFiles?
                    <div>
                        {this.state.displayFiles.map((val, index)=>{
                         return <img alt="" key={index} id={index} style={{margin:'4px'}} onClick={this.deleter} src={URL.createObjectURL(val)} width="100" height="100" />
                        })}
                    </div>:
                    <div></div>
                    }
                </Box>
            </div>
        )
    }
}

const mapStateToProps=(state)=>{
    return{
        participant: state.participant
    }
}

export default connect(mapStateToProps)(Gallery);
