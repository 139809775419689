import React, { Component } from 'react';
import { Avatar, Button, Typography, Box, IconButton } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import StarsIcon from '@material-ui/icons/Stars';
import { connect } from 'react-redux';
import { getAllCategory, processHeader, fetchProfessionals } from '../../global/action';
import Axios from 'axios';
import { EXT_PROXY, PROXY } from '../../global/constants/action_types';
import {noImage} from '../../global/generalMethods/general';
import { Modal } from 'react-bootstrap';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CUstomModal } from './CustomModal';
import { Nosubscrition } from './NoSubscription';

class TagProfessional extends Component {
    state = {
        minimumUploadAltered:false,
        minimumUploadAlteredMessage:'',
        participant:{},
        showModal: false,
        name: 'init',
        matchedSub: [],
        allocationDescription: null,
        displayFiles: [],
        selectedCategory: null,
        selectedSubcategory: null,
        selectedProfessionalName: null,
        category_subcategoryError:false,
        professionals: {},
        timeLimit: null,
        timeLimitError: false,
        showProfessionals: false,
        gettingProfessional:false,
        descriptionError: false,
        professionalNameError: false,
        processingSubmit: false,
        showProfessionalButton: false,
        zeroProfessional: false,
        modal:false,
        successful: false,
        somethingWentWrong: false,
        canAllocate: false, // Status from user's current subscription 
        modalTrigger: false,
    }

    timerHolder;
    mounted;
    lister;

    UNSAFE_componentWillMount=()=>{
        if(!this.props.participant.email_verified_at) return;
        this.props.categories();
        this.getUsersSubscription();
    }    
    componentDidMount=async()=>{
        this.mounted= true;
        if(!this.props.participant.email_verified_at) return;
        if(Object.entries(this.props.participant).length > 0){
            (this.mounted) &&  await this.setState({participant:this.props.participant});
        }
    }
    UNSAFE_componentWillReceiveProps=async(next)=>{
        if(Object.entries(next.professionalList).length > 0){
            (this.mounted) && await this.setState({professionals: next.professionalList});
            // alert(JSON.stringify(this.state.professionals));
        }
        console.log(next.categoryList, this.props.categoryList);
        if(next.categoryList?.length && !this.props.categoryList.length){
            // check if there's a saved allocation in progress
            await this.getSavedAllocation(next.categoryList)
        }

    }
    componentWillUnmount=()=>{
        this.mounted= false;
    }
    /** Get's users current subscription and checks if elligible to create project */
    getUsersSubscription=async()=>{
        try {
            const url = `${EXT_PROXY}/api/subscriber/current/${this.props.participant.uuid}`
            const res = await fetch(url)
            const {current_info} = await res.json()
            this.setState({canAllocate: current_info.Elligible})
        } catch (error) {
            if(!this.mounted) return
             this.setState({canAllocate: false})
          }
    }
    showProfessionalModal=async(e)=>{
        e.preventDefault();
        console.log(this.state.participant);
        if(!this.state.canAllocate){
            await this.setState({modalTrigger:true})
            this.saveAcitveAllocation()
            return
        }
        await this.setState({modal:true});
        await this.virtualScrolling();
    }
    modalCloser=(e)=>{
        e?.preventDefault()
        this.setState({modalTrigger:false})
    }
    getProfessionals =()=>{
        if(!this.mounted || this.state.gettingProfessional) return;
        let url;
        if(Object.entries(this.state.professionals).length>0 && this.state.professionals.next_page_url){
            url = this.state.professionals.next_page_url
        }else if(Object.entries(this.state.professionals).length>0 && !this.state.professionals.next_page_url){
            return;
        }else{
            url = `${PROXY}/api/get_specified_professionals/${this.state.participant.userName}/${this.state.selectedCategory}/${this.state.selectedSubcategory}`
        }
        this.setState({gettingProfessional:true});
        Axios.get(url, {headers:processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return
                const incomingData =[...res.data.data.data];
                this.mounted && await this.setState({showProfessionals: true});
                if(Object.entries(this.state.professionals).length>0){
                    res.data.data.data =[...this.state.professionals.data,...incomingData];
                    await this.props.getProfessionals(res.data.data);
                }else{
                    await this.props.getProfessionals(res.data.data);
                }
                this.mounted && await this.setState({gettingProfessional:false});
            }
        }).catch(err=>{
            if(!this.mounted) return
            this.setState({gettingProfessional:false});
        })

    }
    /** find subcategory for selected category */
    categoryToSubHandler = async(category, categoryList=[])=>{
        const selected = categoryList.find(val => val.name === category);
        console.log({selected});
        if (selected) {
            if(!this.mounted) return
            await this.setState({selectedCategory: selected.id});
            await this.setState({matchedSub:selected.subcategory });
            await this.setState({selectedProfessionalName: null});
        }
    }
    /** Compute the professional's  category and subcategory from selected subcategory*/
    subcategoryHandler=async(subcategory)=>{
        const selected = this.state.matchedSub.find(val=>val.name === subcategory);
        if(!this.mounted) return
        if(selected)  await this.setState({selectedSubcategory: selected.id});
        await this.props.getProfessionals({});
        await this.setState({professionals:{}});
        await this.setState({category_subcategoryError: false});
        await this.getProfessionals();
        await this.setState({showProfessionalButton: true});
        await this.setState({selectedProfessionalName: null});
    }
    /** Save active data till subscription is completed  */
    saveAcitveAllocation=()=>{
        const data = {};
        const {selectedCategory, selectedSubcategory, allocationDescription} =  this.state
        if(selectedCategory) data.selectedCategory = document.getElementById('categoryBox').value 
        if(selectedSubcategory) data.selectedSubcategory = 
                document.getElementById('subcategoryBox').value 
        if(allocationDescription) data['allocationDescription'] = allocationDescription
        localStorage.activeAllocation = JSON.stringify(data)
    }
    /**Get saved data to resume project allocation */
    getSavedAllocation=async(categoryList=[])=>{
        let activeAllocation = localStorage.activeAllocation
        if(!activeAllocation || !this.mounted) return
        activeAllocation = JSON.parse(activeAllocation);
        const {selectedCategory, selectedSubcategory, allocationDescription} = activeAllocation
        if(selectedCategory) {
            await this.categoryToSubHandler(selectedCategory, categoryList)
            document.getElementById('categoryBox').value = selectedCategory;
        } 
        if(selectedSubcategory){
            console.log({selectedSubcategory});
            await this.subcategoryHandler(selectedSubcategory)
            document.getElementById('subcategoryBox').value = selectedSubcategory;
        }
        if(allocationDescription){
            this.setState({allocationDescription: allocationDescription})
            document.getElementById('descriptionId').value = allocationDescription;
        }
    }
    /** Clear active data from local storage */
    clearActiveAllocation=()=>{
        localStorage.removeItem("activeAllocation")
    }
    clearTimeHolder=async()=>{
        await clearTimeout(this.timerHolder);
        await this.setState({gettingProfessional:false});
    }

    prepareSearchedProfessionals =async(e)=>{
        e.preventDefault();
        const searchedKey = e.target.value.trim();
        await this.clearTimeHolder();
        if(!searchedKey){
            await this.setState({professionals: this.props.professionalList})
            await this.setState({gettingProfessional:false});
            return;
        }
        const {selectedCategory, selectedSubcategory} = this.state;
        if(typeof(selectedSubcategory) ==='number' && typeof(selectedCategory) ==='number'){
            const tempData = Object.assign({}, this.state.professionals);
            let matchedSearch;
            await this.setState({gettingProfessional:true});
            if(tempData.data && tempData.data.length > 0){
                matchedSearch = await tempData.data.filter(data=>{
                    return (
                        data.userName.toLowerCase().includes(searchedKey.toLowerCase()) ||
                        data.firstName.toLowerCase().includes(searchedKey.toLowerCase()) ||
                        data.lastName.toLowerCase().includes(searchedKey.toLowerCase())
                    )
                })
                if (matchedSearch && matchedSearch.length > 0){
                    tempData.data = matchedSearch;
                    await this.setState({professionals: tempData});
                    await this.setState({gettingProfessional:false});
                    return;
                }
                else{
                    this.timerHolder=setTimeout((()=> this.getSearchedProfessionals(searchedKey)),2000);
                }
            }
            else{
                if(searchedKey) this.timerHolder=setTimeout((()=> this.getSearchedProfessionals(searchedKey)),2000);
            }

        }else{
            await this.setState({category_subcategoryError: true});
        }
    }
    getSearchedProfessionals =(search)=>{
        if(!search.trim()) return;
        const url = `api/get_searched_professionals/${this.state.participant.userName}/${this.state.selectedCategory}/${this.state.selectedSubcategory}/${search}`
        Axios.get(`${PROXY}/${url}`, {headers:processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                await this.setState({showProfessionals: true})
                await this.setState({professionals: res.data.data});
                await this.setState({gettingProfessional:false});
            }
        }).catch(err=>{
            if(!this.mounted) return;
            this.setState({gettingProfessional:false});
        })
    }
    handleProceed=async(e)=>{
        e.preventDefault();
        if(!this.state.selectedProfessionalName){
            await this.setState({professionalNameError: true});
            return;
        }
        if(this.lister) this.lister.removeEventListener('scroll', this.getProfessionals);
        await this.setState({modal:false});
    }
    processCatToSub=async(e)=>{
        e.preventDefault();
        console.log(e.target.value);
        await this.categoryToSubHandler(e.target.value, this.props.categoryList)
    }
    processSub=async(e)=>{
        e.preventDefault();
        await this.subcategoryHandler(e.target.value)
    }
    processProfessionalName=async(professionalName)=>{
        const {selectedProfessionalName} = this.state
        if(selectedProfessionalName){
            document.getElementById(`${selectedProfessionalName}`).style.background='white';
            document.getElementById(`${selectedProfessionalName}`).style.color='black';
        }
        await this.setState({selectedProfessionalName: professionalName});
        await this.setState({professionalNameError: false});
        document.getElementById(`${selectedProfessionalName}`).style.background='black';
        document.getElementById(`${selectedProfessionalName}`).style.color='white';
    }
    processDescription = async(e)=>{
        e.preventDefault();
        const description = e.target.value.trim();
        await this.setState({descriptionError: false});
        if(description.length < 10){
            await this.setState({descriptionError: true});
                return
        }
        await this.setState({allocationDescription: description})
    }
    handleFileUploadChange=async(e)=>{
        e.preventDefault();
        const fileCollector = e.target.files;
        await this.setState({minimumUploadAltered:false});
        if(fileCollector.length > 4){
            await this.setState({minimumUploadAlteredMessage: 'You can only send maximum of Four files'})
            await this.setState({minimumUploadAltered:true});
            return;
        }
        const Temp = [];
        let storeSize=0;
        for (let i = 0; i < fileCollector.length; i++) {
            Temp.push(fileCollector[i]);
            storeSize += fileCollector[i].size;
        }
        if(storeSize > 12582912){
            await this.setState({minimumUploadAlteredMessage: 'File(s) too large, maximimum of 12MB is allowed'})
            await this.setState({minimumUploadAltered:true});
            return;
        }
        await this.setState({displayFiles:Temp});
    }
    handleTimeLimit=async(e)=>{
        e.preventDefault();
        let time = e.target.value.toString().trim();
        time = time.replace('.', '');
        time =  time.replace('+','');
        time = time.replace('-','');        
        document.getElementById('time').value = Number(time);
        await this.setState({timeLimitError: false});
        if(time < 18){
            await this.setState({timeLimitError: true});
            return;
        }
        await this.setState({timeLimit: Number(time)});
    }
    allocate=async()=>{
        if(!this.props.participant.email_verified_at) return;
        if(!this.state.processingSubmit){
            await this.setState({successful: false});
            await this.setState({somethingWentWrong: false});
            const formMedia = new FormData();
            if(this.state.displayFiles.length > 0){
                for (let i = 0; i < this.state.displayFiles.length; i++) {
                    formMedia.append(`allocationFile[${i}]`, this.state.displayFiles[i]);
                }
            };
            if(!this.state.allocationDescription ){
                await this.setState({descriptionError: true});
                return
            }            
            if(!this.state.selectedCategory || !this.state.selectedSubcategory
                || typeof(this.state.selectedSubcategory) !== 'number' || typeof(this.state.selectedCategory) !== 'number'){
                await this.setState({category_subcategoryError:true});
                return;
            }
            if(!this.state.selectedProfessionalName){
                await this.setState({professionalNameError: true});
                return;
            }
            if(!this.state.timeLimit){
                await this.setState({timeLimitError:true});
                return;
            }
            await this.setState({processingSubmit: true});
            formMedia.append('category', this.state.selectedCategory);
            formMedia.append('subcategory', this.state.selectedSubcategory);
            formMedia.append('timeLimit', this.state.timeLimit);
            formMedia.append('description', this.state.allocationDescription);
            formMedia.append('professional_name', this.state.selectedProfessionalName)

            Axios.post(`${PROXY}/api/allocate/${this.state.participant.userName}`,
                formMedia, {headers:processHeader()})
            .then(async(res)=>{
                if(res.data.success){
                    if(!this.mounted) return;
                    //remove the active allocation from localStorage if any
                    await this.setState({selectedCategory: null});
                    await this.setState({selectedSubcategory: null});
                    await this.setState({selectedProfessionalName: null});
                    await this.setState({allocationDescription: null});
                    if(this.mounted) await this.setState({displayFiles:[]});
                    if(this.mounted) await this.setState({showProfessionalButton: false});
                    if(this.mounted) await this.setState({successful: true});
                    if(this.mounted) await this.setState({processingSubmit: false});
                    document.getElementById('descriptionId').value = '';
                    document.getElementById('categoryBox').value = 'Category';
                    document.getElementById('subcategoryBox').value = 'Subcategory';
                    // Re update canAllocate clause
                    this.getUsersSubscription()
                }else{
                    if(res.data.Error === 'Intruder'){
                        window.localStorage.clear();
                        this.props.history.replace('/');
                    }
                }

            })
            .catch(async(err)=>{
                if(!this.mounted)return;
                //remove the active allocation from localStorage if any
                (this.state.mounted) && await this.setState({somethingWentWrong: true});
                (this.state.mounted) && await this.setState({processingSubmit: false});
            }).finally(()=>{
                this.clearActiveAllocation();                
            })
        }
    }
    deleteFile=async(e)=>{
        e.preventDefault();
        const id = Number(e.target.id);
        const go = await window.confirm("Do you want to remove this file?");
        if(!go) return;
        const collector = [...this.state.displayFiles];
        collector.splice(id, 1);
        if(collector.length < 1){
			const uploader = document.getElementById('icon-button-file');
			uploader.value = null;
		}
        await this.setState({displayFiles: collector});
    }
    virtualScrolling=()=>{
        this.lister = document.getElementById('professionalContainer');
        if(!this.lister) return;
        const loadMoreProfessionals=()=>{
            if(this.lister.clientHeight + this.lister.scrollTop >= this.lister.scrollHeight ){
                if(!this.state.professionals.data || !this.state.professionals.next_page_url) return;
                this.getProfessionals();
            }
        }
        this.lister.addEventListener('scroll', loadMoreProfessionals);
    }
    render() {
       const data = this.props.categoryList;
       const {canAllocate} = this.state
        return (
            <div className="componentBg pb-4">
                {/* No Subscription modal */}
                <CUstomModal trigger={this.state.modalTrigger}  closer={this.modalCloser} >
                    <aside className="w-full px-2 py-3 p-sm-3 max-w-4h">
                        <Nosubscrition participant={this.state.participant} />
                    </aside>
                </CUstomModal>
                <form>
                    <Box className="row">
                        <Box className="col-12">
                            <Typography variant="h6" className="mb-2">Get Your Project Done Here</Typography>
                            <div className='d-flex justify-content-center mb-4 w3-small w3-text-blue'>
                                <div>
                                    If you need help on how to assign your project to a professional,
                                     please click 
                                    <a href='https://www.youtube.com/watch?v=12a1Q62NnGI&feature=youtu.be'
                                        target='_blank' rel="noopener noreferrer"
                                        className='mx-1 customPrimaryBgColor px-2 text-white rounded-pill '
                                        style={{cursor:'pointer', textDecoration:'none'}}
                                    > 
                                        <b>HERE</b>
                                    </a>
                                      to watch the video.
                                </div>
                            </div>
                            <Box className="d-flex align-items-center">
                                <div className="tag-professional-group">
                                    <textarea 
                                        type="text" id='descriptionId' 
                                        className="form-control rounded"
                                        placeholder="Describe your project here"
                                        onBlur={this.processDescription}
                                        maxLength='1000'
                                        onChange={(e)=>{if(this.state.descriptionError){this.processDescription(e)}}}
                                    >
                                    </textarea>
                                    { canAllocate && 
                                        <div className="btn btn-default searchIcon icon">
                                            <input title='Click here to upload file(s)' accept="image/*" multiple 
                                                className="d-none" id="icon-button-file" 
                                                onChange={this.handleFileUploadChange} type="file" 
                                            />
                                            <label htmlFor="icon-button-file">
                                                <IconButton color="default" aria-label="upload picture" component="span">
                                                <AttachmentIcon />
                                                </IconButton>
                                            </label>
                                        </div>
                                    }
                                </div>
                            </Box>
                            <Box className='d-flex justify-content-center'>
                                {this.state.minimumUploadAltered && 
                                    <div className='text-danger'>
                                        <small>{this.state.minimumUploadAlteredMessage}</small>
                                    </div>
                                }
                                {this.state.descriptionError &&
                                    <div className='text-danger'>
                                        <small>
                                            Description is required with minimum 10 characters
                                         </small>
                                    </div>
                                }                                
                            </Box>
                        </Box>
                    </Box>
                    <Box className="row mt-1">
                        <Box className="col-sm-12 col-md-2 col-lg-2 col-xl-2"></Box>
                        <Box className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            {(this.state.displayFiles.length > 0) &&
                                <Box className="row justify-content-center mt-2">
                                    {this.state.displayFiles.map((val, index)=>
                                        <Box key={index} className="">
                                            <img alt=""  id={index} style={{margin:'6px'}} onClick={this.deleteFile}
                                                src={URL.createObjectURL(val)} width='100' height='120' className='w3-round'
                                            />
                                        </Box>
                                    )}
                                </Box>
                            }
                            <Typography variant="subtitle1" 
                                className="font-weight-bold text-center smallerText mt-3 mb-2"
                            >
                                Select a Category and Subcategory
                            </Typography>
                            <div className=" smallerText">
                                <select id='categoryBox' onChange={this.processCatToSub} className="form-control rounded mr-1">
                                    <option> Category</option>
                                    { data? data.map((val,index)=><option key={index}>{val.name}</option> ) : ''}
                                </select>
                                <br />
                                <select id='subcategoryBox' onChange={this.processSub} className="form-control rounded ml-1">
                                    <option>Subcategory</option>
                                    {this.state.matchedSub && this.state.matchedSub.map((val,index)=><option key={index}>{val.name}</option> )}
                                </select>
                            </div>
                            {this.state.category_subcategoryError && 
                                <div className='text-danger'>
                                    <small>Please select the category and subcategory of you project</small>
                                </div>
                            }
                            {/*<Typography variant="subtitle1" className="font-weight-bold text-center smallerText mb-2">Select a Professional to do your job</Typography>*/}
                            {(this.state.showProfessionalButton)  &&
                                <div className="text-center py-2">
                                    <button onClick={this.showProfessionalModal}
                                        className="tag-pro-btn rounded"
                                    >
                                        <span className="text-primary">Select a Professional to do your job</span>
                                    </button>
                                    {this.state.selectedProfessionalName &&
                                        <div 
                                            className='my-1 small text-info'
                                        >
                                            You have selected <b>{this.state.selectedProfessionalName}</b> as the professional
                                        </div>
                                    }
                                </div>
                            }
                            <Modal
                                show={this.state.modal}
                                size="md"
                                onHide={()=>{this.setState({modal:false})}}
                            >
                                <Modal.Body className='px-2 py-1'>
                                    <div className='mb-1 py-2' style={{position:'relative'}}>
                                        <Typography variant="h6">
                                            Tag A Professional
                                        </Typography>
                                        <div className='px-2 py-1 w3-circle w3-light-grey'
                                            onClick={()=>{this.setState({modal:false})}} 
                                            style={{position:'absolute', top:0, right:0, cursor:'pointer'}}>
                                            <button className="btn px-2">&times;</button>
                                        </div>
                                    </div>
                                    <div>
                                        <input type="search" onKeyUp={this.prepareSearchedProfessionals}
                                            className="form-control rounded-pill" 
                                            placeholder="Search for a professional" 
                                        />
                                    </div>
                                    { this.state.gettingProfessional &&
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="spinner-grow text-muted"></div>
                                            <div className="spinner-grow text-info"></div>
                                        </div>
                                    }
                                    {this.state.professionalNameError && 
                                        <div className='text-center text-danger'>
                                            <small>Please select a professional</small>
                                        </div>
                                    }

                                    { this.state.professionals.data &&
                                        <div id='professionalContainer' style={{maxHeight: '70vh', overflowY:'auto'}}>
                                            {(this.state.professionals.data.length>0) ?
                                                <div className='mb-1 w3-card pt-2'>
                                                    <Typography className='ml-3 my-2'>Suggested</Typography>
                                                    { this.state.professionals.data.map((data, index) => (
                                                        <div key={data.userName} 
                                                            onClick={()=>{this.processProfessionalName(data.userName)}}
                                                            className="d-flex w3-round align-items-center my-3
                                                             w3-hover-pale-blue"
                                                            id={data.userName}
                                                        >
                                                            <div className="d-flex align-items-center flex-fill">
                                                                <Avatar alt="avatar1" className="img-fluid mr-2 border" 
                                                                    src={data.profilePic? data.profilePic: noImage}
                                                                    style={{width:'50px', height:'50px'}}
                                                                />
                                                                <div className="d-flex flex-column">
                                                                    <div className="font-weight-bold" style={{fontSize: "13px"}}>
                                                                        @{ data.userName.substr(0,19) }
                                                                    </div>
                                                                    <small>
                                                                        {`${ data.firstName} ${data.lastName}`}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                            <div className="ml-auto my-auto d-flex align-items-center" style={{width: '100px'}}>
                                                                {(this.state.selectedProfessionalName === data.userName) &&
                                                                     <CheckCircleIcon className='mx-1' /> 
                                                                }
                                                                <span>
                                                                    <StarsIcon fontSize="small" className="text-warning mr-1" />
                                                                </span>
                                                                <Typography variant="subtitle2" 
                                                                    className="align-self-center pr-1"
                                                                >
                                                                    { data.ratings} points
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    ))}                                                    
                                                </div>:
                                                <div className='text-center'>
                                                    <small><b>Sorry, No professional found for this category or name right now.</b></small>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <div className="text-center">
                                        <button onClick={this.handleProceed} 
                                            className="btn px-4 mt-1 customPrimaryBgColor text-white rounded-pill"
                                        >
                                            Proceed
                                        </button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            {this.state.professionalNameError && 
                                <div className='text-danger text-center'>
                                    <small>Please assign your work to a professional</small>
                                </div>
                            }
                        </Box>
                        <Box className="col-sm-12 col-md-2 col-lg-2 col-xl-2"></Box>
                    </Box>
                    <Box className="row mt-1">
                        <Box className="col-sm-12 col-md-2"></Box>
                        <Box className="col-12 col-md-8">
                            <Typography variant="subtitle1" className="font-weight-bold text-center smallerText">
                                Set Time Limit
                            </Typography>
                            <div className="text-center">
                                <small> Note: The minimum is 18 hours</small>
                                {this.state.timeLimitError &&
                                    <div className=''>
                                        <small className='text-danger'>
                                            Minimum time should not be less than be 18 hours
                                        </small>
                                    </div>}
                            </div>
                            <Box mt={3}>
                                <div className="form-group">
                                    <input id='time' type="number" min='18'
                                         className="form-control" placeholder="e.g (24)"
                                        onChange={this.handleTimeLimit}
                                    />
                                </div>
                                <div className='my-2 text-center' >
                                    {this.state.processingSubmit &&
                                        <div className='d-flex justify-content-center'>
                                            <div className='w3-spin spinner w3-circle mt-1'></div>
                                        </div>
                                    }
                                    {this.state.successful &&
                                        <div className='d-flex justify-content-center'>
                                            <div className='mt-2 w3-text-blue font-weight-bold'>
                                                Your project was successfully submitted
                                            </div>
                                        </div>
                                    }
                                    {this.state.somethingWentWrong &&
                                        <div className='d-flex justify-content-center'>
                                            <div className='mt-2 text-danger small'>Something went wrong, please try again </div>
                                        </div>
                                    }
                                </div>

                                <Button variant="contained" disableElevation 
                                    className="customPrimaryBgColor btn-block text-white rounded-pill" 
                                    onClick={this.allocate}
                                >
                                    Finish
                                </Button>
                            </Box>                            
                        </Box>                        
                    </Box>
                </form>
            </div>
        );
    }
}

const mapStateToProps =(state)=>({
    participant: state.participant,
    categoryList: state.categories,
    professionalList: state.professionals
})

const mapDispatchToProps =(dispatch)=>({
    categories: ()=>dispatch(getAllCategory()),
    getProfessionals: (payload)=>dispatch(fetchProfessionals(payload)),
})

export default connect (mapStateToProps, mapDispatchToProps)(TagProfessional);
