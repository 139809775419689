import { Avatar, Box, Paper, Typography } from '@material-ui/core';
import { Attachment, ChevronRight } from '@material-ui/icons';
import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { processHeader } from '../../../global/action';
import { PROXY } from '../../../global/constants/action_types';
import { CHAT_LIKE_TIME, staticPusherConfig } from '../../../global/generalMethods/general';
import NotFound from '../../../NotFound';
import SomethingWentWrong from '../../../SomethingWentWrong';
import { useNavigate } from "react-router-dom";

const classes = {
	userChatStyle: {
		float:'right',
		maxWidth: '85%',
		backgroundColor: '#F9FBFF',
		borderRadius: '15px 0 15px 15px',
	},
	otherChatStyle: {
		float:'left',
		maxWidth:'85%',
		borderRadius: '0 15px 15px 15px',
	},
	chatInput: {
		height: '50px',
	},
	chatInputDiv: {
		position: 'absolute',
		zIndex: '1',
		backgroundColor: '#F9FBFF',
		width: '100%',
		bottom: '0',
		padding: '7px 12px',
	}
}

const AdminChat=({participant, id})=>{

  const mounted = useRef(true);
  const next_url = useRef(null);
  let scrollBox = useRef(window.document.getElementById('scrollBox'));
  let messageHolder = useRef(null);
  let reference = useRef(null);
  const navigate = useNavigate();
  
  const [messageList, setMessageList] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [wentWrong, setWentWrong] = useState(false);
  const [unread, setUnread] = useState(0);
  const [unreadId, setUnreadId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(1);
  const [sendingMessage , SetSendingMessage] = useState(false);
  const [minimumUploadAltered, setMinimumUploadAltered] = useState(false);
  const [minimumUploadAlteredMessage, setMinimumUploadAlteredMessage] = useState('');
  const [messageFile, setMessageFile] = useState([]);
  const [fetchingMoreMessages, setFetchingMoreMessages] = useState(false);
  const [attending, setAttending] = useState('');

  useEffect(()=>{
    const ID = `ADMIN_${participant.userName}`;
    const fetchMessages=()=>{
      Axios.get(`${PROXY}/api/get_participant_message/${participant.userName}`, {headers: processHeader()})
      .then(async(res)=>{
        if(!mounted.current) return;
        await setLoading(false);
        if(res.data.success){
          if(res.data.data.messages.data && res.data.data.messages.data.length < 1){
            if(!mounted.current) return;
						window.localStorage.clear();
            return navigate('/');
          }
          const tempMessage = res.data.data.messages
          tempMessage.data = tempMessage.data.reverse();
          await setMessageList(tempMessage);
          await setAttending(res.data.data.attending);
          messageHolder.current = tempMessage;
          await setUnread(res.data.data.unread);
          next_url.current = tempMessage.next_page_url;
          const firstNoRead = tempMessage.data.find(raw=> raw.read == 0 && raw.isAdmin == 1)
          if(firstNoRead){
            await setUnreadId(firstNoRead.id);
            markUnread();
          }
          const gotoUnread =  document.getElementById('new_messages');
					if(gotoUnread){
						gotoUnread.scrollIntoView();
						window.document.body.scrollTop = 0;
						window.document.documentElement.scrollTop = 0;
					}else{ 
            const lastLine =  document.getElementById('last');    
            if(lastLine){
              lastLine.scrollIntoView();
            }
          }
          prepareSearchMore();
          
        }else{          
          if(!mounted.current) return;
          if(res.data.Error === 'Intruder'){
						if(!mounted.current) return;
						window.localStorage.clear();
            return navigate('/');
          }
          await setMessageList({data:[]})
          await setWentWrong(true);
          await setLoading(false);
        }
      })
      .catch(err=>{

      })
    }

    const verifyId=()=>{
      try {
        if(window.atob(window.atob(id)) !== ID){
          setNotFound(true);
        }        
      } catch (_) {}
    }

    const markUnread =async()=>{
      const url = `${PROXY}/api/participant_mark_admin_chat_unread_messages/${participant.userName}`
      await Axios.get(url, {headers:processHeader()})
    }

    const fetchMoreMessages=async()=>{
      if(fetchingMoreMessages) return;
      if(next_url.current){
        await setFetchingMoreMessages(true);
        Axios.get(`${next_url.current}`, {headers: processHeader()})
        .then(async(res)=>{
          if(res.data.success){
            if(!mounted.current) return;
            const currentData = [...messageHolder.current.data];
            const incomingData = [...res.data.data.messages.data.reverse()];
            res.data.data.messages.data=[...incomingData,...currentData];
            await setMessageList(res.data.data.messages);         
            messageHolder.current = res.data.data.messages;
            next_url.current = res.data.data.messages.next_page_url
            scrollBox.current.scrollTop = 5;
            await setFetchingMoreMessages(false);
          }
        })
        .catch(async(_)=>{
          if(!mounted.current) return;
          await setFetchingMoreMessages(false);
        })
      }
    }

    const scrollBoxEvent=async()=>{
      if(scrollBox.current){
        if(scrollBox.current.scrollTop < 1){  
          await fetchMoreMessages();
        }
      }
    }
    
    const prepareSearchMore=async()=>{
      if(!scrollBox.current){
        scrollBox.current = window.document.getElementById('scrollBox');
      }
      if(scrollBox.current){
        await scrollBox.current.addEventListener('scroll', scrollBoxEvent);
      }
    }

    if(loading && !messageList){
      verifyId();
      fetchMessages();
    }

    return()=>{
      if(scrollBox.current) scrollBox.current.removeEventListener('scroll', scrollBoxEvent);
    }

  },[id, participant, messageList, navigate, loading, fetchingMoreMessages]);

  const handleFileUploadChange=async(e)=>{
    e.preventDefault();
    const fileCollector = e.target.files;
    await setMinimumUploadAltered(false);
    if(fileCollector.length > 4){
        await setMinimumUploadAlteredMessage('You can only send maximum of Four files');
        await setMinimumUploadAltered(true);
        return;
    }
    const Temp = [];
    let storeSize=0;
    for (let i = 0; i < fileCollector.length; i++) {
        Temp.push(fileCollector[i]);
        storeSize += fileCollector[i].size;
    }
    if(storeSize > 12582912){
        await setMinimumUploadAlteredMessage('File(s) too large, maximimum of 12MB is allowed');
        await setMinimumUploadAltered(true);
        return;
    }
    await setMessageFile(Temp);
    await setType(2)
  }

  const sendMessageFile=async(e)=>{
    e.preventDefault();
    if(sendingMessage) return;
    const form = new FormData();
    if(messageFile.length > 0){
      for (let i = 0; i < messageFile.length; i++) {
        form.append(`pc_files[${i}]`, messageFile[i]);
      }
    }else{      
      await setType(1);
      return;
    }
    form.append('message', '');
    form.append('receiver', lastAdmin());
    const AUTH = processHeader();
    AUTH['X-Socket-Id'] = window.Echo.socketId();
    SetSendingMessage(true);
    Axios.post(`${PROXY}/api/reply_admin_chat/${participant.userName}`, form, {headers: AUTH})
    .then(async(res)=>{
      if(res.data.success){
      if(!mounted.current) return;
        const tempMessage= Object.assign({}, messageHolder.current)
        const tempData = [...tempMessage.data,...res.data.data];
        tempMessage.data=tempData;
        await setMessageList(tempMessage)
        messageHolder.current = tempMessage
        reference.current = null;
        await setMessageFile([]);
        await setType(1);
        mounted.current && SetSendingMessage(false);
        const lastLine =  document.getElementById('last');    
        if(lastLine){
          lastLine.scrollIntoView();
        }
      }else{
        if(res.data.Error === 'Intruder'){
          window.localStorage.clear();
          return navigate('/');
        }
      }
    })
    .catch((_)=>{
      if(!mounted.current) return;
      setType(1);
      SetSendingMessage(false);

    })

  }

  const replyAdminMessage=async(e)=>{
    e.preventDefault();
    if(sendingMessage) return;
    const lastLine =  document.getElementById('last');    
    if(lastLine){
      lastLine.scrollIntoView();
    }
    const message = window.document.getElementById('sendInput').value.trim();
    if(!message) return;        
    const form = new FormData();
    form.append('message', message);
    form.append('receiver', lastAdmin());
    form.append('pc_files[]','');
    const AUTH = processHeader();
    AUTH['X-Socket-ID'] = window.Echo.socketId();
    SetSendingMessage(true);
    Axios.post(`${PROXY}/api/reply_admin_chat/${participant.userName}`, form, {headers:AUTH})
    .then(async(res)=>{
      if(res.data.success){
        if(!mounted.current) return;
        const tempMessage= Object.assign({}, messageHolder.current)
        const tempData = [...tempMessage.data,...res.data.data];
        tempMessage.data=tempData;
        window.document.getElementById('sendInput').value='';
        await setMessageList(tempMessage);
        messageHolder.current = tempMessage
        reference.current = null;
        mounted.current && SetSendingMessage(false);
        if(scrollBox.current) scrollBox.current.scrollTop = scrollBox.current.scrollHeight - scrollBox.current.clientHeight;

      }else{
        if(res.data.Error === 'Intruder'){
          window.localStorage.clear();
          return navigate('/');
        }
      }
    })
    .catch(async(_)=>{
      mounted.current && await SetSendingMessage(false);
    })
  }

  const deleteFile=(e)=>{
    e.preventDefault();
    const go = window.confirm("Do you want to remove this file?");
    if(!go) return;
    const collector = [...messageFile];
    collector.splice(Number(e.target.id), 1);
    if(collector.length < 1){
			const uploader = document.getElementById('filer');
			uploader.value = null;
		}
    setMessageFile(collector);
  }

  const lastAdmin=()=>{
    if(reference.current){
      return reference.current
    }else{
      return attending;
    }
  }

  useEffect(()=>{
    mounted.current = true;
    const listenToNewMessages=async()=>{
      if(!window.Echo) return;
      await window.Echo.private(`chat.${participant.userName}`)
      .listen('.adminNewChat', async(data)=>{
        const tempMessage= Object.assign({}, messageHolder.current);
        const tempData = [...tempMessage.data,...JSON.parse(data[0])];
        tempMessage.data=tempData;
        await setMessageList(tempMessage);
        messageHolder.current = tempMessage;
        if(scrollBox.current){
          scrollBox.current.scrollTop = scrollBox.current.scrollHeight - scrollBox.current.clientHeight;
        }else{              
          scrollBox.current =   window.document.getElementById('scrollBox');
          if(scrollBox.current){
            scrollBox.current.scrollTop = scrollBox.current.scrollHeight - scrollBox.current.clientHeight;
          }
        }
      })
    }

    staticPusherConfig();
    listenToNewMessages();
    
    return()=>{
      mounted.current = false;
    } 
      // eslint-disable-next-line
  },[]);

  return(<div>
    {loading?
      <div className='d-flex justify-content-center'>
        <div className='w3-spin spinner w3-circle mt-2'></div>
      </div>:
      <div>
        {notFound?
          <div>
            <NotFound />
          </div>:
          <div>
            {wentWrong?
              <div> 
                <SomethingWentWrong />
              </div>:
              <div>
                <div className=" w3-card w3-round-xlarge position-relative bg-light mb-3"
                  style={{ height:'87vh' }}
                >
                <div className="card-header px-3 border-0 py-1">
                  <div className="d-flex">
                    <div className="d-flex">
                        <Avatar alt="avatar1" className="img-fluid mr-2" style={ {width:'30px', height:'30px'} } 
                          src={'admin'}
                        />
                    </div>
                    <div className="ml-auto my-auto text-primary">
                      <label>
                        <Attachment />
                        <input 
                          onChange={handleFileUploadChange}
                          className='d-none' accept='.png, .svg, .gif, .jpg, .3gp, .avi, .mpeg, .mp4, .mkv' 
                          multiple type='file' id='filer'
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div id='scrollBox' className="card mb-5" style={{height:'70vh', overflowY: 'auto'}} >
                  <div className="card-body">
                    <div>
                      {fetchingMoreMessages &&
                        <div className='d-flex justify-content-center'>
                          <div className='w3-spin spinner w3-circle mt-2'></div>
                        </div>
                      }
                      {messageList && messageList.data &&  messageList.data.map((message, index) =>
                        <div key={`${message.id}-${index}}`}>
                          <div  className='w3-container'>
                          {(unreadId && unreadId == message.id) &&
                              <small id='new_messages' className='w3-text-blue mb-4'>
                                {unread > 1 && <span> {unread} unread messages</span>}
                                {unread == 1 && <span>1 unread message</span>}
                              </small>
                            }
                          </div>
                          { (message.type == 0) &&
                            <Paper elevation={0} style={!Boolean(message.isAdmin)? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
                              <div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
                              <Typography style={ {fontSize: '0.9rem'} }>
                                {message.message}
                              </Typography>
                            </Paper>
                          }
                          { (message.type == 1)&&
                            <Paper elevation={0} style={!Boolean(message.isAdmin)? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
                              <div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
                              <Typography style={ {fontSize: '0.9rem'} }>
                                <img alt='noImage' src={`${message.message}`} width='150' height='150' />
                              </Typography>
                            </Paper>
                          }
                          { (message.type == 2) &&
                            <Paper elevation={0} style={!Boolean(message.isAdmin)? classes.userChatStyle : classes.otherChatStyle} className="p-2 py-1 mb-2 border">
                              <div><small>{CHAT_LIKE_TIME(message.created_at)}</small></div>
                              <Typography style={ {fontSize: '0.9rem'} }>
                                <video width='150' height='150' controls>
                                  <source src={`${message.message}`} />
                                  Your device cannot play this video
                                </video>
                                {/*<iframe width='150' height='150' src={`${message.message}`}></iframe>*/}
                              </Typography>
                            </Paper>
                          }
                              
                          <div className="clearfix" style={{clear:'both'}}></div>
                        </div>
                      ) }
                        <div id="last"></div>
                        </div>
                    </div>
                </div>
                <div style={classes.chatInputDiv}>
                    {minimumUploadAltered ?
                      <div>
                        <small className='text-danger'>
                          {minimumUploadAlteredMessage}
                        </small>
                      </div>:      
                      <form>
                        {type == 1 ?
                          <Box className="d-flex">
                            <textarea id="sendInput"
                              className="form-control rounded-pill mr-1"
                              placeholder="your message here..." 
                              style={classes.chatInput}
                              maxLength='2000'
                            />
                            <button className="btn customPrimaryBgColor text-white rounded-pill" onClick={replyAdminMessage}>
                              <ChevronRight  />
                            </button>
                              {/* <ChevronRight onClick={replyAdminMessage} /> */}
                          </Box>:
                          <Box style={{position: 'relative'}}>
                            <div className="d-flex justify-content-center" id="sendInput" style={classes.chatInput}>
                              {messageFile.length > 0 && messageFile.map((data, index)=>
                                <div className='mx-1 w3-round' key={index} style={{display: 'inline'}}>
                                  {data.type.includes('image')?
                                  <img alt='message' id={index} onClick={deleteFile}  width='40' height='40' src={URL.createObjectURL(data)} />:
                                  <video id={index} onClick={deleteFile} width='40' height='40' controls>
                                    <source src={URL.createObjectURL(data)} />
                                    Your device cannot play this video
                                  </video>
                                  }
                                </div>
                              )}
                              <button 
                                className=" btn customPrimaryBgColor text-white rounded-pill" 
                                onClick={sendMessageFile}
                                style={{position: 'absolute', right:0}}
                              >
                                <ChevronRight />
                              </button>
                            </div>
                          </Box>
                        }
                      </form>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
    }
    {
    
  }
  </div>)
}

const mapStateToProp=(state)=>{
  return{
    participant: state.participant
  }
}

const mapDispatchToProps=(dispatch)=>{
  return {

  }
}

export default connect(mapStateToProp, mapDispatchToProps)(AdminChat);

