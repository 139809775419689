import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom';


class SomethingWentWrong extends Component {
  render() {
    return (<React.Fragment>            
      <div className='w3-container' style={{height:window.innerHeight, marginTop:'70px'}}>
        <div className="card-body d-flex flex-column justify-content-center align-items-center">
          <Typography variant="h3" className="mb-3">Oops! Something went wrong.</Typography>
          <Typography variant="body1" className="mb-3">This could be a Network Error or Server Error.</Typography>
          
          <div className="chatNotFoundBg"></div>

          <Typography variant="h5" className="mt-5 mb-2">How about...</Typography>
          
          <div className="row">
            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-3">
              <Link to="/" className="btn customPrimaryBgColor rounded-pill text-white">
                Starting again from homepage?
              </Link>
            </div>
              <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2 mb-3 text-center">
                OR
              </div>
            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-3">
              <Link to="/templates" className="btn customSecondaryBgColor rounded-pill text-white text-nowrap">
                Enjoying our free templates?
              </Link>
            </div>
          </div>
        </div>
      </div>
            
    </React.Fragment>)
  }
}

export default SomethingWentWrong;