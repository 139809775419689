import React, { Component } from 'react';
import '../../CSSs/Business/thirteen/thirteen.css';
import { Typography } from '@material-ui/core';
import download from 'downloadjs';
import * as htmlToImage from 'html-to-image';
import GDHeader from '../GDHeader';

class BusinessEditThirteen extends Component {
    state = {
        subCategoryTitle: { title: 'Graphic Design Templates', subtitle: 'Personalize ready made Graphic Design Templates' },
        isEditFinish: false,
        newArrivals: "New Arrivals",
        otherTexts: "Lorem Ipsum dolor sit amet, Lorem ipsum dolor",
        productDetails: "Lorem Ipsum dolor sit amet, Lorem ipsum dolor",
        logoText: "Your Logo",
        contactDetails: "Contact Details",
        addressHere: "Address Here",
    }
    changeBackground = (e) => {
        e.preventDefault();
        try {
            const file = e.target.files[0];
            if(!file)  return;
            let back = document.getElementById("outSideDiv13");
            if(back){
                back.style.background = `linear-gradient(0deg, rgba(8, 62, 92, 0.7), rgba(11, 90, 133, 0.7)), url(${URL.createObjectURL(file)})`;
                back.style.backgroundSize = `100%`;
                back.style.backgroundRepeat = `no-repeat`;
                back.style.backgroundPosition = `center`;
            }
        } catch (e) {
            return;
        }
    }
    changeImage = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const slot = document.getElementById('imgSlot');
        if(!slot) return;
        slot.src = URL.createObjectURL(file);
    }
    changeLogo = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const logo = document.getElementById('logoSlot13');
        if(!logo) return;
        logo.src = URL.createObjectURL(file);
    }
    
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }
    handleFinishEdit = (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: true });
    }

    backToEdit= (e) => {
        e.preventDefault();
        this.setState({ isEditFinish: false });
    }

    downloadJPEG=()=>{
        htmlToImage
            .toJpeg(document.getElementById('outSideDiv13'), { quality: 1.0, pixelRatio: 1 })
            .then(function(dataUrl) {
                var link = document.createElement("a");
                link.download = "medillery-template.jpeg";
                link.href = dataUrl;
                link.click();
            });          
    }
    
    downloadPNG=()=>{
        htmlToImage
        .toPng(document.getElementById('outSideDiv13'), { quality: 1.0, pixelRatio: 1 })
        .then(function (dataUrl) {
            download(dataUrl, 'medillery-template.png');
        });
    }
    
    downloadNow=(e)=>{
        e.preventDefault();
        const value = e.target.value;
        if(value === 'PNG'){
            return this.downloadPNG();
        }else if(value==='JPEG'){
            return this.downloadJPEG();
        }
    }

    render() {
        return (<div style={{marginTop: '90px'}}>
            <GDHeader caty='business' />
            
            <div className="container">
                <div className="row mb-4 py-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row py-4">
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 mb-2">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* this is the outside div that encapsulate all the others */}
                                        <div className="outSideDiv13" id="outSideDiv13">
                                            {/* this is the zig-zag circles */}
                                            <div className="circle13">
                                                <div className="yourLogo13">
                                                    <img id="logoSlot13" src={require("../../../../assets/images/img.png")} 
                                                        alt="living Room" className="logoImage13" />
                                                </div>
                                                <div className="triangle13"></div> 
                                                <div className="triangle13"></div> 
                                                <div className="triangle13"></div> 
                                                <div className="triangle13"></div> 
                                                <div className="triangle13"></div> 
                                                <div className="triangle13"></div> 
                                                <div className="triangle13"></div> 
                                                <div className="triangle13"></div> 
                                                <div className="triangle13"></div> 
                                                <div className="triangle13"></div> 
                                                <div className="triangle13"></div> 
                                                <div className="triangle13"></div>
                                            </div>
                                            {/* the rightsided text div above */}
                                            <div className="righttextDiv13" id="righttext13">
                                                <p>{this.state.otherTexts}</p>
                                            </div>
                                            {/* clear the float right */}
                                            <div className="clearRight13"></div>
                                            {/* The text details div  */}
                                            <div className="textDetailsDiv13">
                                                <h1 className="styledWord13">New</h1>
                                                <h1><span>Arrivals</span></h1>
                                                {/* the image rectangular div container */}
                                                <div className="divImageRect13">
                                                    {/* the rectangular image itself */}
                                                    <img id="imgSlot"
                                                    src={require("../../../../assets/images/templates/heroes/1.jpeg")} 
                                                    alt="living Room" className="imageRect13" />
                                                </div>
                                                {/* last flex div underneath the image flex */}
                                                <div className="underTextDiv13">
                                                    {/* the buynow div class */}
                                                    <div className="buyNowDiv13">
                                                        <h1 className="buyNowClass13 buyNowThree13">BUY NOW</h1>
                                                        <h1 className="buyNowClass13 buyNowTwo13">BUY NOW</h1>
                                                        <h1 className="buyNowClass13 buyNowOne13">BUY NOW</h1>
                                                    </div>
                                                    {/* right sided text details div--> */}
                                                    <div className="rightSideText13">
                                                        {/* the text div below the image div */}
                                                        <div className="imagetextDiv13 imagetextDivOne13">
                                                            <p>{this.state.productDetails}</p>
                                                        </div>
                                                        {/* the text div below the image div */}
                                                        <div className="imagetextDiv13 imagetextDivTwo13">
                                                            <p>{this.state.contactDetails}</p>
                                                            <p>{this.state.addressHere}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 mb-4">
                            {!this.state.isEditFinish?
                                <div>
                                    <Typography variant="subtitle2">
                                        Insert your details to customize the template to your taste.
                                        Edit placeholders and change the colors.
                                    </Typography>
                                    <form>
                                        <div className="form-group mt-3">
                                            <label>Background Image</label>
                                            <label className="form-control rounded-pill">
                                                <input 
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeBackground}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label>Image</label>
                                            <label className="form-control rounded-pill">
                                                <input
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeImage}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label>Logo</label>
                                            <label className="form-control rounded-pill">
                                                <input
                                                    type="file" style={{display:'none'}} 
                                                    className="form-control rounded-pill" 
                                                    onChange={this.changeLogo}
                                                    />
                                            </label>
                                        </div>
                                        <div className="form-group d-flex">
                                            <div className="mr-2">
                                                <label>New Arrivals</label>
                                                <input 
                                                    disabled
                                                    type="text" id="newArrivals" 
                                                    value={this.state.newArrivals} 
                                                    onChange={ this.handleChange } 
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div>
                                                <label>Objectives</label>
                                                <input
                                                    type="text" id="otherTexts" 
                                                    value={this.state.otherTexts}
                                                    onChange={this.handleChange}  
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                       
                                        <div className="form-group">
                                            <div>
                                                <label>Product Description</label>
                                                <input
                                                    type="text" id="productDetails" 
                                                    value={this.state.productDetails}
                                                    onChange={this.handleChange}  
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>  
                                        </div>
                                        
                                        <div className="form-group d-flex">
                                            <div className="mr-2">
                                                <label>Contact Details</label>
                                                <input id="contactDetails"
                                                    type="text"  maxLength="40"
                                                    value={this.state.contactDetails}
                                                    onChange={this.handleChange}
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                            <div>
                                                <label>Address Here</label>
                                                <input id="addressHere"
                                                    type="text" maxLength="40"
                                                    value={this.state.addressHere}
                                                    onChange={this.handleChange}
                                                    className="form-control rounded-pill" 
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <button 
                                                className="btn btn-block rounded-pill customPrimaryBgColor" 
                                                onClick={ this.handleFinishEdit }
                                            > 
                                                <span className="small text-white">Insert Details</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>:
                                <div className=" text-center">
                                    <h4 className="mr-2 my-4 w3-text-blue">
                                        Your image is ready!
                                    </h4>
                                    <div className=' text-center d-flex justify-content-center'>
                                        <div className="w-50 mb-3 ">
                                            <Typography variant="subtitle2" className="mr-2 mb-1">Download Format</Typography>
                                            <select className="form-control form-control-sm rounded-pill"
                                                onChange={this.downloadNow}
                                            >   <option>Choose Format</option>
                                                <option >PNG</option>
                                                <option >JPEG</option>
                                                {/*<option>PDF</option>*/}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-center mt-2">
                                        <button 
                                        className="btn btn-block rounded-pill customPrimaryBgColor" 
                                        onClick={ this.backToEdit }
                                        > 
                                            <span className="small text-white">Back to edit</span>
                                        </button>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>);
    }
}
// jquery.scrollLeft() to move the whole div left and right
// https://stackoverflow.com/questions/27313480/scroll-div-content-horizontally-using-left-and-right-arrow-in-jquery

export default BusinessEditThirteen;
