import React from 'react';
import { connect } from 'react-redux';
import { useState, useRef } from 'react';
import { useEffect } from 'react';
import Axios from 'axios';
import { participantInfo } from '../../global/action';
import { PUT_BEFORE, TOKES, PROXY } from '../../global/constants/action_types';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import NotFound from '../../NotFound';
import { EXTRACTOR } from '../../global/generalMethods/general';
import TemplateRoutes from './TemplateRoute';
import LazyLoader from '../../LazyLoader';
import HomePageNavbar from '../website/HomePageNavbar';
import { useRoutes } from 'react-router-dom';


const quitRequest = Axios.CancelToken.source();

const TemplateCover=({participant, resetIdentity })=>{

    const mounted = useRef(true);

    const templateRoutes = useRoutes(TemplateRoutes);

    const [identity, setIdentity] = useState(participant);
    const [loadingParticipant, setLoadingParticipant] = useState(true);


    useEffect(()=>{
        if(Object.entries(participant).length < 1){
            const getParticipant=()=>{
                const processHeader=()=>{
                    const identifier = EXTRACTOR(PUT_BEFORE,localStorage.MedToken,TOKES)
                    if(!identifier || identifier.length < 50){
                        return false;                
                    }else{
                      return {Authorization: `Bearer ${identifier}`};
                    }
                }
                let auth = processHeader();
                if(!auth) return setLoadingParticipant(false);
                Axios.get(`${PROXY}/api/identify/XXPAXX`, {headers: auth, cancelToken: quitRequest.token})
               .then(async(res)=>{
                   if (res.data.success){
                       if(mounted.current){
                           await resetIdentity(JSON.parse(window.atob(res.data.data)));
                           mounted.current && setLoadingParticipant(false);
                       }
                   }
                }).catch(err=>{
                    if(mounted.current){
                        return setLoadingParticipant(false);
                    }
                });
            }            
            getParticipant();
        }else{
            setIdentity(participant);
            mounted.current && setLoadingParticipant(false);
        }
        return () => {
            // quitRequest.cancel();
            mounted.current = false;
          }
    }, [participant, resetIdentity])

    useEffect(() => window.scrollTo(0, 0));


    return(<div>
        {(loadingParticipant) ?
            <div >
                <LazyLoader />
            </div> :
            <div>
                <div>
                    {(Object.entries(identity).length < 1)?
                        <HomePageNavbar />:
                        <DashboardNavbar />
                    }
                </div>
                <div  style={{marginTop: '90px'}}>
                    {templateRoutes || <NotFound />}
                </div>                               
            </div>
        }
    </div>)
}


const mapStateToProps=(state)=>{
	return{
        participant: state.participant,
	}
}

const mapDispatchToProps=(dispatch)=>{
    return{
        resetIdentity: (payload)=> dispatch(participantInfo(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateCover)