import React, { useState, useEffect } from 'react';
import { Typography, Box} from '@material-ui/core';
import Axios from 'axios';
import { PROXY } from '../../../global/constants/action_types';
import { ROUNDS } from '../../../global/generalMethods/general';

const Highlights = () => {
  const [stat, setStat] = useState(null);
  const highlightItems = [
    { number: 400, map: 'users', title: 'Medillery Users', image: 'medillery-users' },
    { number: 150, map: 'professionals', title: 'Professionals', image: 'professionals' },
    { number: 85, map: 'completed_projects', title: 'Completed Projects', image: 'completed-projects' },
  ];

  useEffect(() => {
    let mounted = true;
    const getStats = async () => {
      try {
        const res = await Axios.get(`${PROXY}/api/stat`);
        if (res.data.success && mounted) {
          setStat(JSON.parse(window.atob(res.data.data)));
        }
      } catch (err) {
        if (mounted) return;
      }
    };
    getStats();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className="container highlightsBackground mb-5">
      <Box className="text-center" mb={4}>
        <Typography variant="h4" className="sectionTitle font-weight-bold mb-2">
          Highlights
        </Typography>
        <Typography variant="subtitle1" className="heroSecondText text-secondary">
          We are dedicated to making freelance free indeed
        </Typography>
      </Box>
      <Box className="row text-center" mb={4}>
        {stat && highlightItems.map((item, index) => (
          <Box key={index} className="col-sm-12 col-md-3 col-lg-3 col-xl-3" mb={4}>
            <img src={ require(`../../../assets/images/website/homepage/${item.image}.png`) } 
              className="img-fluid w-25 mb-3" alt={item.title} 
            />
            <Typography variant="h4">{ROUNDS(stat[item.map])}</Typography>
            <Typography variant="subtitle2" className="text-capitalize">
              {item.title}
            </Typography>
          </Box>
        ))}

        <Box className="col-sm-12 col-md-3 col-lg-3 col-xl-3" mb={4}>
          <img src={ require(`../../../assets/images/website/homepage/completed-projects.png`) } 
            className="img-fluid w-25 mb-3" alt="Completed Projects" 
          />
          <Typography variant="h4">10K+</Typography>
          <Typography variant="subtitle2" className="text-capitalize">Templates Done</Typography>
        </Box>

      </Box>
      
    </div>
  );
};

export default Highlights;
