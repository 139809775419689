import { Button } from '@material-ui/core';
import Axios from 'axios';
import React, { useEffect, useState } from  'react';
import { useNavigate } from 'react-router-dom';
import { PROXY, PUT_BEFORE, TOKES } from '../../../global/constants/action_types';
import { EXTRACTOR } from '../../../global/generalMethods/general';
import LazyLoader from '../../../LazyLoader';



const Suspended =()=>{
  const navigate = useNavigate()

  const [fetching, setFetching] = useState(true);
  useEffect(()=>{
    const processHeader=()=>{
      const identifier = EXTRACTOR(PUT_BEFORE,localStorage.MedToken,TOKES)
      if(!identifier || identifier.length < 50){
        return false;
      }else{
        return {Authorization: `Bearer ${identifier}`};
      }
    }
    // if (!processHeader()) return 
    // setFetching(false);
    // 
    Axios.get(`${PROXY}/api/identify/XXPAXX`, {headers:processHeader()})
    .then(res=>{
      if (res.data.success){	
        // console.log(JSON.parse(window.atob(res.data.data)));
        return navigate('/dashboard', {replace:true});
      }else{
        return setFetching(false);
      }
    }).catch(err=>{
      return setFetching(false);
    })
	});

  return(<div style={{marginTop:'70px', height:'90vh'}}>
    {fetching?
      <LazyLoader />:
      <div>
        <h4 className='d-flex justify-content-center px-2'>
          Sorry, this account is currently under review and suspended for the main time.
          <br />
          You will receive an email notification when the review is over. Thanks.
        </h4>
        <div  className='d-flex justify-content-center py-3'>
          <Button
            className='customSecondaryBgColor text-white rounded-pill'
            onClick={()=>navigate('/')}
          >
            Back to home
          </Button>
        </div>

      </div>
    }
  </div>)

}

export default Suspended;