import React, { Component } from 'react';
import { Avatar, Typography } from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { connect } from 'react-redux';
import Axios from 'axios';
import { PROXY } from '../../../global/constants/action_types';
import { processHeader } from '../../../global/action';
import { noImage } from '../../../global/generalMethods/general';
import LazyLoader from '../../../LazyLoader';
import { Link, useNavigate, useParams } from 'react-router-dom';

const submitButton= {
  background: 'linear-gradient(90deg, #387DFF -4.49%, #004BD6 104.49%)',
}
const imageStyle = {
  width: '100%',
}
const quitRequest = Axios.CancelToken.source();

class ProjectSubmission extends Component {
    state = {
        loading:false,
        notFount: false,
        allocation: {},
        minimumUploadAltered:false,
        minimumUploadAlteredMessage:'',
        displayFiles:[],
        commentPatternError:false,
        processing:false,
        successful: false,
        somethingWentWrong: false,
        goBack: false,
    }

    mounted;

    componentDidMount=async()=>{
        this.mounted=true;
        await this.filterRouteId();
    }

    componentWillUnmount=()=>{
        this.mounted = false;
    }

    filterRouteId=async()=>{
        if(!this.mounted) return;
        await this.setState({loading:true})
        let encodedPath;
        try {
            encodedPath = window.atob(window.atob(this.props.params.details));
        } catch (e) {
            if(!this.mounted) return;
            await this.setState({notFound:true});
            await this.setState({loading:false})
            return;
        }
		let sliceRandomAway = encodedPath.slice(6, encodedPath.length);
        const allocationId = Number(sliceRandomAway.slice(0, sliceRandomAway.length-6));
        if(isNaN(allocationId)){
            if(!this.mounted) return;
            await this.setState({notFound:true});
            await this.setState({loading:false})
            return;
        }
        await this.setState({allocationId:allocationId});
        Axios.get(`${PROXY}/api/confirm_allocation/${this.props.participant.userName}/${allocationId}`,
         {headers:processHeader(), cancelToken: quitRequest.token})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                if(Number(res.data.data.status) !== 1){
                    return this.props.navigate(
                        `/dashboard/projects/${this.props.params.details}`,
                        {replace:true}
                    );
                }
                this.mounted && await this.setState({allocation:res.data.data});
                this.mounted && await this.setState({loading:false})

            }else{
                if(res.data.Error === 'Intruder'){
                    if(!this.mounted) return;
                    window.localStorage.clear();
                    return this.props.navigate('/', {replace:true});
                }
            }
        })
        .catch(async(_)=>{
            this.mounted && await this.setState({notFound:true});
            this.mounted && await this.setState({loading:false})
        })
    }

    handleFileUploadChange=async(e)=>{
        e.preventDefault();
        const fileCollector = e.target.files;
        await this.setState({minimumUploadAltered:false});
        if(fileCollector.length > 4){
            await this.setState({minimumUploadAlteredMessage: 'You can only send maximum of Four files'})
            await this.setState({minimumUploadAltered:true});
            return;
        }
        const Temp = [];
        let storeSize=0;
        for (let i = 0; i < fileCollector.length; i++) {
            // if(fileCollector[i].size >( 1024*8) ){
            //     await this.setState({minimumUploadAlteredMessage: 'One of your file(s) is larger than 800kb'})
            //     await this.setState({minimumUploadAltered:true});
            // break;
            // }
            Temp.push(fileCollector[i]);
            storeSize += fileCollector[i].size;
        }
        if(storeSize > 12582912){
            await this.setState({minimumUploadAlteredMessage: 'File(s) too large, maximimum of 12MB is allowed'})
            await this.setState({minimumUploadAltered:true});
            return;
        }
        await this.setState({displayFiles:Temp});
    }

    deleteFile=async(e)=>{
        e.preventDefault();
        const id = Number(e.target.id);
        const go = await window.confirm("Do you want to remove this file?");
        if(!go) return;
        const collector = [...this.state.displayFiles];
        collector.splice(id, 1);
        await this.setState({displayFiles: collector});
    }

    handleSubmit=async(e)=>{
        e.preventDefault();
        if(!this.state.processing){
            await this.setState({processing:true});
            await this.setState({successful:false});
            await this.setState({goBack:false});
            await this.setState({somethingWentWrong:false});
            const formMedia = new FormData();
            if(this.state.displayFiles.length <1){
                await this.setState({minimumUploadAlteredMessage: 'File must be presented to your client'})
                await this.setState({minimumUploadAltered:true});
                await this.setState({processing:false});
                return;
            }
            for (let i = 0; i < this.state.displayFiles.length; i++) {
                formMedia.append(`doneFile[${i}]`, this.state.displayFiles[i]);
            }
            let comment = window.document.getElementById('comment').value.trim()
            formMedia.append('message', comment);
            formMedia.append('allocation_id', this.state.allocation.id)
            Axios.post(`${PROXY}/api/allocation_done/${this.props.participant.userName}`, formMedia,
             {headers:processHeader(), cancelToken: quitRequest.token})
            .then(async(res)=>{
                if(res.data.success){
                    if(!this.mounted) return;
                    this.setState({successful:true});
                    this.setState({goBack:true});
                }else{
                    if(res.data.Error==='Intruder'){
                        if(!this.mounted) return;
                        window.localStorage.clear();
                        return this.props.navigate('/');
                    }
                }
            })
            .catch(async(_)=>{
                this.mounted && await this.setState({somethingWentWrong:true});
            }).finally(()=>{
               this.mounted && this.setState({processing:false});
            })
        }
    }

    render() {
        const {allocation, successful, goBack, processing, loading, notFound, displayFiles,
            minimumUploadAltered, minimumUploadAlteredMessage, somethingWentWrong
        } = this.state
        return (<div>             
            {loading?
                <div>
                    <LazyLoader />
                </div>:
                <div>
                {notFound ?
                    <div style={{height:window.innerHeight}}>
                        Page Not found
                    </div> :
                    <div>
                        {(allocation && Object.entries(allocation).length) &&
                        <div className="card w3-animate-right">                            
                            <div>
                                {( successful  && goBack) ?
                                    <div className='d-block'>
                                        <div className='w3-text-teal d-flex justify-content-center'>
                                            You have Successfully Submitted the project.                                                                 
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <div className="text-center py-2">
                                                <Link  
                                                    className="btn text-decoration-none rounded-pill text-white"
                                                    style={submitButton}
                                                    to={`/dashboard/projects/${this.props.params.details}`}
                                                >
                                                    Proceed
                                                </Link>
                                            </div>
                                        </div>
                                    </div>:
                                    <div>
                                        <div className="card-header d-flex align-items-center" style={{backgroundColor: '#F9FBFF'}}>
                                            <Avatar alt="avatar1" className="img-fluid mr-2" src={
                                                allocation.professional?
                                                `${allocation.professional.profilePic?`${allocation.professional.profilePic}`: `${noImage}`}`
                                                :
                                                `${allocation.owner.profilePic? `${allocation.owner.profilePic}`: `${noImage}`}`
                                            }
                                            />
                                            <Typography className="font-weight-bold">
                                                {allocation.owner?
                                                    `@${allocation.owner.userName}`:
                                                    `@${allocation.professional.userName}`
                                                }
                                            </Typography>
                                        </div>
                                        <div className="card-body py-5">
                                            <div className="row mb-4">
                                                <div className="col-sm-12 col-md-2"></div>
                                                <div className="col-sm-12 col-md-8">
                                                    <div className="row">
                                                        <div className='w3-container d-flex justify-content-center my-2'>
                                                            You can upload the maximum of four files and you are advised
                                                             to ensure that each of the files is not more than 1mb.
                                                            <br />
                                                            Any file that is larger than 1mb can also be sent through the project message.
                                                        </div>
                                                        <div className="col-sm-12 col-md-3 col-lg-3">
                                                            <label className="card text-center mt-4 mb-2 cursorPointer" style={{height: '100px',}}>
                                                                <div className="my-auto">
                                                                    <div className="text-center"><AddPhotoAlternateIcon className="text-muted" /></div>
                                                                    <Typography variant="caption" className="text-muted smallerText">Attach File</Typography>
                                                                    <input type="file" accept='image/*' multiple onChange={this.handleFileUploadChange} className="d-none" />
                                                                </div>
                                                            </label>                                                            
                                                        </div>
                                                        <div className='w3-container d-flex justify-content-center'>
                                                        {minimumUploadAltered &&
                                                            <div className='mt-2 text-danger'><small>{minimumUploadAlteredMessage}</small></div>
                                                        }
                                                        </div>
                                                        <div className="col-sm-12 col-md-9 col-lg-9">
                                                            <div className="row">
                                                            { displayFiles.length > 0 && displayFiles.map((file, index) => (
                                                                <div  key={index} className="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-1">
                                                                    <div className="" id={index} onClick={this.deleteFile} height='150' style={{objectPosition:'cover'}}>
                                                                        <img alt={file.fileName} className="img-fluid rounded"
                                                                            style={imageStyle} src={URL.createObjectURL(file)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-2"></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-2"></div>
                                                <div className="col-sm-12 col-md-8">
                                                    <form>
                                                        <textarea id='comment' className="form-control" placeholder="add note (optional)"></textarea>
                                                        <div className='w3-container d-flex justify-content-center'>
                                                            {processing &&
                                                                <div className=''>
                                                                    <div className='w3-spin spinner w3-circle mt-2 mr-2'></div>
                                                                </div>
                                                            }                                                
                                                            {somethingWentWrong &&
                                                                <div className='text-danger'>
                                                                    Oops, something went wrong. 
                                                                    Check your internet connection or compress the file(s) size.                                                       
                                                                </div>
                                                            }
                                                        </div>
                                                        {(allocation.status == 1)  &&
                                                            <div className="text-center py-2">
                                                                <button className="btn btn-sm rounded-pill text-white" 
                                                                    onClick={this.handleSubmit} style={submitButton}
                                                                >
                                                                    Submit Project
                                                                </button>
                                                            </div>
                                                        }                                            
                                                    </form>
                                                </div>
                                                <div className="col-sm-12 col-md-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    </div>
                }
                </div>
            }            
        </div>);
    }
}

const mapStateToProps=(state)=>{
    return{
        participant: state.participant
    }
}

const withRouter=Child=>props=>{
    const params = useParams();
    const navigate = useNavigate();
    return(
        <Child 
        {...props} 
        params={params} 
        navigate={navigate} 
        />
 )
}


export default connect(mapStateToProps)(withRouter(ProjectSubmission));