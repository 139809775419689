import React from 'react';
import { Typography } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import { Link } from 'react-router-dom';


const subCategoryTitle = { 
  title: 'Graphic Design Templates', 
  subtitle: 'Personalize ready made Graphic Design Templates' 
};

const GDHeader=({caty})=>{
  return(<div className="container">
    <div className="row my-4" style={{borderRadius: '18px',backgroundColor: 'lightgrey'}}>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-dark text-center py-4">
        <Typography variant="h4" className="mb-2">
          {subCategoryTitle.title }
        </Typography>
        <Typography variant="subtitle2" className="font-weight-light mb-2">
          {subCategoryTitle.subtitle }
        </Typography>
      </div>
    </div>
    <div>
      <Typography variant="h5" className="h5 text-center">
        <Link to={`/templates/graphics-design/${caty}`} 
          className='customPrimaryBgColor px-4 rounded-pill mr-4'
        >
          <KeyboardBackspace className="text-white" />
        </Link>
        Edit Template
      </Typography>
    </div>
  </div>)
} 


    

export default GDHeader;
