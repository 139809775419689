import React, {Component} from 'react';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { loadUserFollowers, loadUserFollowings, processHeader } from '../../../../global/action';
import Axios from 'axios';
import { PROXY } from '../../../../global/constants/action_types';
import { Modal } from 'react-bootstrap';
import UserFollowers from './UserFollowers';
import UserFollowings from './UserFollowings';
import { Link } from 'react-router-dom';
// import { navigate } from 'hookrouter';



const profileBox ={
    height: '90px',
    width: '90px',
    cursor: 'pointer'
};


class BasicInfo extends Component{
    state={
        showModal: false,
        showFollowing:false,
        showFollowers: true,
        gettingFollowers: false,
        gettingFollowings: false,
        
    }


    mounted;

    componentDidMount=async()=>{
        this.mounted = true;
        // this.getFollowers();
        // this.getFollowings();
    }

    componentWillUnmount=()=>{
        this.mounted = false;
    }

    getFollowers=()=>{
        if(!this.props.participant.userName) return;
        if(this.state.gettingFollowers) return;
        this.setState({gettingFollowers: true});
        Axios.get(`${PROXY}/api/get_user_followers/${this.props.participant.userName}/${this.props.user.userName}`,
            {headers: processHeader()}
        ).then(res=>{
            if(res.data.success){
                if(!this.mounted) return;
                // console.log(res.data.data, 'followers');
                this.props.loadFollowers(res.data.data);
                this.setState({gettingFollowers: false});
            }
        })
        .catch(err=>{
            if(!this.mounted) return;
            if(err.response) this.props.loadFollowers({data:[]});
            this.setState({gettingFollowers: false});
        })

    }

    getFollowings=()=>{
        if(this.state.gettingFollowings) return;
        if(!this.props.participant.userName) return;
        this.setState({gettingFollowings: true});
        Axios.get(`${PROXY}/api/get_user_followings/${this.props.participant.userName}/${this.props.user.userName}`,
            {headers: processHeader()}
        ).then(res=>{
            if(res.data.success){
                if(!this.mounted) return;
                // console.log(res.data.data, 'followings');
                this.props.loadFollowings(res.data.data);
                this.setState({gettingFollowings: false});
            }
        })
        .catch(err=>{
            if(!this.mounted) return;
            if(err.response) this.props.loadFollowings({data:[]});
            this.setState({gettingFollowings: false});
        })
    }

    showFollowers=(e)=>{
        e.preventDefault();
        if(this.props.user.followers_count < 1) return;
        if(!this.props.participant.userName) return;
        this.getFollowers();
        this.setState({showFollowers: true});
        this.setState({showModal: true});
        this.setState({showFollowings: false});
    }
    showFollowings=(e)=>{
        e.preventDefault();
        if(this.props.user.following_count < 1) return;
        if(!this.props.participant.userName) return;
        this.getFollowings();
        this.setState({showFollowers: false});
        this.setState({showModal: true});
        this.setState({showFollowings: true});
    }

    closeModal=()=>{
        if(!this.props.participant.userName) return;
        this.setState({showModal: false});
      }

    render(){
        const {user} = this.props;
        // console.log(this.props, 'basic user')

        /** The user above can be tested with const user in variables. Project cover pre loads it and the
         * part of <USER /> rendering makes the entire user part appear.
         */
        return(<div>
            
                <div className="mb-3">
                    <Typography variant="h6">{`${user.firstName} ${user.lastName}`}</Typography>
                    <Typography variant="subtitle2"><b>@{user.userName}</b></Typography>
                    {this.props.user && this.props.user.role === 1 &&
                        <div className='mt-1'>
                            {this.props.user.active === 1 &&
                                <div className='bg-primary btn rounded-pill text-white'>Active</div>
                            }
                            {this.props.user.active === 0 && 
                                <div className='bg-secondary btn rounded-pill text-white'>Busy</div>
                            }
                            <Link 
                                className='bg-info btn rounded-pill text-white ml-3'
                                to={`/user/${this.props.user.userName}/completed-projects`}
                            >
                                Completed projects
                            </Link>
                        </div>
                    }
                </div>
                {user.rating &&
                    <div className="container-fluid mb-2">
                        <div className="row">
                            <div className="col-12 pl-0">
                                <Typography variant="subtitle2" className="text-uppercase">PROFESSIONAL SKills</Typography>
                            </div>
                            <div className="col-md-3 col-lg-6 col-xl-6 col-6 mb-2 pl-0">
                                {user.rating.category} ({user.rating.subcategory})
                            </div>
                        </div>
                    </div>
                }
                {user.details &&
                    <div className="mb-2">
                        {user.details.bio &&
                            <div>
                                <Typography variant="subtitle2" className="text-muted">Bio</Typography>
                                <Typography variant="body2">
                                    {user.details.bio}
                                </Typography>
                            </div>
                        }
                    </div>
                }
                <div className="mb-4 d-flex">
                    {user.rating &&
                        <div className="p-3 mr-2 border d-flex flex-column justify-content-center align-items-center" 
                            style={profileBox}
                        >
                            <Typography variant="h6" className="text-muted">{user.rating.ratings}</Typography>
                            <Typography variant="subtitle2" className="text-muted">Points</Typography>
                        </div>
                    }
                    <div className="p-3 mr-2 border d-flex flex-column justify-content-center align-items-center" 
                        style={profileBox} onClick={this.showFollowers}
                    >
                        <Typography variant="h6" className="text-muted">{user.followers_count}</Typography>
                        <Typography variant="subtitle2" className="text-muted">Followers</Typography>
                    </div>
                    <div className="p-3 mr-2 border d-flex flex-column justify-content-center align-items-center" 
                        style={profileBox} onClick={this.showFollowings}
                        
                    >
                        <Typography variant="h6" className="text-muted">{user.following_count}</Typography>
                        <Typography variant="subtitle2" className="text-muted">Followings</Typography>
                    </div>                  
                </div>
                {user.details &&
                    <div className="container-fluid mb-3">
                        <div className="row">
                            <div className="col-12 pl-0">
                                <Typography variant="subtitle2" className="mb-2">Social</Typography>
                            </div>
                            { user.details.whatsappNumber &&
                                <div className="col-md-3 col-lg-6 col-xl-6 col-6 mb-2 pl-0">
                                    <a href={`https://wa.me/${user.details.whatsappNumber}`} 
                                        target='_blank' rel="noopener noreferrer"
                                        className="btn text-left pl-3 btn-sm btn-block border rounded-pill text-capitalize"
                                    >
                                        <WhatsAppIcon fontSize="small" /> whatsapp
                                    </a>
                                </div>
                            }
                            { user.details.facebook &&
                                <div className="col-md-3 col-lg-6 col-xl-6 col-6 mb-2 pl-0">
                                    <a href={`https://${user.details.facebook}`} className="btn text-left pl-3 btn-sm btn-block border rounded-pill text-capitalize">
                                        <FacebookIcon fontSize="small" /> facebook
                                    </a>
                                </div>
                            }
                            { user.details.instagram &&
                                <div className="col-md-3 col-lg-6 col-xl-6 col-6 mb-2 pl-0">
                                    <a 
                                        href={`https://instagram.com/${user.details.instagram.replace(/@/gi, '')}`} 
                                        target='_blank' rel="noopener noreferrer"
                                        className="btn text-left pl-3 btn-sm btn-block border rounded-pill text-capitalize"
                                    >
                                        <InstagramIcon fontSize="small" /> instagram
                                    </a>
                                </div>
                            }
                            { user.details.twitter &&
                                <div className="col-md-3 col-lg-6 col-xl-6 col-6 mb-2 pl-0">
                                    <a 
                                        href={`https://twitter.com/${user.details.twitter.replace(/@/gi, '')}`}
                                        target='_blank'  rel="noopener noreferrer"
                                        className="btn text-left pl-3 btn-sm btn-block border rounded-pill text-capitalize"
                                    >
                                        <TwitterIcon fontSize="small" /> twitter
                                    </a>
                                </div>
                            }
                        </div>
                    </div>
                }
            <Modal
                show={this.state.showModal}
                onHide={this.closeModal}
              >
                <Modal.Body className='pt-1 pr-1'>
   
                    { (this.state.showModal && this.state.showFollowers) &&
                      <div>
                        <UserFollowers close={this.closeModal} getting={this.state.gettingFollowers} />
                      </div>
                    }
                    { (this.state.showModal && this.state.showFollowings) &&
                      <div>
                        <UserFollowings close={this.closeModal}  getting={this.state.gettingFollowings} />
                      </div>
                    }
                                  
                </Modal.Body>
    
            </Modal>            
       </div> )
    }
}

const mapStateToProps=(state)=>{
	return{
        participant: state.participant,
        user: state.userPageOwner,
	}
}

const mapDispatchToProps=(dispatch)=>{
    return{
        loadFollowers: (payload)=>dispatch(loadUserFollowers(payload)),
        loadFollowings: (payload)=>dispatch(loadUserFollowings(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);