import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CreateIcon from '@material-ui/icons/Create';
import { connect } from 'react-redux';
import { becomeProfessionalModal, loadPersonalDetails,
    processHeader, participantInfo
 } from '../../../global/action';
import BecomeProfessional from './BecomeProfessional';
import Password from './Password';
import Personal from './Personal';
import SocialMedial from './SocialMedial';
import Email from './Email';
import Axios from 'axios';
import { PROXY } from '../../../global/constants/action_types';
import { noImage } from '../../../global/generalMethods/general';
import StarsIcon from '@material-ui/icons/Stars';
import { Link, useNavigate, useParams } from 'react-router-dom';
class Profile extends Component {

    state = {
        showPasswordSection: false,
        showPersonalSection: false,
        showSocialSection: false,
        showEmailSection: false,
        fileTooLarge:false,
        uploading:false,
        somethingWentWrong: false,
        successful: false,
        file: null,
        fileSelected: false,
        isProfessional: true,
        profileCards: [
            {   label: 'Followers'},
            {   label: 'Projects'},
            {   label: 'Points'},
        ],
        skillsList: ['graphics', 'banner', 'animation'],
        skillsSubCategoryList: ['sub-graphics', 'sub-banner', 'sub-animation'],
    }

    mounted;

    componentDidMount=async()=>{
        this.mounted = true;
        if(!this.props.participant.email_verified_at) return;
        await this.getDetails();
    }

    componentWillUnmount=()=>{
        this.mounted = false;
    }

    getDetails=async()=>{
        if(!this.mounted) return;
        if(!this.props.participant.userName) return;
        Axios.get(`${PROXY}/api/get_details/${this.props.participant.userName}`, {headers: processHeader()})
        .then(res=>{
            if(res.data.success){
                if(!this.mounted) return;
                this.props.loadPersonalDetails(res.data.data);
            }else{
                if(res.data.Error === 'Not_Found'){
                    if(!this.mounted) return;
                    this.props.loadPersonalDetails({});
                }
            }
        })
        .catch(err=>{
            if(!this.mounted) return;
            if(err.response) console.log(err)
        })
    }

    bgToggle=(part)=>{
        const container = window.document.getElementById('profilePart');
        if(!container) return;
        if(part){
            container.className+=' bg-white';            
        }else{
            const temp = container.className.replace(/bg-white/ig, '');
            container.className = temp;
        }
    }

    toggleSocial=async(e)=>{
        e.preventDefault();
        await this.setState((state)=>{return{showSocialSection: !state.showSocialSection}});
        this.bgToggle(this.state.showSocialSection);
    }
    
    togglePersonal=async(e)=>{
        e.preventDefault();
        await this.setState((state)=>{return{showPersonalSection: !state.showPersonalSection}});
        this.bgToggle(this.state.showPersonalSection);
    }

    togglePassword=async(e)=>{
        e.preventDefault();
        await this.setState((state)=>{return{showPasswordSection: !state.showPasswordSection}});
        this.bgToggle(this.state.showPasswordSection);
    }
    toggleEmail=async(e)=>{
        e.preventDefault();
        await this.setState((state)=>{return{showEmailSection: !state.showEmailSection}});
        this.bgToggle(this.state.showEmailSection);
    }

    handleFileUpload=async(e)=>{
        e.preventDefault();        
        const fileCollector = e.target.files[0];
        if(!fileCollector) return;
        if(this.state.successful) await this.setState({successful: false});
        if(this.state.fileSelected) await this.setState({fileSelected: false});
        if(this.state.somethingWentWrong) await this.setState({somethingWentWrong: false}); 
        await this.setState({fileTooLarge: false});
        if(fileCollector.size > 12582912){
            await this.setState({fileTooLarge: false});
            return;
        }
        const picBox = await window.document.getElementById('profilePicBox');
        if(this.mounted) await this.setState({file: fileCollector});
        if(this.mounted) await this.setState({fileSelected: true});
        // if(!picBox) return alert(555);
        picBox.src = URL.createObjectURL(fileCollector);
    }
    saveFile=async(e)=>{
        e.preventDefault();
        if(!this.mounted) return;
        if(!this.state.file) return;
        if(this.state.uploading) return;
        await this.setState({uploading: true});
        if(this.state.successful) await this.setState({successful: false});
        if(this.state.somethingWentWrong) await this.setState({somethingWentWrong: false});
        const form = new FormData();
        form.append('profilePic', this.state.file);
        Axios.post(`${PROXY}/api/update_ProfilePic/${this.props.participant.userName}`, form, {headers:processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                await this.props.resetIdentity(JSON.parse(window.atob(res.data.data)))
                await this.setState({uploading: false});
                if(this.mounted) await this.setState({successful: true});
                if(this.mounted) await this.setState({file: null});
            }else{
                if(res.data.Error === 'Intruder'){
                    window.localStorage.clear();
                    return this.props.navigate('/meddillery');
                }
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            await this.setState({uploading: false});
            if(err.response) await this.setState({somethingWentWrong: true});

        })
    }

    render() {
        const {participant, personalDetails}=this.props;
        return (
            <React.Fragment>
                <div className="text-white profilePageHero">
                    <span className="profilePageHeroChild"></span>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <Typography variant="h4" className="text-capitalize sectionTitle text-white mb-3 font-weight-bold">Welcome back Medillery</Typography>
                        <Typography variant="caption" className="text-white">These are your Stats</Typography>
                    </div>
                </div>
                <div style={{marginTop: '-60px'}}>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row col-sm-12 col-md-9 col-lg-7 col-xl-7 justify-content-between">
                        {(personalDetails && Object.entries(personalDetails).length > 0) && this.state.profileCards.map((card, index) => (
                            <div key={index} className="col-4 p-md-2 p-0">
                                    {card.label !== 'Points'&&
                                        <div className="card mb-2">
                                            <div className="card-body p-2 p-md-3">
                                                <Typography variant="h5" 
                                                    className="text-capitalize sectionTitle mb-3 font-weight-bold"
                                                >
                                                    { personalDetails[card.label] }
                                                </Typography>
                                                <Typography variant="caption">{card.label}</Typography>
                                            </div>
                                        </div>
                                    }
                                    {( card.label === 'Points' && this.props.participant && this.props.participant.role === 1 ) &&
                                        <div className="card mb-2">
                                            <div className="card-body p-2 p-md-3">
                                                <Typography variant="h5" 
                                                    className="text-capitalize sectionTitle mb-3 font-weight-bold"
                                                    >
                                                    { personalDetails[card.label]? personalDetails[card.label] : 0  }
                                                </Typography>
                                                <Typography variant="caption">{card.label}</Typography>
                                            </div>
                                        </div>
                                    }
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <div className="componentBg mt-2">
                    <div>
                        <div className='d-flex justify-content-center'>
                            <Typography variant="h4" className="sectionTitle mb-3 font-weight-bold">
                                My Account
                            </Typography>
                        </div>
                        {(participant.role === 1 && personalDetails.Category && personalDetails.Subcategory) &&
                            <div className='d-block'>
                                <Typography className='d-flex justify-content-center' variant="h6">
                                    <StarsIcon className='text-primary' />
                                      Professional Account  
                                    <StarsIcon className='text-primary' />
                                </Typography>
                                <div className='d-flex justify-content-center'>
                                    <Typography variant="subtitle2" className="">
                                        YOUR CATEGORY: {personalDetails.Category} ({personalDetails.Subcategory})
                                    </Typography>
                                </div>
                                <div className='d-flex mt-1 pt-1 justify-content-center'>
                                    <Link 
                                        className='bg-info btn rounded-pill text-white ml-3'
                                        to={`/dashboard/profile/completed-projects`}
                                    >
                                        Completed projects
                                    </Link>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 text-center mt-4 mb-4">
                                {(participant.role !== "1" && participant.email_verified_at) &&
                                    <div>
                                        <div className="mb-3">
                                            <button 
                                                onClick={()=>this.props.loadProModal(true)} 
                                                className="btn btn-sm btn-outline-primary py-1 ml-auto rounded-pill"
                                            >
                                                Become a Professional
                                            </button>
                                        </div>
                                        <div>
                                            <BecomeProfessional />
                                        </div>
                                    </div>
                                }                                
                                <div className='row '>
                                    <div className='w3-mobile'>
                                        <div className="w3-center">
                                            <div className='w3-center pl-2'>
                                                <Link to={`${participant.profilePic}`} rel="noopener noreferrer" 
                                                    target='_blank' download
                                                >
                                                    <img 
                                                        id='profilePicBox'
                                                        src={participant.profilePic? participant.profilePic: noImage}
                                                        alt={noImage}
                                                        height='200'
                                                        width='200'
                                                        className='w3-card p-1 w3-round'
                                                    />
                                                </Link>
                                            </div>
                                            <div className='d-flex justify-content-center mt-2'>
                                                <label className=' w3-text-indigo text-center'>
                                                    <div className="my-auto">
                                                        <div className="text-center">
                                                            <AddPhotoAlternateIcon fontSize='large'  />
                                                        </div>
                                                        <input onChange={this.handleFileUpload} 
                                                            type="file" accept='image/*' 
                                                            className="d-none" 
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='my-2'>
                                                <button 
                                                    onClick={this.saveFile} 
                                                    className="btn rounded-pill customPrimaryBgColor text-white"
                                                >
                                                    <small>Upload profile Picture</small>
                                                </button>
                                            </div>
                                            <div className='d-block ml-3'>
                                                {this.state.uploading &&
                                                    <div className="w3-spin spinner w3-circle"></div>
                                                }
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center my-3'>
                                            {this.state.successful &&
                                                <small className='w3-text-teal'> Operation was successful</small>
                                            }
                                            {this.state.fileTooLarge &&
                                                <small className='text-danger'>File too large.</small>
                                            }
                                            {this.state.fileSelected &&
                                                <small className='w3-text-teal'>New File chosen </small>
                                            }
                                            {this.state.somethingWentWrong &&
                                                <small className='text-danger'>Oops! Something  went wrong</small>
                                            }                                                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='profilePart' className="col-sm-12 col-md-9 col-lg-9 col-xl-9">
                                <div className="d-flex justify-content-end mt-2">
                                    <button className="btn btn-sm btn-outline-primary py-1 ml-auto rounded-pill"
                                        onClick={this.togglePersonal}
                                    >
                                        <CreateIcon fontSize="small" />
                                        <span>Edit Personal</span>
                                    </button>
                                </div>
                                {this.state.showPersonalSection &&
                                    <Personal navigate={this.props.navigate} />
                                }
                                {((this.props.participant.type) === "AppAuth") &&
                                    <div className="d-flex justify-content-end mt-4">                                    
                                        <button className="btn btn-sm btn-outline-primary py-1 ml-auto rounded-pill"
                                            onClick={this.togglePassword}
                                        >
                                            <CreateIcon fontSize="small" />
                                            <span>Change Password</span>
                                        </button>
                                    </div>
                                }
                                {(this.state.showPasswordSection && (this.props.participant.type) === "AppAuth") && 
                                    <Password navigate={this.props.navigate} />
                                }
                                {((this.props.participant.type) === "AppAuth") &&
                                    <div className="d-flex justify-content-end mt-4">                                    
                                        <button className="btn btn-sm btn-outline-primary py-1 ml-auto rounded-pill"
                                            onClick={this.toggleEmail}
                                        >
                                            <CreateIcon fontSize="small" />
                                            <span>Change Email</span>
                                        </button>
                                    </div>
                                }
                                {(this.state.showEmailSection &&  (this.props.participant.type) === "AppAuth")  
                                    && <Email navigate={this.props.navigate} />
                                }
                                <div className="d-flex justify-content-end mt-4">
                                    <button className="btn btn-sm btn-outline-primary py-1 ml-auto rounded-pill"
                                        onClick={this.toggleSocial}
                                    >
                                        <CreateIcon fontSize="small" />
                                        <span>Edit Social</span>
                                    </button>
                                </div>
                                { this.state.showSocialSection && 
                                    <SocialMedial />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps=(state)=>({
    participant: state.participant,
    personalDetails: state.personalDetails,
})

const mapDispatchToProps=(dispatch)=>({
    loadProModal:(payload)=>dispatch(becomeProfessionalModal(payload)),
    loadPersonalDetails:(payload)=>dispatch(loadPersonalDetails(payload)),
    resetIdentity: (payload)=>dispatch(participantInfo(payload)),
})

const withRouter=Child=>props=>{
    const params = useParams();
    const navigate = useNavigate();
    return(
      <Child 
      {...props} 
      params={params} 
      navigate={navigate} 
      />
    )
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));

