import React from 'react';
import { Modal, Carousel } from 'react-bootstrap';
// import Carousel from 'react-material-ui-carousel/dist/components/Carousel';


const statusImageCard = {
    // objectFit: 'contain',
    height: '400px',
    width:'100%'
};

const DetailModal=({show, setShow, all, item})=>{
    const [shuffle, setShuffle] = React.useState([]);
    const [lastId, setLastId] = React.useState('');

    React.useEffect(()=>{
        if(!all || !item)return;

        const index = all.findIndex(data=>data.id === item.id);
        if(index === 0) return setShuffle(all);
        const newAll = [...all];
        newAll.splice(index,1);
        newAll.unshift(item)
        setShuffle(newAll);
    },[item, all]);

    const controller=(e)=>{
        try{
            if(e.cancelable){
                e.preventDefault();
              }
            const focal = e.target.id;
            const former = document.getElementById(lastId);
            // console.log(lastId)       
            if(former){
                if(focal === lastId){
                    if(former.paused) return former.play();
                    return former.pause();
                } 
                former.pause();
            }
            const dom = document.getElementById(focal);
            if(!dom) return;
            if(focal !== lastId) setLastId(focal);
            if(dom.paused){
                dom.play();
            }else{
                dom.pause();
            }
        }catch(err){
            return
        }
    }

    return(<div>

        {item && <Modal
            show={show}
            size="md"
            onHide={()=>setShow(false)}            
            dialogClassName="modal-50w"
        >
            <Modal.Body className='p-1'  >
                <Carousel
                    interval={null}
                >
                    {(shuffle.length > 0) && shuffle.map(data=>
                        <Carousel.Item key={data.id} style={{height: '400px', objectFit:'cover' }}>
                            { (data.type === 1 || data.type===2) &&
                                <div> 
                                    <img className="img-fluid cursorZoomIn image-responsive" style={statusImageCard} 
                                        id="enjoyImage" src={`${data.name}`} alt={data.name} 
                                    />
                                </div>
                            }
                            { (data.type === 3) &&
                                <div className="embed-responsive embed-responsive-4by3 py-0" style={{height:'100%'}}>
                                    <video
                                        id={`current-${data.name}`} 
                                        controls={window.innerWidth > 768} 
                                        onClick={controller} 
                                        className="embed-responsive-item" >
                                        <source src={`${data.name}`} type={`video/${data.name.slice(-3)}`} />
                                        {/*<source src="../../../assets/videos/video_2.mp4" type="video/mp4" />*/}
                                        Your browser does not support HTML5 video.
                                    </video>
                                </div>
                            }
                        </Carousel.Item>
                    )}
                </Carousel>
                
            </Modal.Body>
        </Modal>}
    </div>)
}
export default DetailModal;
