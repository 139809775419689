import React,{Component} from 'react';
import { getAllCategory, becomeProfessionalModal, participantInfo, processHeader } from '../../../global/action';
import { Modal } from 'react-bootstrap';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { PROXY } from '../../../global/constants/action_types';
import Axios from 'axios';

class BecomeProfessional extends Component{
    state={      
        getSelectedCategory: null,
        getSelectedSubcategory: null,
        category_subcategoryError: false,
        processing:false,
        successful:false,
        instruction:false,
        selectedCat:null,
        selectedSub:null,
        newParticipant:null,
        somethingWentWrong:false,
    }

    mounted;
    componentDidMount=async()=>{
        this.mounted = true;
        await this.props.loadCategories();
    }

    componentWillUnmount=()=>{
        this.mounted = false;
    }

    processCatToSub=async(e)=>{
        e.preventDefault();
        const selected = this.props.categoryList.find(val => val.name === e.target.value);
        if (selected) {
            await this.setState({getSelectedCategory: selected.id});
            await this.setState({selectedCat: selected.name});
            await this.setState({matchedSub:selected.subcategory });
            await this.setState({getSelectedSubcategory:null});
        }
    }

    processSub=async(e)=>{
        e.preventDefault();
        const selected = this.state.matchedSub.find(val=>val.name === e.target.value);
        if(selected){
            await this.setState({getSelectedSubcategory: selected.id});
            await this.setState({selectedSub: selected.name});
            await this.setState({category_subcategoryError:false});
            await this.setState({somethingWentWrong:false});
        }
    }

    becomePro=async(e)=>{
        e.preventDefault();
        if(!this.mounted) return;
        if(this.state.processing) return; 
        (this.state.somethingWentWrong) && await this.setState({somethingWentWrong:false});       
        if(!this.state.getSelectedCategory || !this.state.getSelectedSubcategory
            || typeof(this.state.getSelectedSubcategory) !== 'number' || typeof(this.state.getSelectedCategory) !== 'number'){
            await this.setState({category_subcategoryError:true});
            return;
        }
        const form = new FormData();
        form.append('category_id', this.state.getSelectedCategory);
        form.append('subcategory_id', this.state.getSelectedSubcategory);
        (this.mounted) && await this.setState({processing: true});
        const affirm = window.confirm('HI! I hope you chose your best field?')
        if(!affirm) return await this.setState({processing: false});
        Axios.post(`${PROXY}/api/become_professional/${this.props.participant.userName}`, form, {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                (this.mounted) && await  this.setState({selectedSub: null});
                (this.mounted) && await this.setState({selectedCat: null});                
                (this.mounted) && await this.setState({getSelectedCategory:null});
                (this.mounted) && await this.setState({getSelectedSubcategory:null});
                (this.mounted) && await this.setState({newParticipant: JSON.parse(window.atob(res.data.data))});                
                document.getElementById('categoryBox').value = '';
                document.getElementById('subcategoryBox').value = '';
                (this.mounted) && await this.setState({successful:true});
                (this.mounted) && await this.setState({processing:false});

            }else{
                if(res.data.Error === 'Intruder'){
                    window.localStorage.clear();
                    this.props.navigate('/', {replace:true});
                }
            }

        })
        .catch(async(err)=>{
            if(!this.mounted)return;
            if(err){
                document.getElementById('categoryBox').value = 'Category';
                document.getElementById('subcategoryBox').value = 'Subcategory';
                (this.mounted) && await this.setState({processing:false});
                (this.mounted) && await this.setState({getSelectedCategory:null});
                (this.mounted) && await this.setState({getSelectedSubcategory:null});
                (this.mounted) && await this.setState({somethingWentWrong:true});
            }
        })
    }

    handleProceed=(e)=>{
        e.preventDefault();
        if(this.state.newParticipant){
            this.props.resetIdentity(this.state.newParticipant);
            this.props.loadProModal(false);
        }
    }


    render(){
        const data = this.props.categoryList;
        return(
            <React.Fragment>
                <Modal
                    show={this.props.modal}
                    onHide={()=>{this.props.loadProModal(false)}}
                >                    
                    <Modal.Body className='py-1'>
                        <div style={{position:'relative'}}>
                            <div className="d-flex justify-content-center mr-4">
                                <Typography variant="h6" 
                                    className="sectionTitle font-weight-bold text-center"
                                >
                                    {this.state.instruction?
                                        `Important Information about professionals`:
                                        `Please choose your Specification here`
                                    }                           
                                </Typography>
                            </div>                            
                            <div className='px-2 ml-1 py-1 w3-circle w3-light-grey'
                                onClick={()=>{this.props.loadProModal(false)}} 
                                style={{position:'absolute', top:0, right:0, cursor:'pointer'}}>
                                X
                            </div>
                        </div>
                        {this.state.instruction ?
                            <div style={{maxHeight:'350px', overflowY:'auto'}}>
                                You are about to make the decision to be a professional on Medillery.
                                You can only choose one subcategory, please note that this decision cannot be altered later, we therefore advise that you select your major strength among the subcategories listed.
                                
                                Participants will give projects to you based on the subcategory you choose.
                                
                                We are glad you are making this decision to contribute to poverty alleviation in our community, which is the primary goal of this platform.
                                
                                Earn as you utilize your skills for a better world.
                                
                                Making freelancing free indeed.
                            </div>:
                            <div>
                                <div> 
                                    <div>
                                        <small> 
                                            This is the category and subcategory which you will be found when users want to assign
                                            a project of the same specifications to a professional. Please carefully choose your best
                                            field to satisfy your clients and earn more points according to the rating of your work.             
                                        </small>
                                        <div className='my-1'>
                                            See full information
                                            <b onClick={()=>this.setState({instruction:true})}className='text-primary ml-2' style={{cursor:'pointer'}}>
                                                HERE
                                            </b>
                                        </div>
                                    </div> 
                                    <hr />                                                      
                                    <Typography variant="subtitle1" className="font-weight-bold text-center smallerText mb-2">Select a Category and Subcategory</Typography>
                                    <div className=" my-2 form-group d-flex justify-content-between smallerText">
                                        <select id='categoryBox' onChange={this.processCatToSub} className="form-control rounded-pill mr-1">
                                            <option>Category</option>
                                            { data? data.map((val,index)=><option key={index}>{val.name}</option> ) : ''}
                                        </select>

                                        <select id='subcategoryBox' onChange={this.processSub} className="form-control rounded-pill ml-1">
                                            <option>Subcategory</option>
                                            {this.state.matchedSub && this.state.matchedSub.map((val,index)=><option key={index}>{val.name}</option> )}
                                        </select>
                                    </div>
                                </div>
                                <div className=" my-2 d-flex justify-content-center smallerText">
                                    {this.state.category_subcategoryError && 
                                        <div className='text-danger'>
                                            <small>Please select the category and subcategory of you project</small>
                                        </div>
                                    }
                                    {(this.state.getSelectedCategory && this.state.getSelectedSubcategory) &&
                                        <div>You selected the category of <b>{this.state.selectedCat}</b> and specialize in <b>{this.state.selectedSub}</b></div>
                                    }

                                    {this.state.somethingWentWrong &&
                                        <div><small className='text-danger my-2'>Something went wrong, please try again later</small></div>
                                    }
                                    {this.state.successful && 
                                        <div><small className='w3-text-teal my-2'><b>You are now a Professional</b></small></div>
                                    }
                                </div>
                                {this.state.processing &&
                                    <div className='d-flex justify-content-center'>
                                        <div className='w3-spin spinner w3-circle my -2'></div>
                                    </div>
                                }
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.instruction?
                            <div>
                                <button onClick={()=>this.setState({instruction: false})} className="btn w3-teal">
                                    Back
                                </button>
                            </div>:
                            <div>
                                {this.state.successful?
                                    <div className="text-center">
                                        <button onClick={this.handleProceed} className="btn btn-block customPrimaryBgColor text-white rounded-pill">
                                            Proceed
                                        </button>
                                    </div>:
                                    <div className="text-center">
                                        <button onClick={this.becomePro} className="btn btn-block customPrimaryBgColor text-white rounded-pill">
                                            Become Professional
                                        </button>
                                    </div>
                                }
                            </div>
                        }
                        
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }

}

const mapStateToProps=(state)=>({
    participant: state.participant,
    categoryList: state.categories,
    modal: state.professionalModal,
})

const mapDispatchToProps=(dispatch)=>({
    loadCategories: ()=>dispatch(getAllCategory()),
    loadProModal:(payload)=>dispatch(becomeProfessionalModal(payload)),
    resetIdentity: (payload)=>dispatch(participantInfo(payload))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(BecomeProfessional);