import React, {Component} from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import ErrorDisplay from './ErrorMessage';
import Axios from 'axios';
import { PROXY } from '../../../global/constants/action_types';
import { processHeader, participantInfo } from '../../../global/action';




const SignupSchema = Yup.object().shape({
	password: Yup.string()
    .min(8, 'Hint: Minimum is 8')
    .required(' Password is required'),
      
	email: Yup.string()
	  .email('Invalid email')
	  .required('Email is required'),
  });






class Email extends Component{
    state={
        processing: false,
        incorrectPassword: false,
        successful: false,
        somethingWentWrong: false,
        emailTaken: false,
    }
    mounted;

    componentDidMount=async()=>{
        this.mounted = true;
    }

    componentWillUnmount=async()=>{
        this.mounted = false;
    }

    handleSave=async(values)=>{
        if(this.state.processing) return;
        console.log(1)
        if(!this.props.participant.email_verified_at) return;
        console.log(2)
        if(this.props.participant.email === values.email) return;
        console.log(3)
        if(this.mounted) await this.setState({processing: true});
        (this.state.successful) && await this.setState({successful: false});
        (this.state.emailTaken) && await this.setState({emailTaken: false});
        (this.state.incorrectPassword) && await this.setState({incorrectPassword: false});
        (this.state.somethingWentWrong) && await this.setState({somethingWentWrong: false});
        
        const form = new FormData();
        form.append('email', values.email);
        form.append('password', values.password);
        Axios.post(`${PROXY}/api/change_email`, form, {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                await this.props.resetIdentity(JSON.parse(window.atob(res.data.data)));
                (this.mounted) && await this.setState({successful: true});
                (this.mounted) && await this.setState({processing: false});
            } else{
                if(res.data.Error === 'Intruder'){
                    window.localStorage.clear();
                    this.props.navigate('/', {replace:true});
                }
                if(res.data.Error === 'MailError'){
                    if(!this.mounted) return;
                    await this.setState({somethingWentWrong: true});
                    await this.setState({processing: false});
                }
                if(res.data.Error ===  'email_taken'){
                    
                    if(!this.mounted) return;
                    await this.setState({emailTaken: true});
                    await this.setState({processing: false});
                }
                if(res.data.Error === 'Incorrect_Key'){
                    if(!this.mounted) return;
                    await this.setState({incorrectPassword: true});                  
                    await this.setState({processing: false});
                }
            }

        })
        .catch(async(err)=>{
            if(!this.mounted) return;
           (err.response) && await this.setState({somethingWentWrong: true});
            await this.setState({processing: false});
        })
    }
    render(){
        return(
            <React.Fragment>
                <div className='w3-animate-zoom'>
                    <div className='w3-text-teal'>
                        <label className="ml-3">Change Email</label>
                    </div>
                    <Formik
                        initialValues={{email: this.props.participant.email, password: ''}}
                        validationSchema={SignupSchema}
                        onChange={this.handleChange}
                        onSubmit={this.handleSave}
                    >
                    {({ errors,
                        touched,
                        values}) => (
                            <Form>
                                <div className='form-group'>
                                    <label className="ml-3">Email</label>
                                    <Field type="email" className="form-control rounded-pill" name='email' placeholder={values.email} />
                                    {(touched.email && errors.email) && <ErrorDisplay message={errors.email} />}
                                </div>
                                <div className='form-group'>
                                    <label className="ml-3">Password</label>
                                    <Field type="password" className="form-control rounded-pill" name='password' placeholder="Enter your password" />
                                    {(touched.password && errors.password) && <ErrorDisplay message={errors.password} />}
                                </div>
                                <div className='form-group'>                                    
                                    <div className='my-3 d-flex justify-content-center'>
                                        {this.state.processing &&
                                            <div className="w3-spin spinner w3-circle"></div>
                                        }
                                        {this.state.incorrectPassword &&
                                            <small className='text-danger'>Sorry, the password provided doesn't match</small>
                                        }
                                        {this.state.somethingWentWrong &&
                                            <small className='text-danger'>Oops! Something went wrong.</small>
                                        }
                                        {this.state.emailTaken &&
                                            <small className='text-danger'>Sorry! You can't use this email.</small>
                                        }
                                        {this.state.successful && 
                                            <small className='w3-text-teal'>
                                                <b>Operation was successful.</b><br />
                                                You can only access your account henceforth if you have confirmed the new email 
                                                in the link sent to the new email provided.                                 
                                            </small>                                
                                        }
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <button className="btn rounded-pill customPrimaryBgColor text-white" type='submit'>Save</button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                    </Formik>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps=(state)=>{
	return{
        participant: state.participant,

	}
}

const mapDispatchToProps=(dispatch)=>{
    return{
        resetIdentity:(payload)=>dispatch(participantInfo(payload)),
    }
}


  export default connect(mapStateToProps, mapDispatchToProps)(Email);