import React, {Component} from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import ErrorDisplay from './ErrorMessage';
import Axios from 'axios';
import { PROXY } from '../../../global/constants/action_types';
import { processHeader } from '../../../global/action';
import { connect } from 'react-redux';



const SignupSchema = Yup.object().shape({
	newPassword: Yup.string()
	  .min(8, 'Minimum of 8 is required')
      .required(' Password is required')
      .test('new password', 'new password is required', function(value) {
        return this.parent.currentPassword !== value;}
      )
	  .matches(/(^([a-zA-Z0-9_]){8,}$)/, "0-9 , _ , a-z are only accepted"),

	confirmPassword: Yup.string()
		.required("Required")
		.test('passwords-match', 'Password must match', function(value) {
		  	return this.parent.newPassword === value;}
		),

    currentPassword: Yup.string()
        .min(8, 'Hnt: Minimum is 8')
		.required('Your first name is required'),
});






class Password extends Component{
    state={
        processing: false,
        incorrectPassword: false,
        successful: false,
        somethingWentWrong:false,

    }
    mounted;

    componentDidMount=async()=>{
        this.mounted = true;
    }

    componentWillUnmount=async()=>{
        this.mounted = false;
    }

    handleSave=async(values)=>{
        if(this.state.processing) return;
        if(!this.props.participant.email_verified_at) return;
        (this.mounted) && await this.setState({processing: true});
        (this.state.incorrectPassword) && await this.setState({incorrectPassword: false});
        (this.state.successful) && await this.setState({successful: false});
        const form = new FormData();
        form.append('oldPassword', values.currentPassword);
        form.append('newPassword', values.newPassword);
        Axios.post(`${PROXY}/api/change_password`, form, {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                (this.mounted) && await this.setState({successful: true});

            }else{
                if(res.data.Error === 'Intruder'){
                    window.localStorage.clear();
                    this.props.navigate('/', {replace:true});
                }
                if(res.data.Error === 'Incorrect'){
                    if(!this.mounted) return;
                    await this.setState({incorrectPassword: true});  
                }
            }
        })
        .catch((err)=>{
            if(!this.mounted) return;
            if(err.response) this.setState({somethingWentWrong: true});
        }).finally(()=>{
            this.mounted && this.setState({processing: false});
        })
    }

    render(){
        return(
            <React.Fragment>
                <div className='w3-animate-zoom'>
                    <div className='w3-text-teal'>
                        <label className="ml-3">Change Password</label>
                    </div>
                    <Formik
                        initialValues={{currentPassword: '', confirmPassword: '', newPassword: ''}}
                        validationSchema={SignupSchema}
                        onSubmit={this.handleSave}
                    >
                    {({ errors,
                        touched,
                        }) => (
                            <Form>
                                <div className='row'>
                                    <div className='form-group col-sm-12 col-md-6'>
                                        <label className="ml-3">Current password</label>
                                        <Field name='currentPassword' type="password" className="form-control rounded-pill" placeholder="******" />
                                        {(touched.currentPassword && errors.currentPassword) && <ErrorDisplay message={errors.currentPassword} />}
                                    </div>
                                    <div className='form-group "form-group col-sm-12 col-md-6'>
                                        <label className="ml-3">New password</label>
                                        <Field name='newPassword' type="password" className="form-control rounded-pill" placeholder="******" />
                                        {(touched.newPassword && errors.newPassword) && <ErrorDisplay message={errors.newPassword} />}
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className="ml-3">Confirm password</label>
                                    <Field name='confirmPassword' type="password" className="form-control rounded-pill" placeholder="******" />
                                    {(touched.confirmPassword && errors.confirmPassword) && <ErrorDisplay message={errors.confirmPassword} />}
                                </div>
                                <div className='form-group'>                                    
                                    <div className='my-3 d-flex justify-content-center'>
                                        {this.state.processing &&
                                            <div className="w3-spin spinner w3-circle"></div>
                                        }
                                        {this.state.incorrectPassword &&
                                            <small className='text-danger'>Incorrect password provided.</small>
                                        }
                                        {this.state.somethingWentWrong &&
                                            <small className='text-danger'>Oops! Something went wrong.</small>
                                        }
                                        {this.state.successful && 
                                            <small className='w3-text-teal'><b>Your password has successfully been changed.</b></small>                                
                                        }
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <button className="btn rounded-pill customPrimaryBgColor text-white" type='submit'>Save</button>
                                    </div>
                                </div>                            
                            </Form>
                        )
                    }
                    </Formik>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps=(state)=>{
	return{
        participant: state.participant,
	}
}


  
  export default connect(mapStateToProps)(Password);