import React,{Component} from 'react';
import { Input, InputLabel, FormControl, InputAdornment} from '@material-ui/core';
import { processHeader, participantInfo } from '../../global/action';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Axios from 'axios';
import { PROXY } from '../../global/constants/action_types';
import { connect } from 'react-redux';


class ResendEmailConfirmation extends Component{

    state = {
        resendingLink: false,
        resendWentWrong: false,
        resendSuccessful: false,
        showKeyHolder:false,
        showProceed: true,
        canCheck: false,
        lastEmailValue:'',
        processing:false,
        emailError: false,
        passwordError: false,
        emailErrorMessage: '',
        passwordErrorMessage: '',
        available: false,
        taken:false,
        emailChanged: false,
        emailChangeError: false,

    }

    mounted;
    timerHolder;

    componentDidMount=async()=>{
        this.mounted = true;
    }
    componentWillUnmount=async()=>{
        this.mounted = false;
    }

    resendLink=async(e)=>{
        e.preventDefault();
        if(this.state.resendingLink) return;
        (this.mounted) && await this.setState({resendingLink: true});
        (this.mounted) && await this.setState({resendSuccessful: false});
        (this.mounted) && await this.setState({resendWentWrong: false});
        Axios.get(`${PROXY}/api/resend_email_verification`, {headers:processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                await this.setState({resendSuccessful: true});
                await this.setState({resendingLink: false});
            }
            else{
                if(res.data.Error === 'Intruder'){                    
                    if(!this.mounted) return;
                    await this.setState({resendingLink: false});
                    window.localStorage.clear();
                    this.props.history.replace('/');
                }
                if(res.data.Error === 'MailError'){
                    if(!this.mounted) return;
                    await this.setState({resendWentWrong: true});
                    await this.setState({resendingLink: false});
                }
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
                await this.setState({resendWentWrong: true});
                await this.setState({resendingLink: false});
        })
    }

    changeEmail=async(e)=>{
        e.preventDefault();
        if(this.state.processing) return;
        (this.mounted) && await this.setState({emailError: false});
        (this.mounted) && await this.setState({passwordError: false});
        (this.mounted) && await this.setState({emailChangeError: false});
        const email = document.getElementById('new_email').value.trim();
        const passKey = document.getElementById('user_password').value.trim();
        if(!email){
            if(!this.mounted) return;
            await this.setState({emailError: true});
            await this.setState({emailErrorMessage: 'Email is required'});
            return;
        }
        if(!/(^[-_.a-zA-Z0-9]+)([@])([-._a-zA-Z0-9]+)(\.)([a-zA-Z0-9]+)$/.test(email)){
            if(!this.mounted) return;
            await this.setState({emailErrorMessage: 'Invalid Email'});
            await this.setState({emailError: true});
            return;
        }
        if(!passKey){
            if(!this.mounted) return;
            await this.setState({passwordErrorMessage: 'Password is required'});
            await this.setState({passwordError: true});
            return;
        }
        if(!/(^([a-zA-Z0-9_]){8,}$)/.test(passKey)){
            if(!this.mounted) return;
            await this.setState({passwordError: true});
            await this.setState({passwordErrorMessage: 'Password can only contain Alphanumeric with minimum of 8 characters'});
            return;
        }

        const form = new FormData();
        form.append('password', passKey);
        form.append('email', email)
        Axios.post(`${PROXY}/api/change_email`, form, {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                this.props.resetIdentity(JSON.parse(window.atob(res.data.data)));
                (this.mounted) && await this.setState({emailChanged: true});
                (this.mounted) && await this.setState({processing: false});
            }
            else{
                if(res.data.Error === 'Intruder'){
                    window.localStorage.clear();
                    this.props.history.replace('/');
                }
                if(res.data.Error === 'MailError'){
                    if(!this.mounted) return;
                    await this.setState({emailChangeError: true});
                    await this.setState({process: false});
                }
                if(res.data.Error === 'Incorrect_Key'){
                    if(!this.mounted) return;
                    await this.setState({emailChangeError: true});
                    await this.setState({passwordError: true});
                    await this.setState({passwordErrorMessage: 'Incorrect password'});                   
                    await this.setState({process: false});
                }
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            await this.setState({processing: false});
            // if(err.response) console.log(err.response);
        })
    }

    validator=async(email)=>{
        if(!email){
            if(!this.mounted) return;
            await this.setState({emailError: true});
            await this.setState({emailErrorMessage: 'Email is required'});
            return;
        }
        if(!/(^[-_.a-zA-Z0-9]+)([@])([-._a-zA-Z0-9]+)(\.)([a-zA-Z0-9]+)$/.test(email)){
            if(!this.mounted) return;
            await this.setState({emailErrorMessage: 'Invalid Email'});
            await this.setState({emailError: true});
            (this.mounted) && await this.setState({processing: false});
            return;
        }
    }

    prepareEmail=async(e)=>{
        e.preventDefault();
        const email = e.target.value.trim();
        await this.setState({emailError: false});
        await this.validator(email);         
    }
    

    emailOnchange=async(e)=>{
        e.preventDefault();
        const email = e.target.value.trim();
        if(email === this.state.lastEmailValue) return;         
        if(this.timerHolder) clearTimeout(this.timerHolder);
        await this.setState({emailError: false});
        if(this.state.emailError){
         await this.validator(email)
        }
        if(!email) return;
        if(!/(^[-_.a-zA-Z0-9]+)([@])([-._a-zA-Z0-9]+)(\.)([a-zA-Z0-9]+)$/.test(email)) return;
        
        await this.setState({available: false});
        await this.setState({taken: false});
        if(email === this.props.participant.email){
            await this.setState({taken: true});
            await this.setState({showProceed: false});
            return;
        }
        this.timerHolder = setTimeout((async()=>await this.checkEmail(email)),1600);
    }

    preparePassword=async(e)=>{
        e.preventDefault();
        const passKey = e.target.value.trim();
        await this.setState({passwordError: false});
        if(!passKey){
            if(!this.mounted) return;
            await this.setState({passwordErrorMessage: 'Password is required'});
            await this.setState({passwordError: true});
            return;
        }
        if(!/(^([a-zA-Z0-9_]){8,}$)/.test(passKey)){
            if(!this.mounted) return;
            await this.setState({passwordError: true});
            await this.setState({passwordErrorMessage: 'Password can only contain Alphanumeric with minimum of 8 characters'});
            return;
        }
    }

    canShowKeyHolder=async(e)=>{
        e.preventDefault();
        if(this.state.available ){
            (this.mounted) && await this.setState({showKeyHolder: true});
            (this.mounted) && await this.setState({showProceed: false});
        }
    }

    checkEmail=async(email)=>{
        if(!this.mounted) return;
        if(this.state.processing) return;
        await this.setState({showProceed: true});          
        (this.mounted) && await this.setState({processing: true});
        (this.mounted) && await this.setState({lastEmailValue: email});     
        const form = new FormData();
        form.append('email', email)
        Axios.post( `${PROXY}/api/check_email`, form, {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                (this.mounted) && await this.setState({showProceed: true});
                (this.mounted) && await this.setState({available: true});
                (this.mounted) && await this.setState({processing: false});
            }
            else{
                if(res.data.Error === 'Intruder'){
                    alert(10)
                    window.localStorage.clear();
                    this.props.history.replace('/');
                }
                if(res.data.Error === 'Exists'){
                    if(!this.mounted) return;
                    (this.mounted) && await this.setState({showProceed: false});
                    await this.setState({taken: true});
                    await this.setState({processing: false});
                }
            }
        })
        .catch(async(err)=>{
            if(!this.mounted) return;
            await this.setState({processing: false});
            (this.mounted) && await this.setState({showProceed: false});
            // if(err.response) console.log(err.response);
        })
    }


    render(){
        return(
            <div className='w3-container py-4 w3-round w3-card w3-light-grey'>
                <Typography variant="h6" className="sectionTitle text-capitalize font-weight-bold text-center">
                      Unverified Account                    
                </Typography>
                <div className='d-flex justify-content-center'>
                    <div className='w3-container mx-lg-5'>
                        We sent a link to your email <b>{this.props.participant.email}</b>,
                        check your email and click on verify account. <br /> If you did not receive any email,
                        click on resend button below to send the link to you email again.
                    </div>
                </div>
                <div>
                    <div className='d-flex justify-content-center my-2'>
                        {(this.state.resendWentWrong) &&
                            <div><small className='text-danger'>Oops! Something went wrong</small></div> 
                        }
                        {(this.state.resendSuccessful) &&
                            <div><small className='text-info'>New link has been sent to your email address</small></div> 
                        }
                    </div>
                    <div className='d-flex justify-content-center my-2'>
                        <div className='mr-2'>
                            <button className='btn customPrimaryBgColor rounded-pill text-white' onClick={this.resendLink}>
                                Re-send
                            </button>
                        </div>                        
                        <div>
                            {(this.state.resendingLink) &&
                                <div className="w3-spin spinner w3-circle ml-2"></div>
                            }
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center my-3'>
                    If you wish to change your email for verification purpose, please enter the new email below
                </div>
                <div>
                    <div>
                        <div className='d-flex  justify-content-center'>
                            <FormControl size='medium' className='mx-2 mb-2'>
                                <InputLabel htmlFor="new_email">Enter new Email</InputLabel>
                                <Input  
                                    error={this.state.emailError} 
                                    id="new_email" 
                                    onBlur={this.prepareEmail}
                                    onKeyUp={this.emailOnchange}
                                    endAdornment={
                                        <InputAdornment onClick={this.canShowKeyHolder} position="end">
                                            {(this.state.showProceed) &&
                                                <div className='customPrimaryBgColor' style={{cursor:'pointer'}}>
                                                    <ChevronRightIcon  />
                                                </div>
                                            }
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className='d-flex justify-content-center'>
                            {(this.state.emailError) && 
                                <small className='text-danger mb-2'>{this.state.emailErrorMessage}</small>
                            }
                        </div>
                           
                        <div className="text-right">
                                        
                            { this.state.available &&
                                <Typography variant="subtitle2" className="text-capitalize text-success"><CheckBoxIcon fontSize="small" />
                                    Available
                                </Typography>
                            }
                            { this.state.taken &&
                                <Typography variant="subtitle2" className="text-capitalize text-danger"><CancelIcon fontSize="small" />
                                    Already Taken
                                </Typography>
                            }
                        </div>
                        
                    </div>
                    {(this.state.showKeyHolder) &&
                        <div>
                            <div className='d-flex  justify-content-center'>
                                <FormControl className='mx-2 mb-3'>
                                    <InputLabel htmlFor="user_password">Enter Password</InputLabel>
                                    <Input type="password" id="user_password"  onBlur={this.preparePassword} 
                                        onChange={(event)=>{this.state.passwordError && this.preparePassword(event)}}
                                    />                                    
                                </FormControl>
                            </div>
                            <div className='d-flex  justify-content-center'>
                                {(this.state.passwordError) && 
                                    <small className='text-danger mb-2'>{this.state.passwordErrorMessage}</small>
                                }
                            </div>
                            <div className='d-flex  mb-2 justify-content-center'>   
                                {this.state.emailChanged && 
                                    <div>
                                        <small>Your Email has been changed successfully</small>
                                    </div>
                                }
                                {this.state.emailChangeError && 
                                    <div>
                                        <small className='text-danger'>Oops! something went wrong.</small>
                                    </div>
                                }
                                <div>
                                    {(this.state.processing) &&
                                        <div className="w3-spin spinner w3-circle ml-2"></div>
                                    }
                                </div>
                            </div>
                            <div className='d-flex  justify-content-center'>
                                <button onClick={this.changeEmail} className="btn customPrimaryBgColor text-white rounded-pill">
                                    Change Email
                                </button>
                            </div>
                        
                        </div>
                    }                    
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return{
        participant: state.participant,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        resetIdentity: (payload)=>dispatch(participantInfo(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResendEmailConfirmation);