import React, { Component } from 'react';
import { Button, Typography } from '@material-ui/core';
import './HowItWorks.css';
import { connect } from 'react-redux';
import { showSignupModal, showAuthModal } from '../../../global/action';
import { Link } from 'react-router-dom';

class HowItWorks extends Component {
    state = {  }
    render() {
        return (
            <div style={{marginTop: '90px'}}>
                <div className="container">
                    <div className="row headerBg mt-4" style={{marginBottom: '-150px'}}>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 py-5 px-4">
                            <Typography variant="h4" className="sectionTitle mb-2">How it works</Typography>
                            <Typography variant="subtitle2" className="font-weight-light mb-2">
                                Walking you through how Medillery works.
                            </Typography>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-right pr-4 mr-5">
                            <img alt='how-it-works' className="howItWorksHero" src={ require('../../../assets/images/website/how-it-works/how-it-works-hero.png') } />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row my-5">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-5 text-center">
                            <img alt='how-it-works' className="img-fluid" style={{ height: '300px'}} src={ require('../../../assets/images/website/how-it-works/ready-made-templates-icon.png') } />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-5 my-auto">
                            <Typography variant="h6" className="sectionTitle">Ready Made Templates</Typography>
                            <Typography variant="body1" className='mb-4'>
                                When you sign up on our platform as a user,
                                you have access to a wide range of already made templates which you can select from,
                                customize and download for immediate use ABSOLUTELY FREE!
                            </Typography>
                            <Link to='/templates'
                                className="customSecondaryBgColor text-white py-2 px-4 rounded-pill mr-3 mt-5"
                            >
                                TRY IT NOW
                            </Link>
                        </div>
                    </div>
                    <div className="row my-5 py-5 secondBg">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-5 d-flex align-items-center">
                            <div>
                                <Typography variant="h6" className="sectionTitle">Hire a Professional</Typography>
                                <Typography variant="body1">
                                    You can as well hire a professional to handle your project on our platform for free.
                                    The professional gets the job done within the stipulation of the terms and conditions.
                                    <br /><br /> 
                                    The professional sends you the project for review when is done for review,
                                     then you rate the professional based on the quality of the work done.
                                      Then we pay the professional
                                </Typography>
                                <Button variant="contained" 
                                    disableElevation onClick={()=>{this.props.setAuthModal(true); this.props.setShowSignUp(true)}} 
                                    className="customSecondaryBgColor text-white rounded-pill mr-3 mt-2"
                                >
                                    Get Started
                                </Button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-5 text-center order-md-last order-first">
                            <img alt='how-it-works' className="img-fluid" style={{ height: '300px'}} src={ require('../../../assets/images/website/how-it-works/hire-a-professional-icon.png') } />
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-5 d-flex align-items-center">
                            <div>
                                <Typography variant="h6" className="sectionTitle">Register as a Professional</Typography>
                                <Typography variant="body1">When a professional registers on Medillery, and begins to work on projects, he or she begins to earn points as clients rate him or her based on the quality of delivery on assigned projects. <br /> <br /> When a profession reaches a total of 100 points, Medillery begins to pay the professional monthly. Even if the professional stops working, we keep paying him or her.</Typography>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-5 text-center order-md-last order-first">
                            <img alt='how-it-works' className="img-fluid" style={{ height: '300px'}} src={ require('../../../assets/images/website/how-it-works/register-as-professional-icon.png') } />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps=(dispatch)=>{
    return{
      setShowSignUp: (payload)=>dispatch(showSignupModal(payload)),
      setAuthModal: (payload)=>dispatch(showAuthModal(payload)),
    }
  }
export default connect(null, mapDispatchToProps)(HowItWorks);