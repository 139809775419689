import React, { Component } from 'react';
import { Avatar, Typography } from '@material-ui/core';
import { noImage, ESTIMATE_TIME, generateFiveRandomNumbers } from '../../global/generalMethods/general'
import { connect } from 'react-redux';
import { loadNotifications, processHeader, notificationsCounter} from '../../global/action';
import Axios from 'axios';
import { PROXY } from '../../global/constants/action_types';
import { Link, useNavigate } from 'react-router-dom';

class Notifications extends Component {
    state = {
        loading: false,
        notificationsCollector: {},
        marking: false,
        type: 0,
    }

    mounted;

    componentDidMount=async()=>{
        this.mounted = true;
        if(!this.props.participant.email_verified_at) return;
        await this.getNotifications(); 
    }
    UNSAFE_componentWillReceiveProps=async(next)=>{
        if(!this.mounted) return;
        if(next.notificationList){
            this.mounted && await this.setState({notificationsCollector: next.notificationList})
        }
    }
    componentWillUnmount=()=>{
        this.mounted = false;
        window.removeEventListener('scroll', this.virtualScroll);
    }

    getNotifications=async()=>{
        if(!this.loading){            
            if(!this.loading) await this.setState({loading: true});
            Axios.get(`${PROXY}/api/get_notifications/${this.props.participant.userName}`,
             {headers: processHeader()})
            .then(async(res)=>{
                if(res.data.success){
                    try {                        
                        if(!this.mounted) return;
                        this.mounted && await this.props.loadNotifications(res.data.data);
                        (this.mounted)  && await this.setState({loading: false});
                        this.prepareGetMore();
                    } catch (_){}
                }
            })
            .catch(async(_)=>{
                if(!this.mounted) return;
                await this.setState({loading: false});    
            })
        }
    }

    getMoreNotifications=()=>{
        if(this.loading) return;
        const {notificationsCollector:notifications} = this.state
        if(this.state.loading || !notifications.data || !notifications.next_page_url) return;
        console.log(this.state.loading);
        this.setState({loading: true});
        Axios.get(`${notifications.next_page_url}`, {headers: processHeader()})
        .then(async(res)=>{
            if(res.data.success){                
                if(!this.mounted) return;
                const temp = Object.assign({}, this.props.notificationList);
                const mergeData = [...temp.data, ...res.data.data.data]
                res.data.data.data = mergeData;
                await this.props.loadNotifications(res.data.data);
                await this.setState({loading: false});
            }
        })
        .catch(async(_)=>{
            if(!this.mounted) return;
            await this.setState({loading: false});
        })
    }

    virtualScroll=()=>{
        if(window.pageYOffset+window.innerHeight >= document.body.offsetHeight+70){
            if(this.state.notificationsCollector.next_page_url){
                this.getMoreNotifications();
            }
        }
    }

    prepareGetMore=()=>{
        if(!this.state.loading && this.state.notificationsCollector.next_page_url ){
            window.addEventListener('scroll', this.virtualScroll);
        }
    }

    markAllAsRead=(e)=>{
        e.preventDefault();
        if(this.state.marking) return;
        if(this.mounted) this.setState({marking:true});
        Axios.get(`${PROXY}/api/mark_read_all_notifications/${this.props.participant.userName}`, {headers:processHeader()})
        .then(async(res)=>{
            if(res.data.success){
                if(!this.mounted) return;
                const temp = Object.assign({}, this.state.notificationsCollector);
                const tempData = temp.data.map(data=>{
                    return{
                        read:1, id:data.id, key:data.key, sub:data.sub, origin:data.origin, image:data.image,
                        type:data.type, created_at:data.created_at, updated_at:data.updated_at
                    }
                })
                
                temp.data = tempData;
                if(!this.mounted) return;
                this.props.loadNotificationsCount(0);
                // console.log(temp);
                await this.setState({notificationsCollector:temp})
                await this.setState({marking:false});
            }
        })
        .catch(async(_)=>{})
    }

    formatTime=(time)=>{
        if(!time) return;
        //convert time to timestamp and compare with current timestamp
        if(!window.luxon) return;
        // const local = window.luxon.DateTime.local();
        const format = time.trim().replace(' ',"T")
        const ms = window.luxon.DateTime.fromISO(format, {zone: 'Africa/Accra'});
        // const rezoned = ms.setZone(local.zoneName);
        const diff = Date.now()-Date.parse(ms);
        const sec=((diff)/(1000));
        return ESTIMATE_TIME(sec);
    }

    markAndNavigateToChat=(key)=>{
        const routeEncode = `${generateFiveRandomNumbers()}${key}${generateFiveRandomNumbers()}`;
        return `/dashboard/inbox/${window.btoa(window.btoa(routeEncode))}`;
    }

    markAndNavigateToAdminChat=()=>{
        const ID = `ADMIN_${this.props.participant.userName}`;
        return `/dashboard/admin/${window.btoa(window.btoa(ID))}`;
    }

    markAndNavigateToProfile=(key, id)=>{
        const item = this.state.notificationsCollector.data.find(data=>data.id == id);
        if(item && item.read == 1) return this.props.navigate(`/user/${key}`);
        Axios.get(`${PROXY}/api/mark_read_notification/${this.props.participant.userName}/${id}`, {headers:processHeader()})
        .then((res)=>{
            if(res.data.success){
                if(!this.mounted) return;                
                return this.props.navigate(`/user/${key}`);
            }
        })
        .catch(async(err)=>{
            return this.props.navigate(`/user/${key}`);
        })
    }

    markAndNavigateToProject=(key)=>{                       
        const formatRoute =  `${generateFiveRandomNumbers()}_${key}_${generateFiveRandomNumbers()}`;
        const encodedRoute= window.btoa(window.btoa(formatRoute));
        return `/dashboard/projects/${encodedRoute}`;
    }

    markAndNavigateToProjectIssue=(key)=>{
        return `/dashboard/projects/issue/${window.btoa(window.btoa(key))}`;
    }
    
    reduceCount=()=>{
        if(this.props.countNotification && this.props.countNotification > 0){
            const minusCount = this.props.countNotification - 1;
            this.props.loadNotificationsCount(minusCount);                       
        } 
    }

    render() {
        const {notificationsCollector: notifications} = this.state
        console.log(notifications);
        return (
            <div>
                <div className="row mb-2">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <Typography variant="h6">Notifications</Typography>
                    </div>
                    <div className="col-12 mb-2">
                        <div className='w3-container' style={{ cursor:'pointer' }}>
                            <div className='w3-row'>
                                <div className=" py-1 w3-col s6 m6 l6 w3-center"
                                onClick={()=>{this.setState({type:0})}}
                                    style={{ background: `${!this.state.type?'white':'inherit'}` }}
                                >
                                    Personal
                                </div>
                                <div className=" py-1 w3-col  s6 m6 l6 w3-center"
                                    onClick={()=>{this.setState({type:1})}}
                                    style={{ background: `${this.state.type?'white':'inherit'}` }}
                                >
                                    From Admin
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 d-flex justify-content-end align-items-center">
                        <div className="d-flex mr-3">
                        {this.state.loading &&
                            <div><div className="w3-spin spinner w3-circle mt-2"></div></div>
                        }

                        {(notifications.data && notifications.data.length) &&
                            <button onClick={this.markAllAsRead} className="btn btn-sm rounded-pill bg-secondary text-white">Mark all as read</button>
                        }
                        </div>
                    </div>
                </div>
                <div className="componentBg bg-light" style={{ marginBottom:'0px', paddingBottom:'0px' }}>

                {notifications.data && notifications.data.length?                    
                    <div>
                    {Boolean(this.state.type)?
                        <div>
                        {notifications.data.filter(item=>item.fromAdmin == 1).length?
                            <div>
                                {notifications.data.filter(item=>item.fromAdmin == 1).map(item=>
                                    <div className={`row ${(item.read == 0)? `bg-white`:``}`} key={item.id}>
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="d-flex justify-content-between mb-2">
                                                <div className="d-flex pt-2">
                                                    <Avatar alt="avatar1" className="img-fluid mr-2" 
                                                        src={'admin'} 
                                                    />
                                                    <div>
                                                        {item.type == 1 &&
                                                            <div>
                                                                {item.sub == 4 &&
                                                                    <span><b>Admin </b>has replied a message on one of your projects' issue ticket</span>
                                                                }
                                                            </div>
                                                       }
                                                        {item.type == 2 &&
                                                            <Typography variant="subtitle1">New personal message from <span><b>ADMIN</b></span></Typography>
                                                        }
                                                        <span className="small text-muted">{this.formatTime(item.created_at)} ago</span>
                                                    </div>
                                                </div>
                                                <div className="ml-2 pt-2" onClick={()=>this.reduceCount()}>
                                                    {item.type == 1 &&
                                                        <div>
                                                            {item.sub == 4 &&
                                                                <Link      
                                                                    to={this.markAndNavigateToProjectIssue(`${item.key}`)}
                                                                    className="btn btn-sm customPrimaryBgColor px-2 py-1 text-white rounded-pill"
                                                                >
                                                                    <span className="small">View</span>
                                                                </Link>
                                                            }
                                                        </div>
                                                        
                                                    }    
                                                    {item.type == 2 &&
                                                        <Link 
                                                            to={this.markAndNavigateToAdminChat()} 
                                                            className="btn btn-sm customPrimaryBgColor px-2 py-1 text-white rounded-pill"
                                                        >   <span className="small">View</span>
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                )}
                            </div>:
                            <div>
                                No notification from admin
                            </div>
                        }
                        </div>:
                        <div>
                        {notifications.data.filter(item=>item.fromAdmin != 1).length?
                            <div>
                                {notifications.data.filter(item=>item.fromAdmin != 1).map(item=>
                                    <div className={`row ${(item.read == 0)? `bg-white`:``}`} key={item.id}>
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="d-flex justify-content-between mb-2">
                                                <div className="d-flex pt-2">
                                                    <Avatar alt="avatar1" className="img-fluid mr-2" 
                                                        src={item.image?`${item.image}`:`${noImage}`} 
                                                    />
                                                    <div>
                                                        {item.type == 1 &&
                                                            <div>
                                                                {item.sub == 0 &&
                                                                    <Typography variant="subtitle1">New project from <span><b>@{item.origin}</b></span></Typography>
                                                                }
                                                                {item.sub == 1 &&
                                                                    <Typography variant="subtitle1"><span><b>@{item.origin} </b>has Accepted your project</span></Typography>
                                                                }
                                                                {item.sub == 2 &&
                                                                    <Typography variant="subtitle1"><span><b>@${item.origin} </b>has Completed your project</span></Typography>
                                                                }
                                                                {item.sub == 3 &&
                                                                    <Typography variant="subtitle1"><span><b>@{item.origin} </b>has Rejected your project</span></Typography>
                                                                }
                                                                {item.sub == 4 &&
                                                                    <Typography variant="subtitle1">
                                                                        {!item.fromAdmin?
                                                                            <span><b>@{item.origin} </b>has sent a message on one of your projects' issue ticket</span>:
                                                                            <span><b>Admin </b>has replied a message on one of your projects' issue ticket</span>
                                                                        }
                                                                    </Typography>
                                                                }
                                                            </div>
                                                        }
                                                        {item.type == 2 &&
                                                            <Typography variant="subtitle1">New message from <span><b>@{item.origin}</b></span></Typography>
                                                        }
                                                        {item.type == 3 &&
                                                            <Typography variant="subtitle1"><span><b>@{item.origin}</b> started following you</span></Typography>
                                                        }
                                                        <span className="small text-muted">{this.formatTime(item.created_at)} ago</span>
                                                    </div>
                                                </div>
                                                <div className="ml-2 pt-2" onClick={()=>this.reduceCount()}>
                                                    {item.type == 1 &&
                                                        <div>
                                                            { (item.sub != 4) ?
                                                                <Link      
                                                                    to={this.markAndNavigateToProject(`${item.key}`)}
                                                                    className="btn btn-sm customPrimaryBgColor px-2 py-1 text-white rounded-pill"
                                                                >
                                                                    <span className="small">View</span>
                                                                </Link>:
                                                                <Link      
                                                                    to={this.markAndNavigateToProjectIssue(`${item.key}`)}
                                                                    className="btn btn-sm customPrimaryBgColor px-2 py-1 text-white rounded-pill"
                                                                >
                                                                    <span className="small">View</span>
                                                                </Link>
                                                            }
                                                        </div>
                                                    }
                                                    {item.type == 2 &&
                                                        <Link 
                                                            to={this.markAndNavigateToChat(`${item.key}`)} 
                                                            className="btn btn-sm customPrimaryBgColor px-2 py-1 text-white rounded-pill"
                                                        >   <span className="small">View</span>
                                                        </Link>
                                                    }
                                                    {item.type == 3 &&
                                                        <button 
                                                            onClick={()=>this.markAndNavigateToProfile(`${item.origin}`, `${item.id}`)}
                                                            className="btn btn-sm customPrimaryBgColor px-2 py-1 text-white rounded-pill"
                                                        >   
                                                            <span className="small">View</span>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                )}
                            </div>:
                            <div>
                                No notification found
                            </div>
                        }
                        </div>
                    }
                    </div>:
                    <div>
                        No notification found
                    </div>
                }                 
                </div>
            </div>
        );
    }
}
const mapStateToProps=(state)=>({
    participant: state.participant,
    notificationList: state.notifications,
    countNotification: state.notificationCount,
})

const mapDispatchToProps=(dispatch)=>({
    loadNotificationsCount: (payload)=>dispatch(notificationsCounter(payload)),
    loadNotifications: (payload)=>dispatch(loadNotifications(payload)),
})

const withRouter=Child=>props=>{
	const navigate = useNavigate();
	return(
        <Child 
            {...props} 
            navigate={navigate} 
        />
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notifications));